import React from 'react';
import { Flex, Text, Spacer, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TickIcon } from 'src/icons';
import { QuestionBankChapter } from 'src/models/QuestionBank';

interface ChapterCardProps extends QuestionBankChapter {
  selectedChapters: {
    selectedChapters: number[];
    setSelectedChapters: React.Dispatch<React.SetStateAction<number[]>>;
  };
}

function ChapterCard({ id, name, questionCount, selectedChapters }: ChapterCardProps): JSX.Element {
  const { t } = useTranslation();
  const isSelected = selectedChapters.selectedChapters.includes(id);

  const onClick = () => {
    const selected = selectedChapters.selectedChapters;
    if (isSelected) {
      const index = selected.indexOf(id);
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    selectedChapters.setSelectedChapters([...selected]);
  };

  return (
    <Flex
      w="100%"
      h="fit-content"
      p={5}
      flexDirection="column"
      border="1px solid #707070"
      minH="120px"
      onClick={onClick}
      cursor="pointer"
    >
      <Text fontSize={14} fontWeight="medium">
        {name}
      </Text>
      <Spacer />
      <Flex>
        <Text fontSize={12} color="#595959">
          {questionCount} {t('questions')}
        </Text>
        <Spacer />
        {isSelected ? (
          <TickIcon boxSize="20px" />
        ) : (
          <Box w="20px" h="20px" borderRadius="full" background="#C3C3C3" />
        )}
      </Flex>
    </Flex>
  );
}

export default ChapterCard;
