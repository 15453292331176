import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Divider,
  Stack,
  useDisclosure,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DashboardShell from 'src/components/DashboardShell';
import { selectLanguage } from 'src/redux/language/selectors';
import { changeLanguage } from 'src/redux/language/actions';
import usePageTracking from '../PageTracking';

function Language(): JSX.Element {
  usePageTracking('language');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const languageFromRedux = useSelector(selectLanguage);

  const [language, setLanguage] = useState<'en' | 'ms'>('en');

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    setLanguage(languageFromRedux);
  }, [languageFromRedux]);

  const onClickClose = () => {
    onClose();
    navigate('/settings');
  };

  const onClickConfirm = () => {
    dispatch(changeLanguage(language));
    i18n.changeLanguage(language).catch(() => {
      toast({
        title: t('failed_to_change_language'),
        position: 'top',
        status: 'error',
      });
    });
    onClose();
    navigate('/dashboard');
  };

  return (
    <DashboardShell settingsScreen={true}>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={20} fontWeight="semibold">
            {t('choose_language')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalCloseButton mt={2} mr={3} onClick={onClickClose} />
          <ModalBody pb={6}>
            <RadioGroup
              defaultValue={language}
              onChange={(value: 'en' | 'ms') => setLanguage(value)}
            >
              <Stack spacing={5} direction="column" fontWeight="medium">
                <Radio colorScheme="blue" value="en">
                  English
                </Radio>
                <Radio colorScheme="blue" value="ms">
                  Bahasa Melayu
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClickClose} fontWeight="semibold" mr={3}>
              {t('cancel')}
            </Button>
            <Button onClick={onClickConfirm} fontWeight="semibold">
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardShell>
  );
}

export default Language;
