import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Img, Spacer, Text } from '@chakra-ui/react';

import {
  // BookIcon,
  CourseIcon,
  HomeIcon,
  DocumentIcon,
  GearIcon,
} from 'src/icons';
import TavisLogo from 'src/images/tavis.png';
import NavItem from './NavItem';
import { appVersion } from 'src/constants/version';

function Sidebar(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      as="nav"
      h="100vh"
      overflowX="hidden"
      overflowY="auto"
      bg="#07080F"
      color="white"
      w="300px"
    >
      <Flex alignItems="center" justifyContent="center" w="100%" h="10%">
        <Box as={Link} to="/dashboard">
          <Img src={TavisLogo} />
        </Box>
      </Flex>
      <Flex direction="column" fontSize="sm" aria-label="Main Navigation" h="90%">
        <NavItem icon={HomeIcon} to="/dashboard">
          {t('dashboard')}
        </NavItem>
        <NavItem icon={CourseIcon} to="/courses">
          {t('classes')}
        </NavItem>
        <NavItem to="/resources" icon={DocumentIcon}>
          {t('resources')}
        </NavItem>
        <NavItem icon={GearIcon} to="/settings">
          {t('settings')}
        </NavItem>
        <Spacer />
        <Flex mx="8" my="4" alignItems="center" justifyContent="space-between">
          <Text>{appVersion}</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Sidebar;
