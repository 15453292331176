import React from 'react';
import { Heading, Text, Stack } from '@chakra-ui/react';
import RightTopbar from './RightTopbar';

interface TitleTopbarProps {
  title: string;
  subtitle?: string;
  showVerifyEmailWarning?: boolean;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
}

function TitleTopbar({
  title,
  subtitle,
  keywordSearch,
  showVerifyEmailWarning,
}: TitleTopbarProps): JSX.Element {
  return (
    <RightTopbar keywordSearch={keywordSearch} showVerifyEmailWarning={showVerifyEmailWarning}>
      <Stack direction="column">
        <Heading fontSize={30} fontWeight="semibold">
          {title}
        </Heading>
        <Text fontSize={18} color="#AFAFAF">
          {subtitle}
        </Text>
      </Stack>
    </RightTopbar>
  );
}

export default TitleTopbar;
