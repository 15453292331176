import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import format from 'date-fns/format';
import { Event } from 'src/models/Event';

function PastEventCard(event: Event): JSX.Element {
  return (
    <Flex justifyContent="center" flexDirection="column">
      <Flex
        fontSize={20}
        borderLeft="3.5px solid #929292"
        p={2}
        pl={5}
        justify="center"
        direction="column"
        h="fit-content"
        color="#929292"
      >
        <Text fontWeight="bold" whiteSpace="nowrap">
          {event.courseName}
        </Text>
        <Text whiteSpace="nowrap">
          {format(new Date(event.start_time), 'HH:mm') +
            ' - ' +
            format(new Date(event.end_time), 'HH:mm')}
        </Text>
        <Text whiteSpace="nowrap" fontWeight="semibold">
          Cikgu {event.teacherFirstname + ' ' + event.teacherLastname}
        </Text>
      </Flex>
    </Flex>
  );
}

export default PastEventCard;
