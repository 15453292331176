import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Heading, Spacer } from '@chakra-ui/react';
import { compareAsc } from 'date-fns';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';

import { Event } from 'src/models/Event';
import CalendarIconButton from './CalendarIconButton';
import CalendarEventCard from './CalendarEventCard';
import NoPurchase from 'src/components/NoPurchase';

interface CalendarProps {
  events: Event[] | null;
  daysInMonth: Date[];
  onClickPreviousMonth: () => void;
  onClickNextMonth: () => void;
  date: Date;
}

function Calendar({
  events,
  daysInMonth,
  onClickPreviousMonth,
  onClickNextMonth,
  date,
}: CalendarProps): JSX.Element {
  const { t } = useTranslation();
  const calendarRef = useRef<OverlayScrollbarsComponent | null>(null);
  const [maxWidth, setMaxWidth] = useState(0);

  const today = new Date();
  const minYear = today.getFullYear() - 1;

  const isLastMonthOfCurrentYear =
    date.getMonth() === 11 && date.getFullYear() === today.getFullYear();

  const isFirstMonthOfLastYear = date.getMonth() === 0 && date.getFullYear() === minYear;

  /*
   * Scroll the calendar when the user is in current month, so that today is always visible.
   */
  useEffect(() => {
    if (events && events.length > 0) {
      const year = date.getFullYear();
      const month = date.getMonth();

      if (year === today.getFullYear() && month === today.getMonth()) {
        const currentDay = today.toLocaleDateString('en-us', { day: 'numeric' });
        const scrollTo = document.getElementById(`day${currentDay}`);
        const scrollHorizontalTo = document.getElementById('active');
        if (scrollHorizontalTo) {
          const x = scrollHorizontalTo.offsetLeft - 96;
          const y = scrollHorizontalTo.offsetTop;
          calendarRef.current?.osInstance()?.scroll({ x: x, y: y });
        } else if (scrollTo) {
          calendarRef.current?.osInstance()?.scroll(scrollTo);
        }
      } else {
        calendarRef.current?.osInstance()?.scroll(0);
      }
      const width = calendarRef.current?.osInstance()?.getState().contentScrollSize.width;
      if (width !== undefined && width > maxWidth) {
        setMaxWidth(width);
      }
    }
  }, [events]);

  return (
    <React.Fragment>
      <Flex mb={5}>
        {isFirstMonthOfLastYear ? (
          <CalendarIconButton isNextMonth={false} isDisabled={true} />
        ) : (
          <CalendarIconButton isNextMonth={false} onClick={onClickPreviousMonth} />
        )}
        <Spacer />
        <Heading fontSize={26} fontWeight="normal">
          <Box as="span" fontWeight="semibold">
            {t(date.toLocaleDateString('en-us', { month: 'long' }).toLowerCase())}
          </Box>
          &nbsp;
          {date.toLocaleDateString('en-us', { year: 'numeric' })}
        </Heading>
        <Spacer />
        {isLastMonthOfCurrentYear ? (
          <CalendarIconButton isNextMonth={true} isDisabled={true} />
        ) : (
          <CalendarIconButton isNextMonth={true} onClick={onClickNextMonth} />
        )}
      </Flex>
      {events && events.length > 0 ? (
        <OverlayScrollbarsComponent
          ref={calendarRef}
          style={{
            flex: '1',
            paddingRight: '15px',
            paddingBottom: '15px',
          }}
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
        >
          {daysInMonth.map((day: Date) => {
            const date = day.toLocaleDateString('en-us', { day: 'numeric' });
            const event = events.filter((item) => {
              const date = new Date(item.start_time);
              date.setHours(0, 0, 0, 0);
              return compareAsc(day, date) === 0;
            });
            return (
              <CalendarEventCard key={date} day={day} date={date} event={event} width={maxWidth} />
            );
          })}
        </OverlayScrollbarsComponent>
      ) : (
        <NoPurchase />
      )}
    </React.Fragment>
  );
}

export default Calendar;
