import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Text, HStack, Spinner } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { useNavigate } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import RecordedLectureCard from './RecordedLectureCard';
import FilterTab from 'src/components/FilterTab';
import {
  getMoreRecordedLectures,
  getRecordedLectures,
  resetResources,
} from 'src/redux/resources/actions';
import {
  selectRecordedLectures,
  selectRecordedLecturesLoading,
  selectRecordedLecturesNetworkError,
  selectRecordedLecturesPagination,
  selectRecordedLecturesRefetching,
  selectRecordedLecturesStatusCode,
} from 'src/redux/resources/selectors';
import { RecordedLectureQuery } from 'src/models/resources/RecordedLectureQuery';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import useSessionExpired from 'src/components/SessionExpired';
import NetworkErrorComponent from 'src/components/NetworkError';
import usePageTracking from '../PageTracking';

function RecordedLectures(): JSX.Element {
  usePageTracking('recorded-lectures');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | null>(null);

  const recordedLectures = useSelector(selectRecordedLectures);
  const recordedLecturesStatusCode = useSelector(selectRecordedLecturesStatusCode);
  const recordedLecturesNetworkError = useSelector(selectRecordedLecturesNetworkError);
  const loading = useSelector(selectRecordedLecturesLoading);
  const pagination = useSelector(selectRecordedLecturesPagination);
  const refetching = useSelector(selectRecordedLecturesRefetching);

  const availableRecordedLectures = recordedLectures?.filter((item) => item.availability);

  const getApiQuery = () => {
    const apiQuery: RecordedLectureQuery = {
      subject: selectedSubject.join(','),
      form: selectedLevel.join(','),
      price_up: maxPrice,
      price_down: minPrice,
      keyword: keyword,
    };

    return apiQuery;
  };

  useEffect(() => {
    return () => {
      dispatch(resetResources());
    };
  }, []);

  useEffect(() => {
    // Wait 0.5s before make API request
    const debounce = setTimeout(() => dispatch(getRecordedLectures(getApiQuery())), 500);
    return () => clearTimeout(debounce);
  }, [dispatch, selectedSubject, selectedLevel, minPrice, maxPrice, keyword]);

  const showContent = () => {
    if (loading || !availableRecordedLectures) {
      return (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    } else if (availableRecordedLectures.length === 0) {
      return (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          {t('no_recorded_classes')}
        </Flex>
      );
    } else {
      return (
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap="4"
          py="8"
          pl="8"
          pr="8"
          h="100%"
          w="full"
          maxW="70%"
          mb="8"
        >
          {availableRecordedLectures.map((item, position) => (
            <Waypoint key={item.id} onEnter={() => onEnter(position)}>
              <RecordedLectureCard key={item.id} lecture={item} />
            </Waypoint>
          ))}
          {refetching && (
            <Flex
              flexDirection="column"
              mx="auto"
              rounded="lg"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          )}
        </Grid>
      );
    }
  };

  const onEnter = (position: number) => {
    if (recordedLectures) {
      const hasMore = pagination?.page !== pagination?.totalPages;
      const isLastItem = position === Number(recordedLectures.length - 1);

      const apiQuery = {
        ...getApiQuery(),
        page: pagination ? pagination.page + 1 : 1,
      };

      if (isLastItem && hasMore) {
        dispatch(getMoreRecordedLectures(apiQuery));
      }
    }
  };

  useSessionExpired(recordedLecturesStatusCode);
  if (recordedLecturesNetworkError) return <NetworkErrorComponent />;

  return (
    <DashboardShell>
      <BackTopbar
        title={t('recorded_classes')}
        pathname="/resources"
        keywordSearch={{ keyword, setKeyword }}
      />
      <Flex m="3vh" mt="0" h="100%" maxH="85vh">
        <Flex flexDirection="column" w="30%" minW="30%">
          <HStack py="4" bg="white" spacing="8">
            <Box
              as="button"
              onClick={() => navigate('/resources/purchased-recorded-lectures')}
              px="4"
              py="2"
              color="#595959"
              borderBottomColor="#595959"
              borderBottomWidth="1px"
              fontSize={20}
              fontWeight="medium"
            >
              {t('purchased')}
            </Box>
            <Box
              as="button"
              onClick={() => navigate('/resources/recorded-lectures')}
              px="4"
              py="2"
              color="#65CD7D"
              borderBottomColor="#65CD7D"
              borderBottomWidth="1px"
              fontSize={20}
              fontWeight="medium"
            >
              {t('browse')}
            </Box>
          </HStack>
          <Text fontSize={18} fontWeight="medium" as="h2" mt="4">
            {t('filter')}:
          </Text>
          <FilterTab
            width="70%"
            subjectFilter={{ selectedSubject, setSelectedSubject }}
            levelFilter={{ selectedLevel, setSelectedLevel }}
            priceFilter={{ minPrice, setMinPrice, maxPrice, setMaxPrice }}
          />
        </Flex>
        <OverlayScrollbarsComponent
          style={{ width: '100%', height: '100%' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          {showContent()}
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default RecordedLectures;
