import React from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { BookmarkIcon } from 'src/icons';

interface NavigationCardProps {
  children: React.ReactNode;
  pathname: string;
  state?: unknown;
  bookmark: boolean;
  backgroundColor: string;
  color: string;
  closeModal?: () => void;
}

function NavigationCard({
  children,
  pathname,
  state,
  bookmark,
  backgroundColor,
  color,
  closeModal,
}: NavigationCardProps): JSX.Element {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(pathname, { replace: true, state: state });

    if (closeModal) {
      closeModal();
    }
  };

  return (
    <Flex
      bg={backgroundColor}
      p={2}
      borderRadius="10px"
      my={2}
      userSelect="none"
      onClick={onClick}
      cursor="pointer"
      color={color}
    >
      {children}
      <Spacer />
      {bookmark && <BookmarkIcon boxSize="22" color={color} />}
    </Flex>
  );
}

export default NavigationCard;
