import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function RedoIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        id="Practice_redo_icon"
        data-name="Practice/redo icon"
        d="M10.013,0a10,10,0,1,0,7.109,17.1l-1.8-1.8A7.51,7.51,0,1,1,9.987,2.5a7.286,7.286,0,0,1,5.232,2.275L12.491,7.5H20V0L17.021,2.975A9.963,9.963,0,0,0,9.987,0Z"
      ></path>
    </Icon>
  );
}
