import React, { useEffect, useRef, useState } from 'react';
import { Text, Flex } from '@chakra-ui/react';
import format from 'date-fns/format';
import { Event } from 'src/models/Event';
import { useTranslation } from 'react-i18next';
import { intervalToDuration, isWithinInterval } from 'date-fns';

interface EventCardProps {
  event: Event;
  isToday: boolean;
}

function EventCard({ event, isToday }: EventCardProps): JSX.Element {
  const { t } = useTranslation();

  const [masterTime, setMasterTime] = useState(event.serverTime);
  const masterTimeRef = useRef(event.serverTime);

  const today = new Date(masterTime * 1000);
  const startTime = new Date(event.start_time);
  const endTime = new Date(event.end_time);

  // Update class "starts in" countdown every 1 minute
  useEffect(() => {
    const timer = setInterval(() => {
      masterTimeRef.current = masterTimeRef.current + 60;
      setMasterTime(masterTimeRef.current);
    }, 60000);

    // clearing interval
    return () => clearInterval(timer);
  }, []);

  const showTime = () => {
    const interval = intervalToDuration({ start: today, end: startTime });
    if (
      !isWithinInterval(today, { start: startTime, end: endTime }) &&
      interval.hours != null &&
      interval.minutes != null
    ) {
      if ((interval.hours === 2 && interval.minutes === 0) || interval.hours < 2) {
        if (interval.hours > 0 && interval.minutes > 0) {
          return (
            <>{`${t('starts_in')} ${t('hour', { count: interval.hours })} ${t('minute', {
              count: interval.minutes,
            })}`}</>
          );
        } else if (interval.hours > 0) {
          return <>{`${t('starts_in')} ${t('hour', { count: interval.hours })}`}</>;
        } else if (interval.minutes > 0) {
          return <>{`${t('starts_in')} ${t('minute', { count: interval.minutes })}`}</>;
        }
      }
    }
  };

  return (
    <Flex justifyContent="center" flexDirection="column">
      <Flex
        fontSize={20}
        borderLeft={`3.5px solid ${event.courseColor}`}
        p={2}
        pl={5}
        justify="center"
        direction="column"
        h="fit-content"
      >
        <Text fontWeight="bold" whiteSpace="nowrap">
          {event.courseName}
        </Text>
        <Text whiteSpace="nowrap">
          {format(new Date(event.start_time), 'HH:mm') +
            ' - ' +
            format(new Date(event.end_time), 'HH:mm')}
        </Text>
        <Text whiteSpace="nowrap" fontWeight="semibold">
          Cikgu {event.teacherFirstname + ' ' + event.teacherLastname}
        </Text>
        {isToday && (
          <Text whiteSpace="nowrap" color="red" fontSize={16}>
            {showTime()}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export default EventCard;
