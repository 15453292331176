import { Flex, Heading } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import NavigationCard from './NavigationCard';
import { Question } from 'src/models/Question';
import { useTranslation } from 'react-i18next';

interface QuestionList {
  type: 'homework' | 'questionBank';
  questions: Question[];
  homeworkId?: string;
  subjectId?: string;
  state?: unknown;
}

function QuestionList({
  type,
  questions,
  homeworkId,
  subjectId,
  state,
}: QuestionList): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex
      bg="white"
      h="80%"
      w="100%"
      borderRadius="10px"
      flexDirection="column"
      p={5}
      boxShadow="0 0 5px gray"
    >
      <Heading mb={5} mx={5} fontSize={24} fontWeight="medium">
        {t('question_list')}
      </Heading>
      <OverlayScrollbarsComponent
        style={{ flex: '1' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Flex flexDirection="column" pr={5}>
          {questions.map((item) => {
            return (
              <NavigationCard
                key={item.id}
                backgroundColor={item.answer === null ? 'black' : '#E6E6E6'}
                color={item.answer === null ? 'white' : 'black'}
                bookmark={item.bookmark}
                pathname={
                  type === 'homework'
                    ? `/homework/${homeworkId!}/${item.id}`
                    : `/question-bank-question/${subjectId!}/${item.attemptId}/${item.id}`
                }
                state={type === 'homework' ? undefined : state}
              >
                {item.quesName}
              </NavigationCard>
            );
          })}
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default QuestionList;
