import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ConfirmIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 12.785 12.371">
      <path
        id="Path_1141"
        data-name="Path 1141"
        d="M408.495,18.4l4.02,4.247,5.98-10"
        transform="translate(-407.081 -11.276)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Icon>
  );
}
