import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function HomeIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M11,0 C11.1995692,0 11.3934277,0.0659496144 11.5508982,0.18731476 L21.6526946,7.96443391 C21.8716851,8.13277598 22,8.3918007 22,8.66627149 L22,20.8879668 C21.9980201,22.605228 20.5922274,23.9968545 18.857485,23.9988145 L14.3856675,23.9998009 C14.3792835,23.9999334 14.3728831,24 14.3664671,24 L14.347,23.998 L7.65153576,23.9998009 C7.64515177,23.9999334 7.63875141,24 7.63233533,24 L7.613,23.998 L3.14251497,23.9988145 C1.40777265,23.9968545 0.00197992469,22.605228 0,20.8879668 L0,8.66627149 C0,8.3918007 0.128314896,8.13277598 0.347305389,7.96443391 L10.4491018,0.18731476 C10.6065723,0.0659496144 10.8004308,0 11,0 Z M11,2.01541197 L1.79640719,9.10136337 L1.79640719,20.8879668 C1.79772553,21.6233687 2.39962693,22.2192047 3.14251497,22.2205098 L6.734,22.22 L6.73413174,12 C6.73413174,11.5089347 7.13627118,11.1108477 7.63233533,11.1108477 L14.3664671,11.1108477 C14.8625312,11.1108477 15.2646707,11.5089347 15.2646707,12 L15.264,22.22 L18.8550898,22.2205098 C19.5979779,22.2192047 20.1998793,21.6233687 20.2011976,20.8879668 L20.2035928,9.10136337 L11,2.01541197 Z M13.4682635,12.8891523 L8.53053892,12.8891523 L8.53,22.22 L13.468,22.22 L13.4682635,12.8891523 Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
