import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function WrongAnswerIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <g id="X_sign" data-name="X sign" transform="translate(-340 2)">
        <g id="X_mark" data-name="X mark" transform="translate(9.5 -6.5)">
          <line
            id="Line_15"
            data-name="Line 15"
            x2="8"
            y2="8"
            transform="translate(336.5 10.5)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_16"
            data-name="Line 16"
            x1="8"
            y2="8"
            transform="translate(336.5 10.5)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
        <g
          id="Ellipse_117"
          data-name="Ellipse 117"
          transform="translate(340 -2)"
          fill="none"
          stroke="red"
          strokeWidth="2"
        >
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9" fill="none" />
        </g>
      </g>
    </Icon>
  );
}
