import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function PdfIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1677" data-name="Rectangle 1677" width="20" height="26" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_375" data-name="Group 375" clipPath="url(#clip-path)">
        <path
          id="Path_1160"
          data-name="Path 1160"
          d="M41.644,4.469v21.06a.9.9,0,0,1-.9.906H24.1a.9.9,0,0,1-.9-.906V1.406A.9.9,0,0,1,24.1.5H37.689Z"
          transform="translate(-21.677 -0.467)"
          fill="#fff"
        />
        <path
          id="Path_1161"
          data-name="Path 1161"
          d="M41.644,4.469v21.06a.9.9,0,0,1-.9.906H24.1a.9.9,0,0,1-.9-.906V1.406A.9.9,0,0,1,24.1.5H37.689Z"
          transform="translate(-21.677 -0.467)"
          fill="none"
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_1162"
          data-name="Path 1162"
          d="M230.241,4.469v21.06a.9.9,0,0,1-.9.906H228.05a.9.9,0,0,0,.9-.906V4.469L225,.5h1.289Z"
          transform="translate(-210.274 -0.467)"
          fill="#e5e5e5"
        />
        <path
          id="Path_1163"
          data-name="Path 1163"
          d="M248.648,4.469H245.6a.9.9,0,0,1-.9-.906V.5Z"
          transform="translate(-228.681 -0.467)"
          fill="#cecece"
        />
        <path
          id="Path_1164"
          data-name="Path 1164"
          d="M84.454,168.837H73.736a.658.658,0,0,1,0-1.316H84.454a.658.658,0,0,1,0,1.316"
          transform="translate(-68.298 -156.52)"
          fill="#cecece"
        />
        <path
          id="Path_1165"
          data-name="Path 1165"
          d="M84.454,220.141H73.736a.658.658,0,0,1,0-1.316H84.454a.658.658,0,0,1,0,1.316"
          transform="translate(-68.298 -204.455)"
          fill="#cecece"
        />
        <path
          id="Path_1166"
          data-name="Path 1166"
          d="M84.454,271.444H73.736a.658.658,0,0,1,0-1.316H84.454a.658.658,0,0,1,0,1.316"
          transform="translate(-68.298 -252.389)"
          fill="#cecece"
        />
        <path
          id="Path_1167"
          data-name="Path 1167"
          d="M9.065,75.941H0V72.259a.5.5,0,0,1,.5-.5H9.065a.5.5,0,0,1,.5.5v3.179a.5.5,0,0,1-.5.5"
          transform="translate(-0.002 -67.044)"
          fill="#f55b4b"
        />
        <path
          id="Path_1168"
          data-name="Path 1168"
          d="M9.564,75.375v.063a.5.5,0,0,1-.5.5H0V72.259a.5.5,0,0,1,.5-.5H.759v1.309a2.306,2.306,0,0,0,2.3,2.31Z"
          transform="translate(0 -67.044)"
          fill="#dd4e43"
        />
        <path
          id="Path_1169"
          data-name="Path 1169"
          d="M0,135.484l1.518,1.737v-1.737Z"
          transform="translate(-0.002 -126.587)"
          fill="#db1b1b"
        />
        <path
          id="Path_1170"
          data-name="Path 1170"
          d="M17.926,84.828v.441h-1.37v-.441h.331V83.1h-.331V82.66h1.37a1.1,1.1,0,0,1,.739.225.759.759,0,0,1,.261.606.848.848,0,0,1-.092.395.706.706,0,0,1-.235.272.88.88,0,0,1-.327.129,2.545,2.545,0,0,1-.461.035h-.249v.506Zm-.363-.942h.1a.615.615,0,0,0,.448-.113.411.411,0,0,0,.1-.282.418.418,0,0,0-.069-.243.284.284,0,0,0-.159-.124,1.292,1.292,0,0,0-.293-.023h-.127Z"
          transform="translate(-15.473 -77.232)"
          fill="#fff"
        />
        <path
          id="Path_1171"
          data-name="Path 1171"
          d="M55.2,85.269v-.441H55.6V83.1H55.2V82.66h1.232a3.289,3.289,0,0,1,.626.048,1.114,1.114,0,0,1,.454.212,1.166,1.166,0,0,1,.348.44,1.408,1.408,0,0,1,.131.611,1.477,1.477,0,0,1-.092.519,1.229,1.229,0,0,1-.227.4,1.01,1.01,0,0,1-.324.24,1.336,1.336,0,0,1-.347.115,3.27,3.27,0,0,1-.489.026Zm1.069-.441h.163a1.172,1.172,0,0,0,.468-.077.588.588,0,0,0,.278-.271,1.067,1.067,0,0,0,.1-.507,1.11,1.11,0,0,0-.106-.506.586.586,0,0,0-.286-.287,1.152,1.152,0,0,0-.459-.079H56.27Z"
          transform="translate(-51.589 -77.232)"
          fill="#fff"
        />
        <path
          id="Path_1172"
          data-name="Path 1172"
          d="M102.385,85.269v-.441h.372V83.1h-.372V82.66h2.327v.8h-.5V83.1h-.781v.6H104v.441h-.563v.683h.4v.441Z"
          transform="translate(-95.686 -77.232)"
          fill="#fff"
        />
        <path
          id="Path_1173"
          data-name="Path 1173"
          d="M29.74,136.708H23.194v-1.224h7.569v.2a1.025,1.025,0,0,1-1.023,1.027"
          transform="translate(-21.676 -126.587)"
          fill="#e5e5e5"
        />
      </g>
    </Icon>
  );
}
