import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Flex, Box, HStack, Spinner, Text } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';

import {
  getPurchasedQuestionBank,
  getMorePurchasedQuestionBank,
  resetQuestionBank,
} from 'src/redux/questionBank/actions';
import {
  selectPurchasedQuestionBank,
  selectPurchasedQuestionBankLoading,
  selectPurchasedQuestionBankPagination,
  selectPurchasedQuestionBankRefetching,
  selectPurchasedQuestionBankStatusCode,
  selectPurchasedQuestionBankNetworkError,
} from 'src/redux/questionBank/selectors';
import DashboardShell from 'src/components/DashboardShell';
import Loader from 'src/components/Loader';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import QuestionBankCard from './QuestionBankCard';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import usePageTracking from '../PageTracking';

function PurchasedQuestionBank(): JSX.Element {
  usePageTracking('purchased-question-bank');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string | null>(null);

  const questionBank = useSelector(selectPurchasedQuestionBank);
  const refetching = useSelector(selectPurchasedQuestionBankRefetching);
  const pagination = useSelector(selectPurchasedQuestionBankPagination);
  const loading = useSelector(selectPurchasedQuestionBankLoading);
  const purchasedQuestionBankStatusCode = useSelector(selectPurchasedQuestionBankStatusCode);
  const purchasedQuestionBankNetworkError = useSelector(selectPurchasedQuestionBankNetworkError);

  useEffect(() => {
    const debounce = setTimeout(() => dispatch(getPurchasedQuestionBank({ keyword })), 500);
    return () => clearTimeout(debounce);
  }, [dispatch, keyword]);

  useEffect(() => {
    dispatch(resetQuestionBank());
  }, [dispatch]);

  useSessionExpired(purchasedQuestionBankStatusCode);
  if (purchasedQuestionBankNetworkError) return <NetworkErrorComponent />;

  if (!questionBank)
    return (
      <DashboardShell>
        <BackTopbar
          title={t('question_bank')}
          pathname="/resources"
          keywordSearch={{ keyword, setKeyword }}
        />
        <Loader />
      </DashboardShell>
    );

  const onEnter = (position: number) => {
    const hasMore = pagination?.page !== pagination?.totalPages;
    const isLastItem = position === Number(questionBank.length - 1);

    const apiQuery = {
      keyword,
      page: pagination ? pagination.page + 1 : 1,
    };

    if (isLastItem && hasMore) {
      dispatch(getMorePurchasedQuestionBank(apiQuery));
    }
  };

  const showContent = () => {
    if (loading) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    }

    if (questionBank.length === 0) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Text>{t('no_question_bank_available')}</Text>
        </Flex>
      );
    }

    return (
      <OverlayScrollbarsComponent
        style={{ height: '100%', width: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        {questionBank.map((item, position) => {
          return (
            <Waypoint key={item.id} onEnter={() => onEnter(position)}>
              <QuestionBankCard {...item} />
            </Waypoint>
          );
        })}
        {refetching && <Box p={5} mb={5}></Box>}
      </OverlayScrollbarsComponent>
    );
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={t('question_bank')}
        pathname="/resources"
        keywordSearch={{ keyword, setKeyword }}
      />
      <Flex m="3vh" mt="0" h="100%" maxH="85vh" flexDirection="column">
        <HStack py="4" bg="white" spacing="8">
          <Box
            as="button"
            onClick={() => navigate('/purchased-question-bank')}
            px="4"
            py="2"
            color="#65CD7D"
            borderBottomColor="#65CD7D"
            borderBottomWidth="1px"
            fontSize={20}
            fontWeight="medium"
          >
            {t('purchased')}
          </Box>
          <Box
            as="button"
            onClick={() => navigate('/question-bank')}
            px="4"
            py="2"
            color="#595959"
            borderBottomColor="#595959"
            borderBottomWidth="1px"
            fontSize={20}
            fontWeight="medium"
          >
            {t('browse')}
          </Box>
        </HStack>
        <OverlayScrollbarsComponent
          style={{ width: '100%', height: '100%' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          {showContent()}
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default PurchasedQuestionBank;
