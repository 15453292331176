import { ContactUsActionType, ContactUsState } from './models';
import {
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_ERROR,
  CONTACT_US_NETWORK_ERROR,
  RESET_CONTACT_US,
} from './constants';

export const contactUsInitialState: ContactUsState = {
  // Contact Us
  contactUsLoading: false,
  contactUsStatusCode: null,
  contactUsNetworkError: false,
};

export const contactUsReducer = (
  state = contactUsInitialState,
  action: ContactUsActionType,
): ContactUsState => {
  switch (action.type) {
    case CONTACT_US:
      return {
        ...state,
        contactUsLoading: true,
        contactUsStatusCode: null,
        contactUsNetworkError: false,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsLoading: false,
        contactUsStatusCode: action.payload.code,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        contactUsLoading: false,
        contactUsStatusCode: action.payload.code,
      };
    case CONTACT_US_NETWORK_ERROR:
      return {
        ...state,
        contactUsLoading: false,
        contactUsNetworkError: true,
      };
    case RESET_CONTACT_US:
      return contactUsInitialState;
    default:
      return state;
  }
};
