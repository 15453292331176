import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function TwitterIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 26 21.117" {...props}>
      <path
        id="Icon_awesome-twitter"
        data-name="Icon awesome-twitter"
        d="M23.327,8.643c.017.231.017.462.017.693A15.057,15.057,0,0,1,8.183,24.5,15.058,15.058,0,0,1,0,22.105a11.024,11.024,0,0,0,1.287.066A10.672,10.672,0,0,0,7.9,19.895,5.338,5.338,0,0,1,2.92,16.2a6.72,6.72,0,0,0,1.006.082,5.636,5.636,0,0,0,1.4-.181,5.329,5.329,0,0,1-4.273-5.23V10.8a5.367,5.367,0,0,0,2.409.676,5.337,5.337,0,0,1-1.65-7.127A15.147,15.147,0,0,0,12.8,9.93a6.015,6.015,0,0,1-.132-1.221,5.334,5.334,0,0,1,9.222-3.646,10.491,10.491,0,0,0,3.382-1.287,5.314,5.314,0,0,1-2.343,2.937A10.683,10.683,0,0,0,26,5.888a11.455,11.455,0,0,1-2.673,2.755Z"
        transform="translate(0 -3.381)"
        fill="#fff"
      />
    </Icon>
  );
}
