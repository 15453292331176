import React, { useEffect, useRef } from 'react';
import { Tr, Td, HStack, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ProgressBar from 'src/components/ProgressBar';
import { HomeworkReview } from 'src/models/homework/HomeworkReview';
import {
  selectHomeworkQuestions,
  selectHomeworkRecordQuestions,
} from 'src/redux/homework/selectors';
import { getHomeworkQuestions, getHomeworkRecord } from 'src/redux/homework/actions';

interface TableBodyProps {
  record: HomeworkReview;
  index: number;
}

function TableBody({ record, index }: TableBodyProps): JSX.Element {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ homeworkId: string }>();

  const clickStartButton = useRef(false);
  const clickReviewButton = useRef(false);
  const questions = useSelector(selectHomeworkQuestions);
  const reviewQuestions = useSelector(selectHomeworkRecordQuestions);

  useEffect(() => {
    if (clickStartButton.current && questions) {
      if (questions.length > 0) {
        navigate(`/homework/${params.homeworkId!}/${questions[0].id}`);
      } else {
        window.messageBox.alertBox(t('no_questions'));
        clickStartButton.current = false;
      }
    }
  }, [questions]);

  useEffect(() => {
    if (clickReviewButton.current && reviewQuestions) {
      navigate(`/homework-review/${params.homeworkId!}/${record.id}/${reviewQuestions[0].id}`, {
        state: { homeworkName: record.homeworkName },
      });
      clickReviewButton.current = false;
    }
  }, [reviewQuestions]);

  const onClickStart = () => {
    dispatch(getHomeworkQuestions(Number(params.homeworkId)));
    clickStartButton.current = true;
  };

  const onClickReview = () => {
    dispatch(getHomeworkRecord(record.id));
    clickReviewButton.current = true;
  };

  const showButton = () => {
    if (record.endDate) {
      return (
        <Button
          pos="unset"
          fontSize={16}
          fontWeight="medium"
          bg="#249520"
          color="white"
          _hover={{ bg: '#006600' }}
          _active={{ bg: '#006600' }}
          onClick={onClickReview}
        >
          {t('review')}
        </Button>
      );
    } else if (record.lastAttempt) {
      return (
        <Button
          pos="unset"
          fontSize={16}
          fontWeight="medium"
          bg="#194BA9"
          color="white"
          _hover={{ bg: '#002579' }}
          _active={{ bg: '#002579' }}
          onClick={onClickStart}
        >
          {t('continue')}
        </Button>
      );
    } else {
      return (
        <Button
          pos="unset"
          fontSize={16}
          fontWeight="medium"
          bg="#194BA9"
          color="white"
          _hover={{ bg: '#002579' }}
          _active={{ bg: '#002579' }}
          onClick={onClickStart}
        >
          {t('start')}
        </Button>
      );
    }
  };

  const showProgress = () => {
    const progress = Math.floor((record.answeredQuestions / record.totalQuestions) * 100);
    if (record.endDate) {
      return 100;
    } else {
      if (Number.isNaN(progress)) {
        return 0;
      } else {
        return progress;
      }
    }
  };

  return (
    <>
      <Tr fontSize={18} border="none">
        <Td borderColor="#707070" px={0}>
          {index}.
        </Td>
        <Td borderColor="#707070" px={0}>
          <HStack spacing={5}>
            <ProgressBar value={showProgress()} w="100%" pos="unset" />
            <Text fontSize={14}>{showProgress()}%</Text>
          </HStack>
        </Td>
        <Td borderColor="#707070" px={0} textAlign="center">
          {record.endDate ? new Date(record.endDate).toLocaleDateString() : 'N/A'}
        </Td>
        <Td borderColor="#707070" px={0} w="300px">
          <HStack spacing={5}>{showButton()}</HStack>
        </Td>
      </Tr>
    </>
  );
}

export default TableBody;
