import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Flex, Image, Text, Badge, HStack, Box, LinkBox, LinkOverlay } from '@chakra-ui/react';

import { Course } from 'src/models/cart/Course';
import { formatPrice } from 'src/utils/formatPrice';
import { formatClassTime } from 'src/utils/formatClassTime';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';

function PeopleAlsoBuyCard(course: Course): JSX.Element {
  const { t } = useTranslation();
  const hasDiscount = Boolean(course.discountPrice);

  return (
    <Flex as={LinkBox} w="100%" mb="4" borderBottomWidth="1px" borderBottomColor="gray.600" pb="4">
      <Image src={course.courseImageUrl || TavisPlaceholder} w="70px" h="70px" />
      <Box ml={3}>
        <LinkOverlay as={Link} to={`/courses/${course.id}`}>
          <Text fontSize={18} fontWeight="semibold">
            {course.courseName} - {course.form.name} - {format(parseISO(course.startDate), 'yyyy')}{' '}
            - Cikgu {course.teacher.firstname} {course.teacher.lastname}
          </Text>
        </LinkOverlay>
        <HStack mt="2" spacing="4">
          {course.isFeatured && (
            <Badge
              fontSize="xs"
              textAlign="center"
              fontWeight="normal"
              px="2"
              py="1"
              bg="yellow.200"
              textTransform="capitalize"
              borderRadius={5}
            >
              <Text>{t('featured')}</Text>
            </Badge>
          )}
          <Text fontSize="xs">
            {t('every')}{' '}
            {course.weekDays.map((day, index) => {
              if (course.weekDays.length - 1 > index) {
                return `${t(day.toLowerCase())}, `;
              } else {
                return t(day.toLowerCase());
              }
            })}
          </Text>
          <Text fontSize="xs">{formatClassTime(course.startDate)}</Text>
          {hasDiscount ? (
            <Flex>
              <Text textDecoration="line-through" mr="2">
                {formatPrice(String(course.price))}
              </Text>
              <Text color="red.500" fontWeight="medium">
                {formatPrice(String(course.discountPrice))}
              </Text>
            </Flex>
          ) : (
            <Text fontWeight="medium">{formatPrice(String(course.price))}</Text>
          )}
        </HStack>
      </Box>
    </Flex>
  );
}

export default PeopleAlsoBuyCard;
