import { AppActions } from '../rootActions';
import { CHANGE_LANGUAGE } from './constants';

/**
 * Functions to be called in React components
 */
export const changeLanguage = (language: 'en' | 'ms'): AppActions => ({
  type: CHANGE_LANGUAGE,
  language: language,
});
