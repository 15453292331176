import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { Editor as tinyEditor } from '@tinymce/tinymce-react/node_modules/tinymce/tinymce';
import {
  Flex,
  Text,
  Box,
  HStack,
  Spacer,
  Stack,
  Heading,
  Divider,
  Input,
  IconButton,
  AspectRatio,
  Image,
  useToast,
} from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocation } from 'react-router-dom';

import MathType from 'src/containers/MathType';
import { Answer } from 'src/models/Answer';
import { BookmarkIcon } from 'src/icons';
import TopButton from './TopButton';
import BottomButton from './BottomButton';
import ProgressBar from '../ProgressBar';
import CheckboxOptionGroup from './CheckboxOptionGroup';
import { Question as HomeworkQuestion } from 'src/models/Question';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';

require('tinymce');
require('@wiris/mathtype-tinymce5');

interface QuestionProps {
  currentQuestion: number;
  totalQuestions: number;
  progress?: number;
  question: HomeworkQuestion;
  // Save answer temporarily if users selected/wrote an answer but didn't submit the answer
  tempQuestion?: HomeworkQuestion;
  onClickPreviousQuestion: () => void;
  onClickNextQuestion: () => void;
  setFinalAnswer?: React.Dispatch<React.SetStateAction<string[] | null>>;
  bookmark?: boolean | null;
  setBookmark?: React.Dispatch<React.SetStateAction<boolean | null>>;
  marks?: number | null;
  setMarks?: React.Dispatch<React.SetStateAction<number | null>>;
}

function Question({
  currentQuestion,
  totalQuestions,
  progress,
  question,
  tempQuestion,
  onClickPreviousQuestion,
  onClickNextQuestion,
  setFinalAnswer,
  bookmark,
  setBookmark,
  marks,
  setMarks,
}: QuestionProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const toast = useToast();

  const maximumScoreToastId = 'maximumScore';
  const emptyAnswerToastId = 'emptyAnswer';

  const isReview = location.pathname.includes('review');

  const checkSubmitAnswer = () => {
    if (question.answer || isReview) {
      return true;
    } else {
      return false;
    }
  };

  const [showSubmitMarksButton, setShowSubmitMarksButton] = useState(false);
  const [answer, setAnswer] = useState<string[] | null>(tempQuestion?.answer || question.answer);
  const [submitAnswer, setSubmitAnswer] = useState(checkSubmitAnswer());
  const [options, setOptions] = useState<Answer[]>(question.options);
  const [showAnswer, setShowAnswer] = useState(isReview);
  const editorRef = useRef<tinyEditor>();

  useEffect(() => {
    if (question.shuffleAnswers) {
      const shuffleOptions = JSON.parse(JSON.stringify(question.options)) as [];
      let currentIndex = shuffleOptions.length;
      let randomIndex;

      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffleOptions[currentIndex], shuffleOptions[randomIndex]] = [
          shuffleOptions[randomIndex],
          shuffleOptions[currentIndex],
        ];
      }

      setOptions(shuffleOptions);
    }
  }, []);

  const correctAnswer = question.options.filter((item) => {
    for (let i = 0; i < question.correctAnsId.length; i++) {
      if (item.id === question.correctAnsId[i]) {
        return true;
      }
    }
    return false;
  });

  const editor = () => {
    return (
      <Flex h="500px" minH="300px" flexDirection="column">
        <Text fontSize={18} fontWeight="medium">
          {t('answer')}:
        </Text>
        <Box flex="1">
          <Editor
            key={question.id}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onChange={() => {
              if (tempQuestion) {
                tempQuestion.answer = [editorRef.current?.getContent() || ''];
              }
            }}
            init={{
              height: '100%',
              menubar: true,
              branding: false,
              external_plugins: {
                tiny_mce_wiris: '@wiris/mathtype-tinymce5/plugin.min.js',
              },
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount emoticons hr',
                'imagetools',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: function (cb) {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                  if (input.files) {
                    const file = input.files[0];
                    const reader = new FileReader();

                    reader.onload = function () {
                      if (editorRef.current) {
                        const id = 'blobid' + new Date().getTime().toString();
                        const blobCache = editorRef.current.editorUpload.blobCache;
                        const result = reader.result as string;
                        const base64 = result.split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                      }
                    };
                    reader.readAsDataURL(file);
                  }
                };
                input.click();
              },
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              init_instance_callback: function (editor: tinyEditor) {
                const freeTiny = document.querySelector(
                  '.tox .tox-notification--in',
                ) as HTMLDivElement;

                if (freeTiny) {
                  freeTiny.style.display = 'none';
                }

                if (isReview) {
                  editorRef.current?.mode.set('readonly');
                }

                if (answer) {
                  editor.setContent(answer[0]);
                  if (question.answer) {
                    editorRef.current?.mode.set('readonly');
                  }
                }
              },
            }}
          />
        </Box>
      </Flex>
    );
  };

  const setCheckboxAnswer = (ans: string[]) => {
    if (ans.length === 0) {
      setAnswer(null);
      return;
    }
    setAnswer(ans);
    if (tempQuestion) {
      tempQuestion.answer = ans;
    }
  };

  const onClickSubmit = () => {
    if (setFinalAnswer) {
      if (question.quesType === 'Essay') {
        let ans = editorRef.current?.getContent().trim();
        if (ans?.includes('math')) {
          editorRef.current?.mode.set('readonly');
          setFinalAnswer([editorRef.current?.getContent() || '']);
        } else {
          ans = editorRef.current?.getContent({ format: 'text' }).trim();
          if (ans?.length === 0 && question.responseRequired) {
            if (!toast.isActive(emptyAnswerToastId)) {
              toast({
                id: emptyAnswerToastId,
                title: 'Answer cannot empty',
                position: 'top',
                status: 'error',
              });
            }
            return;
          } else {
            editorRef.current?.mode.set('readonly');
            setFinalAnswer([editorRef.current?.getContent() || '']);
          }
        }
      } else {
        setFinalAnswer(answer || ['']);
      }
    }
    setSubmitAnswer(true);
  };

  const onClickShowAnswer = () => {
    setShowAnswer(true);
  };

  const onClickBookmark = () => {
    if (bookmark !== null && setBookmark) {
      setBookmark(!bookmark);
    }
  };

  const onClickSubmitMarks = () => {
    const input = document.getElementById('marksInput') as HTMLInputElement;
    const marksInput = parseInt(input.value);

    if (marksInput <= question.defaultmark) {
      setShowSubmitMarksButton(false);
      if (setMarks) {
        setMarks(marksInput);
      }
    } else {
      if (!toast.isActive(maximumScoreToastId)) {
        toast({
          id: maximumScoreToastId,
          title: 'Marks cannot greater than maximum marks',
          position: 'top',
          status: 'error',
        });
      }
    }
  };

  const onChangeMarks = () => {
    const input = document.getElementById('marksInput') as HTMLInputElement;

    if (input.value.length > 0 && !showSubmitMarksButton) {
      setShowSubmitMarksButton(true);
    } else if (input.value.length <= 0 && showSubmitMarksButton) {
      setShowSubmitMarksButton(false);
    }
  };

  const showQuestionBlock = () => {
    return (
      <Box mt={3} mb={5} fontSize={20} fontWeight="medium">
        <Box mb={3} dangerouslySetInnerHTML={{ __html: question.quesText }}></Box>
        {question.imageUrl && (
          <Image m="auto" maxW="500px" src={question.imageUrl} fallbackSrc={TavisPlaceholder} />
        )}
        {question.videoUrl && (
          <AspectRatio maxW="500px" ratio={16 / 9} m="auto">
            <Box
              as="video"
              width="full"
              height="full"
              controls
              poster={question.videoThumbnail || TavisPlaceholder}
              controlsList="nodownload"
            >
              <source src={question.videoUrl} />
            </Box>
          </AspectRatio>
        )}
      </Box>
    );
  };

  const showAnswerBlock = () => {
    if (question.quesType === 'Essay') {
      return editor();
    } else if (question.quesType === 'Mcq') {
      return (
        <Stack direction="column" w="100%" spacing={3}>
          <CheckboxOptionGroup
            key={question.id}
            selectedAnswer={answer}
            correctAnswer={correctAnswer}
            options={options}
            onChange={setCheckboxAnswer}
            submitAnswer={submitAnswer}
            showAnswer={showAnswer}
            numbering={question.answerNumbering}
            isSingle={question.single}
          />
        </Stack>
      );
    }
  };

  const getSelectedAnswer = () => {
    const selectedAnswer: Answer[] = [];
    answer?.forEach((ans) => {
      const selected = options.filter((option) => option.id === Number(ans));
      selectedAnswer.push(selected[0]);
    });
    return selectedAnswer;
  };

  const showExplanation = () => {
    if (showAnswer) {
      return (
        <Box mt={3}>
          <Text
            fontSize={14}
            fontWeight="medium"
            mb={3}
            dangerouslySetInnerHTML={{ __html: question.generalFeedback }}
          ></Text>
          {question.quesType === 'Essay' && (
            <Box
              fontSize={14}
              fontWeight="medium"
              mb={3}
              dangerouslySetInnerHTML={{ __html: correctAnswer[0].answer }}
            ></Box>
          )}
          {question.quesType === 'Mcq' &&
            getSelectedAnswer().map((ans) => {
              return (
                <Text
                  key={ans.id}
                  fontSize={14}
                  fontWeight="medium"
                  mb={3}
                  dangerouslySetInnerHTML={{ __html: ans.description }}
                ></Text>
              );
            })}
          {question.quesType === 'Essay' && (
            <HStack spacing={3}>
              <Text fontWeight="medium">{t('self_marking')}:</Text>
              <Input
                id="marksInput"
                type="number"
                min={0}
                max={question.defaultmark}
                w="fit-content"
                defaultValue={marks || question.mark || ''}
                disabled={marks !== null || isReview}
                onChange={onChangeMarks}
              />
              <Text fontSize={20} fontWeight="medium">
                /{question.defaultmark}
              </Text>
              <Spacer />
              {showSubmitMarksButton && (
                <BottomButton onClick={onClickSubmitMarks}>{t('submit_marks')}</BottomButton>
              )}
            </HStack>
          )}
        </Box>
      );
    }
  };

  const showButton = () => {
    if (!submitAnswer && (question.quesType === 'Essay' || answer !== null)) {
      return (
        <Flex justifyContent="flex-end" mt={3}>
          <BottomButton onClick={onClickSubmit}>{t('submit_answer')}</BottomButton>
        </Flex>
      );
    } else if (submitAnswer && currentQuestion < totalQuestions - 1) {
      return (
        <Flex justifyContent="flex-end" mt={3}>
          <HStack spacing={5}>
            {!showAnswer && (
              <BottomButton onClick={onClickShowAnswer}>{t('show_answer')}</BottomButton>
            )}
            <BottomButton onClick={onClickNextQuestion}>{t('next_question')}</BottomButton>
          </HStack>
        </Flex>
      );
    } else if (submitAnswer) {
      return (
        <Flex justifyContent="flex-end" mt={3}>
          {!showAnswer && (
            <BottomButton onClick={onClickShowAnswer}>{t('show_answer')}</BottomButton>
          )}
        </Flex>
      );
    }
  };

  return (
    <MathType h="100%" w="70%">
      <Flex
        bg="white"
        h="100%"
        w="100%"
        borderRadius="10px"
        p={10}
        overflow="hidden"
        flexDirection="column"
        boxShadow="0 0 5px gray"
      >
        <Flex>
          <Box>
            <Heading fontSize={24} fontWeight="regular">
              {`${t('question')} ${(
                currentQuestion + 1
              ).toString()} of ${totalQuestions.toString()}`}
            </Heading>
            <Text fontSize={14} fontWeight="regular" color="#AFAFAF">
              {t('question')} ID: {question.quesId}
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={5}>
            {!isReview && (
              <IconButton
                aria-label="bookmark"
                icon={<BookmarkIcon boxSize="22" filled={bookmark !== null ? bookmark : false} />}
                bg="white"
                onClick={onClickBookmark}
              />
            )}
            {currentQuestion !== 0 ? (
              <TopButton isNextQuestion={false} onClick={onClickPreviousQuestion} />
            ) : (
              <TopButton isNextQuestion={false} isDisabled={true} />
            )}
            {currentQuestion !== totalQuestions - 1 ? (
              <TopButton isNextQuestion={true} onClick={onClickNextQuestion} />
            ) : (
              <TopButton isNextQuestion={true} isDisabled={true} />
            )}
          </HStack>
        </Flex>
        {progress !== undefined ? (
          <Box my={5}>
            <ProgressBar value={progress} />
          </Box>
        ) : (
          <Divider my={4} orientation="horizontal" borderColor="#707070" />
        )}
        <OverlayScrollbarsComponent
          style={{ flex: '1' }}
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflowBehavior: { x: 'scroll', y: 'scroll' },
          }}
        >
          <Flex h="100%" w="100%" flexDirection="column">
            {showQuestionBlock()}
            {showAnswerBlock()}
            {showExplanation()}
            <Spacer />
            {showButton()}
          </Flex>
        </OverlayScrollbarsComponent>
      </Flex>
    </MathType>
  );
}

export default React.memo(Question);
