import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logout } from 'src/redux/auth/actions';

function useSessionExpired(statusCode: number | null): void {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (statusCode === 401) {
      window.messageBox.alertBox(t('session_expired'));
      dispatch(logout());
      navigate('/');
    }
  }, [statusCode, dispatch, navigate]);
}

export default useSessionExpired;
