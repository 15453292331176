import { QuestionBankQuery } from 'src/models/QuestionBank';
import { AppActions } from '../rootActions';
import {
  GET_QUESTION_BANK,
  GET_MORE_QUESTION_BANK,
  GET_QUESTION,
  SAVE_ANSWER,
  GET_PURCHASED_QUESTION_BANK,
  GET_MORE_PURCHASED_QUESTION_BANK,
  RESET_QUESTION_BANK,
  RESET_SAVE_ANSWER,
  GET_QUESTION_BANK_DETAILS,
  GET_QUESTION_BANK_CHAPTERS,
  SUBMIT_NEW_ATTEMPT,
  DELETE_ATTEMPT,
  BOOKMARK_QUESTION,
  RESET_BOOKMARK,
  UPDATE_TIMER,
  END_QUESTION_BANK,
  GET_ATTEMPT_DETAILS,
  EDIT_ATTEMPT,
  GET_QUESTION_BANK_PERFORMANCE,
  GET_QUESTION_BANK_MAX_QUESTIONS,
} from './constants';
import {
  EditAttemptPayload,
  EndQuestionBankPayload,
  MaxQuestionsPayload,
  NewAttemptPayload,
  QuestionBankDetailsPayload,
  SaveAnswerPayload,
  UpdateTimerPayload,
} from './models';

/**
 * Functions to be called in React components
 */

export const getQuestionBank = (query: QuestionBankQuery): AppActions => ({
  type: GET_QUESTION_BANK,
  payload: query,
});

export const resetQuestionBank = (): AppActions => ({ type: RESET_QUESTION_BANK });

export const getMoreQuestionBank = (query: QuestionBankQuery): AppActions => ({
  type: GET_MORE_QUESTION_BANK,
  payload: query,
});

export const getPurchasedQuestionBank = (query: QuestionBankQuery): AppActions => ({
  type: GET_PURCHASED_QUESTION_BANK,
  payload: query,
});

export const getMorePurchasedQuestionBank = (query: QuestionBankQuery): AppActions => ({
  type: GET_MORE_PURCHASED_QUESTION_BANK,
  payload: query,
});

export const getQuestion = (payload: {
  questionBankId: number;
  attemptId: number;
}): AppActions => ({
  type: GET_QUESTION,
  payload: payload,
});

export const getQuestionBankDetails = (payload: QuestionBankDetailsPayload): AppActions => ({
  type: GET_QUESTION_BANK_DETAILS,
  payload: payload,
});

export const getQuestionBankChapters = (id: number): AppActions => ({
  type: GET_QUESTION_BANK_CHAPTERS,
  id: id,
});

export const getQuestionBankMaxQuestions = (payload: MaxQuestionsPayload): AppActions => ({
  type: GET_QUESTION_BANK_MAX_QUESTIONS,
  payload: payload,
});

export const submitNewAttempt = (payload: NewAttemptPayload): AppActions => ({
  type: SUBMIT_NEW_ATTEMPT,
  payload: payload,
});

export const deleteAttempt = (id: number): AppActions => ({
  type: DELETE_ATTEMPT,
  id: id,
});

export const bookmarkQuestion = (payload: {
  questionId: number;
  bookmark: boolean;
}): AppActions => ({
  type: BOOKMARK_QUESTION,
  payload: payload,
});

export const resetBookmark = (): AppActions => ({
  type: RESET_BOOKMARK,
});

export const saveAnswer = (payload: SaveAnswerPayload): AppActions => ({
  type: SAVE_ANSWER,
  payload: payload,
});

export const resetSaveAnswer = (): AppActions => ({
  type: RESET_SAVE_ANSWER,
});

export const updateTimer = (payload: UpdateTimerPayload): AppActions => ({
  type: UPDATE_TIMER,
  payload: payload,
});

export const endQuestionBank = (payload: EndQuestionBankPayload): AppActions => ({
  type: END_QUESTION_BANK,
  payload: payload,
});

export const getAttemptDetails = (id: number): AppActions => ({
  type: GET_ATTEMPT_DETAILS,
  id: id,
});

export const editAttempt = (payload: EditAttemptPayload): AppActions => ({
  type: EDIT_ATTEMPT,
  payload: payload,
});

export const getQuestionBankPerformance = (id: number): AppActions => ({
  type: GET_QUESTION_BANK_PERFORMANCE,
  id: id,
});
