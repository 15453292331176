import { QuestionBankActionType, QuestionBankState } from './models';
import * as QBConstants from './constants';

export const questionBankInitialState: QuestionBankState = {
  // Question bank
  questionBank: null,
  questionBankLoading: false,
  questionBankStatusCode: null,
  questionBankRefetching: false,
  questionBankPagination: undefined,
  questionBankNetworkError: false,

  // Purchased question bank
  purchasedQuestionBank: null,
  purchasedQuestionBankLoading: false,
  purchasedQuestionBankStatusCode: null,
  purchasedQuestionBankRefetching: false,
  purchasedQuestionBankPagination: undefined,
  purchasedQuestionBankNetworkError: false,

  // Question bank details
  questionBankDetails: null,
  questionBankDetailsStatusCode: null,
  questionBankDetailsLoading: false,
  questionBankDetailsNetworkError: false,

  // Question bank chapters
  questionBankChapters: null,
  questionBankChaptersStatusCode: null,
  questionBankChaptersLoading: false,
  questionBankChaptersNetworkError: false,

  // Max questions in new attempt
  maxQuestions: null,
  maxQuestionsStatusCode: null,
  maxQuestionLoading: false,
  maxQuestionNetworkError: false,

  // Submit new attempt
  submitNewAttemptStatusCode: null,
  submitNewAttemptLoading: false,
  submitNewAttemptNetworkError: false,

  // Delete attempt
  deleteAttemptStatusCode: null,
  deleteAttemptLoading: false,
  deleteAttemptNetworkError: false,

  // Question
  question: null,
  questionLoading: false,
  questionStatusCode: null,
  questionNetworkError: false,

  // Bookmark
  bookmarkLoading: false,
  bookmarkStatusCode: null,
  bookmarkNetworkError: false,

  // Save Answer
  saveAnswerLoading: false,
  saveAnswerStatusCode: null,
  saveAnswerNetworkError: false,

  // Update timer
  updateTimerLoading: false,
  updateTimerStatusCode: null,
  updateTimerNetworkError: false,

  // End question bank
  endQuestionBankLoading: false,
  endQuestionBankStatusCode: null,
  endQuestionBankNetworkError: false,

  // Get attempt details
  attemptData: null,
  getAttemptDetailsLoading: false,
  getAttemptDetailsStatusCode: null,
  getAttemptDetailsNetworkError: false,

  // Edit attempt
  editAttemptLoading: false,
  editAttemptStatusCode: null,
  editAttemptNetworkError: false,

  // Get question bank performance
  questionBankPerformance: null,
  questionBankPerformanceLoading: false,
  questionBankPerformanceStatusCode: null,
  questionBankPerformanceNetworkError: false,
};

export const questionBankReducer = (
  state = questionBankInitialState,
  action: QuestionBankActionType,
): QuestionBankState => {
  switch (action.type) {
    case QBConstants.GET_QUESTION_BANK:
      return {
        ...state,
        questionBankStatusCode: null,
        questionBankLoading: true,
        questionBankNetworkError: false,
      };
    case QBConstants.GET_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        questionBankLoading: false,
        questionBankStatusCode: action.payload.code,
        questionBank: action.payload.data.objects,
        questionBankPagination: action.payload.pagination,
      };
    case QBConstants.GET_QUESTION_BANK_ERROR:
      return {
        ...state,
        questionBankLoading: false,
        questionBankStatusCode: action.payload.code,
      };
    case QBConstants.GET_QUESTION_BANK_NETWORK_ERROR:
      return {
        ...state,
        questionBankLoading: false,
        questionBankNetworkError: true,
      };
    case QBConstants.RESET_QUESTION_BANK:
      return questionBankInitialState;
    case QBConstants.GET_MORE_QUESTION_BANK:
      return {
        ...state,
        questionBankStatusCode: null,
        questionBankRefetching: true,
      };
    case QBConstants.GET_MORE_QUESTION_BANK_SUCCESS: {
      if (!state.questionBank) {
        return {
          ...state,
          questionBankStatusCode: action.payload.code,
        };
      }

      return {
        ...state,
        questionBankRefetching: false,
        questionBankStatusCode: action.payload.code,
        questionBank: [...state.questionBank, ...action.payload.data.objects],
        questionBankPagination: action.payload.pagination,
      };
    }
    case QBConstants.GET_PURCHASED_QUESTION_BANK:
      return {
        ...state,
        purchasedQuestionBankNetworkError: false,
        purchasedQuestionBankStatusCode: null,
        purchasedQuestionBankLoading: true,
      };
    case QBConstants.GET_PURCHASED_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        purchasedQuestionBankStatusCode: action.payload.code,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBank: action.payload.data.objects,
        purchasedQuestionBankPagination: action.payload.pagination,
      };
    case QBConstants.GET_PURCHASED_QUESTION_BANK_ERROR:
      return {
        ...state,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBankStatusCode: action.payload.code,
      };
    case QBConstants.GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR:
      return {
        ...state,
        purchasedQuestionBankLoading: false,
        purchasedQuestionBankNetworkError: true,
      };
    case QBConstants.GET_MORE_PURCHASED_QUESTION_BANK:
      return {
        ...state,
        purchasedQuestionBankStatusCode: null,
        purchasedQuestionBankRefetching: true,
      };
    case QBConstants.GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS: {
      if (!state.purchasedQuestionBank) {
        return {
          ...state,
          purchasedQuestionBankStatusCode: action.payload.code,
        };
      }

      return {
        ...state,
        purchasedQuestionBankRefetching: false,
        purchasedQuestionBankStatusCode: action.payload.code,
        purchasedQuestionBank: [...state.purchasedQuestionBank, ...action.payload.data.objects],
        purchasedQuestionBankPagination: action.payload.pagination,
      };
    }
    case QBConstants.GET_QUESTION: {
      return {
        ...state,
        questionNetworkError: false,
        questionLoading: true,
        questionStatusCode: null,
      };
    }
    case QBConstants.GET_QUESTION_SUCCESS: {
      return {
        ...state,
        questionLoading: false,
        questionStatusCode: action.payload.code,
        question: action.payload.data.objects,
      };
    }
    case QBConstants.GET_QUESTION_ERROR:
      return {
        ...state,
        questionLoading: false,
        questionStatusCode: action.payload.code,
      };
    case QBConstants.GET_QUESTION_NETWORK_ERROR:
      return {
        ...state,
        questionLoading: false,
        questionNetworkError: true,
      };
    case QBConstants.GET_QUESTION_BANK_DETAILS:
      return {
        ...state,
        questionBankDetails: null,
        questionBankDetailsStatusCode: null,
        questionBankDetailsLoading: true,
        questionBankDetailsNetworkError: false,
      };
    case QBConstants.GET_QUESTION_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        questionBankDetails: action.payload.data.objects,
        questionBankDetailsStatusCode: action.payload.code,
        questionBankDetailsLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_DETAILS_ERROR:
      return {
        ...state,
        questionBankDetailsStatusCode: action.payload.code,
        questionBankDetailsLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        questionBankDetailsLoading: false,
        questionBankDetailsNetworkError: true,
      };
    case QBConstants.GET_QUESTION_BANK_CHAPTERS:
      return {
        ...state,
        questionBankChapters: null,
        questionBankChaptersStatusCode: null,
        questionBankChaptersLoading: true,
        questionBankChaptersNetworkError: false,
      };
    case QBConstants.GET_QUESTION_BANK_CHAPTERS_SUCCESS:
      return {
        ...state,
        questionBankChapters: action.payload.data.objects.chapters,
        questionBankChaptersStatusCode: action.payload.code,
        questionBankChaptersLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_CHAPTERS_ERROR:
      return {
        ...state,
        questionBankChaptersStatusCode: action.payload.code,
        questionBankChaptersLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_CHAPTERS_NETWORK_ERROR:
      return {
        ...state,
        questionBankChaptersLoading: false,
        questionBankChaptersNetworkError: true,
      };
    case QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS:
      return {
        ...state,
        maxQuestions: null,
        maxQuestionsStatusCode: null,
        maxQuestionLoading: true,
        maxQuestionNetworkError: false,
      };
    case QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_SUCCESS:
      return {
        ...state,
        maxQuestions: action.payload.data.objects.maxQuestionCount,
        maxQuestionsStatusCode: action.payload.code,
        maxQuestionLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_ERROR:
      return {
        ...state,
        maxQuestionsStatusCode: action.payload.code,
        maxQuestionLoading: false,
      };
    case QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_NETWORK_ERROR:
      return {
        ...state,
        maxQuestionLoading: false,
        maxQuestionNetworkError: true,
      };
    case QBConstants.SUBMIT_NEW_ATTEMPT:
      return {
        ...state,
        submitNewAttemptStatusCode: null,
        submitNewAttemptLoading: true,
        submitNewAttemptNetworkError: false,
      };
    case QBConstants.SUBMIT_NEW_ATTEMPT_SUCCESS:
      return {
        ...state,
        question: action.payload.data.objects,
        submitNewAttemptStatusCode: action.payload.code,
        submitNewAttemptLoading: false,
      };
    case QBConstants.SUBMIT_NEW_ATTEMPT_ERROR:
      return {
        ...state,
        submitNewAttemptStatusCode: action.payload.code,
        submitNewAttemptLoading: false,
      };
    case QBConstants.SUBMIT_NEW_ATTEMPT_NETWORK_ERROR:
      return {
        ...state,
        submitNewAttemptLoading: false,
        submitNewAttemptNetworkError: true,
      };
    case QBConstants.DELETE_ATTEMPT:
      return {
        ...state,
        deleteAttemptStatusCode: null,
        deleteAttemptLoading: true,
        deleteAttemptNetworkError: false,
      };
    case QBConstants.DELETE_ATTEMPT_SUCCESS:
      return {
        ...state,
        deleteAttemptStatusCode: action.payload.code,
        deleteAttemptLoading: false,
      };
    case QBConstants.DELETE_ATTEMPT_ERROR:
      return {
        ...state,
        deleteAttemptStatusCode: action.payload.code,
        deleteAttemptLoading: false,
      };
    case QBConstants.DELETE_ATTEMPT_NETWORK_ERROR:
      return {
        ...state,
        deleteAttemptLoading: false,
        deleteAttemptNetworkError: true,
      };
    case QBConstants.BOOKMARK_QUESTION:
      return {
        ...state,
        bookmarkLoading: true,
        question:
          state.question?.map((item) =>
            item.id !== action.payload.questionId
              ? item
              : { ...item, bookmark: action.payload.bookmark },
          ) || null,
      };
    case QBConstants.BOOKMARK_QUESTION_SUCCESS:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: action.payload.code,
      };
    case QBConstants.BOOKMARK_QUESTION_ERROR:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: action.payload.code,
      };
    case QBConstants.BOOKMARK_QUESTION_NETWORK_ERROR:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkNetworkError: true,
      };
    case QBConstants.RESET_BOOKMARK:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkStatusCode: null,
        bookmarkNetworkError: false,
      };
    case QBConstants.SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: true,
        question:
          state.question?.map((item) =>
            item.id !== action.payload.questionId
              ? item
              : { ...item, answer: action.payload.answer, mark: action.payload.mark },
          ) || null,
      };
    case QBConstants.SAVE_ANSWER_SUCCESS:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
      };
    case QBConstants.SAVE_ANSWER_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: action.payload.code,
      };
    case QBConstants.SAVE_ANSWER_NETWORK_ERROR:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerNetworkError: true,
      };
    case QBConstants.RESET_SAVE_ANSWER:
      return {
        ...state,
        saveAnswerLoading: false,
        saveAnswerStatusCode: null,
        saveAnswerNetworkError: false,
      };
    case QBConstants.UPDATE_TIMER:
      return {
        ...state,
        updateTimerLoading: true,
        updateTimerStatusCode: null,
        updateTimerNetworkError: false,
      };
    case QBConstants.UPDATE_TIMER_SUCCESS:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerStatusCode: action.payload.code,
      };
    case QBConstants.UPDATE_TIMER_ERROR:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerStatusCode: action.payload.code,
      };
    case QBConstants.UPDATE_TIMER_NETWORK_ERROR:
      return {
        ...state,
        updateTimerLoading: false,
        updateTimerNetworkError: true,
      };
    case QBConstants.END_QUESTION_BANK:
      return {
        ...state,
        endQuestionBankLoading: true,
        endQuestionBankStatusCode: null,
        endQuestionBankNetworkError: false,
      };
    case QBConstants.END_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        endQuestionBankLoading: false,
        endQuestionBankStatusCode: action.payload.code,
      };
    case QBConstants.END_QUESTION_BANK_ERROR:
      return {
        ...state,
        endQuestionBankLoading: false,
        endQuestionBankStatusCode: action.payload.code,
      };
    case QBConstants.END_QUESTION_BANK_NETWORK_ERROR:
      return {
        ...state,
        endQuestionBankLoading: false,
        endQuestionBankNetworkError: true,
      };
    case QBConstants.GET_ATTEMPT_DETAILS:
      return {
        ...state,
        attemptData: null,
        question: null,
        getAttemptDetailsLoading: true,
        getAttemptDetailsStatusCode: null,
        getAttemptDetailsNetworkError: false,
      };
    case QBConstants.GET_ATTEMPT_DETAILS_SUCCESS:
      return {
        ...state,
        attemptData: action.payload.data.objects.attemptData,
        question: action.payload.data.objects.questions,
        getAttemptDetailsLoading: false,
        getAttemptDetailsStatusCode: action.payload.code,
      };
    case QBConstants.GET_ATTEMPT_DETAILS_ERROR:
      return {
        ...state,
        getAttemptDetailsLoading: false,
        getAttemptDetailsStatusCode: action.payload.code,
      };
    case QBConstants.GET_ATTEMPT_DETAILS_NETWORK_ERROR:
      return {
        ...state,
        endQuestionBankLoading: false,
        getAttemptDetailsNetworkError: true,
      };
    case QBConstants.EDIT_ATTEMPT:
      return {
        ...state,
        editAttemptLoading: true,
        editAttemptStatusCode: null,
        editAttemptNetworkError: false,
      };
    case QBConstants.EDIT_ATTEMPT_SUCCESS:
      return {
        ...state,
        editAttemptLoading: false,
        editAttemptStatusCode: action.payload.code,
      };
    case QBConstants.EDIT_ATTEMPT_ERROR:
      return {
        ...state,
        editAttemptLoading: false,
        editAttemptStatusCode: action.payload.code,
      };
    case QBConstants.EDIT_ATTEMPT_NETWORK_ERROR:
      return {
        ...state,
        editAttemptLoading: false,
        editAttemptNetworkError: true,
      };
    case QBConstants.GET_QUESTION_BANK_PERFORMANCE:
      return {
        ...state,
        questionBankPerformance: null,
        questionBankPerformanceLoading: true,
        questionBankPerformanceStatusCode: null,
        questionBankPerformanceNetworkError: false,
      };
    case QBConstants.GET_QUESTION_BANK_PERFORMANCE_SUCCESS:
      return {
        ...state,
        questionBankPerformance: action.payload.data.objects,
        questionBankPerformanceLoading: false,
        questionBankPerformanceStatusCode: action.payload.code,
      };
    case QBConstants.GET_QUESTION_BANK_PERFORMANCE_ERROR:
      return {
        ...state,
        questionBankPerformanceLoading: false,
        questionBankPerformanceStatusCode: action.payload.code,
      };
    case QBConstants.GET_QUESTION_BANK_PERFORMANCE_NETWORK_ERROR:
      return {
        ...state,
        questionBankPerformanceLoading: false,
        questionBankPerformanceNetworkError: true,
      };
    default:
      return state;
  }
};
