import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Flex, Button } from '@chakra-ui/react';

import { GoogleIcon } from 'src/icons';
import { loginWithGoogle } from 'src/redux/auth/actions';
import { GoogleProfile } from 'src/models/SocialLogin';

interface SignInWithGoogleProps {
  setFirstLogin: () => void;
}

function SignInWithGoogle({ setFirstLogin }: SignInWithGoogleProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    window.auth.signInWithGoogle();
    window.auth.googleResponse((profile: GoogleProfile) => {
      const { sub, email, given_name, family_name } = profile;
      setFirstLogin();
      dispatch(
        loginWithGoogle({
          sub,
          email,
          given_name,
          family_name,
          role: 'student',
        }),
      );
    });
  };

  return (
    <Button
      minW="fit-content"
      w="300px"
      color="black"
      bg="white"
      fontWeight="regular"
      rounded="full"
      justifyContent="flex-start"
      onClick={onClick}
    >
      <GoogleIcon />
      <Flex justifyContent="center" flex="1">
        {t('sign_in_with', { provider: 'Google' })}
      </Flex>
    </Button>
  );
}

export default SignInWithGoogle;
