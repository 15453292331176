import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ResourcesHomeworkIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 33.69 38.941" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-0.151"
          y1="-0.042"
          x2="-0.104"
          y2="-0.042"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d7ebfb" />
          <stop offset="1" stopColor="#cbe8ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.716"
          y1="-6.661"
          x2="-0.629"
          y2="-6.661"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#90d1f3" />
          <stop offset="1" stopColor="#04a9ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-0.716"
          y1="-10.081"
          x2="-0.629"
          y2="-10.081"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-1.28"
          y1="-13.5"
          x2="-1.125"
          y2="-13.5"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="-2.532"
          y1="-0.179"
          x2="-2.389"
          y2="-0.179"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#beddfc" />
          <stop offset="1" stopColor="#a2d0ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="-0.816"
          y1="-0.942"
          x2="-0.753"
          y2="-0.942"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00a1ff" />
          <stop offset="1" stopColor="#00a2ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="-4.895"
          y1="-6.223"
          x2="-4.618"
          y2="-6.223"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#90d1f3" />
          <stop offset="1" stopColor="#72cfff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="-1.774"
          y1="-1.992"
          x2="-1.665"
          y2="-1.992"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d7ebfb" />
          <stop offset="1" stopColor="#d0eaff" />
        </linearGradient>
      </defs>
      <g
        id="_06_-_text_writing_study_learn_student_reading"
        data-name="06 - text, writing, study, learn, student, reading"
        transform="translate(-3.239 -1.25)"
      >
        <path
          id="Path_1225"
          data-name="Path 1225"
          d="M6.869,40.191h21.1a3.616,3.616,0,0,0,2.123-.685.99.99,0,0,0-.9-1.741,8.474,8.474,0,1,1,.982-15.649.991.991,0,0,0,1.422-.891V9.265A3.634,3.634,0,0,0,30.539,6.7C29.4,5.561,27.29,3.45,26.153,2.313A3.633,3.633,0,0,0,23.587,1.25H6.869a3.63,3.63,0,0,0-3.63,3.63V36.561a3.63,3.63,0,0,0,3.63,3.63Z"
          transform="translate(0)"
          fillRule="evenodd"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_1226"
          data-name="Path 1226"
          d="M9.222,11.972h13.2a.99.99,0,1,0,0-1.98H9.222a.99.99,0,1,0,0,1.98Z"
          transform="translate(1.598 2.798)"
          fillRule="evenodd"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_1227"
          data-name="Path 1227"
          d="M9.222,17.1h13.2a.99.99,0,1,0,0-1.98H9.222a.99.99,0,1,0,0,1.98Z"
          transform="translate(1.598 4.439)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1228"
          data-name="Path 1228"
          d="M9.222,22.23H15.73a.99.99,0,1,0,0-1.98H9.222a.99.99,0,0,0,0,1.98Z"
          transform="translate(1.598 6.081)"
          fillRule="evenodd"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_1229"
          data-name="Path 1229"
          d="M17.725,1.25h1.226a3.633,3.633,0,0,1,2.566,1.063C22.654,3.45,24.765,5.561,25.9,6.7a3.634,3.634,0,0,1,1.063,2.567V10.49H18.99a1.264,1.264,0,0,1-1.265-1.263V1.25Z"
          transform="translate(4.636 0)"
          fillRule="evenodd"
          fill="url(#linear-gradient-5)"
        />
        <path
          id="Path_1230"
          data-name="Path 1230"
          d="M30.769,17.975a10.453,10.453,0,1,0,0,14.783,10.457,10.457,0,0,0,0-14.783Z"
          transform="translate(3.1 4.373)"
          fillRule="evenodd"
          fill="url(#linear-gradient-6)"
        />
        <path
          id="Path_1231"
          data-name="Path 1231"
          d="M18.24,27.893h6.571a.99.99,0,1,0,0-1.98H18.24a.99.99,0,1,0,0,1.98Z"
          transform="translate(4.484 7.893)"
          fillRule="evenodd"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_1232"
          data-name="Path 1232"
          d="M27.663,22.3a2.374,2.374,0,0,0,0-3.357h0a2.373,2.373,0,0,0-3.356,0c-1.789,1.787-5.563,5.561-7.35,7.35a2.373,2.373,0,0,0,0,3.356h0a2.374,2.374,0,0,0,3.357,0l7.35-7.35Z"
          transform="translate(4.168 5.441)"
          fillRule="evenodd"
          fill="url(#linear-gradient-8)"
        />
        <path
          id="Path_1233"
          data-name="Path 1233"
          d="M22.4,25.787l-1.4,1.4L17.642,23.83l1.4-1.4Z"
          transform="translate(4.609 6.778)"
          fillRule="evenodd"
          fill="url(#linear-gradient-7)"
        />
      </g>
    </Icon>
  );
}
