import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function LockIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 50 65" {...props}>
      <path
        id="Icon_material-lock-outline"
        data-name="Icon material-lock-outline"
        d="M31,51.024a6.191,6.191,0,1,0-6.25-6.19A6.239,6.239,0,0,0,31,51.024ZM49.75,23.167H46.625v-6.19a15.626,15.626,0,0,0-31.25,0v6.19H12.25A6.239,6.239,0,0,0,6,29.357V60.31a6.239,6.239,0,0,0,6.25,6.19h37.5A6.239,6.239,0,0,0,56,60.31V29.357A6.239,6.239,0,0,0,49.75,23.167Zm-28.438-6.19a9.688,9.688,0,0,1,19.375,0v6.19H21.313ZM49.75,60.31H12.25V29.357h37.5Z"
        transform="translate(-6 -1.5)"
        fill="currentColor"
      />
    </Icon>
  );
}
