import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex, Button } from '@chakra-ui/react';

import { FacebookIcon } from 'src/icons';
import { loginWithFacebook } from 'src/redux/auth/actions';
import { FacebookProfile } from 'src/models/SocialLogin';

interface SignInWithFacebookProps {
  setFirstLogin: () => void;
}

function SignInWithFacebook({ setFirstLogin }: SignInWithFacebookProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    window.auth.signInWithFacebook();
    window.auth.facebookResponse(async (url: string) => {
      setFirstLogin();
      const formattedUrl = url.replace('#', '?');
      const { query } = queryString.parseUrl(formattedUrl);

      const requestUrl = queryString.stringifyUrl({
        url: 'https://graph.facebook.com/me',
        query: {
          fields: 'first_name,last_name,gender,birthday,email',
          access_token: query.access_token,
        },
      });

      const response = await axios.get(requestUrl);
      const data = response.data as FacebookProfile;

      dispatch(
        loginWithFacebook({
          id: Number(data.id),
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          birthday: data.birthday,
          gender: data.gender,
          role: 'student',
        }),
      );
    });
  };

  return (
    <Button
      minW="fit-content"
      w="300px"
      color="white"
      bg="facebook.500"
      fontWeight="regular"
      rounded="full"
      justifyContent="flex-start"
      _hover={{ bg: 'facebook.700' }}
      _active={{ bg: 'facebook.700' }}
      onClick={onClick}
    >
      <FacebookIcon />
      <Flex justifyContent="center" flex="1">
        {t('sign_in_with', { provider: 'Facebook' })}
      </Flex>
    </Button>
  );
}

export default SignInWithFacebook;
