import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ResourcesQuestionBankIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 30.499 39.242" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-0.197"
          y1="-0.042"
          x2="-0.154"
          y2="-0.042"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f0f3f7" />
          <stop offset="1" stopColor="#cbe8ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.197"
          y1="-0.222"
          x2="-0.154"
          y2="-0.222"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#44c9ff" />
          <stop offset="1" stopColor="#0094cf" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-2.507"
          y1="-4.329"
          x2="-2.25"
          y2="-4.329"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#65cd7d" />
          <stop offset="1" stopColor="#1bc35c" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="-1.783"
          y1="-12.038"
          x2="-1.665"
          y2="-12.038"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="-1.783"
          y1="-16.369"
          x2="-1.665"
          y2="-16.369"
          xlinkHref="#linear-gradient-2"
        />
      </defs>
      <g
        id="_29_-_exam_result_study_online_class_learn_graduation"
        data-name="29 - exam, result, study, online class, learn, graduation"
        transform="translate(-4.529 -1.25)"
      >
        <path
          id="Path_1246"
          data-name="Path 1246"
          d="M35.028,4.906A3.656,3.656,0,0,0,31.373,1.25H8.185A3.656,3.656,0,0,0,4.529,4.906v31.93a3.656,3.656,0,0,0,3.656,3.656H31.373a3.656,3.656,0,0,0,3.656-3.656Z"
          transform="translate(0)"
          fillRule="evenodd"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_1247"
          data-name="Path 1247"
          d="M4.529,8.744h30.5V4.906A3.656,3.656,0,0,0,31.373,1.25H8.185A3.656,3.656,0,0,0,4.529,4.906V8.744Z"
          fillRule="evenodd"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_1250"
          data-name="Path 1250"
          d="M11.93,20.012l-1.884-1.884a1,1,0,1,0-1.411,1.411l1.884,1.884a1,1,0,0,0,1.411-1.411Z"
          transform="translate(1.256 5.464)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1251"
          data-name="Path 1251"
          d="M11.463,21.745l3.183-3.183a1,1,0,1,0-1.409-1.411l-3.184,3.183a1,1,0,1,0,1.411,1.411Z"
          transform="translate(1.723 5.142)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1252"
          data-name="Path 1252"
          d="M11.93,26.509l-1.884-1.885a1,1,0,0,0-1.411,1.411l1.884,1.884a1,1,0,0,0,1.411-1.409Z"
          transform="translate(1.256 7.604)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1253"
          data-name="Path 1253"
          d="M11.463,28.24l3.183-3.183a1,1,0,0,0-1.409-1.411l-3.184,3.184a1,1,0,1,0,1.411,1.409Z"
          transform="translate(1.723 7.282)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1254"
          data-name="Path 1254"
          d="M16.151,20.051h9.306a1,1,0,1,0,0-1.994H16.151a1,1,0,0,0,0,1.994Z"
          transform="translate(3.5 5.536)"
          fillRule="evenodd"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_1255"
          data-name="Path 1255"
          d="M16.151,26.547h9.306a1,1,0,1,0,0-1.994H16.151a1,1,0,0,0,0,1.994Z"
          transform="translate(3.5 7.676)"
          fillRule="evenodd"
          fill="url(#linear-gradient-8)"
        />
        <g id="Close_icon" data-name="Close icon" transform="translate(11.176 13.638)">
          <line
            id="Line_95"
            data-name="Line 95"
            x2="3.988"
            y2="3.988"
            transform="translate(0 0)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_96"
            data-name="Line 96"
            x1="3.988"
            y2="3.988"
            transform="translate(0 0)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_1256"
          data-name="Path 1256"
          d="M16.151,20.051h9.306a1,1,0,1,0,0-1.994H16.151a1,1,0,0,0,0,1.994Z"
          transform="translate(3.5 -3.099)"
          fillRule="evenodd"
          fill="url(#linear-gradient-7)"
        />
        <text
          id="Question"
          transform="translate(18.176 6.002)"
          fill="#fff"
          fontSize="4"
          fontFamily="Poppins-Bold, Poppins"
          fontWeight="700"
        >
          <tspan x="-9.296" y="0">
            Question
          </tspan>
        </text>
      </g>
    </Icon>
  );
}
