import React from 'react';
import { Flex, Spacer } from '@chakra-ui/react';

interface QuestionCardProps {
  children: React.ReactNode;
  marks: number;
  totalMarks: number;
}

function QuestionCard({ children, marks, totalMarks }: QuestionCardProps): JSX.Element {
  return (
    <Flex bg="#E6E6E6" p={3} borderRadius="10px" my={2} userSelect="none" minW="fit-content">
      {children}
      <Spacer />
      {`${marks}/${totalMarks}`}
    </Flex>
  );
}

export default QuestionCard;
