import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import { getAttemptDetails } from 'src/redux/questionBank/actions';
import {
  selectAttemptDetails,
  selectAttemptDetailsStatusCode,
  selectQuestion,
} from 'src/redux/questionBank/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import Loader from 'src/components/Loader';
import usePageTracking from '../PageTracking';
import RecordDetails from 'src/components/RecordDetails/RecordDetails';

interface LocationState {
  questionBankName: string;
  questionType: string;
  attemptName: string;
}

function QuestionBankRecordDetails(): JSX.Element {
  usePageTracking('question-bank-record-details');
  const dispatch = useDispatch();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { attemptName, questionBankName, questionType } = state;
  const params = useParams<{ subjectId: string; attemptId: string }>();

  const questions = useSelector(selectQuestion);
  const statusCode = useSelector(selectAttemptDetailsStatusCode);
  const attemptDetails = useSelector(selectAttemptDetails);

  useEffect(() => {
    dispatch(getAttemptDetails(Number(params.attemptId)));
  }, [dispatch]);

  useSessionExpired(statusCode);
  if (!questions || !attemptDetails) {
    return (
      <DashboardShell>
        <BackTopbar
          title={attemptName}
          pathname={`/question-bank-records/${params.subjectId!}`}
          state={{ questionType, questionBankName }}
        />
        <Loader />
      </DashboardShell>
    );
  }

  return (
    <RecordDetails
      attemptName={attemptName}
      pathname={`/question-bank-records/${params.subjectId!}`}
      state={{ questionType, questionBankName }}
      attemptDetails={attemptDetails}
      name={questionBankName}
      questionType={questionType}
      questions={questions}
    />
  );
}

export default QuestionBankRecordDetails;
