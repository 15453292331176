import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Image, LinkBox, LinkOverlay, Spacer, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { formatPrice } from 'src/utils/formatPrice';
import { getPurchaseHistory } from 'src/redux/purchase/actions';
import { selectPurchaseHistory, selectPurchaseHistoryLoading } from 'src/redux/purchase/selectors';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import DashboardShell from 'src/components/DashboardShell';
import NavigateTopbar from 'src/components/Topbar/NavigateTopbar';
import Loader from 'src/components/Loader';
import NoPurchase from 'src/components/NoPurchase';
import usePageTracking from '../PageTracking';

function MyPurchases(): JSX.Element {
  usePageTracking('my-purchases');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const purchases = useSelector(selectPurchaseHistory);
  const loading = useSelector(selectPurchaseHistoryLoading);

  const { search } = useLocation();
  const { from } = queryString.parse(search);

  useEffect(() => {
    // Coming after payment success
    if (from) {
      setTimeout(() => {
        dispatch(getPurchaseHistory());
      }, 2000);
    }

    // Default behavior
    else {
      dispatch(getPurchaseHistory());
    }
  }, [dispatch]);

  if (!purchases || loading)
    return (
      <DashboardShell>
        <NavigateTopbar
          currentTab={t('my_purchases')}
          firstTitle={t('profile')}
          firstTo="/profile"
          secondTitle={t('my_purchases')}
          secondTo="/my-purchases"
        />
        <Loader />
      </DashboardShell>
    );

  const hasPurchase = Boolean(purchases.length);

  if (!hasPurchase) {
    return (
      <DashboardShell>
        <NavigateTopbar
          currentTab={t('my_purchases')}
          firstTitle={t('profile')}
          firstTo="/profile"
          secondTitle={t('my_purchases')}
          secondTo="/my-purchases"
        />
        <NoPurchase />
      </DashboardShell>
    );
  }

  return (
    <DashboardShell>
      <NavigateTopbar
        currentTab={t('my_purchases')}
        firstTitle={t('profile')}
        firstTo="/profile"
        secondTitle={t('my_purchases')}
        secondTo="/my-purchases"
      />
      <Box px="8" overflowY="auto" h="full" pt="8">
        {purchases.map((order) => {
          const isPrepaid = order.gateway === 'iPayOnlineBanking';
          return (
            <LinkBox as={Flex} key={order.id} direction="column" boxShadow="base" p="8" my="6">
              <LinkOverlay
                display="Flex"
                as={Link}
                to={`/my-purchases/${order.id}`}
                w="full"
                justifyContent="space-between"
                fontSize="large"
              >
                <Text fontWeight="medium">#{order.refNo}</Text>
                <Text>{order.date}</Text>
              </LinkOverlay>
              <Flex w="full" direction="column" mt="4">
                {order.items?.map((item) => (
                  <Flex
                    key={item.id}
                    alignItems="center"
                    borderBottomColor="gray.500"
                    borderBottomWidth="1px"
                    py="8"
                  >
                    <Image
                      src={item.imageURL || TavisPlaceholder}
                      minW="55px"
                      minH="55px"
                      maxW="55px"
                      maxH="55px"
                      fit="cover"
                    />
                    <Text ml="4">{item.title}</Text>
                    <Spacer />
                    <Text>
                      {formatPrice(item.priceInfo.finalPrice)}
                      {isPrepaid || Number(item.priceInfo.finalPrice) === 0 ? '' : '/month'}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </LinkBox>
          );
        })}
      </Box>
    </DashboardShell>
  );
}

export default MyPurchases;
