import { AuthState } from './models';
import AppState from '../rootState';
import { StudyLevel } from 'src/models/SocialLogin';
import { Pagination } from 'src/models/Pagination';

const selectAuthState = (state: AppState): AuthState => state.auth;

export const selectLoginStatus = (state: AppState): boolean | null =>
  selectAuthState(state).loginStatus;

export const selectLoginStatusCode = (state: AppState): number | null =>
  selectAuthState(state).loginStatusCode;

export const selectLoginRole = (state: AppState): string | null => selectAuthState(state).loginRole;

export const selectLoginLoading = (state: AppState): boolean | undefined =>
  selectAuthState(state).loginLoading;

export const selectMoodleSession = (state: AppState): string | null =>
  selectAuthState(state).moodleSession;

export const selectRememberMe = (state: AppState): boolean => selectAuthState(state).rememberMe;

export const selectForgetPasswordMessage = (state: AppState): string | null =>
  selectAuthState(state).forgetPasswordMessage;

export const selectRegisterStatus = (state: AppState): boolean | null =>
  selectAuthState(state).registerStatus;

export const selectRegisterErrorMessage = (state: AppState): string | null =>
  selectAuthState(state).registerErrorMessage;

export const selectRegisterLoading = (state: AppState): boolean | undefined =>
  selectAuthState(state).registerLoading;

export const selectLoginMethod = (state: AppState): string | null =>
  selectAuthState(state).loginMethod;

export const selectLoginErrorMessage = (state: AppState): string | null =>
  selectAuthState(state).loginErrorMessage;

// Confirm verification code
export const selectConfirmVerificationCodeMessage = (state: AppState): string | null =>
  selectAuthState(state).confirmVerificationCodeMessage;

export const selectConfirmVerificationCodeNetworkError = (state: AppState): boolean =>
  selectAuthState(state).confirmVerificationCodeNetworkError;

export const selectConfirmVerificationCodeStatusCode = (state: AppState): number | null =>
  selectAuthState(state).confirmVerificationCodeStatusCode;

export const selectConfirmVerificationCodeLoading = (state: AppState): boolean | undefined =>
  selectAuthState(state).confirmVerificationCodeLoading;

// Resend verification code
export const selectResendVerificationCodeMessage = (state: AppState): string | null =>
  selectAuthState(state).resendVerificationCodeMessage;

export const selectResendVerificationCodeNetworkError = (state: AppState): boolean =>
  selectAuthState(state).resendVerificationCodeNetworkError;

export const selectResendVerificationCodeStatusCode = (state: AppState): number | null =>
  selectAuthState(state).resendVerificationCodeStatusCode;

// Get user details
export const selectUserDetailsStatusCode = (state: AppState): number | null =>
  selectAuthState(state).userDetailsStatusCode;

// Update onboarding details
export const selectOnboadingDetailsStatusCode = (state: AppState): number | null =>
  selectAuthState(state).updateOnboardingDetailsStatusCode;

export const selectOnboadingDetailsLoading = (state: AppState): boolean | undefined =>
  selectAuthState(state).updateOnboardingDetailsLoading;

export const selectOnboadingDetailsMessage = (state: AppState): string | null =>
  selectAuthState(state).updateOnboardingDetailsMessage;

// Get level of study
export const selectStudyLevel = (state: AppState): StudyLevel[] | null =>
  selectAuthState(state).studyLevel;

export const selectStudyLevelStatusCode = (state: AppState): number | null =>
  selectAuthState(state).studyLevelStatusCode;

export const selectStudyLevelPagination = (state: AppState): Pagination | null =>
  selectAuthState(state).studyLevelPagination;

// Get free trial expiry
export const selectFreeTrialExpiry = (state: AppState): Date | null => {
  const expiryDate = selectAuthState(state).freeTrialExpiry;
  if (expiryDate) {
    return new Date(expiryDate * 1000);
  }
  return null;
};

// Get free trial access date
export const selectFreeTrialAccessDate = (state: AppState): Date | null => {
  const startDate = selectAuthState(state).freeTrialAccessStartDate;
  if (startDate) {
    return new Date(Number(startDate) * 1000);
  }
  return null;
};

// Get user register date
export const selectRegisterDate = (state: AppState): Date | null => {
  const registerDate = selectAuthState(state).registerDate;
  if (registerDate) {
    return new Date(Number(registerDate) * 1000);
  }
  return null;
};

// Get skip verification info
export const selectAccountVerified = (state: AppState): boolean | null => {
  const accountVerified = selectAuthState(state).accountVerified;
  if (accountVerified === 1) {
    return true;
  } else if (accountVerified === 0) {
    return false;
  }
  return null;
};

export const selectverificationSkipsLeft = (state: AppState): number | null =>
  selectAuthState(state).verificationSkipsLeft;

export const selectSkipVerificationStatusCode = (state: AppState): number | null =>
  selectAuthState(state).skipVerificationStatusCode;

export const selectSkipVerificationMessage = (state: AppState): string | null =>
  selectAuthState(state).skipVerificationMessage;
