import {
  Flex,
  Grid,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
} from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HomeworkTrackerItem from './HomeworkTrackerItem';
import DashboardShell from 'src/components/DashboardShell';
import useSessionExpired from 'src/components/SessionExpired';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import { getHomeworkList } from 'src/redux/homework/actions';
import {
  selectHomeworkList,
  selectHomeworkListLoading,
  selectHomeworkListStatusCode,
  selectHomeworkQuestionsStatusCode,
} from 'src/redux/homework/selectors';
import usePageTracking from '../PageTracking';

function Homework(): JSX.Element {
  usePageTracking('homework');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const homeworkList = useSelector(selectHomeworkList);
  const homeworkListStatusCode = useSelector(selectHomeworkListStatusCode);
  const homeworkListLoading = useSelector(selectHomeworkListLoading);

  const questionStatusCode = useSelector(selectHomeworkQuestionsStatusCode);

  useEffect(() => {
    dispatch(getHomeworkList());
  }, [dispatch]);

  useSessionExpired(homeworkListStatusCode);
  useSessionExpired(questionStatusCode);

  const showHomework = (tabIndex: number) => {
    if (homeworkListLoading) {
      return (
        <Flex flex="1" justifyContent="center">
          <Spinner />
        </Flex>
      );
    } else if (homeworkList) {
      let homework;
      if (tabIndex === 0) {
        // Due homework
        homework = homeworkList.filter((item) => !item.isSubmitted);
      } else {
        // Submitted homework
        homework = homeworkList.filter((item) => item.isSubmitted);
      }
      return (
        <Grid templateColumns="repeat(2, 1fr)" gap="10" h="100%" w="full" maxW="70%">
          {homework.length === 0 ? (
            <Text>{t('no_homework')}</Text>
          ) : (
            homework.map((item) => {
              return <HomeworkTrackerItem key={item.id} {...item} />;
            })
          )}
        </Grid>
      );
    }
  };

  return (
    <DashboardShell>
      <BackTopbar title={t('homework')} pathname="/resources" />
      <Flex m="3vh" mt="0" h="100%" maxH="85vh">
        <Tabs overflow="hidden" flex="1" display="flex" flexDirection="column" isLazy>
          <TabList>
            <Tab
              mr={10}
              color="#595959"
              borderColor="#595959"
              fontSize={20}
              fontWeight="medium"
              _focus={{ outline: 'none' }}
              _selected={{ borderColor: '#65CD7D', color: '#65CD7D' }}
            >
              {t('due')}
            </Tab>
            <Tab
              color="#595959"
              borderColor="#595959"
              fontSize={20}
              fontWeight="medium"
              _focus={{ outline: 'none' }}
              _selected={{ borderColor: '#65CD7D', color: '#65CD7D' }}
            >
              {t('submitted')}
            </Tab>
          </TabList>
          <TabPanels
            flex="1"
            as={OverlayScrollbarsComponent}
            options={{ scrollbars: { autoHide: 'scroll' }, overflowBehavior: { x: 'hidden' } }}
          >
            <TabPanel as={Flex} direction="column" pb="8">
              {showHomework(0)}
            </TabPanel>
            <TabPanel as={Flex} direction="column" pb="8">
              {showHomework(1)}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </DashboardShell>
  );
}

export default Homework;
