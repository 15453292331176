import { Flex, Text } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttemptData } from 'src/models/homework/AttemptData';
import { QuestionBankAttemptData } from 'src/models/QuestionBank';
import NavigationCard from './NavigationCard';

interface QuestionListProps {
  attemptData: AttemptData | QuestionBankAttemptData;
  type: 'homework' | 'questionBank';
  subjectId?: string;
  homeworkId?: string;
  attemptId: string | undefined;
  state: unknown;
}

function QuestionList({
  attemptData,
  type,
  subjectId,
  homeworkId,
  attemptId,
  state,
}: QuestionListProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      bg="white"
      h="50%"
      w="100%"
      borderRadius="10px"
      justifyContent="center"
      p={5}
      boxShadow="0 0 5px gray"
    >
      <Text mb={5} fontSize={18} fontWeight="medium">
        {t('review')}
      </Text>
      <OverlayScrollbarsComponent
        style={{ height: '100%', width: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Flex flexDirection="column" pr={5}>
          {attemptData.questions.map((question) => {
            return (
              <NavigationCard
                key={question.id}
                marks={question.mark}
                totalMarks={question.defaultMark}
                pathname={
                  type === 'questionBank'
                    ? `/question-bank-review/${subjectId!}/${attemptId!}/${question.id}`
                    : `/homework-review/${homeworkId!}/${attemptId!}/${question.id}`
                }
                state={state}
              >
                {question.name}
              </NavigationCard>
            );
          })}
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default QuestionList;
