import { AppActions } from '../rootActions';
import { CONTACT_US, RESET_CONTACT_US } from './constants';
import { ContactUsPayload } from './models';

/**
 * Functions to be called in React components
 */
export const contactUs = (payload: ContactUsPayload): AppActions => ({
  type: CONTACT_US,
  payload,
});

export const resetContactUs = (): AppActions => ({ type: RESET_CONTACT_US });
