import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function NotificationIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 18 18">
      <path
        id="Icon_material-notifications-active"
        data-name="Icon material-notifications-active"
        d="M8.055,5.208,6.764,3.888a9.689,9.689,0,0,0-3.719,7.246H4.85A7.84,7.84,0,0,1,8.055,5.208ZM19.24,11.135h1.805a9.748,9.748,0,0,0-3.719-7.246l-1.282,1.32a7.886,7.886,0,0,1,3.2,5.926Zm-1.778.462c0-2.834-1.48-5.206-4.062-5.834V5.135a1.354,1.354,0,1,0-2.708,0v.628C8.1,6.39,6.629,8.753,6.629,11.6v4.615L4.823,18.058v.923H19.267v-.923l-1.805-1.846ZM12.045,21.75a1.548,1.548,0,0,0,.361-.037,1.842,1.842,0,0,0,1.3-1.089,1.89,1.89,0,0,0,.135-.72H10.231A1.839,1.839,0,0,0,12.045,21.75Z"
        transform="translate(-3.045 -3.75)"
        fill="currentColor"
      />
    </Icon>
  );
}
