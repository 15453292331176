import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function CorrectAnswerIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        id="Icon_ionic-md-checkmark-circle-outline"
        data-name="Icon ionic-md-checkmark-circle-outline"
        d="M9.275,11.475l-1.4,1.4,4.5,4.5,10-10-1.4-1.4-8.6,8.55Zm12.1,1.9a7.966,7.966,0,1,1-5.8-7.7l1.55-1.55a9.3,9.3,0,0,0-3.75-.75,10,10,0,1,0,10,10Z"
        transform="translate(-3.375 -3.375)"
        fill="#00ff31"
      ></path>
    </Icon>
  );
}
