import React, { useEffect, useRef } from 'react';
import { Text, Flex, HStack, Stack } from '@chakra-ui/react';
import compareAsc from 'date-fns/compareAsc';
import intervalToDuration from 'date-fns/intervalToDuration';
import daysToWeeks from 'date-fns/daysToWeeks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ProgressBar from 'src/components/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { Homework } from 'src/models/homework/Homework';
import { getHomeworkQuestions } from 'src/redux/homework/actions';
import { selectHomeworkQuestions } from 'src/redux/homework/selectors';

function HomeworkTrackerItem(homework: Homework): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clickButton = useRef(false);
  const questions = useSelector(selectHomeworkQuestions);

  useEffect(() => {
    if (clickButton.current && questions) {
      if (questions.length > 0) {
        navigate(`/homework/${homework.id}/${questions[0].id}`);
      } else {
        window.messageBox.alertBox(t('no_questions'));
        clickButton.current = false;
      }
    }
  }, [questions]);

  const onClickDetails = () => {
    navigate(`/homework-records/${homework.id}`);
  };

  const onClickHomework = () => {
    if (!homework.isSubmitted) {
      dispatch(getHomeworkQuestions(homework.id));
      clickButton.current = true;
    } else if (homework.isSubmitted) {
      onClickDetails();
    }
  };

  const showHomeworkDeadline = () => {
    const today = new Date();
    const homeworkDeadline = new Date(homework.deadline);
    const result = compareAsc(homeworkDeadline, today);

    if (result === -1) {
      return <Text color="red">{t('late')}</Text>;
    } else {
      const duration = intervalToDuration({ start: today, end: homeworkDeadline });
      if (duration.months && duration.months !== 0)
        return t('due_in_month', { count: duration.months });
      else if (duration.days && duration.days !== 0) {
        if (duration.days < 7) {
          return t('due_in_day', { count: duration.days });
        }
        const weeks = daysToWeeks(duration.days);
        return t('due_in_week', { count: weeks });
      } else {
        return t('due_today');
      }
    }
  };

  const showHomeworkSubmissionDate = () => {
    const submissionDate = new Date(homework.endDate);
    const deadline = new Date(homework.deadline);
    const result = compareAsc(deadline, submissionDate);

    if (result === -1) {
      return (
        <Text color="red">
          {t('late_submission')} {new Date(homework.endDate).toLocaleDateString()}
        </Text>
      );
    } else {
      return `${t('submitted')} ${new Date(homework.endDate).toLocaleDateString()}`;
    }
  };

  const showProgress = () => {
    const progress = Math.floor((homework.answeredQuestions / homework.totalQuestions) * 100);

    if (homework.isSubmitted) {
      return 100;
    } else {
      if (Number.isNaN(progress)) {
        return 0;
      } else {
        return progress;
      }
    }
  };

  return (
    <Stack
      boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
      direction="column"
      borderRadius="10px"
      p="4"
      mt="3"
      onClick={onClickHomework}
      cursor="pointer"
    >
      <Text fontSize="sm" fontWeight="medium">
        {homework.name}
      </Text>
      <Flex color="black" fontSize={12}>
        {homework.isSubmitted ? showHomeworkSubmissionDate() : showHomeworkDeadline()}
      </Flex>
      <HStack w="100%" spacing={5}>
        <ProgressBar value={showProgress()} w="85%" />
        <Text fontSize={12} color="#707070">
          {showProgress()}%
        </Text>
      </HStack>
      {/* <Flex
        mt="2"
        fontSize={12}
        color="#707070"
        justifyContent="space-between"
        alignItems="center"
        w="full"
      >
        
        
      </Flex> */}
    </Stack>
  );
}

export default HomeworkTrackerItem;
