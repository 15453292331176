import { NotificationDetails } from 'src/models/parentsNotification/NotificationDetails';
import AppState from '../rootState';
import { ParentsNotificationState } from './models';

const selectParentsNotification = (state: AppState): ParentsNotificationState =>
  state.parentsNotification;

// Notification Details
export const selectNotificationDetailsAuth = (state: AppState): boolean | null =>
  selectParentsNotification(state).auth;

export const selectNotificationDetails = (state: AppState): NotificationDetails | null =>
  selectParentsNotification(state).notificationDetails;

export const selectNotificationDetailsLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).notificationDetailsLoading;

export const selectNotificationDetailsNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).notificationDetailsNetworkError;

// Verify password
export const selectVerifyPasswordStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).verifyPasswordStatusCode;

export const selectVerifyPasswordLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).verifyPasswordLoading;

export const selectVerifyPasswordMessage = (state: AppState): string | null =>
  selectParentsNotification(state).verifyPasswordMessage;

export const selectVerifyPasswordNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).verifyPasswordNetworkError;

// Save details
export const selectSaveDetailsStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).saveDetailsStatusCode;

export const selectSaveDetailsLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).saveDetailsLoading;

export const selectSaveDetailsNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).saveDetailsNetworkError;

// Update details
export const selectUpdateDetailsStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).updateDetailsStatusCode;

export const selectUpdateDetailsNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).updateDetailsNetworkError;

export const selectUpdateDetailsLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).updateDetailsLoading;

// Cancel notification
export const selectCancelNotificationStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).cancelNotificationStatusCode;

export const selectCancelNotificationLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).cancelNotificationLoading;

export const selectCancelNotificationNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).cancelNotificationNetworkError;

// Confirm OTP
export const selectConfirmOTPStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).confirmOTPStatusCode;

export const selectConfirmOTPLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).confirmOTPLoading;

export const selectConfirmOTPMessage = (state: AppState): string | null =>
  selectParentsNotification(state).confirmOTPMessage;

export const selectConfirmOTPNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).confirmOTPNetworkError;

// Resend OTP
export const selectResendOTPStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).resendOTPStatusCode;

export const selectResendOTPMessage = (state: AppState): string | null =>
  selectParentsNotification(state).resendOTPMessage;

export const selectResendOTPNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).resendOTPNetworkError;

// Update password
export const selectUpdatePasswordStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).updatePasswordStatusCode;

export const selectUpdatePasswordNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).updatePasswordNetworkError;

export const selectUpdatePasswordLoading = (state: AppState): boolean | undefined =>
  selectParentsNotification(state).updatePasswordLoading;

// Forgot password
export const selectForgotPasswordStatusCode = (state: AppState): number | null =>
  selectParentsNotification(state).forgotPasswordStatusCode;

export const selectForgotPasswordMessage = (state: AppState): string | null =>
  selectParentsNotification(state).forgotPasswordMessage;

export const selectForgotPasswordNetworkError = (state: AppState): boolean | null =>
  selectParentsNotification(state).forgotPasswordNetworkError;
