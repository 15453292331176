import React, { useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalFooter,
  useDisclosure,
  useToast,
  Button,
  AspectRatio,
  HStack,
  Box,
  Spacer,
} from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/analytics';
import {
  selectAddToCartErrorMessage,
  selectAddToCartNetworkError,
  selectAddToCartResouceId,
  selectAddToCartStatusCode,
} from 'src/redux/cart/selectors';
import { RecordedLecture } from 'src/models/resources/RecordedLecture';
import { addToCart, getCart, resetAddToCart } from 'src/redux/cart/actions';
import { formatPrice } from 'src/utils/formatPrice';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import { IS_DEV } from 'src/constants/environment';
import { createFreeClassOrder } from 'src/redux/order/actions';
import {
  selectFreeClassId,
  selectFreeClassOrderNetworkError,
  selectFreeClassOrderStatusCode,
} from 'src/redux/order/selectors';

interface RecordedLectureCardProps {
  lecture: RecordedLecture;
}

function RecordedLectureCard(
  { lecture }: RecordedLectureCardProps,
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buyNow, setBuyNow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const addToCartStatusCode = useSelector(selectAddToCartStatusCode);
  const addToCartErrorMessage = useSelector(selectAddToCartErrorMessage);
  const addToCartNetworkError = useSelector(selectAddToCartNetworkError);
  const addToCartResourceId = useSelector(selectAddToCartResouceId);

  const freeClassOrderStatusCode = useSelector(selectFreeClassOrderStatusCode);
  const freeClassOrderNetworkError = useSelector(selectFreeClassOrderNetworkError);
  const freeClassId = useSelector(selectFreeClassId);

  useEffect(() => {
    if (freeClassOrderStatusCode === 200 && freeClassId === Number(lecture.id)) {
      toast({
        position: 'top',
        title: t('successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/resources/purchased-recorded-lectures');
    }
  }, [freeClassOrderStatusCode, freeClassId]);

  useEffect(() => {
    if (freeClassOrderNetworkError && freeClassId === Number(lecture.id)) {
      toast({
        title: t('network_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [freeClassOrderNetworkError, freeClassId]);

  useEffect(() => {
    if (addToCartResourceId === lecture.id) {
      // Success
      if (addToCartStatusCode === 200) {
        // Update cart badge number
        dispatch(getCart());

        if (!IS_DEV) {
          logEvent(analytics, 'add_to_cart', {
            currency: 'MYR',
            items: [
              {
                item_id: lecture.id.toString(),
                item_name: lecture.filename,
                price: lecture.discountPrice ? lecture.discountPrice : lecture.price,
                item_category: 'Resources',
                item_list_name: 'Resources',
              },
            ],
          });
        }

        toast({
          title: t('added_to_cart'),
          position: 'top',
          status: 'success',
          isClosable: true,
          duration: 1500,
        });

        if (buyNow) {
          setTimeout(() => {
            navigate('/prepaid');
          }, 500);
        }
      }

      // Failed with error
      if (addToCartErrorMessage) {
        toast({
          title: addToCartErrorMessage,
          position: 'top',
          status: 'error',
          isClosable: true,
          duration: 1500,
        });
      }

      // Network error
      if (addToCartNetworkError) {
        toast({
          title: t('add_to_cart_error'),
          position: 'top',
          status: 'error',
          isClosable: true,
          duration: 1500,
        });
      }
    }

    setTimeout(() => dispatch(resetAddToCart()), 500);
  }, [addToCartResourceId, addToCartStatusCode, addToCartErrorMessage, addToCartNetworkError]);

  const onAddToCart = () => {
    dispatch(
      addToCart({
        cartType: 'resource',
        instanceId: String(lecture.id),
      }),
    );

    onClose();
  };

  const onBuyNow = () => {
    onAddToCart();
    setBuyNow(true);
  };

  const onEnrolFreeRecordedLectures = () => {
    dispatch(
      createFreeClassOrder({
        id: Number(lecture.id),
        categoryType: 'resource',
      }),
    );
  };

  return (
    <React.Fragment>
      <Flex
        boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
        minW="240px"
        minH="250px"
        h="fit-content"
        onClick={onOpen}
        borderRadius="10px"
        flexDirection="column"
        ref={ref}
      >
        <AspectRatio ratio={16 / 9}>
          <Image
            fit="cover"
            src={lecture.thumbnail_url}
            fallbackSrc={TavisPlaceholder}
            roundedTop="lg"
          />
        </AspectRatio>
        <HStack flex="1" align="center" p={2}>
          <Text fontSize={12}>{lecture.filename}</Text>
          <Spacer />
          <Box>
            {lecture.discountPrice ? (
              <>
                <Box
                  px={3}
                  bg="#65CD7D"
                  color="white"
                  borderRadius="full"
                  fontSize={12}
                  fontWeight="semibold"
                  pos="relative"
                  _before={{
                    pos: 'absolute',
                    content: '""',
                    left: 0,
                    top: '50%',
                    right: 0,
                    borderTop: '2px solid red',
                    transform: 'rotate(-10deg)',
                  }}
                >
                  {formatPrice(lecture.price)}
                </Box>
                <Box
                  mt={3}
                  px={3}
                  bg="#65CD7D"
                  color="white"
                  borderRadius="full"
                  fontSize={12}
                  fontWeight="semibold"
                >
                  {formatPrice(String(lecture.discountPrice))}
                </Box>
              </>
            ) : (
              <Box
                px={3}
                bg="#65CD7D"
                color="white"
                borderRadius="full"
                fontSize={12}
                fontWeight="semibold"
              >
                {formatPrice(lecture.price)}
              </Box>
            )}
          </Box>
        </HStack>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={26} fontWeight="semibold">
            {t('please_choose')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalFooter as={Flex} justifyContent="space-around" alignItems="center">
            {lecture.isFree ? (
              <Button
                disabled={lecture.purchased}
                as="button"
                py="1"
                px="6"
                fontWeight="semibold"
                bg="white"
                borderColor="gray.400"
                borderWidth="1px"
                onClick={onEnrolFreeRecordedLectures}
              >
                {t('watch_replay')}
              </Button>
            ) : (
              <>
                <Button
                  fontWeight="medium"
                  backgroundColor="#3007A3"
                  color="white"
                  _hover={{ backgroundColor: '#000073' }}
                  _active={{ backgroundColor: '#000073' }}
                  onClick={onBuyNow}
                >
                  {t('checkout_now')}
                </Button>
                <Button
                  background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                  color="white"
                  fontWeight="medium"
                  _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                  _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                  onClick={onAddToCart}
                >
                  {t('add_to_cart')}
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

export default forwardRef(RecordedLectureCard);
