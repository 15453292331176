import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function SocialMediaFacebookIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 26 26">
      <path
        id="Icon_ionic-logo-facebook"
        data-name="Icon ionic-logo-facebook"
        d="M29.065,4.5H5.935A1.436,1.436,0,0,0,4.5,5.935V29.065A1.436,1.436,0,0,0,5.935,30.5H17.5V20.208H14.392V16.417H17.5v-2.8c0-3.358,2.329-5.186,5.329-5.186,1.435,0,2.979.108,3.338.156V12.09h-2.39c-1.632,0-1.943.772-1.943,1.909v2.417H25.72l-.508,3.792H21.833V30.5h7.231A1.436,1.436,0,0,0,30.5,29.065V5.935A1.436,1.436,0,0,0,29.065,4.5Z"
        transform="translate(-4.5 -4.5)"
        fill="#fff"
      />
    </Icon>
  );
}
