export const progress = {
  progressRed: {
    50: '#FFE5E5',
    100: '#FFB8B8',
    200: '#FF8A8A',
    300: '#FF5C5C',
    400: '#FF2E2E',
    500: '#FF4747',
    600: '#CC0000',
    700: '#990000',
    800: '#660000',
    900: '#330000',
  },
  progressYellow: {
    50: '#FFFCE5',
    100: '#FFF6B8',
    200: '#FFF08A',
    300: '#FFEB5C',
    400: '#FFE52E',
    500: '#FFE847',
    600: '#CCB300',
    700: '#998600',
    800: '#665900',
    900: '#332D00',
  },
  progressBlue: {
    50: '#E5F0FF',
    100: '#B8D4FF',
    200: '#8AB9FF',
    300: '#5C9DFF',
    400: '#2E82FF',
    500: '#4791FF',
    600: '#0052CC',
    700: '#003E99',
    800: '#002966',
    900: '#001533',
  },
  progressGreen: {
    50: '#EBFFE5',
    100: '#C8FFB8',
    200: '#A4FF8A',
    300: '#81FF5C',
    400: '#5EFF2E',
    500: '#71FF47',
    600: '#2FCC00',
    700: '#239900',
    800: '#176600',
    900: '#0C3300',
  },
};
