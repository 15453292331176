import { CartActionType, CartState } from './models';
import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  GET_CART,
  GET_CART_SUCCESS,
  GET_PEOPLE_ALSO_BUY,
  GET_PEOPLE_ALSO_BUY_SUCCESS,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_NETWORK_ERROR,
  RESET_ADD_TO_CART,
  GET_CART_ERROR,
  GET_CART_NETWORK_ERROR,
  RESET_PEOPLE_ALSO_BUY,
  GET_CUT_OFF_DATE,
  GET_CUT_OFF_DATE_SUCCESS,
  GET_CUT_OFF_DATE_ERROR,
  GET_CUT_OFF_DATE_NETWORK_ERROR,
  APPLY_PROMO_CODE,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_ERROR,
  RESET_PROMO_CODE,
} from './constants';
import i18n from 'i18next';

export const cartInitialState: CartState = {
  // Add to cart
  addToCartErrorMessage: null,
  addToCartLoading: false,
  addToCartStatusCode: null,
  addToCartNetworkError: false,
  addToCartResourceId: '',

  // Cart
  courses: null,
  questionBank: null,
  resources: null,
  currentDate: null,
  getCartLoading: false,
  getCartStatusCode: null,
  getCartNetworkError: false,

  // People also buy
  peopleAlsoBuyItem: null,
  peopleAlsoBuyLoading: false,

  // Delete cart item
  deleteCartItemStatus: null,
  deleteCartItemErrorMessage: null,
  deleteCartItemLoading: false,

  // Cut off date
  cutOffDate: null,
  cutOffDateLoading: false,
  cutOffDateStatusCode: null,
  cutOffDateNetworkError: false,

  // Promo code
  promoCode: null,
  promoCodeLoading: false,
  promoCodeErrorMessage: '',
};

export const cartReducer = (state = cartInitialState, action: CartActionType): CartState => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        addToCartLoading: true,
        addToCartResourceId: action.payload.instanceId,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartLoading: false,
        addToCartStatusCode: action.payload.code,
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartLoading: false,
        addToCartStatusCode: action.payload.code,
        addToCartErrorMessage:
          i18n.language === 'en'
            ? action.payload.translatedErrors.en
            : action.payload.translatedErrors.ms,
      };
    case ADD_TO_CART_NETWORK_ERROR:
      return {
        ...state,
        addToCartLoading: false,
        addToCartNetworkError: true,
      };
    case RESET_ADD_TO_CART:
      return {
        ...state,
        addToCartErrorMessage: null,
        addToCartLoading: false,
        addToCartStatusCode: null,
        addToCartNetworkError: false,
      };
    case GET_CART:
      return {
        ...state,
        getCartLoading: true,
        getCartNetworkError: false,
        getCartStatusCode: null,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        courses: action.payload.data.objects.courses,
        questionBank: action.payload.data.objects.questionBanks,
        resources: action.payload.data.objects.resources,
        currentDate: action.payload.data.objects.currentDate,
        getCartLoading: false,
        getCartStatusCode: action.payload.code,
      };
    case GET_CART_ERROR:
      return {
        ...state,
        getCartLoading: false,
        getCartStatusCode: action.payload.code,
      };
    case GET_CART_NETWORK_ERROR:
      return {
        ...state,
        getCartLoading: false,
        getCartNetworkError: true,
      };
    case GET_PEOPLE_ALSO_BUY:
      return {
        ...state,
        peopleAlsoBuyLoading: true,
      };
    case GET_PEOPLE_ALSO_BUY_SUCCESS:
      return {
        ...state,
        peopleAlsoBuyItem: action.payload.data.objects,
        peopleAlsoBuyLoading: false,
      };
    case RESET_PEOPLE_ALSO_BUY:
      return {
        ...state,
        peopleAlsoBuyItem: [],
        peopleAlsoBuyLoading: false,
      };
    case DELETE_CART_ITEM:
      return {
        ...state,
        deleteCartItemStatus: null,
        deleteCartItemErrorMessage: null,
        deleteCartItemLoading: false,
      };
    case DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        deleteCartItemStatus: action.payload.status,
        deleteCartItemErrorMessage: action.payload.error || null,
      };
    case GET_CUT_OFF_DATE:
      return {
        ...state,
        cutOffDate: null,
        cutOffDateLoading: true,
        cutOffDateStatusCode: null,
        cutOffDateNetworkError: false,
      };
    case GET_CUT_OFF_DATE_SUCCESS:
      return {
        ...state,
        cutOffDate: action.payload.data.coursePurchaseCutoffDate,
        cutOffDateLoading: false,
        cutOffDateStatusCode: action.payload.code,
      };
    case GET_CUT_OFF_DATE_ERROR:
      return {
        ...state,
        cutOffDateLoading: false,
        cutOffDateStatusCode: action.payload.code,
      };
    case GET_CUT_OFF_DATE_NETWORK_ERROR:
      return {
        ...state,
        cutOffDateLoading: false,
        cutOffDateNetworkError: true,
      };
    case APPLY_PROMO_CODE:
      return {
        ...state,
        promoCode: null,
        promoCodeLoading: true,
        promoCodeErrorMessage: '',
      };
    case APPLY_PROMO_CODE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          promoCode: action.payload.data,
          promoCodeLoading: false,
          promoCodeErrorMessage: '',
        };
      }
      // promo code not found
      else {
        return {
          ...state,
          promoCode: null,
          promoCodeLoading: false,
          promoCodeErrorMessage: 'Invalid code',
        };
      }
    case APPLY_PROMO_CODE_ERROR:
      return {
        ...state,
        promoCode: null,
        promoCodeLoading: false,
        promoCodeErrorMessage: action.payload.error,
      };
    case RESET_PROMO_CODE:
      return {
        ...state,
        promoCode: null,
        promoCodeLoading: false,
        promoCodeErrorMessage: '',
      };
    default:
      return state;
  }
};
