import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function RemoveIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <g id="Remove" transform="translate(-1005 -404)">
        <g
          id="Ellipse_27"
          data-name="Ellipse 27"
          transform="translate(1005 404)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        >
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.5" />
        </g>
        <line
          id="Line_78"
          data-name="Line 78"
          x2="10"
          y2="10"
          transform="translate(1010 409)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_79"
          data-name="Line 79"
          x1="10"
          y2="10"
          transform="translate(1010 409)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </Icon>
  );
}
