import React from 'react';
import { Box, useCheckbox, HStack, Text, UseCheckboxProps, Flex } from '@chakra-ui/react';
import { CorrectAnswerIcon, WrongAnswerIcon } from 'src/icons';
import { Answer } from 'src/models/Answer';

interface CheckboxOptionProps {
  option: string;
  correctAnswer: Answer[];
  selectedAnswer: string[] | null;
  children: React.ReactNode;
  checkboxProps: UseCheckboxProps;
  submitAnswer: boolean;
  showAnswer: boolean;
}

function CheckboxOption({
  option,
  correctAnswer,
  selectedAnswer,
  children,
  checkboxProps,
  submitAnswer,
  showAnswer,
}: CheckboxOptionProps): JSX.Element {
  const { getInputProps, getCheckboxProps } = useCheckbox(checkboxProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  if (submitAnswer) {
    checkboxProps.isDisabled = true;
  }

  const checkCorrectAnswer = () => {
    if (checkboxProps.value) {
      for (let i = 0; i < correctAnswer.length; i++) {
        if (correctAnswer[i].id.toString().includes(checkboxProps.value?.toString())) {
          return true;
        }
      }
    }
    return false;
  };
  const checkSelectedAnswer = () => {
    if (selectedAnswer && checkboxProps.value) {
      for (let i = 0; i < selectedAnswer.length; i++) {
        if (selectedAnswer[i].includes(checkboxProps.value?.toString())) {
          return true;
        }
      }
    }
    return false;
  };

  const isCorrectAnswer = checkCorrectAnswer();
  const isSelectedAnswer = checkSelectedAnswer();

  const showIcon = () => {
    if (showAnswer && isCorrectAnswer) {
      return (
        <Flex flex="1" justifyContent="flex-end">
          <CorrectAnswerIcon boxSize="25px" />
        </Flex>
      );
    } else if (showAnswer) {
      return (
        <Flex flex="1" justifyContent="flex-end">
          <WrongAnswerIcon boxSize="25px" />
        </Flex>
      );
    }
  };

  return (
    <Box as="label" position="relative" w="100%">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        border="1px solid #AFAFAF"
        borderRadius="md"
        px={5}
        py={3}
        aria-checked={isSelectedAnswer}
        aria-selected={submitAnswer && isSelectedAnswer}
        _checked={{ borderColor: '#1F58FF' }}
        _selected={{ color: 'white', background: '#0067FF' }}
        w="100%"
      >
        <HStack spacing={5}>
          <Text>{option}</Text>
          <Box>{children}</Box>
        </HStack>
        {showIcon()}
      </Flex>
    </Box>
  );
}

export default CheckboxOption;
