import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ReviewIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 15 20">
      <path
        id="Review_icon"
        data-name="Review icon"
        d="M13.125,2.5H10a2.5,2.5,0,1,0-5,0H1.875A1.875,1.875,0,0,0,0,4.375v13.75A1.875,1.875,0,0,0,1.875,20h11.25A1.875,1.875,0,0,0,15,18.125V4.375A1.875,1.875,0,0,0,13.125,2.5ZM7.5,1.563a.938.938,0,1,1-.937.937A.935.935,0,0,1,7.5,1.563Zm4.734,9.055L6.648,16.156a.469.469,0,0,1-.664,0L2.758,12.9a.469.469,0,0,1,0-.664l1.109-1.1a.469.469,0,0,1,.664,0l1.8,1.813L10.473,8.84a.469.469,0,0,1,.664,0l1.1,1.109A.469.469,0,0,1,12.234,10.617Z"
      />
    </Icon>
  );
}
