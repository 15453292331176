import { AppActions } from '../rootActions';
import {
  CANCEL_NOTIFICATION,
  CONFIRM_OTP,
  GET_NOTIFICATION_DETAILS,
  RESEND_OTP,
  RESET_PARENTS_NOTIFICATION,
  SAVE_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION_DETAILS,
  UPDATE_PASSWORD,
  VERIFY_PASSWORD,
  FORGOT_PASSWORD,
} from './contants';
import { SaveDetailsPayload, UpdateDetailsPayload } from './models';

/**
 * Functions to be called in React components
 */

export const getNotificationDetails = (): AppActions => ({
  type: GET_NOTIFICATION_DETAILS,
});

export const verifyPassword = (password: string): AppActions => ({
  type: VERIFY_PASSWORD,
  password: password,
});

export const saveDetails = (payload: SaveDetailsPayload): AppActions => ({
  type: SAVE_NOTIFICATION_DETAILS,
  payload: payload,
});

export const updateDetails = (payload: UpdateDetailsPayload): AppActions => ({
  type: UPDATE_NOTIFICATION_DETAILS,
  payload: payload,
});

export const cancelNotification = (notificationId: number): AppActions => ({
  type: CANCEL_NOTIFICATION,
  notificationId: notificationId,
});

export const confirmOTP = (payload: { email: string; OTP: string }): AppActions => ({
  type: CONFIRM_OTP,
  payload: payload,
});

export const resendOTP = (email: string): AppActions => ({
  type: RESEND_OTP,
  email: email,
});

export const updatePassword = (payload: { email: string; password: string }): AppActions => ({
  type: UPDATE_PASSWORD,
  payload: payload,
});

export const forgotPassword = (email: string): AppActions => ({
  type: FORGOT_PASSWORD,
  email: email,
});

export const resetParentsNotification = (): AppActions => ({
  type: RESET_PARENTS_NOTIFICATION,
});
