import { of } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';
import { API_URL } from 'src/constants/api';

import { ContactUsAction, ContactUsResponse, ContactUsSuccessAction } from './models';
import {
  CONTACT_US,
  CONTACT_US_ERROR,
  CONTACT_US_NETWORK_ERROR,
  CONTACT_US_SUCCESS,
} from './constants';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { LOGOUT } from '../auth/constants';

function contactUsEpic(action$: ActionsObservable<ContactUsAction>) {
  return action$.pipe(
    ofType(CONTACT_US),
    switchMap((action) => {
      const formData = new FormData();
      formData.append('firstName', action.payload.firstName);
      formData.append('lastName', action.payload.lastName);
      formData.append('email', action.payload.email);
      formData.append('phoneNumber', action.payload.phoneNumber);
      formData.append('message', action.payload.message);
      formData.append('captcha', action.payload.captcha);
      if (action.payload.attachment) {
        formData.append('attachment', action.payload.attachment);
      }
      return ajax({
        url: API_URL + '/site/contact-us',
        method: 'post',
        withCredentials: true,
        body: formData,
      }).pipe(
        map((payload: AjaxResponse): ContactUsSuccessAction => {
          return {
            type: CONTACT_US_SUCCESS,
            payload: payload.response as ContactUsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: CONTACT_US_NETWORK_ERROR,
            });
          }
          return of({
            type: CONTACT_US_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const contactUsEpics: Epic = combineEpics(...[contactUsEpic]);
