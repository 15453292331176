import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function TickIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <g id="Tick_indicator" data-name="Tick indicator" transform="translate(-546 -402)">
        <path
          id="Icon_ionic-ios-checkmark-circle"
          data-name="Icon ionic-ios-checkmark-circle"
          d="M13.375,3.375a10,10,0,1,0,10,10A10,10,0,0,0,13.375,3.375Zm5.12,7.236-6.428,6.457h0a.868.868,0,0,1-.558.264.842.842,0,0,1-.562-.274L8.25,14.365a.192.192,0,0,1,0-.274l.856-.856a.186.186,0,0,1,.269,0L11.51,15.37l5.865-5.909A.19.19,0,0,1,17.51,9.4h0a.175.175,0,0,1,.135.058l.841.87A.19.19,0,0,1,18.5,10.611Z"
          transform="translate(542.625 398.625)"
          fill="#003bff"
        />
      </g>
    </Icon>
  );
}
