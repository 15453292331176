import React from 'react';
import { Th, TableColumnHeaderProps } from '@chakra-ui/react';

interface TableHeaderProps extends TableColumnHeaderProps {
  children: React.ReactNode;
}

function TableHeader({ children, ...rest }: TableHeaderProps): JSX.Element {
  return (
    <Th
      border="none"
      px={0}
      color="black"
      fontSize={16}
      fontWeight="medium"
      textTransform="none"
      pos="sticky"
      top="0"
      bg="white"
      {...rest}
    >
      {children}
    </Th>
  );
}

export default TableHeader;
