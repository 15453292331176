export const translationMs = {
  // Month
  january: 'Januari',
  february: 'Februari',
  march: 'Mac',
  april: 'April',
  may: 'Mei',
  june: 'Jun',
  july: 'Julai',
  august: 'Ogos',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Disember',

  // Weekdays short form
  mon: 'Isnin',
  tue: 'Selasa',
  wed: 'Rabu',
  thu: 'Khamis',
  fri: 'Jumaat',
  sat: 'Sabtu',
  sun: 'Ahad',

  // Weekdays
  monday: 'Isnin',
  tuesday: 'Selasa',
  wednesday: 'Rabu',
  thursday: 'Khamis',
  friday: 'Jumaat',
  saturday: 'Sabtu',
  sunday: 'Ahad',

  // Login
  welcome_to_tavis: 'Selamat Datang Ke Tavis!',
  sign_in: 'Log Masuk',
  sign_in_with: 'Log Masuk dengan {{provider}}',
  create_account: 'Cipta Akaun',
  remember_me: 'Ingat saya',
  email: 'E-mel',
  password: 'Kata laluan',
  forgot_password: 'Lupa kata laluan?',
  join_tavis: 'Sertai tavis',
  dont_have_account_yet: 'Tidak mempunyai akaun lagi?',
  please_enter_your_email: 'Sila masukkan E-mel anda',
  please_fill_up_this_field: 'Sila isi ruangan ini',
  please_enter_a_number: 'Please enter a number', // no
  valid_phone_number: 'Nombor telefon mestilah di antara 10 - 13 digit',
  wrong_email_format: 'E-mel ini salah!',

  // Register
  register: 'Daftar',
  register_with_us: 'Daftar dengan kami sekarang!',
  confirm_password: 'Sahkan kata laluan',
  date_of_birth: 'Date of Birth',
  gender: 'Jantina',
  password_validation:
    'Kata laluan mestilah sekurang-kurangnya 8 aksara panjang, dengan sekurang-kurangnya 1 huruf kecil dan sekurang-kurangnya 1 angka',
  same_password: 'Sila pastikan kata laluan dan sahkan kata laluan adalah sama',
  please_select: 'Sila Pilih',

  // Email verification
  verification_code: 'Kod Pengesahan',
  email_verification_instruction: 'Please enter the verification code sent to {{email}}',
  confirm: 'Sahkan',
  resend_in_seconds: 'Hantar semula dalam {{seconds}} saat',
  resend: 'Ketik disini untuk menghantar semula',
  did_not_receive: 'Tidak menerima?',
  verify_code_error: 'Tidak dapat mengesahkan kod pengesahan. Sila cuba semula.',
  send_code_error: 'Tidak dapat menghantar kod pengesahan ke {{email}}. Sila cuba semula.',

  // Common
  id: 'ID',
  ic: 'IC / Passport No.',
  language: 'Bahasa',
  filter: 'Tapis',
  subject: 'Subjek',
  level: 'Tahap',
  price: 'Harga',
  search: 'Carian',
  length: 'Tempoh',
  start_date: 'Tarikh mula',
  tutor: 'Tutor',
  name: 'Nama',
  firstname: 'Nama depan',
  lastname: 'Nama belakang',
  male: 'Lelaki',
  female: 'Perempuan',
  profile: 'Profil',
  payment: 'Bayaran',
  amount: 'Jumlah',
  total: 'Jumlah',
  phone_number: 'Nombor Telefon',
  category: 'Kategori',
  chapters: 'Bab',
  questions: 'Soalan',
  answer: 'Jawapan',
  question: 'Soalan',
  time: 'Masa',
  description: 'Penerangan',
  not_available: 'Tidak tersedia',
  network_error: 'Anda berada di luar talian.',
  session_expired: 'Sesi Tamat, sila log masuk semula!',
  internet_connect_error: 'Semak internet anda dan cuba sebentar lagi.',
  retry: 'Cuba semula',
  full_name: 'Nama Penuh',
  phone_no: 'Nombor Telefon',
  address: 'Alamat',
  cart: 'Troli',
  week_word: 'Minggu',
  weekdays_word: 'Weekdays', // no
  logout: 'Log keluar',
  logout_prompt: 'Adakah anda pasti anda mahu log keluar?',
  resend_code_successful: 'Kod pengesahan berjaya dihantar',

  // Dynamic i18n
  week_zero: '{{count}} minggu',
  week_one: '{{count}} minggu',
  week_other: '{{count}} minggu',

  month_zero: '{{count}} bulan',
  month_one: '{{count}} bulan',
  month_other: '{{count}} bulan',

  lecture_zero: '{{count}} kuliah',
  lecture_one: '{{count}} kuliah',
  lecture_other: '{{count}} kuliah',

  class_zero: '{{count}} kelas',
  class_one: '{{count}} kelas',
  class_other: '{{count}} kelas',

  student_taugh_zero: '{{count}} pelajar diajar',
  student_taught_one: '{{count}} pelajar diajar',
  student_taught_other: '{{count}} pelajar diajar',

  // Sidebar
  dashboard: 'Dashboard',
  resources: 'Sumber',
  classes: 'Kelas',
  question_bank: 'Bank soalan',
  purchased: 'Telah Dibeli',
  settings: 'Tetapan',

  // Settings Sidebar
  notification_to_parents: 'Notifikasi kepada ibu bapa',
  about: 'Perihal',

  // Sort
  alphabetical: 'Mengikut abjad',
  price_asc: 'Harga - Meningkat',
  price_des: 'Harga - Menurun',

  // class
  add_class_instruction:
    'Tambah kelas di tahap yang sama (warna yang sama) untuk mendapatkan diskaun secara pukal.',
  featured: 'Ditawarkan',
  about_class: 'Mengenai kelas ini',
  study_track: 'Trek Belajar',
  add_to_cart: 'Tambah ke troli',
  buy_now: 'Beli sekarang',
  subscribe: 'Langgan',
  subscribe_info: 'Pembayaran berulang bulanan automatik - Hanya kad kredit / debit diterima.',
  buy_and_add_to_cart_info: 'Bayaran pendahuluan untuk kelas',
  no_classes_available: 'Tiada kelas ditemui',

  // Dashboard
  no_classes: 'Tiada kelas',
  greetings: 'Hi {{name}} Selamat kembali!',
  previous_month: 'Bulan Sebelumnya',
  next_month: 'Bulan Seterusnya',
  homework_tracker: 'Penjejak Kerja Rumah',
  starts_in: 'Akan bermula dalam',
  minute_zero: '{{count}} minit',
  minute_one: '{{count}} minit',
  minute_other: '{{count}} minit',
  hour_zero: '{{count}} jam',
  hour_one: '{{count}} jam',
  hour_other: '{{count}} jam ',
  class_not_started: 'Kelas belum bermula lagi. Sila cuba semula 5-10 minit sebelum kelas.',

  // Prepaid
  pay_as_you_go: 'Pay-as-you-go',
  prepaid: 'Prabayar',
  please_select_month_for_class: 'Sila pilih bulan untuk kelas',
  subtotal: 'Sub Jumlah',
  total_savings: 'Jumlah Simpanan',
  promo_code: 'Kod Promo',
  grand_total: 'Jumlah Penuh',
  others: 'Lain-lain',
  people_also_buy: 'Orang juga membeli',
  duration: 'Tempoh',
  starting_month: 'Bulan Permulaan',
  starting_date: 'Starting Date', // no
  every: 'Setiap',
  payment_policy: 'Dasar Pembayaran',
  fee: 'Yuran',
  number_of_months: 'Bilangan bulan',
  apply: 'Aplikasi',

  // Subscription
  subscription: 'Langganan',
  please_select_starting_month: 'Sila pilih bulan permulaan',
  monthly_savings: 'Simpanan Bulanan',
  monthly_recurring_payment: 'Bayaran Ulangan Bulanan',
  year: 'Tahun',
  month_word: 'Bulan',
  billed_instruction: 'Dibilkan pada setiap 1 haribulan',
  one_month_free_trial: '*1 month free trial', // no

  // My Purchases
  my_purchases: 'Pembelian saya',
  invoice_no: 'Nombor Invois.',
  order_date: 'Tarikh Pesanan',
  total_price: 'Jumlah Harga',
  no_purchases_yet: 'Tiada pembelian dibuat lagi',
  no_purchases_free_trial_description:
    'Students who purchase subjects after {{date}} of each month will start their classes in the following month', // no
  browse_classes: 'Semak imbas kelas',
  terminate: 'Tamatkan',
  terminate_subscription: 'Tamatkan Langganan',
  terminate_confirmation: 'Adakah anda pasti untuk menamatkan langganan?',

  // Payment
  payment_method: 'Kaedah pembayaran',

  // Profile
  upload_image: 'Muat naik gambar',
  update_birthday_instruction: 'Ruangan tarikh lahir diperlukan',
  update_name_instruction: 'Nama depan dan nama belakang diperlukan',
  dob: 'D.O.B',

  // Question Bank
  no_question_bank_available: 'Tiada bank soalan ditemui',
  no_attempts: 'Tiada percubaan ditemui',

  // Question Bank Details
  please_choose: 'Sila pilih',
  buy_and_add_to_cart: 'Beli / Tambah ke troli',

  // Question bank records
  total_questions: 'Jumlah Soalan',
  correct: 'Betul',
  incorrect: 'Salah',
  answered: 'Telah dijawab',
  new: 'Baru',
  sort_by: 'Susun mengikut',
  date_descending: 'Tarikh menurun',
  date_ascending: 'Tarikh menaik',
  in_progress: 'Dalam progres',
  done: 'Selesai',
  edit_name: 'Ubah Nama',
  delete: 'Padam',
  average_score: 'Purata Markah',
  performance_tracker: 'Penjejak Prestasi',
  question_bank_records: 'Rekod Bank Soalan',
  no: 'No.',
  scores: 'Skor',

  // Question Bank New Attempt
  // Step 1
  step_1: 'Langkah 1 dari 4: Pilih bab',
  choose_chapters_instruction: 'Pilih bab untuk disertakan',
  choose_chapters_error: 'Sila pilih sekurang-kurangnya 1 bab',
  select_all: 'Pilih semua',
  unselect_all: 'Tidak pilih semua',
  next_step: 'Langkah seterusnya',

  // Step 2
  step_2: 'Langkah 2 dari 4: Jenis Soalan',
  select_questions_instruction: 'Pilih jenis soalan untuk disertakan',
  select_questions_error: 'Sila pilih jenis soalan',
  all_questions: 'Semua soalan dari bab yang dipilih',
  unattempted_questions: 'Soalan yang tidak dicuba',
  previously_incorrect: 'Sebelum ini tidak betul',
  previous_step: 'Langkah sebelum',

  // Step 3
  step_3: 'Langkah 3 dari 4: Bilangan soalan',
  select_number_of_questions_instruction: 'Berapa banyak soalan untuk anda praktis?',
  maximum_questions_instruction: 'bilangan soalan maksimum yang boleh diperolehi atau dibenarkan',

  // Step 4
  step_4: 'Langkah 4 dari 4: Penamaan',
  enter_name_instruction: 'Masukkan nama untuk percubaan bank soalan anda:',
  enter_name_error: 'Sila isi nama',
  launch_qb: 'Lancarkan Bank Soalan',

  // Performance tracker
  answered_questions: 'Answered Questions', // no
  performance_by_chapters: 'Prestasi mengikut bab',
  all_chapters: 'Semua Bab',

  // Question bank record details
  results: 'Keputusan',
  show_only: 'Papar sahaja',
  answers: 'Jawapan',
  view: 'Lihat',
  all: 'Semua',
  no_answered_question: 'Tiada soalan terjawab ditemui',

  // Purchased Question Bank
  score: 'Skor',
  last_attempt: 'Percubaan Terakhir',
  actions: 'Tindakan',
  reattempt: 'Cuba Semula',
  launch: 'Mulakan',
  start: 'Mula',
  continue: 'Teruskan',
  review: 'Semakan',

  // Question
  self_marking: 'Semakan Sendiri',
  hide_answer: 'Sembunyi Jawapan',
  show_answer: 'Paparkan Jawapan',
  previous_question: 'Soalan Sebelum',
  next_question: 'Soalan Seterusnya',
  submit_answer: 'Hantar Jawapan',
  no_chapter_name: 'No chapter name', // no
  bookmark_error: 'Soalan tidak boleh di bookmark. Sila cuba sebentar lagi.',
  save_answer_error: 'Tidak dapat menyimpan jawapan. Sila cuba sebentar lagi',
  submit_marks: 'Hantar markah',

  // Question End Session
  end_session: 'Tamat Sesi',
  confirm_end_session: 'Sahkan Tamat Sesi?',
  end_session_text:
    'Dengan menamatkan sesi, ringkasan soalan yang dicuba akan dipaparkan. Sila pastikan semua soalan subjektif ditandakan untuk pemarkahan yang tepat.',
  end_session_network_error: 'Tidak dapat menamatkan sesi',

  // End Session
  questions_unmarked: 'Soalan tidak bertanda',
  end: 'Tamat',
  cancel: 'Batal',

  // Question bank summary
  question_bank_summary: 'Rumusan Bank Soalan',

  // Homework summary
  homework_summary: 'Ringkasan Kerja Rumah',

  // Question bank and homework summary
  no_of_questions: 'Bilangan Soalan:',
  questions_answered: 'Soalan telah dijawab',
  questions_unanswered: 'Soalan tidak dijawab',
  end_date: 'Tarikh akhir',
  total_time: 'Jumlah masa',

  // Resources
  reading_materials: 'Bahan bacaan',
  recorded_classes: 'Rakaman Kelas',
  no_reading_materials: 'Tiada bahan bacaan dijumpai',
  no_recorded_classes: 'Tiada rakaman kelas',
  watch_replay: 'Tonton Semula',
  successful: 'Berjaya',
  browse: 'Layari',

  // Homework
  question_list: 'Senarai Soalan',
  homework: 'Kerja Rumah',

  // Homework records
  progress: 'Progres',
  homework_records: 'Rekod Kerja Rumah',
  date: 'Tarikh',
  no_records: 'Tiada rekod kerja rumah dijumpai.',

  // Homework record
  attempt_details: 'Butiran percubaan',

  // Homework tracker
  due: 'Tamat Tempoh',
  submitted: 'Dihantar',
  no_homework: 'Tiada kerja rumah hari ini!',
  late: 'Lewat',
  details: 'Butiran',
  late_submission: 'Penyerahan lewat',
  due_in_month_zero: 'Tamat tempoh dalam {{count}}',
  due_in_month_one: 'Tamat tempoh dalam {{count}}',
  due_in_month_other: 'Tamat tempoh dalam {{count}}',
  due_in_week_zero: 'Tamat tempoh dalam {{count}} minggu',
  due_in_week_one: 'Tamat tempoh dalam {{count}} minggu',
  due_in_week_other: 'Tamat tempoh dalam {{count}} minggu',
  due_in_day_zero: 'Tamat tempoh dalam {{count}} hari',
  due_in_day_one: 'Tamat tempoh dalam {{count}} hari',
  due_in_day_other: 'Tamat tempoh dalam {{count}} hari',
  due_today: 'Tamat Tempoh Hari Ini',

  // Homework and Question Bank
  no_questions: 'Tiada soalan ditemui',

  // Add to cart
  added_to_cart: 'Item ditambah ke troli',
  add_to_cart_error: 'Item tidak dapat ditambah ke troli. Sila cuba sebentar lagi',

  // Payment
  checkout_now: 'Semak keluar sekarang',
  billing_info: 'Maklumat bil',
  add_to_subs: 'Tambah ke langganan',

  // Parents notification
  please_enter_your_password: 'Sila isi kata laluan anda',
  please_enter_your_new_password: 'Sila isi kata laluan baru anda',
  verify: 'Sahkan',
  reset_password: 'Set semula kata laluan',
  verification_instruction: 'Sila masukkan kod pengesahan 6 digit yang dihantar ke e-mel anda.',
  submit: 'Hantar',
  optional: 'opsyenal',
  save: 'Simpan',
  parents_email: 'E-mel Ibu Bapa',
  parents_name: 'Nama Ibu Bapa',
  parents_mobile_number: 'No. telefon ibu bapa',
  students_name: 'Nama pelajar',
  edit_details: 'Edit Butiran',
  are_you_sure_to_cancel_notification: 'Adakah anda pasti untuk membatalkan notifikasi?',
  cancel_notification: 'Batalkan Notifikasi',
  verify_email: 'Sahkan e-mel',
  resend_code: 'Hantar semula kod',
  unverified_email: 'E-mel belum disahkan',
  resend_verification_code: 'Hantar semula kod pengesahan',
  residence: 'Penghuni',
  parents_notification_agreement:
    'Adakah anda ingin menerima salinan laporan penilaian, perhatian dan kehadiran pelajar? Laporan akan disediakan dan dihantar kepada pelajar setiap dua minggu.',
  for_parents: ' *Untuk ibu bapa (pilihan)',

  // Change language
  failed_to_change_language: 'Gagal untuk mengubah bahasa',
  choose_language: 'Pilih bahasa',

  // Billing info
  items: 'Item',
  street: 'Alamat jalan, apartmen, suite, dll',
  city: 'Bandar',
  postcode: 'Poskod',
  state: 'Negeri',

  // Purchase details
  purchase_details: 'Butiran Pembelian',
  order_summary: 'Ringkasan Pesanan',
  bundle_discount: 'Diskaun Bundle',
  total_summary: 'Ringkasan Jumlah',
  terminate_subscription_successful: 'Bayaran langganan berjaya ditamatkan',
  total_monthly_recurring: 'Jumlah Berulang Bulanan',
  paid_by: 'Dibayar oleh',
  total_fee: 'Jumlah Yuran',

  // Course details:
  enrol_for_free: 'Daftar secara percuma',
  enrol_for_free_successful: 'Anda telah berjaya mendaftar',
  subscribe_now: 'Subscribe Now', // no
  free_trial_30_days: 'Free Trial (30 days)', // no
  start_1_month_trial: 'Start Your 1 Month Free Trial', // no
  try_course_for_free: 'Try {{name}} free.', // no
  add_to_trial_list: 'Add to trial list', // no
  free_trial_information:
    "*After 1 month of free trial, you will be charged with the class(es)' stated price. Cancel at any time to stop future charges.",
  go_to_cart: 'Go to cart', // no
  add_more_classes: 'Add more classes', // no

  // Contact Us
  contact_us: 'Hubungi Kami',
  get_in_touch: 'Hubungi Kami',
  message: 'Mesej',
  more_info: 'More Info', // no
  tavis_showroom: 'Tavis showroom', // no
  social_media: 'Social Media', // no
  privacy_policy: 'Dasar Privasi',
  customer_service_information:
    'When you contact Customer Service, your personal data will be processed in accordance with our', // no
  message_sent: 'The message is sent successfully', // no
  upload_from_computer: 'Upload from computer', // no
  max_file_size: 'Lampiran tidak boleh besar daripada 10 MB',
  incorrect_captcha: 'Incorrect captcha, please try again.', // no

  // Onboarding
  welcome_to_tavis_community: 'Welcome to the Tavis Community!', // no
  complete_sign_up_process_instruction:
    'To complete the sign up process, please complete the information below:', // no
  student_details: "Student's Details", // no
  mobile_number: 'Mobile Number', // no
  current_level_of_study: 'Current Level of Study', // no
  email_address: 'Email Address', // no
  parents_details: 'Parent’s Details (emergency contact)', // no
  user_agreement: 'User Agreement', // no
  user_agreement_details: 'By completing sign up, you agree to the Tavis', // no
  terms_of_service: 'Terms of Service', // no
  and: 'and', // no
  finish: 'Finish', // no
  reference_code: 'Reference Code: (if any)', // no

  // Exam
  exam: 'Exam', // no
  upcoming: 'Upcoming', // no
  past: 'Past', // no
  test_id: 'Test ID', // no
  time_remaining: 'Time Remaining', // no
  confirm_end_exam: 'Confirm End Exam', // no
  end_exam_description:
    'Ending exam will submit all questions attempted, questions without selections would be marked as incorrect.', // no
  questions_without_selections: 'Questions without selections', // no
};
