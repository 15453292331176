import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function LogOutIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        id="Icon_metro-exit"
        data-name="Icon metro-exit"
        d="M42.571,33.178v-6.25H25.9v-6.25H42.571v-6.25l10,9.375Zm-3.333-3.125v12.5H22.571v9.375l-20-9.375V1.928H39.237V17.553H35.9V5.053H9.237L22.571,11.3V39.428H35.9V30.053Z"
        transform="translate(-2.571 -1.928)"
        fill="currentColor"
      />
    </Icon>
  );
}
