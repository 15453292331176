import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Link,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ReCAPTCHA from 'react-google-recaptcha';

import DashboardShell from 'src/components/DashboardShell';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import {
  InstagramIcon,
  SocialMediaFacebookIcon,
  TikTokIcon,
  TwitterIcon,
  UploadIcon,
  YoutubeIcon,
} from 'src/icons';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs, resetContactUs } from 'src/redux/contactUs/actions';
import useSessionExpired from 'src/components/SessionExpired';
import { selectContactUsStatusCode } from 'src/redux/contactUs/selectors';
import { SmallCloseIcon } from '@chakra-ui/icons';

function ContactUs(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const contactUsStatusCode = useSelector(selectContactUsStatusCode);

  const captchaRef = useRef<ReCAPTCHA | null>(null);

  let captcha = '';

  useEffect(() => {
    if (contactUsStatusCode === 200) {
      toast({
        title: t('message_sent'),
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 1500,
      });
      dispatch(resetContactUs());
      const form = document.getElementById('form') as HTMLFormElement;
      form.reset();
      clearUploadedFile();
      captchaRef.current?.reset();
    }
  }, [dispatch, contactUsStatusCode]);

  const onSubmitForm = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (file) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 10;
      if (fsMb > MAX_FILE_SIZE) {
        setErrorMessage(t('max_file_size'));
        captchaRef.current?.reset();
        return;
      }
    }

    if (captcha == '') {
      toast({
        title: t('incorrect_captcha'),
        position: 'top',
        status: 'error',
        isClosable: true,
        duration: 1500,
      });
      captchaRef.current?.reset();
      return;
    }

    const firstName = (document.getElementById('firstname') as HTMLInputElement).value;
    const lastName = (document.getElementById('lastname') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const phoneNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
    const message = (document.getElementById('message') as HTMLInputElement).value;

    dispatch(
      contactUs({
        firstName,
        lastName,
        email,
        phoneNumber,
        message,
        captcha,
        attachment: file ? file : undefined,
      }),
    );
  };

  const onChange = (value: string | null) => {
    if (value === null) {
      captcha = '';
    } else {
      captcha = value;
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFileName(files[0].name);
      setFile(files[0]);
      setErrorMessage('');
    }
  };

  const clearUploadedFile = () => {
    const fileInput = document.getElementById('file') as HTMLInputElement;
    fileInput.files = null;
    setFile(null);
    setFileName('');
    setErrorMessage('');
  };

  useSessionExpired(contactUsStatusCode);

  return (
    <DashboardShell settingsScreen={true}>
      <TitleTopbar title={t('contact_us')} />
      <Flex flex="1" m={5} boxShadow="0 0 5px gray" borderRadius="10px">
        <Flex h="100%" w="100%">
          <Box h="100%" w="50%" py={5} pl={8}>
            <OverlayScrollbarsComponent
              options={{ scrollbars: { autoHide: 'scroll' } }}
              style={{ height: '100%', width: '100%' }}
            >
              <Stack
                id="form"
                h="100%"
                w="100%"
                direction="column"
                spacing={6}
                pr={8}
                as="form"
                onSubmit={onSubmitForm}
              >
                <Text fontSize={24} fontWeight="medium">
                  {t('get_in_touch')}
                </Text>
                <Flex>
                  <Box>
                    <Text>{t('firstname')}</Text>
                    <Input id="firstname" w="200px" required />
                  </Box>
                  <Spacer />
                  <Box>
                    <Text>{t('lastname')}</Text>
                    <Input id="lastname" w="200px" required />
                  </Box>
                </Flex>
                <Box>
                  <Text>{t('email')}</Text>
                  <Input id="email" type="email" w="300px" required />
                </Box>
                <Box>
                  <Text>{t('phone_number')}</Text>
                  <Input
                    id="phoneNumber"
                    type="number"
                    w="300px"
                    required
                    maxLength={13}
                    onInput={(event) => {
                      const target = event.currentTarget;
                      if (target.value.length > target.maxLength) {
                        target.value = target.value.slice(0, target.maxLength);
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Text>{t('message')}</Text>
                  <Textarea id="message" rows={4} required />
                </Box>
                <Flex flexDir="column" w="100%">
                  {fileName && (
                    <Flex mb={2} alignItems="center" w="100%">
                      <Text mr={5}>{fileName}</Text>
                      <SmallCloseIcon boxSize="23px" onClick={clearUploadedFile} />
                    </Flex>
                  )}
                  <Button
                    w="fit-content"
                    fontSize="14px"
                    fontWeight="medium"
                    onClick={() => {
                      const input = document.getElementById('file') as HTMLInputElement;
                      if (input) {
                        input.click();
                      }
                    }}
                  >
                    <UploadIcon mr={2} />
                    {t('upload_from_computer')}
                  </Button>
                  <Input
                    type="file"
                    id="file"
                    display="none"
                    accept={'.pdf, .doc, .docx, .pptx, .jpg, .jpeg, .png, .csv, .xlsx'}
                    onChange={handleFileUpload}
                  />
                  {errorMessage && (
                    <Text color="red" mb={5}>
                      {errorMessage}
                    </Text>
                  )}
                </Flex>
                <Box mt="40px">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={'6LeHPgEfAAAAACvHPFGT2FmAH_uiwvw9_77_36KS'}
                    onChange={onChange}
                  />
                </Box>
                <Flex justifyContent="flex-end">
                  <Button
                    bg="#1BC35C"
                    color="white"
                    w="fit-content"
                    type="submit"
                    _hover={{ bg: '#00912F' }}
                    _active={{ bg: '#006200' }}
                  >
                    {t('submit')}
                  </Button>
                </Flex>
              </Stack>
            </OverlayScrollbarsComponent>
          </Box>
          <Flex
            minH="100%"
            h="fit-content"
            minW="fit-content"
            w="50%"
            bg="#65CD7D"
            flexDirection="column"
            p={5}
            color="white"
            borderRightRadius="10px"
          >
            <Stack spacing={6}>
              <Text fontSize={24} fontWeight="medium">
                {t('more_info')}
              </Text>
              <Text>{t('tavis_showroom')}</Text>
              <Text whiteSpace="pre-wrap">
                81, Jalan Maarof, <br />
                Bangsar, <br />
                59000 Kuala Lumpur.
              </Text>
              <Link href="mailto:support@tavis.live">support@tavis.live</Link>
            </Stack>
            <Stack spacing={6} mt={10}>
              <Text fontSize={24} fontWeight="medium">
                {t('social_media')}
              </Text>
              <HStack spacing={10}>
                <SocialMediaFacebookIcon boxSize={26} />
                <InstagramIcon boxSize={26} />
                <TwitterIcon boxSize={26} />
                <YoutubeIcon boxSize={30} />
                <TikTokIcon boxSize={26} />
              </HStack>
              <Text w="500px">
                {t('customer_service_information')}&nbsp;
                <Link textDecor="underline" onClick={() => window.openExternal.openPaymentPolicy()}>
                  {t('privacy_policy')}
                </Link>
              </Text>
            </Stack>
          </Flex>
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default ContactUs;
