import React from 'react';
import { Button } from '@chakra-ui/react';

interface BottomButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

function BottomButton({ children, onClick }: BottomButtonProps): JSX.Element {
  return (
    <Button
      fontSize={18}
      fontWeight="semibold"
      minW="fit-content"
      color="white"
      bg="#1BC35C"
      _hover={{ bg: '#00912F' }}
      _active={{ bg: '#00912F' }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default BottomButton;
