import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { HStack, Flex, Text, Input, Button } from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/analytics';
import { IS_DEV } from 'src/constants/environment';
import { formatPrice } from 'src/utils/formatPrice';
import { CreateOrderPayload, PaymentDetails } from 'src/redux/order/models/payload';
import { createOrder } from 'src/redux/order/actions';
import { selectNewOrder } from 'src/redux/order/selectors';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import CourseItem from './CourseItem';
import QuestionBankItem from './QuestionBankItem';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ResourceItem from './ResourceItem';
import { Course } from 'src/models/cart/Course';
import { QuestionBank } from 'src/models/cart/QuestionBank';
import { Resource } from 'src/models/cart/Resource';
import usePageTracking from '../PageTracking';

interface LocationState {
  from: string;
  duration: number[];
  startYear: number;
  startMonth: number;
  promoCode: string | null;
  grandTotalWithPromo: number;
  totalAmount: number;
  courses: Course[] | undefined;
  questionBank: QuestionBank[] | undefined;
  resources: Resource[] | undefined;
}
function BillingInfo(): JSX.Element {
  usePageTracking('billing-info');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const {
    from,
    duration,
    startYear,
    startMonth,
    promoCode,
    grandTotalWithPromo,
    totalAmount,
    courses,
    questionBank,
    resources,
  } = state;

  const hasPromoCode = Boolean(promoCode);

  const orderCreated = useSelector(selectNewOrder);

  const hasCourses = courses && Boolean(courses.length);
  const hasQuestionBank = questionBank && Boolean(questionBank.length);
  const hasResources = resources && Boolean(resources.length);

  const isPrepaid = from === 'prepaid';

  const setupCartItems = () => {
    const hasPrepaidCart = hasCourses || hasQuestionBank || hasResources;

    // Prepaid payment
    if (hasPrepaidCart && from === 'prepaid') {
      const masterCart = [];

      if (hasCourses) {
        let largestDuration = 0;
        for (let i = 0; i <= duration.length; i++) {
          if (duration[i] > largestDuration) {
            largestDuration = duration[i];
          }
        }
        const forCartCourses = courses.map((course, index) => {
          const selectedMonth: number[] = [];
          if (duration && startMonth) {
            const newStartMonth =
              duration[index] === largestDuration
                ? startMonth
                : largestDuration - duration[index] + startMonth;
            for (let i = 0; i < duration[index]; i++) {
              selectedMonth.push(newStartMonth + i);
            }
          }
          return {
            id: course.id,
            categoryType: 'course',
            months: selectedMonth,
            bundleDiscount: course.bundleDiscount ?? 0,
          };
        });
        masterCart.push(...forCartCourses);
      }

      if (hasQuestionBank) {
        const forCartQuestionBank = questionBank.map((question) => ({
          id: question.id,
          categoryType: 'questionbank',
          months: null,
          bundleDiscount: 0,
        }));
        masterCart.push(...forCartQuestionBank);
      }

      if (hasResources) {
        const forCartResources = resources.map((resource) => ({
          id: resource.id,
          categoryType: 'resource',
          months: null,
          bundleDiscount: 0,
        }));
        masterCart.push(...forCartResources);
      }

      return masterCart;
    }

    //Subscription payment
    if (hasCourses && from === 'subscription') {
      return courses.map((course) => ({
        id: course.id,
        bundleDiscount: course.bundleDiscount ?? 0,
      }));
    }

    return [];
  };

  const getItemsList = () => {
    const items: {
      item_id: string;
      item_name: string;
      quantity?: number;
      item_list_name: string;
      item_category: string;
      price: number;
    }[] = [];

    if (isPrepaid) {
      courses?.forEach((item, index) => {
        items.push({
          item_id: item.id.toString(),
          quantity: duration[index],
          item_name: item.courseName,
          price: getCoursePriceStructure(item).hasDiscount
            ? getCoursePriceStructure(item).discountPrice
            : getCoursePriceStructure(item).originalPrice,
          item_list_name: 'Courses',
          item_category: 'Courses',
        });
      });
      questionBank?.forEach((qb) => {
        items.push({
          item_id: qb.id.toString(),
          item_name: qb.questionBankName,
          price: qb.discountPrice ? qb.discountPrice : qb.price,
          item_list_name: 'Question Bank',
          item_category: 'Question Bank',
        });
      });
      resources?.forEach((resource) => {
        items.push({
          item_id: resource.id.toString(),
          item_name: resource.resourceName,
          price: resource.discountPrice ? resource.discountPrice : resource.price,
          item_list_name: 'Resources',
          item_category: 'Resources',
        });
      });
    } else {
      courses?.forEach((item) => {
        items.push({
          item_id: item.id.toString(),
          item_name: item.courseName,
          price: getCoursePriceStructure(item).hasDiscount
            ? getCoursePriceStructure(item).discountPrice
            : getCoursePriceStructure(item).originalPrice,
          item_list_name: 'Courses',
          item_category: 'Courses',
        });
      });
    }
    return items;
  };

  const onSubmit = () => {
    const idNumber = (document.getElementById('ic') as HTMLInputElement).value;
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const phone = (document.getElementById('phone') as HTMLInputElement).value;
    const address = (document.getElementById('address') as HTMLInputElement).value;
    const city = (document.getElementById('city') as HTMLInputElement).value;
    const postcode = (document.getElementById('postcode') as HTMLInputElement).value;
    const state = (document.getElementById('state') as HTMLInputElement).value;

    const cartItems = setupCartItems();

    let paymentDetails: PaymentDetails = {
      freeTrial: !isPrepaid && false,
      gateway: isPrepaid ? 'iPayOnlineBanking' : 'iPayRecurring',
      promocode: Boolean(promoCode) ? String(promoCode) : '',
      idNumber,
      name,
      email,
      phone,
      address,
      city,
      postcode,
      state,
      country: 'Malaysia',
    };

    if (from === 'subscription') {
      paymentDetails = {
        ...paymentDetails,
        startMonth: startMonth,
        startYear: startYear,
      };
    }

    const newOrder: CreateOrderPayload = {
      paymentDetails,
      cartItems,
      testMode: IS_DEV,
    };

    if (!IS_DEV) {
      logEvent(analytics, 'add_payment_info', {
        currency: 'MYR',
        value: isPrepaid ? totalPrice.prepaid : totalPrice.subscription,
        coupon: promoCode ? promoCode : undefined,
        items: getItemsList(),
      });
    }
    dispatch(createOrder(newOrder));
  };

  useEffect(() => {
    if (orderCreated) {
      window.payment.redirectToPayment(orderCreated);
      window.payment.paymentComplete((paymentSuccess: boolean) => {
        if (paymentSuccess) {
          if (!IS_DEV) {
            const items = getItemsList();
            logEvent(analytics, 'purchase', {
              transaction_id: orderCreated.RefNo,
              currency: 'MYR',
              value: isPrepaid ? totalPrice.prepaid : totalPrice.subscription,
              coupon: promoCode ? promoCode : undefined,
              items: items,
            });
          }
          navigate('/my-purchases?from=payment');
        } else {
          const to = isPrepaid ? 'prepaid' : 'subscription';
          navigate(`/${to}?payment=0`);
        }
      });
    }
  }, [orderCreated]);

  const totalPrice = {
    subscription: totalAmount,
    prepaid: totalAmount,
  };

  return (
    <DashboardShell>
      <BackTopbar title={t('checkout_now')} />
      <HStack spacing="8" h="100%" w="100%" p="8">
        <Flex
          direction="column"
          w="50%"
          h="100%"
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Text fontSize="2xl" fontWeight="semibold">
            {t('billing_info')}
          </Text>
          <OverlayScrollbarsComponent
            style={{ width: '100%', height: '100%' }}
            options={{ scrollbars: { autoHide: 'scroll' } }}
          >
            <Text mt="4">{t('email')}</Text>
            <Input mt="2" placeholder="johndoe@test.com" type="email" id="email" required />
            <Text mt="4">{t('full_name')}</Text>
            <Input placeholder="John Doe" mt="2" required id="name" />
            <Text mt="4">{t('phone_no')}</Text>
            <Input type="number" placeholder="60123456789" mt="2" id="phone" required />
            <Text mt="4">{t('ic')}</Text>
            <Input placeholder="010110-10-1010" mt="2" id="ic" required />
            <Text mt="4">{t('address')}</Text>
            <Flex direction="column">
              <Input mt="2" placeholder={t('street')} id="address" required />
              <HStack mt="2" spacing="4">
                <Input placeholder={t('city')} required id="city" />
                <Input type="number" placeholder={t('postcode')} id="postcode" required />
              </HStack>
              <Input mt="2" placeholder={t('state')} required id="state" />
            </Flex>
            <Flex mt="4" justifyContent="flex-end">
              <Button
                bg="#19E596"
                px="8"
                py="4"
                fontWeight="semibold"
                color="white"
                fontSize="lg"
                _hover={{ bg: '#17d48b' }}
                _active={{ bg: '#14c782' }}
                type="submit"
              >
                {t('continue')}
              </Button>
            </Flex>
          </OverlayScrollbarsComponent>
        </Flex>
        <Flex
          w="50%"
          h="100%"
          direction="column"
          p="10"
          bg="#65CD7D"
          color="white"
          borderRadius="xl"
          boxShadow="dark-lg"
        >
          <Text fontSize="2xl" fontWeight="semibold">
            {t('cart')}
          </Text>
          <Flex mt="4" fontSize="lg" fontWeight="medium">
            <Text w="75%">{t('items')}</Text>
            <Text w="25%">{t('amount')}</Text>
          </Flex>
          <OverlayScrollbarsComponent
            style={{ width: '100%', height: '100%' }}
            options={{ scrollbars: { autoHide: 'scroll' } }}
          >
            <Flex mt="4" direction="column" height="100%" maxH="50vh">
              {courses?.map((course, index) => (
                <CourseItem
                  key={course.cartId}
                  course={course}
                  isPrepaid={isPrepaid}
                  duration={duration ? duration[index] : undefined}
                  startYear={startYear ? startYear : undefined}
                  startMonth={startMonth ? startMonth : undefined}
                />
              ))}
              {isPrepaid &&
                questionBank?.map((question) => (
                  <QuestionBankItem key={question.cartId} question={question} />
                ))}
              {isPrepaid &&
                resources?.map((resource) => (
                  <ResourceItem key={resource.cartId} resource={resource} />
                ))}
            </Flex>
          </OverlayScrollbarsComponent>
          <Flex
            justifyContent="space-between"
            fontSize="lg"
            borderTopWidth="1px"
            borderTopColor="white"
            pt="4"
          >
            <Text>{t('total')}</Text>
            {hasPromoCode ? (
              <HStack spacing="2">
                {isPrepaid ? (
                  <Text textDecoration="line-through">
                    {formatPrice(String(totalPrice.prepaid))}
                  </Text>
                ) : (
                  <Text textDecoration="line-through">
                    {formatPrice(String(totalPrice.subscription)) + '/month'}
                  </Text>
                )}
                <Text>
                  {isPrepaid
                    ? formatPrice(String(grandTotalWithPromo))
                    : formatPrice(String(grandTotalWithPromo)) + '/month'}
                </Text>
              </HStack>
            ) : (
              <Text>
                {isPrepaid
                  ? formatPrice(String(totalPrice.prepaid))
                  : formatPrice(String(totalPrice.subscription)) + '/month'}
              </Text>
            )}
          </Flex>
        </Flex>
      </HStack>
    </DashboardShell>
  );
}

export default BillingInfo;
