import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { StudyTrack as IStudyTrack } from 'src/models/StudyTrack';
import { Months } from 'src/constants/months';

interface Props {
  months: number;
  weeks: number;
  lectures: number;
  track?: IStudyTrack;
}

function StudyTrack({ months, weeks, track, lectures }: Props): JSX.Element {
  const { t } = useTranslation();

  // Convert study track to array
  const arrayStudyTrack = [];
  for (const key in track) {
    arrayStudyTrack.push({
      order: Months[key],
      title: key,
      description: track[key],
    });
  }
  const sortedStudyTrack = arrayStudyTrack.sort((a, b) => a.order - b.order);
  const hasStudyTrack = Boolean(sortedStudyTrack.length);

  if (!track || !hasStudyTrack) return <Text mt={4}>{t('not_available')}</Text>;

  return (
    <React.Fragment>
      <Text mt={4} fontSize={14}>
        {Boolean(months) && t('month', { count: Number(months) })}{' '}
        {Boolean(weeks) && t('week', { count: Number(weeks) })} •{t('class', { count: lectures })}
      </Text>
      <Accordion
        allowMultiple
        mt="2"
        pb="4"
        overflowY="scroll"
        w="full"
        as={OverlayScrollbarsComponent}
        style={{ height: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        {sortedStudyTrack.map((item) => (
          <AccordionItem key={item.title}>
            <AccordionButton>
              <AccordionIcon />
              <Flex w="full" justifyContent="space-between">
                <Text textTransform="capitalize" fontSize={18} fontWeight="semibold">
                  {item.title}
                </Text>
              </Flex>
            </AccordionButton>

            <AccordionPanel pb={4} whiteSpace="pre-wrap">
              {parse(item.description)}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </React.Fragment>
  );
}

export default StudyTrack;
