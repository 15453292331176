import React from 'react';
import { Flex, Text, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import QuestionCard from './QuestionCard';
import { Summary } from 'src/models/homework/Summary';

interface SummaryComponentProps {
  summary: Summary;
}

function SummaryComponent({ summary }: SummaryComponentProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Flex my={5} w="100%" h="100%" px={5}>
      <Flex flexDirection="column" mr={20}>
        <Grid templateColumns="auto auto" gap={10} columnGap={20} fontSize={20}>
          <Text fontWeight="medium">{t('score')}:</Text>
          <Text>{`${summary.mark}/${summary.totalMark} (${Math.floor(
            (summary.mark / summary.totalMark) * 100,
          )}%)`}</Text>
          <Text fontWeight="medium">{t('no_of_questions')}:</Text>
          <Text>{summary.totalQuestions}</Text>
          <Text fontWeight="medium">{t('questions_answered')}:</Text>
          <Text>{summary.questionsAnswered}</Text>
          <Text fontWeight="medium">{t('questions_unanswered')}:</Text>
          <Text>{summary.questionsUnanswered}</Text>
          <Text fontWeight="medium">{t('start_date')}:</Text>
          <Text>{new Date(summary.startDate).toLocaleDateString()}</Text>
          <Text fontWeight="medium">{t('end_date')}:</Text>
          <Text>{new Date(summary.endDate).toLocaleDateString()}</Text>
          <Text fontWeight="medium">{t('total_time')}:</Text>
          <Text>{summary.totalTime}</Text>
        </Grid>
      </Flex>
      <Flex
        minW="170px"
        maxW="300px"
        as={OverlayScrollbarsComponent}
        style={{ width: '100%', height: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Flex flexDirection="column" pr={5}>
          {summary.questions.map((item) => {
            return (
              <QuestionCard key={item.id} marks={item.mark} totalMarks={item.defaultMark}>
                {item.name}
              </QuestionCard>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SummaryComponent;
