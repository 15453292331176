import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Text,
  Spacer,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  useToast,
  Input,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  CancelIcon,
  ConfirmIcon,
  DeleteIcon,
  EditQuestionBankIcon,
  ReviewIcon,
  ThreeDotIcon,
} from 'src/icons';
import { QuestionBankAttempt } from 'src/models/QuestionBank';
import {
  deleteAttempt,
  editAttempt,
  getQuestion,
  getQuestionBankDetails,
} from 'src/redux/questionBank/actions';
import {
  selectDeleteAttemptStatusCode,
  selectEditAttemptStatusCode,
  selectQuestion,
  selectQuestionStatusCode,
} from 'src/redux/questionBank/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import { format } from 'date-fns';

interface RecordCardProps {
  attempt: QuestionBankAttempt;
  questionBankName: string;
  questionType: string;
  sort: string | undefined;
  showOnly: string | undefined;
}

function RecordCard({
  attempt,
  questionBankName,
  questionType,
  sort,
  showOnly,
}: RecordCardProps): JSX.Element {
  const params = useParams<{ subjectId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();

  const editNameToastId = 'editNameToastId';

  const [name, setName] = useState(attempt.attemptName);
  const [editName, setEditName] = useState(false);

  const startAttempt = useRef(false);
  const deleteAttemptRef = useRef(false);

  const questions = useSelector(selectQuestion);
  const questionsStatusCode = useSelector(selectQuestionStatusCode);
  const deleteAttemptStatusCode = useSelector(selectDeleteAttemptStatusCode);
  const editAttemptStatusCode = useSelector(selectEditAttemptStatusCode);

  useEffect(() => {
    if (startAttempt.current && questions) {
      navigate(`/question-bank-question/${params.subjectId!}/${attempt.id}/${questions[0].id}`, {
        state: {
          attemptName: attempt.attemptName,
          questionBankName,
          questionType,
        },
      });
      startAttempt.current = false;
    }
  }, [questions]);

  useEffect(() => {
    if (editName && editAttemptStatusCode === 200) {
      dispatch(
        getQuestionBankDetails({
          id: Number(params.subjectId),
          query: {
            sortorder: sort,
            showonly: showOnly,
          },
        }),
      );
      setEditName(false);
    }
  }, [editAttemptStatusCode]);

  useEffect(() => {
    if (deleteAttemptRef.current && deleteAttemptStatusCode === 200) {
      dispatch(
        getQuestionBankDetails({
          id: Number(params.subjectId),
          query: {
            sortorder: sort,
            showonly: showOnly,
          },
        }),
      );
      deleteAttemptRef.current = false;
    }
  }, [deleteAttemptStatusCode]);

  useSessionExpired(editAttemptStatusCode);
  useSessionExpired(deleteAttemptStatusCode);
  useSessionExpired(questionsStatusCode);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const castTarget = e.target as HTMLElement;

    if (castTarget.nodeName !== 'BUTTON' && castTarget.nodeName !== 'INPUT') {
      if (attempt.endDate === null) {
        dispatch(
          getQuestion({
            questionBankId: Number(params.subjectId),
            attemptId: attempt.id,
          }),
        );
        startAttempt.current = true;
      } else {
        onClickReview();
      }
    }
  };

  const onClickReview = () => {
    navigate(`/question-bank-records/${params.subjectId!}/${attempt.id}`, {
      state: {
        attemptName: attempt.attemptName,
        questionBankName,
        questionType,
      },
    });
  };

  const onClickConfirmName = () => {
    if (name.length === 0) {
      if (!toast.isActive(editNameToastId)) {
        toast({
          id: editNameToastId,
          title: t('enter_name_error'),
          position: 'top',
          status: 'error',
          duration: 800,
        });
      }
      return;
    }
    dispatch(editAttempt({ id: attempt.id, name }));
  };

  const onClickDelete = () => {
    deleteAttemptRef.current = true;
    dispatch(deleteAttempt(attempt.id));
  };

  return (
    <Flex
      alignItems="center"
      border="0.5px solid #707070"
      p={3}
      boxShadow="md"
      mb={3}
      h="fit-content"
      onClick={onClick}
      cursor="pointer"
    >
      {editName ? (
        <>
          <Input
            w="420px"
            maxLength={150}
            defaultValue={attempt.attemptName}
            onChange={(e) => setName(e.target.value)}
          />
          <IconButton
            aria-label="edit"
            icon={<CancelIcon />}
            bg="white"
            onClick={(event) => {
              event.stopPropagation();
              setEditName(false);
            }}
          />
          <IconButton
            aria-label="edit"
            icon={<ConfirmIcon />}
            bg="white"
            onClick={(event) => {
              event.stopPropagation();
              onClickConfirmName();
            }}
          />
        </>
      ) : (
        <Text fontSize={14}>{attempt.attemptName}</Text>
      )}
      <Spacer />
      <HStack spacing={5}>
        <Text fontSize={14} fontWeight="light">
          {attempt.endDate === null
            ? attempt.lastAttempt === null
              ? ''
              : format(new Date(attempt.lastAttempt), "do', 'LLLL' 'yyyy' - 'hh:mm' 'aa")
            : format(new Date(attempt.endDate), "do', 'LLLL' 'yyyy' - 'hh:mm' 'aa")}
        </Text>
        {attempt.endDate === null ? (
          <Box
            background="#0045FF"
            color="white"
            fontWeight="semibold"
            fontSize={14}
            p={2}
            borderRadius="5px"
            textTransform="uppercase"
          >
            {t('in_progress')}
          </Box>
        ) : (
          <Box
            background="#00FF27"
            fontWeight="semibold"
            fontSize={14}
            p={2}
            borderRadius="5px"
            textTransform="uppercase"
          >
            {t('done')}
          </Box>
        )}
        <Box>
          <Menu isLazy>
            <MenuButton as={Button} background="none" _focus={{ outline: 'none' }}>
              <ThreeDotIcon />
            </MenuButton>
            <MenuList fontSize={14}>
              <MenuItem
                icon={<EditQuestionBankIcon boxSize="17px" />}
                onClick={() => setEditName(true)}
              >
                <Text>{t('edit_name')}</Text>
              </MenuItem>
              {attempt.endDate !== null && (
                <MenuItem icon={<ReviewIcon boxSize="17px" />} onClick={onClickReview}>
                  <Text>{t('review')}</Text>
                </MenuItem>
              )}
              <MenuItem icon={<DeleteIcon boxSize="17px" />} color="red" onClick={onClickDelete}>
                <Text>{t('delete')}</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </Flex>
  );
}

export default RecordCard;
