import React from 'react';
import { Heading, Divider, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import RightTopbar from './RightTopbar';

interface NavigateTopbarProps {
  currentTab: string;
  firstTitle: string;
  secondTitle: string;
  firstTo: string;
  secondTo: string;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
}

function NavigateTopbar({
  currentTab,
  firstTitle,
  firstTo,
  secondTitle,
  secondTo,
  keywordSearch,
}: NavigateTopbarProps): JSX.Element {
  return (
    <RightTopbar keywordSearch={keywordSearch}>
      <HStack spacing={5}>
        <Link to={firstTo}>
          {currentTab === firstTitle ? (
            <Heading fontSize={30} fontWeight="semibold">
              {firstTitle}
            </Heading>
          ) : (
            <Heading fontSize={30} fontWeight="semibold" color="#AFAFAF">
              {firstTitle}
            </Heading>
          )}
        </Link>
        <Divider orientation="vertical" borderColor="#707070" />
        <Link to={secondTo}>
          {currentTab === secondTitle ? (
            <Heading fontSize={30} fontWeight="semibold">
              {secondTitle}
            </Heading>
          ) : (
            <Heading fontSize={30} fontWeight="semibold" color="#AFAFAF">
              {secondTitle}
            </Heading>
          )}
        </Link>
      </HStack>
    </RightTopbar>
  );
}

export default NavigateTopbar;
