import React from 'react';
import { Box, AspectRatio } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import usePageTracking from '../PageTracking';

interface LocationState {
  video: string;
  thumbnail: string;
  filename: string;
}

function VideoPlayer(): JSX.Element {
  usePageTracking('view-recorded-lectures');
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  return (
    <DashboardShell>
      <BackTopbar title={state.filename} pathname="/resources/purchased-recorded-lectures" />
      <AspectRatio w="100%" h="100%" ratio={21 / 9}>
        <Box
          as="video"
          width="full"
          height="full"
          controls
          poster={state.thumbnail}
          controlsList="nodownload"
        >
          <source src={state.video} />
        </Box>
      </AspectRatio>
    </DashboardShell>
  );
}

export default VideoPlayer;
