import React, { useEffect, useState, useRef, useCallback } from 'react';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  selectQuestion,
  selectQuestionStatusCode,
  selectQuestionNetworkError,
  selectBookmarkStatusCode,
  selectBookmarkNetworkError,
  selectSaveAnswerStatusCode,
  selectSaveAnswerNetworkError,
} from 'src/redux/questionBank/selectors';
import Question from 'src/components/Question/Question';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import NetworkErrorComponent from 'src/components/NetworkError';
import EndSession from './EndSession';
import useSessionExpired from 'src/components/SessionExpired';
import {
  bookmarkQuestion,
  getQuestion,
  resetBookmark,
  resetQuestionBank,
  resetSaveAnswer,
  saveAnswer,
  updateTimer,
} from 'src/redux/questionBank/actions';
import Loader from 'src/components/Loader';
import useBookmark from 'src/components/QuestionAndHomework/Bookmark';
import useSaveAnswer from 'src/components/QuestionAndHomework/SaveAnswer';
import { Question as HomeworkQuestion } from 'src/models/Question';
import usePageTracking from '../PageTracking';
import QuestionList from 'src/components/QuestionAndHomework/QuestionList';

interface LocationState {
  attemptName: string;
  questionBankName: string;
  questionType: string;
}

function QuestionBankQuestion(): JSX.Element {
  usePageTracking('question-bank-question');
  const params = useParams<{ subjectId: string; attemptId: string; questionId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { attemptName, questionBankName, questionType } = state;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState<string[] | null>(null);
  const [marks, setMarks] = useState<number | null>(null);
  const [bookmark, setBookmark] = useState<boolean | null>(null);
  const [tempQuestions, setTempQuestions] = useState<HomeworkQuestion[] | null>(null);

  const timer = useRef<NodeJS.Timer>();
  const startTimer = useRef(false);
  const unmountComponent = useRef(false);
  const timerSeconds = useRef(0);
  const timerMinutes = useRef(0);
  const timerHours = useRef(0);
  const [timerText, setTimerText] = useState('00:00:00');

  const questions = useSelector(selectQuestion);
  const questionsStatusCode = useSelector(selectQuestionStatusCode);
  const questionsNetworkError = useSelector(selectQuestionNetworkError);

  const bookmarkStatusCode = useSelector(selectBookmarkStatusCode);
  const bookmarkNetworkError = useSelector(selectBookmarkNetworkError);

  const saveAnswerStatusCode = useSelector(selectSaveAnswerStatusCode);
  const saveAnswerNetworkError = useSelector(selectSaveAnswerNetworkError);

  useEffect(() => {
    dispatch(
      getQuestion({
        questionBankId: Number(params.subjectId),
        attemptId: Number(params.attemptId),
      }),
    );
    startTimer.current = true;

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        unmountComponent.current = true;
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (startTimer.current) {
      if (questions) {
        const timer = questions[0].totalTime;
        const hours = parseInt(timer.slice(0, 2));
        const minutes = parseInt(timer.slice(3, 5));
        const seconds = parseInt(timer.slice(6, 8));

        timerSeconds.current = seconds;
        timerMinutes.current = minutes;
        timerHours.current = hours;

        setTimerText(
          `${timerHours.current.toString().padStart(2, '0')}:${timerMinutes.current
            .toString()
            .padStart(2, '0')}:${timerSeconds.current.toString().padStart(2, '0')}`,
        );
      }

      startTimer.current = false;
      timer.current = setInterval(() => {
        timerSeconds.current++;
        if (timerSeconds.current > 59) {
          timerSeconds.current = 0;
          timerMinutes.current++;
        }
        if (timerMinutes.current > 59) {
          timerMinutes.current = 0;
          timerHours.current++;
        }

        setTimerText(
          `${timerHours.current.toString().padStart(2, '0')}:${timerMinutes.current
            .toString()
            .padStart(2, '0')}:${timerSeconds.current.toString().padStart(2, '0')}`,
        );
      }, 1000);
    }

    if (questions && tempQuestions === null) {
      setTempQuestions(JSON.parse(JSON.stringify(questions)) as []);
    }
  }, [questions]);

  useEffect(() => {
    return () => {
      if (questions && unmountComponent.current) {
        dispatch(
          updateTimer({
            totalTime: timerText,
            attemptId: questions[currentQuestion].attemptId,
            questionBankId: Number(params.subjectId),
          }),
        );
        dispatch(resetQuestionBank());
      }
    };
  }, [timerText]);

  useEffect(() => {
    if (questions) {
      const temp = questions.filter((item) => item.id.toString() === params.questionId);
      const pos = questions.indexOf(temp[0]);
      setCurrentQuestion(pos);
      setAnswer(questions[pos].answer);
      setMarks(questions[pos].mark);
      setBookmark(questions[pos].bookmark);
      dispatch(resetBookmark());
      dispatch(resetSaveAnswer());
    }
  }, [params.questionId]);

  useEffect(() => {
    if (
      questions &&
      (answer !== questions[currentQuestion].answer || marks !== questions[currentQuestion].mark) &&
      answer !== null
    ) {
      dispatch(
        saveAnswer({
          questionId: questions[currentQuestion].id,
          answer: answer,
          totalTime: timerText,
          mark: calculateMarks(),
          attemptId: questions[currentQuestion].attemptId,
        }),
      );
    }
  }, [answer, marks]);

  useEffect(() => {
    if (questions && bookmark !== questions[currentQuestion].bookmark && bookmark !== null) {
      dispatch(
        bookmarkQuestion({
          questionId: questions[currentQuestion].id,
          bookmark: bookmark,
        }),
      );
    }
  }, [bookmark]);

  window.onbeforeunload = () => {
    if (questions) {
      dispatch(
        updateTimer({
          totalTime: timerText,
          attemptId: questions[currentQuestion].attemptId,
          questionBankId: Number(params.subjectId),
        }),
      );
    }
  };

  const onClickPreviousQuestion = useCallback(() => {
    if (questions) {
      const q = questions[currentQuestion - 1];
      navigate(`/question-bank-question/${params.subjectId!}/${q.attemptId}/${q.id}`, {
        replace: true,
        state: { attemptName, questionBankName, questionType },
      });
    }
  }, [questions, currentQuestion]);

  const onClickNextQuestion = useCallback(() => {
    if (questions) {
      const q = questions[currentQuestion + 1];
      navigate(`/question-bank-question/${params.subjectId!}/${q.attemptId}/${q.id}`, {
        replace: true,
        state: { attemptName, questionBankName, questionType },
      });
    }
  }, [questions, currentQuestion]);

  useSessionExpired(questionsStatusCode);
  useSessionExpired(bookmarkStatusCode);
  useSessionExpired(saveAnswerStatusCode);
  useBookmark(bookmarkNetworkError);
  useSaveAnswer(saveAnswerNetworkError);
  if (questionsNetworkError) return <NetworkErrorComponent />;
  if (!questions || !tempQuestions) {
    return (
      <DashboardShell>
        <BackTopbar
          title={attemptName}
          pathname={`/question-bank-records/${params.subjectId!}`}
          state={{ questionType, questionBankName }}
        />
        <Loader />
      </DashboardShell>
    );
  }

  const calculateMarks = () => {
    if (questions[currentQuestion].quesType === 'Essay' && answer) {
      return marks;
    } else if (answer) {
      for (let i = 0; i < answer.length; i++) {
        if (questions[currentQuestion].correctAnsId.includes(parseInt(answer[i]))) {
          return questions[currentQuestion].defaultmark;
        }
      }
    }
    return 0;
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={attemptName}
        pathname={`/question-bank-records/${params.subjectId!}`}
        state={{ questionType, questionBankName }}
      />
      <HStack flex="1" overflow="hidden" spacing={5} px={10} py={5}>
        <Question
          key={questions[currentQuestion].id}
          question={questions[currentQuestion]}
          tempQuestion={tempQuestions[currentQuestion]}
          currentQuestion={currentQuestion}
          totalQuestions={questions.length}
          onClickPreviousQuestion={onClickPreviousQuestion}
          onClickNextQuestion={onClickNextQuestion}
          setFinalAnswer={setAnswer}
          bookmark={bookmark}
          setBookmark={setBookmark}
          marks={marks}
          setMarks={setMarks}
        />
        <VStack h="100%" w="30%" spacing={5}>
          <QuestionList
            type="questionBank"
            questions={questions}
            subjectId={params.subjectId}
            state={{ attemptName, questionBankName, questionType }}
          />
          <VStack
            bg="white"
            h="20%"
            w="100%"
            borderRadius="10px"
            justifyContent="center"
            p={5}
            boxShadow="0 0 5px gray"
          >
            <Text fontSize={20}>{timerText}</Text>
            <EndSession
              totalTimer={timerText}
              attemptName={attemptName}
              questionBankName={questionBankName}
              questionType={questionType}
            />
          </VStack>
        </VStack>
      </HStack>
    </DashboardShell>
  );
}

export default QuestionBankQuestion;
