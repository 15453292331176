import { of } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import * as QBConstants from './constants';
import {
  QuestionResponse,
  QuestionBankResponse,
  GetQuestionBankAction,
  GetQuestionBankSuccessAction,
  GetMoreQuestionBankAction,
  GetMoreQuestionBankSuccessAction,
  GetPurchasedQuestionBankAction,
  GetPurchasedQuestionBankSuccessAction,
  GetMorePurchasedQuestionBankAction,
  GetMorePurchasedQuestionBankSuccessAction,
  GetQuestionAction,
  GetQuestionSuccessAction,
  SaveAnswerResponse,
  SaveAnswerAction,
  SaveAnswerSuccessAction,
  GetQuestionBankDetailsAction,
  GetQuestionBankDetailsSuccessAction,
  QuestionBankDetailsResponse,
  GetQuestionBankChaptersAction,
  GetQuestionBankChaptersSuccessAction,
  QuestionBankChaptersResponse,
  SubmitNewAttemptAction,
  SubmitNewAttemptSuccessAction,
  DeleteAttemptAction,
  DeleteAttemptSuccessAction,
  DeleteAttemptResponse,
  BookmarkQuestionAction,
  BookmarkQuestionSuccessAction,
  BookmarkResponse,
  UpdateTimerAction,
  UpdateTimerSuccessAction,
  UpdateTimerResponse,
  EndQuestionBankAction,
  EndQuestionBankSuccessAction,
  GetAttemptDetailsAction,
  GetAttemptDetailsSuccessAction,
  AttemptDetailsResponse,
  EndQuestionBankResponse,
  EditAttemptAction,
  EditAttemptSuccessAction,
  EditAttemptResponse,
  GetQuestionBankPerformanceAction,
  QuestionBankPerformanceResponse,
  GetQuestionBankPerformanceSuccessAction,
  GetQuestionBankMaxQuestionsAction,
  GetQuestionBankMaxQuestionsSuccessAction,
  MaxQuestionsResponse,
} from './models';
import { toUrlString } from 'src/utils/queryString';
import { LOGOUT } from '../auth/constants';
import { ErrorResponse } from 'src/models/ErrorResponse';

function getQuestionBankEpic(action$: ActionsObservable<GetQuestionBankAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMoreQuestionBankEpic(action$: ActionsObservable<GetMoreQuestionBankAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_MORE_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMoreQuestionBankSuccessAction => {
          return {
            type: QBConstants.GET_MORE_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getPurchasedQuestionBankEpic(action$: ActionsObservable<GetPurchasedQuestionBankAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_PURCHASED_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank/purchased?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetPurchasedQuestionBankSuccessAction => {
          return {
            type: QBConstants.GET_PURCHASED_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_PURCHASED_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getMorePurchasedQuestionBankEpic(
  action$: ActionsObservable<GetMorePurchasedQuestionBankAction>,
) {
  return action$.pipe(
    ofType(QBConstants.GET_MORE_PURCHASED_QUESTION_BANK),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/questionbank/purchased?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetMorePurchasedQuestionBankSuccessAction => {
          return {
            type: QBConstants.GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS,
            payload: {
              ...(payload.response as QuestionBankResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_PURCHASED_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionEpic(action$: ActionsObservable<GetQuestionAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION),
    switchMap((action) => {
      return ajax({
        url: `${API_URL}/questionbank/${action.payload.questionBankId}/${action.payload.attemptId}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_SUCCESS,
            payload: payload.response as QuestionResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionBankDetailsEpic(action$: ActionsObservable<GetQuestionBankDetailsAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION_BANK_DETAILS),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload.query);
      return ajax({
        url: `${API_URL}/questionbank/${action.payload.id}?${apiQuery}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankDetailsSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_BANK_DETAILS_SUCCESS,
            payload: payload.response as QuestionBankDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionBankChaptersEpic(action$: ActionsObservable<GetQuestionBankChaptersAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION_BANK_CHAPTERS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/attempt/new/' + action.id.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankChaptersSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_BANK_CHAPTERS_SUCCESS,
            payload: payload.response as QuestionBankChaptersResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_CHAPTERS_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_CHAPTERS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionBankMaxQuestionsEpic(
  action$: ActionsObservable<GetQuestionBankMaxQuestionsAction>,
) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload.query);
      return ajax({
        url: `${API_URL}/questionbank/attempt/max-question-count/${action.payload.id.toString()}?${apiQuery}`,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankMaxQuestionsSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_SUCCESS,
            payload: payload.response as MaxQuestionsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_MAX_QUESTIONS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function submitNewAttemptEpic(action$: ActionsObservable<SubmitNewAttemptAction>) {
  return action$.pipe(
    ofType(QBConstants.SUBMIT_NEW_ATTEMPT),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/attempt/new/' + action.payload.id.toString(),
        method: 'post',
        withCredentials: true,
        body: {
          name: action.payload.name,
          chapters: action.payload.chapters,
          questionsType: action.payload.questionsType,
          maxQuestions: action.payload.maxQuestions,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): SubmitNewAttemptSuccessAction => {
          return {
            type: QBConstants.SUBMIT_NEW_ATTEMPT_SUCCESS,
            payload: payload.response as QuestionResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.SUBMIT_NEW_ATTEMPT_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.SUBMIT_NEW_ATTEMPT_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function deleteAttemptEpic(action$: ActionsObservable<DeleteAttemptAction>) {
  return action$.pipe(
    ofType(QBConstants.DELETE_ATTEMPT),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/attempt/' + action.id.toString(),
        method: 'delete',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): DeleteAttemptSuccessAction => {
          return {
            type: QBConstants.DELETE_ATTEMPT_SUCCESS,
            payload: payload.response as DeleteAttemptResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.DELETE_ATTEMPT_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.DELETE_ATTEMPT_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function bookmarkQuestionsEpic(action$: ActionsObservable<BookmarkQuestionAction>) {
  return action$.pipe(
    ofType(QBConstants.BOOKMARK_QUESTION),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/question/bookmark',
        method: 'post',
        withCredentials: true,
        body: {
          questionId: action.payload.questionId,
          bookmark: action.payload.bookmark,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): BookmarkQuestionSuccessAction => {
          return {
            type: QBConstants.BOOKMARK_QUESTION_SUCCESS,
            payload: payload.response as BookmarkResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.BOOKMARK_QUESTION_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.BOOKMARK_QUESTION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function saveAnswerEpic(action$: ActionsObservable<SaveAnswerAction>) {
  return action$.pipe(
    ofType(QBConstants.SAVE_ANSWER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/question/answer',
        method: 'post',
        withCredentials: true,
        body: {
          questionId: action.payload.questionId,
          answer: action.payload.answer.join(','),
          totalTime: action.payload.totalTime,
          mark: action.payload.mark,
          attemptId: action.payload.attemptId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): SaveAnswerSuccessAction => {
          return {
            type: QBConstants.SAVE_ANSWER_SUCCESS,
            payload: payload.response as SaveAnswerResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.SAVE_ANSWER_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.SAVE_ANSWER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function updateTimerEpic(action$: ActionsObservable<UpdateTimerAction>) {
  return action$.pipe(
    ofType(QBConstants.UPDATE_TIMER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/update-timer',
        method: 'post',
        withCredentials: true,
        body: {
          totalTime: action.payload.totalTime,
          attemptId: action.payload.attemptId,
          questionBankId: action.payload.questionBankId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): UpdateTimerSuccessAction => {
          return {
            type: QBConstants.UPDATE_TIMER_SUCCESS,
            payload: payload.response as UpdateTimerResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.UPDATE_TIMER_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.UPDATE_TIMER_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function endQuestionBankEpic(action$: ActionsObservable<EndQuestionBankAction>) {
  return action$.pipe(
    ofType(QBConstants.END_QUESTION_BANK),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/end',
        method: 'post',
        withCredentials: true,
        body: {
          totalTime: action.payload.totalTime,
          attemptId: action.payload.attemptId,
          questionBankId: action.payload.questionBankId,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): EndQuestionBankSuccessAction => {
          return {
            type: QBConstants.END_QUESTION_BANK_SUCCESS,
            payload: payload.response as EndQuestionBankResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.END_QUESTION_BANK_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.END_QUESTION_BANK_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getAttemptDetailsEpic(action$: ActionsObservable<GetAttemptDetailsAction>) {
  return action$.pipe(
    ofType(QBConstants.GET_ATTEMPT_DETAILS),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/attempt/' + action.id.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetAttemptDetailsSuccessAction => {
          return {
            type: QBConstants.GET_ATTEMPT_DETAILS_SUCCESS,
            payload: payload.response as AttemptDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_ATTEMPT_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_ATTEMPT_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function editAttemptEpic(action$: ActionsObservable<EditAttemptAction>) {
  return action$.pipe(
    ofType(QBConstants.EDIT_ATTEMPT),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/attempt/' + action.payload.id.toString(),
        method: 'put',
        withCredentials: true,
        body: {
          name: action.payload.name,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): EditAttemptSuccessAction => {
          return {
            type: QBConstants.EDIT_ATTEMPT_SUCCESS,
            payload: payload.response as EditAttemptResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.EDIT_ATTEMPT_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.EDIT_ATTEMPT_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

function getQuestionBankPerformanceEpic(
  action$: ActionsObservable<GetQuestionBankPerformanceAction>,
) {
  return action$.pipe(
    ofType(QBConstants.GET_QUESTION_BANK_PERFORMANCE),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/questionbank/performance/' + action.id.toString(),
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetQuestionBankPerformanceSuccessAction => {
          return {
            type: QBConstants.GET_QUESTION_BANK_PERFORMANCE_SUCCESS,
            payload: payload.response as QuestionBankPerformanceResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: QBConstants.GET_QUESTION_BANK_PERFORMANCE_NETWORK_ERROR,
            });
          }
          return of({
            type: QBConstants.GET_QUESTION_BANK_PERFORMANCE_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const questionBankEpics: Epic = combineEpics(
  ...[
    getQuestionBankEpic,
    getMoreQuestionBankEpic,
    getQuestionEpic,
    getPurchasedQuestionBankEpic,
    getMorePurchasedQuestionBankEpic,
    getQuestionBankDetailsEpic,
    getQuestionBankChaptersEpic,
    getQuestionBankMaxQuestionsEpic,
    submitNewAttemptEpic,
    deleteAttemptEpic,
    bookmarkQuestionsEpic,
    saveAnswerEpic,
    updateTimerEpic,
    endQuestionBankEpic,
    getAttemptDetailsEpic,
    editAttemptEpic,
    getQuestionBankPerformanceEpic,
  ],
);
