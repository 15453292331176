import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function RecordedClassesIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 33.394 39.3" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-0.138"
          y1="-0.041"
          x2="-0.098"
          y2="-0.041"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#8bd0f7" />
          <stop offset="1" stopColor="#cbe8ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.738"
          y1="-17.561"
          x2="-0.643"
          y2="-17.561"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6b6ba4" />
          <stop offset="1" stopColor="#2a2a7b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-4.703"
          y1="-17.561"
          x2="-4.468"
          y2="-17.561"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-3.528"
          y1="-5.206"
          x2="-3.317"
          y2="-5.206"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="-0.532"
          y1="-0.306"
          x2="-0.468"
          y2="-0.306"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#e47979" />
          <stop offset="1" stopColor="#00a2ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="-0.138"
          y1="-14.832"
          x2="-0.098"
          y2="-14.832"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f0f3f7" />
          <stop offset="1" stopColor="#7aa3d9" />
        </linearGradient>
      </defs>
      <g
        id="_11_-_play_video_player_pause_study_online_class"
        data-name="11 - play, video, player, pause, study, online class"
        transform="translate(-3.453 -1.217)"
      >
        <path
          id="Path_1234"
          data-name="Path 1234"
          d="M36.846,4.9a3.685,3.685,0,0,0-3.683-3.683H7.136A3.685,3.685,0,0,0,3.453,4.9V36.832a3.685,3.685,0,0,0,3.683,3.685H33.163a3.685,3.685,0,0,0,3.683-3.685V4.9Z"
          transform="translate(0 0)"
          fillRule="evenodd"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_1235"
          data-name="Path 1235"
          d="M8.748,28.338H20.725a1,1,0,0,0,0-2H8.748a1,1,0,1,0,0,2Z"
          transform="translate(1.421 8.309)"
          fillRule="evenodd"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_1236"
          data-name="Path 1236"
          d="M21,28.338h3.662a1,1,0,0,0,0-2H21a1,1,0,1,0,0,2Z"
          transform="translate(5.472 8.309)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1237"
          data-name="Path 1237"
          d="M19.909,24.718a3.159,3.159,0,1,0,3.159,3.159A3.161,3.161,0,0,0,19.909,24.718Zm0,2a1.163,1.163,0,1,1-1.163,1.163A1.164,1.164,0,0,1,19.909,26.714Z"
          transform="translate(4.398 7.772)"
          fillRule="evenodd"
          fill="url(#linear-gradient-4)"
        />
        <circle
          id="Ellipse_146"
          data-name="Ellipse 146"
          cx="10.313"
          cy="10.313"
          r="10.313"
          transform="translate(9.836 5.918)"
          fill="url(#linear-gradient-5)"
        />
        <rect
          id="Rectangle_1694"
          data-name="Rectangle 1694"
          width="33.394"
          height="1.996"
          transform="translate(3.453 29.203)"
          fill="url(#linear-gradient-6)"
        />
        <path
          id="Polygon_16"
          data-name="Polygon 16"
          d="M4.658,0,9.315,7.763H0Z"
          transform="translate(25.029 11.552) rotate(90)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}
