import React, { useEffect, useState } from 'react';
import { Flex, Text, HStack, Spacer, Select, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import CircularProgressBar from 'src/components/CircularProgressBar';
import RecordCard from './RecordCard';
import { AddIcon } from 'src/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionBankDetails, resetQuestionBank } from 'src/redux/questionBank/actions';
import {
  selectQuestionBankDetails,
  selectQuestionBankDetailsStatusCode,
} from 'src/redux/questionBank/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import Loader from 'src/components/Loader';
import usePageTracking from '../PageTracking';

interface LocationState {
  questionBankName: string;
  questionType: string;
}

function QuestionBankRecords(): JSX.Element {
  usePageTracking('question-bank-records');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ subjectId: string }>();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { questionBankName, questionType } = state;
  const dispatch = useDispatch();

  const [sort, setSort] = useState<string | undefined>(undefined);
  const [showOnly, setShowOnly] = useState<string | undefined>(undefined);

  const qbDetails = useSelector(selectQuestionBankDetails);
  const statusCode = useSelector(selectQuestionBankDetailsStatusCode);

  useEffect(() => {
    return () => {
      dispatch(resetQuestionBank());
    };
  }, [dispatch]);

  useEffect(() => {
    const debounce = setTimeout(
      () =>
        dispatch(
          getQuestionBankDetails({
            id: Number(params.subjectId),
            query: {
              sortorder: sort,
              showonly: showOnly,
            },
          }),
        ),
      500,
    );
    return () => clearTimeout(debounce);
  }, [dispatch, sort, showOnly]);

  const onClickPerformanceTracker = () => {
    navigate(`/question-bank/performance-tracker/${params.subjectId!}`, {
      state: {
        questionBankName,
        questionType,
      },
    });
  };

  useSessionExpired(statusCode);
  if (!qbDetails) {
    return (
      <DashboardShell>
        <BackTopbar title={questionBankName} pathname="/purchased-question-bank" />
        <Loader />
      </DashboardShell>
    );
  }

  const onClickNew = () => {
    navigate(`/question-bank-records/${params.subjectId!}/new-attempt`, {
      state: {
        questionBankName,
        isFirstAttempt: !Boolean(qbDetails.attempts.length),
        questionType,
      },
    });
  };

  const showContent = () => {
    const attempts = qbDetails.attempts;

    if (attempts.length === 0) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Text>{t('no_attempts')}</Text>
        </Flex>
      );
    } else {
      return (
        <Box h="100%" w="100%">
          {attempts.map((attempt) => (
            <RecordCard
              key={attempt.id}
              attempt={attempt}
              questionBankName={questionBankName}
              questionType={questionType}
              sort={sort}
              showOnly={showOnly}
            />
          ))}
        </Box>
      );
    }
  };

  return (
    <DashboardShell>
      <BackTopbar title={questionBankName} pathname="/purchased-question-bank" />
      <Flex
        as={OverlayScrollbarsComponent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        h="100%"
        m={5}
        boxShadow="0 0 5px gray"
        borderRadius="10px"
        pt={5}
        flexDirection="column"
      >
        <Flex justifyContent="flex-end" mr={5}>
          <Text fontSize={12} cursor="pointer" onClick={onClickPerformanceTracker}>
            {t('performance_tracker')}
          </Text>
        </Flex>
        <Flex w="100%" h="fit-content" justify="center">
          <Flex
            alignSelf="flex-end"
            background="#0699F4"
            borderRadius="full"
            h="140px"
            w="140px"
            justifyContent="center"
            alignItems="center"
            color="white"
            flexDirection="column"
          >
            <Text fontSize={38} fontWeight="medium">
              {qbDetails.totalQuestions}
            </Text>
            <Text fontSize={16} fontWeight="medium">
              {t('total_questions')}
            </Text>
          </Flex>
          <Flex
            alignSelf="center"
            background="#EDEDED"
            borderRadius="full"
            h="150px"
            w="150px"
            justifyContent="center"
            alignItems="center"
            color="black"
            flexDirection="column"
          >
            <Text fontSize={38} fontWeight="medium">
              {qbDetails.correctAnswers}
            </Text>
            <Text fontSize={18} fontWeight="medium">
              {t('correct')}
            </Text>
          </Flex>
          <CircularProgressBar value={Number(qbDetails.score.toFixed(2))} averageScore={true} />
          <Flex
            alignSelf="center"
            background="#EDEDED"
            borderRadius="full"
            h="150px"
            w="150px"
            justifyContent="center"
            alignItems="center"
            color="black"
            flexDirection="column"
          >
            <Text fontSize={38} fontWeight="medium">
              {qbDetails.incorrectAnswers}
            </Text>
            <Text fontSize={18} fontWeight="medium">
              {t('incorrect')}
            </Text>
          </Flex>
          <Flex
            alignSelf="flex-end"
            background="#0699F4"
            borderRadius="full"
            h="140px"
            w="140px"
            justifyContent="center"
            alignItems="center"
            color="white"
            flexDirection="column"
          >
            <Text fontSize={38} fontWeight="medium">
              {qbDetails.totalAnswers}
            </Text>
            <Text fontSize={16} fontWeight="medium">
              {t('answered')}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" h="100%" w="100%" p={10}>
          <Flex w="100%" mb={5} alignItems="center">
            <HStack spacing={3} cursor="pointer" onClick={onClickNew}>
              <AddIcon />
              <Text fontSize={14} fontWeight="semibold">
                {t('new')}
              </Text>
            </HStack>
            <Spacer />
            <HStack>
              <Text fontSize={14} fontWeight="semibold">
                {t('show_only')}
              </Text>
              <Select
                fontSize={14}
                border="none"
                w="fit-content"
                _focus={{ outline: 'none' }}
                defaultValue={showOnly}
                placeholder={t('all')}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setShowOnly(e.target.value)}
              >
                <option value="in_progress">{t('in_progress')}</option>
                <option value="done">{t('done')}</option>
              </Select>
            </HStack>
            <HStack>
              <Text fontSize={14} fontWeight="semibold">
                {t('sort_by')}
              </Text>
              <Select
                fontSize={14}
                border="none"
                w="fit-content"
                _focus={{ outline: 'none' }}
                defaultValue={sort}
                placeholder={t('sort_by')}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSort(e.target.value)}
              >
                <option value="DESC">{t('date_descending')}</option>
                <option value="ASC">{t('date_ascending')}</option>
              </Select>
            </HStack>
          </Flex>
          {showContent()}
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default QuestionBankRecords;
