import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Flex, Image, Text, Stack, HStack, Box, useToast, Button } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/analytics';
import { formatPrice } from 'src/utils/formatPrice';
import { selectQuestionBank } from 'src/redux/questionBank/selectors';
import {
  selectAddToCartStatusCode,
  selectAddToCartErrorMessage,
  selectAddToCartNetworkError,
} from 'src/redux/cart/selectors';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Loader from 'src/components/Loader';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import useSessionExpired from 'src/components/SessionExpired';
import useAddToCart from 'src/components/AddToCart';
import BuyAndAddToCartButton from './BuyAndAddToCartButton';
import { IS_DEV } from 'src/constants/environment';
import {
  selectFreeClassOrderLoading,
  selectFreeClassOrderNetworkError,
  selectFreeClassOrderStatusCode,
} from 'src/redux/order/selectors';
import { createFreeClassOrder } from 'src/redux/order/actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { QuestionBank } from 'src/models/QuestionBank';
import usePageTracking from '../PageTracking';

function QuestionBankDetails(): JSX.Element {
  usePageTracking('question-bank-details');
  const params = useParams<{ subjectId?: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onCheckout, setOnCheckout] = useState(false);
  let questionBank = useSelector(selectQuestionBank)?.filter(
    (item) => item.id.toString() === params.subjectId,
  );

  if (questionBank) {
    window.localStorage.setItem('routeState', JSON.stringify(questionBank));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      questionBank = JSON.parse(item) as QuestionBank[];
    }
  }

  const addToCartStatusCode = useSelector(selectAddToCartStatusCode);
  const addToCartErrorMessage = useSelector(selectAddToCartErrorMessage);
  const addToCartNetworkError = useSelector(selectAddToCartNetworkError);

  const freeClassOrderStatusCode = useSelector(selectFreeClassOrderStatusCode);
  const freeClassOrderNetworkError = useSelector(selectFreeClassOrderNetworkError);
  const freeClassOrderLoading = useSelector(selectFreeClassOrderLoading);

  useEffect(() => {
    if (freeClassOrderStatusCode === 200) {
      toast({
        position: 'top',
        title: t('successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/purchased-question-bank');
    }
  }, [freeClassOrderStatusCode]);

  useEffect(() => {
    if (freeClassOrderNetworkError) {
      toast({
        title: t('network_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [freeClassOrderNetworkError]);

  useEffect(() => {
    if (addToCartStatusCode === 200 && questionBank) {
      if (!IS_DEV) {
        logEvent(analytics, 'add_to_cart', {
          currency: 'MYR',
          items: [
            {
              item_id: questionBank[0].id.toString(),
              item_name: questionBank[0].name,
              price: questionBank[0].discountPrice
                ? questionBank[0].discountPrice
                : questionBank[0].price,
              item_category: 'Question Bank',
              item_list_name: 'Question Bank',
            },
          ],
        });
      }
    }

    if (addToCartStatusCode === 200 && onCheckout) {
      navigate('/prepaid');
    }
  }, [addToCartStatusCode, onCheckout]);

  useAddToCart(addToCartStatusCode, addToCartErrorMessage, addToCartNetworkError);
  useSessionExpired(addToCartStatusCode);
  useSessionExpired(freeClassOrderStatusCode);
  if (!questionBank)
    return (
      <DashboardShell>
        <BackTopbar title="Question Bank" pathname="/question-bank" />
        <Loader />
      </DashboardShell>
    );

  const onEnrolFreeQuestionBank = () => {
    if (questionBank) {
      dispatch(
        createFreeClassOrder({
          id: Number(questionBank[0].id),
          categoryType: 'questionbank',
        }),
      );
    }
  };

  return (
    <DashboardShell>
      <BackTopbar title={questionBank[0].name} pathname="/question-bank" />
      <Flex
        flex="1"
        flexDirection="column"
        overflow="hidden"
        px={10}
        py={5}
        mx={10}
        my={5}
        boxShadow="0 0 5px gray"
        borderRadius="10px"
      >
        <OverlayScrollbarsComponent
          style={{ height: '100%', width: '100%' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          <Flex>
            <Image
              src={questionBank[0].imageUrl}
              h="300px"
              w="300px"
              objectFit="contain"
              borderRadius="10px"
              fallbackSrc={TavisPlaceholder}
            />
            <Stack flexDirection="column" spacing={5} justify="center" ml={10} flex="1">
              <Text fontSize={20} fontWeight="medium">
                {questionBank[0].name}
              </Text>
              <Text fontSize={18} fontWeight="medium" color="#AFAFAF">
                {questionBank[0].formName}
              </Text>
              <HStack fontSize={20} fontWeight="medium" spacing={5}>
                {questionBank[0].discountPrice ? (
                  <>
                    <Text textDecoration="line-through" color="#AFAFAF">
                      {formatPrice(questionBank[0].price.toString())}
                    </Text>
                    <Text>{formatPrice(questionBank[0].discountPrice.toString())}</Text>
                  </>
                ) : (
                  <Text>{formatPrice(questionBank[0].price.toString())}</Text>
                )}
              </HStack>
              {questionBank[0].isFree ? (
                <Button
                  isLoading={freeClassOrderLoading}
                  disabled={questionBank[0].isPurchased}
                  id="buyAndAddToCartButton"
                  w="50%"
                  fontSize={18}
                  minW="fit-content"
                  maxW="300px"
                  bg="#161DFF"
                  color="white"
                  borderRadius={0}
                  _hover={{ background: '#0000CA' }}
                  _active={{ background: '#0000CA' }}
                  onClick={onEnrolFreeQuestionBank}
                >
                  {t('enrol_for_free')}
                </Button>
              ) : (
                <BuyAndAddToCartButton
                  id={questionBank[0].id}
                  onCheckout={{ onCheckout, setOnCheckout }}
                  disable={questionBank[0].isPurchased}
                />
              )}
            </Stack>
          </Flex>
          <Box w="80%" mt={10} fontSize={18}>
            {parse(questionBank[0].description)}
          </Box>
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default QuestionBankDetails;
