import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { getFilterList } from 'src/redux/filter/actions';
import { selectFilterList } from 'src/redux/filter/selectors';
import { getLevels } from 'src/redux/resources/actions';
import { selectLevels } from 'src/redux/resources/selectors';
import { weekdays } from 'src/constants/weekdays';

interface Props {
  width?: string;
  subjectFilter?: {
    selectedSubject: string[];
    setSelectedSubject: React.Dispatch<React.SetStateAction<string[]>>;
  };
  levelFilter?: {
    selectedLevel: string[];
    setSelectedLevel: React.Dispatch<React.SetStateAction<string[]>>;
  };
  languageFilter?: {
    selectedLanguage: string[];
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string[]>>;
  };
  priceFilter?: {
    minPrice: string | null;
    setMinPrice: React.Dispatch<React.SetStateAction<string | null>>;
    maxPrice: string | null;
    setMaxPrice: React.Dispatch<React.SetStateAction<string | null>>;
  };
  weekFilter?: {
    selectedWeek: string[];
    setSelectedWeek: React.Dispatch<React.SetStateAction<string[]>>;
  };
  weekdayFilter?: {
    selectedWeekday: string[];
    setSelectedWeekday: React.Dispatch<React.SetStateAction<string[]>>;
  };
}

function FilterTab({
  subjectFilter,
  levelFilter,
  priceFilter,
  languageFilter,
  weekFilter,
  weekdayFilter,
  width,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(selectFilterList);
  const weeks = useSelector(selectLevels);

  useEffect(() => {
    dispatch(getFilterList());
    dispatch(getLevels());
  }, [dispatch]);

  return (
    <OverlayScrollbarsComponent
      style={{ height: '100%', minWidth: width ? width : '20%', maxWidth: width ? width : '20%' }}
      options={{ scrollbars: { autoHide: 'scroll' } }}
    >
      <Accordion as={Flex} pt="8" direction="column" allowMultiple>
        {subjectFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('subject')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={subjectFilter.selectedSubject}
                onChange={(value: string[]) => subjectFilter.setSelectedSubject(value)}
              >
                <Flex direction="column">
                  {filters?.subjects?.map((subject) => (
                    <Checkbox
                      key={subject.id}
                      value={String(subject.id)}
                      textTransform="capitalize"
                    >
                      {subject.name}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {levelFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('level')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={levelFilter.selectedLevel}
                onChange={(value: string[]) => levelFilter.setSelectedLevel(value)}
              >
                <Flex direction="column">
                  {filters?.forms?.map((form) => (
                    <Checkbox key={form} value={String(form)} textTransform="capitalize">
                      {form}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {languageFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('language')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={languageFilter.selectedLanguage}
                onChange={(value: string[]) => languageFilter.setSelectedLanguage(value)}
              >
                <Flex direction="column">
                  {filters?.languages?.map((language) => (
                    <Checkbox
                      key={language.id}
                      value={String(language.id)}
                      textTransform="capitalize"
                    >
                      {language.name}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {priceFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('price')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex justifyContent="space-between" alignItems="center">
                <Input
                  type="number"
                  step="0.01"
                  placeholder="RM"
                  w="full"
                  maxW="24"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    priceFilter.setMinPrice(e.target.value)
                  }
                />
                –
                <Input
                  type="number"
                  step="0.01"
                  placeholder="RM"
                  w="full"
                  maxW="24"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    priceFilter.setMaxPrice(e.target.value)
                  }
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        )}

        {weekFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('week_word')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={weekFilter.selectedWeek}
                onChange={(value: string[]) => weekFilter.setSelectedWeek(value)}
              >
                <Flex direction="column">
                  {weeks?.map((week) => (
                    <Checkbox key={week} value={week} textTransform="capitalize">
                      {week}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}

        {weekdayFilter && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {t('weekdays_word')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                value={weekdayFilter.selectedWeekday}
                onChange={(value: string[]) => weekdayFilter.setSelectedWeekday(value)}
              >
                <Flex direction="column">
                  {weekdays.map((day) => (
                    <Checkbox key={day} value={day} textTransform="capitalize">
                      {t(day.toLowerCase())}
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </OverlayScrollbarsComponent>
  );
}

export default FilterTab;
