import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ShowMoreIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <g id="_-" data-name="+/-" transform="translate(-346 -335)">
        <g
          id="Rectangle_1614"
          data-name="Rectangle 1614"
          transform="translate(346 335)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        >
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <line
          id="Line_104"
          data-name="Line 104"
          x2="8"
          transform="translate(352 345)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_105"
          data-name="Line 105"
          x2="8"
          transform="translate(356 341) rotate(90)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </Icon>
  );
}
