import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function GlobeIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props} viewBox="0 0 18 18">
      <path
        id="Icon_awesome-globe"
        data-name="Icon awesome-globe"
        d="M12.214,6.079C11.688,2.838,10.446.563,9,.563S6.316,2.838,5.789,6.079Zm-6.7,3.484a22.742,22.742,0,0,0,.12,2.323h6.726a22.742,22.742,0,0,0,.12-2.323,22.742,22.742,0,0,0-.12-2.323H5.637A22.742,22.742,0,0,0,5.517,9.563ZM17.3,6.079A9.02,9.02,0,0,0,11.568.94a12.247,12.247,0,0,1,1.815,5.139ZM6.432.94A9.014,9.014,0,0,0,.7,6.079h3.92A12.2,12.2,0,0,1,6.432.94Zm11.26,6.3H13.528c.076.762.12,1.542.12,2.323s-.044,1.56-.12,2.323h4.16A8.921,8.921,0,0,0,18,9.563,9.076,9.076,0,0,0,17.691,7.24ZM4.356,9.563c0-.78.044-1.56.12-2.323H.312a8.8,8.8,0,0,0,0,4.645h4.16C4.4,11.123,4.356,10.343,4.356,9.563Zm1.434,3.484C6.316,16.287,7.557,18.563,9,18.563s2.686-2.275,3.212-5.516Zm5.782,5.139a9.029,9.029,0,0,0,5.735-5.139h-3.92a12.247,12.247,0,0,1-1.815,5.139ZM.7,13.046a9.02,9.02,0,0,0,5.735,5.139,12.247,12.247,0,0,1-1.815-5.139H.7Z"
        transform="translate(0 -0.563)"
        fill="currentColor"
      />
    </Icon>
  );
}
