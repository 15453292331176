import React, { useEffect } from 'react';
import { Flex, Table, Thead, Tr, Tbody, useDisclosure, Td, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { ShowLessIcon, ShowMoreIcon } from 'src/icons';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { getQuestionBankPerformance, resetQuestionBank } from 'src/redux/questionBank/actions';
import {
  selectQuestionBankPerformance,
  selectQuestionBankPerformanceStatusCode,
} from 'src/redux/questionBank/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import Loader from 'src/components/Loader';
import usePageTracking from '../PageTracking';

interface LocationState {
  questionBankName: string;
  questionType: string;
}

function QuestionBankPerformanceTracker(): JSX.Element {
  usePageTracking('question-bank-performance-tracker');
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { questionBankName, questionType } = state;
  const params = useParams<{ subjectId: string; attemptId: string }>();
  const dispatch = useDispatch();

  const performance = useSelector(selectQuestionBankPerformance);
  const performanceStatusCode = useSelector(selectQuestionBankPerformanceStatusCode);

  useEffect(() => {
    dispatch(getQuestionBankPerformance(Number(params.subjectId)));
    return () => {
      dispatch(resetQuestionBank());
    };
  }, [dispatch]);

  useSessionExpired(performanceStatusCode);
  if (!performance) {
    return (
      <DashboardShell>
        <BackTopbar
          title={questionBankName}
          pathname={`/question-bank-records/${params.subjectId!}`}
          state={{ questionType, questionBankName }}
        />
        <Loader />
      </DashboardShell>
    );
  }

  return (
    <DashboardShell>
      <BackTopbar
        title={questionBankName}
        pathname={`/question-bank-records/${params.subjectId!}`}
        state={{ questionType, questionBankName }}
      />
      <Flex
        position="relative"
        flex="1"
        m={5}
        boxShadow="0 0 5px gray"
        borderRadius="10px"
        p={5}
        flexDirection="column"
      >
        <OverlayScrollbarsComponent
          style={{ height: '100%', width: '100%' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          <Table>
            <Thead>
              <Tr>
                <TableHeader fontSize={20} fontWeight="semibold">
                  {t('performance_by_chapters')}
                </TableHeader>
                <TableHeader textAlign="center">{t('answered_questions')}</TableHeader>
                <TableHeader textAlign="center">{t('correct')}</TableHeader>
                <TableHeader textAlign="center">{t('score')}</TableHeader>
              </Tr>
            </Thead>
            <Tbody>
              <Tr fontWeight="medium" bg="#EDEDED">
                <Td borderColor="#D1D1D1">
                  <HStack spacing={5}>
                    {isOpen ? (
                      <ShowLessIcon onClick={onToggle} cursor="pointer" />
                    ) : (
                      <ShowMoreIcon onClick={onToggle} cursor="pointer" />
                    )}
                    <Text>{t('all_chapters')}</Text>
                  </HStack>
                </Td>
                <Td borderColor="#D1D1D1" textAlign="center">
                  {performance.totalAnswered}
                </Td>
                <Td borderColor="#D1D1D1" textAlign="center">
                  {performance.totalCorrect}
                </Td>
                <Td borderColor="#D1D1D1" textAlign="center">
                  {`${performance.totalScore.toFixed(2)}%`}
                </Td>
              </Tr>
            </Tbody>
            <Tbody display={isOpen ? 'table-row-group' : 'none'}>
              {performance.chapters.map((chapter) => (
                <TableBody
                  key={chapter.id}
                  name={chapter.name}
                  answered={chapter.answered}
                  correct={chapter.correct}
                  score={chapter.score}
                />
              ))}
            </Tbody>
          </Table>
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default QuestionBankPerformanceTracker;
