import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { month } from 'src/constants/months';

import {
  selectPurchaseDetails,
  selectPurchaseDetailsLoading,
  selectTerminateSubscriptionLoading,
  selectTerminateSubscriptionSuccess,
} from 'src/redux/purchase/selectors';
import {
  getPurhcaseDetails,
  resetPurchaseHistory,
  terminateSubscription,
} from 'src/redux/purchase/actions';
import { formatPrice } from 'src/utils/formatPrice';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Loader from 'src/components/Loader';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import usePageTracking from '../PageTracking';

function MyPurchaseDetails(): JSX.Element {
  usePageTracking('purchase-details');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const purchase = useSelector(selectPurchaseDetails);
  const loading = useSelector(selectPurchaseDetailsLoading);
  const terminateLoading = useSelector(selectTerminateSubscriptionLoading);
  const terminateSuccess = useSelector(selectTerminateSubscriptionSuccess);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getPurhcaseDetails(Number(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (terminateSuccess) {
      toast({
        title: t('terminate_subscription_successful'),
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 500,
      });
      setTimeout(() => {
        navigate('/my-purchases');
      }, 1000);

      dispatch(resetPurchaseHistory());
    }
  }, [terminateSuccess]);

  if (!purchase || loading)
    return (
      <DashboardShell>
        <BackTopbar title={t('purchase_details')} pathname="/my-purchases" />
        <Loader />
      </DashboardShell>
    );

  const isSubscription = purchase.gateway === 'iPayRecurring';
  const isFreeTrial = purchase.gateway === 'n/a';
  const gateway = purchase.gateway === 'iPayRecurring' ? 'iPayRecurring' : 'iPayOnlineBanking';

  const pageCopy = {
    iPayRecurring: {
      priceIndicator: `/${t('month_word')}`,
      firstTitle: `${t('total_fee')}/${t('month_word')}`,
      secondTitle: t('monthly_savings'),
      thirdTitle: t('total_monthly_recurring'),
      paidBy: t('billed_instruction'),
    },
    iPayOnlineBanking: {
      priceIndicator: '',
      firstTitle: t('subtotal'),
      secondTitle: t('bundle_discount'),
      thirdTitle: t('grand_total'),
      paidBy: `${t('paid_by')} ${String(purchase.paymentMethod?.name)}`,
    },
  };

  const onTerminateSubscription = () => {
    dispatch(terminateSubscription(purchase.refNo));
  };

  return (
    <DashboardShell>
      <BackTopbar title={`#${purchase.refNo}`} pathname="/my-purchases" />
      <Flex
        pb={5}
        flexDirection="column"
        as={OverlayScrollbarsComponent}
        style={{ width: '100%', height: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Flex justifyContent="space-between" px="16" mt="8" fontSize="xl">
          <Text fontWeight="semibold">{t('order_summary')}</Text>
          <Text>{purchase.date}</Text>
        </Flex>
        <Flex direction="column" px="16" my="6" maxH="35vh" minH="120px" overflowY="auto">
          {purchase.items.map((item) => (
            <Flex
              key={item.id}
              mb="8"
              pb="4"
              w="full"
              alignItems="center"
              fontSize="lg"
              borderBottomWidth="1px"
              borderBottomColor="gray.500"
            >
              <Image
                src={item.imageURL || TavisPlaceholder}
                minW="55px"
                minH="55px"
                maxW="55px"
                maxH="55px"
                fit="cover"
              />
              <Box>
                <Text ml="6">{item.title}</Text>
                {(isSubscription || isFreeTrial) &&
                  Boolean(purchase.startMonth) &&
                  Boolean(purchase.startYear) && (
                    <Text mt={5} ml="6">
                      {t('starting_date')}: {t(month[purchase.startMonth - 1].toLowerCase())}{' '}
                      {purchase.startYear}
                    </Text>
                  )}
              </Box>
              <Spacer />
              <Text>
                {formatPrice(String(item.priceInfo.finalPrice))}
                {pageCopy[gateway].priceIndicator}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex w="full" px="16">
          <Flex direction="column" w="50%" maxW="50%" fontSize="xl">
            {purchase.totalSummary.grandTotal !== 'RM0.00' &&
              purchase.billingInfo.address !== null && (
                <>
                  <Text fontWeight="semibold">{t('billing_info')}</Text>
                  <Text mt="4">{purchase.billingInfo.name}</Text>
                  <Text mt="4" whiteSpace="pre-wrap">
                    {`${purchase.billingInfo.address},\n${purchase.billingInfo.postcode} ${purchase.billingInfo.city},\n${purchase.billingInfo.state}.`}
                  </Text>
                  <Text mt="4">{purchase.billingInfo.phone}</Text>
                  {isSubscription && (
                    <>
                      <Flex mt="16">
                        <Button onClick={onOpen}>{t('terminate')}</Button>
                      </Flex>
                      <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>{t('terminate_subscription')}</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Text>{t('terminate_confirmation')}</Text>
                          </ModalBody>

                          <ModalFooter>
                            <Button mr="2" onClick={onClose}>
                              {t('cancel')}
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={onTerminateSubscription}
                              isLoading={terminateLoading}
                            >
                              {t('confirm')}
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </>
                  )}
                </>
              )}
          </Flex>
          <Flex direction="column" w="50%" maxW="50%" fontSize="xl">
            <Text fontWeight="semibold">{t('total_summary')}</Text>
            <Flex mt="6" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>{pageCopy[gateway].firstTitle}:</Text>
              <Text>{purchase.totalSummary.subTotal}</Text>
            </Flex>
            <Flex mt="4" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>{pageCopy[gateway].secondTitle}:</Text>
              <Text>{purchase.totalSummary.bundleDiscount}</Text>
            </Flex>
            <Flex mt="4" fontWeight="medium" justifyContent="space-between" w="full">
              <Text>{t('promo_code')}:</Text>
              <Text>{purchase.totalSummary.promoDiscount}</Text>
            </Flex>
            <Flex direction="column" mt="6" pt="6" borderTopColor="gray.500" borderTopWidth="1px">
              <Flex mt="4" justifyContent="space-between" w="full">
                <Text fontWeight="semibold">{pageCopy[gateway].thirdTitle}:</Text>
                <Text fontWeight="medium">{purchase.totalSummary.grandTotal}</Text>
              </Flex>
              {purchase.totalSummary.grandTotal !== 'RM0.00' &&
                purchase.paymentMethod?.name !== undefined && (
                  <Text fontSize="lg">{pageCopy[gateway].paidBy}</Text>
                )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default MyPurchaseDetails;
