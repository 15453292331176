import React, { useEffect } from 'react';
import { useCheckboxGroup, Text } from '@chakra-ui/react';
import { Answer } from 'src/models/Answer';

import CheckboxOption from './CheckboxOption';

interface CheckboxOptionGroupProps {
  options: Answer[];
  onChange: (value: string[]) => void;
  numbering: string;
  selectedAnswer: string[] | null;
  correctAnswer: Answer[];
  submitAnswer: boolean;
  showAnswer: boolean;
  isSingle: boolean;
}

function CheckboxOptionGroup({
  options,
  onChange,
  numbering,
  selectedAnswer,
  correctAnswer,
  submitAnswer,
  showAnswer,
  isSingle,
}: CheckboxOptionGroupProps): JSX.Element {
  const romanNumeral = ['I', 'II', 'III', 'IV'];
  const { getCheckboxProps, setValue, value } = useCheckboxGroup({
    onChange: onChange,
  });

  useEffect(() => {
    if (isSingle && !submitAnswer && value.length > 1) {
      const ans = value[value.length - 1];
      setValue([ans]);
    }
  }, [value]);

  const showAnswerNumbering = (position: number) => {
    switch (numbering) {
      case 'abc':
        return `${String.fromCharCode(97 + position)}.`;
      case 'ABCD':
        return `${String.fromCharCode(65 + position)}.`;
      case '123':
        return `${position + 1}.`;
      case 'iii':
        return `${romanNumeral[position].toLowerCase()}.`;
      case 'IIII':
        return `${romanNumeral[position]}.`;
      default:
        return '';
    }
  };

  return (
    <>
      {options.map((item, index) => {
        return (
          <CheckboxOption
            key={item.id}
            selectedAnswer={selectedAnswer}
            correctAnswer={correctAnswer}
            checkboxProps={getCheckboxProps({
              value: item.id.toString(),
            })}
            option={showAnswerNumbering(index)}
            submitAnswer={submitAnswer}
            showAnswer={showAnswer}
          >
            <Text dangerouslySetInnerHTML={{ __html: item.answer }}></Text>
          </CheckboxOption>
        );
      })}
    </>
  );
}

export default CheckboxOptionGroup;
