import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';

import { GlobeIcon, LogOutIcon, NotificationIcon } from 'src/icons';
import DashboardShell from 'src/components/DashboardShell';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ItemCard from './ItemCard';
import usePageTracking from '../PageTracking';

function Settings(): JSX.Element {
  usePageTracking('settings');
  const { t } = useTranslation();

  return (
    <DashboardShell settingsScreen={true}>
      <TitleTopbar title={t('settings')} />
      <OverlayScrollbarsComponent
        style={{ height: '100%', width: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Grid h="100%" w="100%" gridTemplateColumns="auto auto">
          <ItemCard to="/settings/language" icon={GlobeIcon}>
            {t('language')}
          </ItemCard>
          <ItemCard to="/settings/notifications-to-parents" icon={NotificationIcon}>
            {t('notification_to_parents')}
          </ItemCard>
          {/* <ItemCard to="/settings/contact-us" icon={EmailIcon}>
            {t('contact_us')}
          </ItemCard>
          <ItemCard to="about" icon={QuestionIcon}>
            {t('about')}
          </ItemCard> */}
          <ItemCard to="logout" icon={LogOutIcon}>
            {t('logout')}
          </ItemCard>
        </Grid>
      </OverlayScrollbarsComponent>
    </DashboardShell>
  );
}

export default Settings;
