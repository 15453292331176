import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import DashboardShell from 'src/components/DashboardShell';

interface NetworkErrorProps {
  settingsScreen?: boolean;
}

function NetworkError({ settingsScreen }: NetworkErrorProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <DashboardShell settingsScreen={settingsScreen}>
      <VStack h="100vh" justifyContent="center">
        <Text>{t('internet_connect_error')}</Text>
      </VStack>
    </DashboardShell>
  );
}

export default NetworkError;
