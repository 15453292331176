import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  Input,
  Button,
  Stack,
  useDisclosure,
  HStack,
  useToast,
  InputGroup,
  InputRightElement,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { ShowIcon, HideIcon } from 'src/icons';
import DashboardShell from 'src/components/DashboardShell';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import PasswordPrompt from './PasswordPrompt';
import CancelNotificationPrompt from './CancelNotificationPrompt';
import VerifyEmailPrompt from './VerifyEmailPrompt';
import VerificationCodePrompt from './VerificationCodePrompt';
import ResetPasswordPrompt from './ResetPasswordPrompt';
import Loader from 'src/components/Loader';
import {
  selectCancelNotificationLoading,
  selectCancelNotificationNetworkError,
  selectCancelNotificationStatusCode,
  selectConfirmOTPMessage,
  selectConfirmOTPNetworkError,
  selectConfirmOTPStatusCode,
  selectForgotPasswordMessage,
  selectForgotPasswordNetworkError,
  selectForgotPasswordStatusCode,
  selectNotificationDetails,
  selectNotificationDetailsAuth,
  selectNotificationDetailsLoading,
  selectNotificationDetailsNetworkError,
  selectResendOTPMessage,
  selectResendOTPNetworkError,
  selectResendOTPStatusCode,
  selectSaveDetailsLoading,
  selectSaveDetailsNetworkError,
  selectSaveDetailsStatusCode,
  selectUpdateDetailsLoading,
  selectUpdateDetailsNetworkError,
  selectUpdateDetailsStatusCode,
  selectUpdatePasswordNetworkError,
  selectUpdatePasswordStatusCode,
  selectVerifyPasswordMessage,
  selectVerifyPasswordNetworkError,
  selectVerifyPasswordStatusCode,
} from 'src/redux/parentsNotification/selectors';
import {
  getNotificationDetails,
  resetParentsNotification,
  saveDetails,
  verifyPassword,
  cancelNotification,
  confirmOTP,
  updateDetails,
  resendOTP,
  updatePassword,
  forgotPassword,
} from 'src/redux/parentsNotification/actions';
import { selectProfile } from 'src/redux/profile/selectors';
import { InfoIcon } from 'src/icons';
import useSessionExpired from 'src/components/SessionExpired';
import NetworkErrorComponent from 'src/components/NetworkError';
import usePageTracking from '../PageTracking';

function ParentsNotification(): JSX.Element {
  usePageTracking('notifications-to-parents');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();

  const firstLanding = useRef<boolean>();
  const cancelNotificationRef = useRef<boolean>();
  const updateEmail = useRef<boolean>();
  const resetPassword = useRef<boolean>();
  const timerCounter = useRef(60);
  const timer = useRef<NodeJS.Timer>();
  const debounce = useRef<NodeJS.Timeout>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>();
  const [timerText, setTimerText] = useState('60');
  const [showResendButton, setShowResendButton] = useState(false);
  const [disableResendButon, setDisableResendButton] = useState(false);

  const profile = useSelector(selectProfile);

  // Notification details
  const isPasswordSet = useSelector(selectNotificationDetailsAuth);
  const notificationDetails = useSelector(selectNotificationDetails);
  const notificationDetailsLoading = useSelector(selectNotificationDetailsLoading);
  const notificationDetailsNetworkError = useSelector(selectNotificationDetailsNetworkError);

  // Save details
  const saveDetailsStatusCode = useSelector(selectSaveDetailsStatusCode);
  const saveDetailsLoading = useSelector(selectSaveDetailsLoading);
  const saveDetailsNetworkError = useSelector(selectSaveDetailsNetworkError);

  // Update details
  const updateDetailsStatusCode = useSelector(selectUpdateDetailsStatusCode);
  const updateDetailsLoading = useSelector(selectUpdateDetailsLoading);
  const updateDetailsNetworkError = useSelector(selectUpdateDetailsNetworkError);

  // Verify password
  const verifyPasswordStatusCode = useSelector(selectVerifyPasswordStatusCode);
  const verifyPasswordMessage = useSelector(selectVerifyPasswordMessage);
  const verifyPasswordNetworkError = useSelector(selectVerifyPasswordNetworkError);

  // Update password
  const updatePasswordStatusCode = useSelector(selectUpdatePasswordStatusCode);
  const updatePasswordNetworkError = useSelector(selectUpdatePasswordNetworkError);

  // Cancel notification
  const cancelNotificationStatusCode = useSelector(selectCancelNotificationStatusCode);
  const cancelNotificationLoading = useSelector(selectCancelNotificationLoading);
  const cancelNotificationNetworkError = useSelector(selectCancelNotificationNetworkError);

  // Confirm OTP
  const confirmOTPMessage = useSelector(selectConfirmOTPMessage);
  const confirmOTPStatusCode = useSelector(selectConfirmOTPStatusCode);
  const confirmOTPNetworkError = useSelector(selectConfirmOTPNetworkError);

  // Resend OTP
  const resendOTPStatusCode = useSelector(selectResendOTPStatusCode);
  const resendOTPMessage = useSelector(selectResendOTPMessage);
  const resendOTPNetworkError = useSelector(selectResendOTPNetworkError);

  // Forgot password
  const forgotPasswordStatusCode = useSelector(selectForgotPasswordStatusCode);
  const forgotPasswordMessage = useSelector(selectForgotPasswordMessage);
  const forgotPasswordNetworkError = useSelector(selectForgotPasswordNetworkError);

  // Toast ID
  const networkErrorToastId = 'networkErrorToast';
  const confirmOTPToastId = 'confirmOTPToast';
  const verifyPasswordToastId = 'verifyPasswordToast';
  const passwordPatternToastId = 'passwordPatternToast';
  const samePasswordToastId = 'samePasswordToast';

  const {
    isOpen: passwordPromptIsOpen,
    onClose: passwordPromptOnClose,
    onOpen: passwordPromptOnOpen,
  } = useDisclosure();

  const {
    isOpen: verifyEmailPromptIsOpen,
    onClose: verifyEmailPromptOnClose,
    onOpen: verifyEmailPromptOnOpen,
  } = useDisclosure();

  const {
    isOpen: verificationCodePromptIsOpen,
    onClose: verificationCodePromptOnClose,
    onOpen: verificationCodePromptOnOpen,
  } = useDisclosure();

  const {
    isOpen: resetPasswordPromptIsOpen,
    onClose: resetPasswordPromptOnClose,
    onOpen: resetPasswordPromptOnOpen,
  } = useDisclosure();

  useEffect(() => {
    dispatch(getNotificationDetails());
    firstLanding.current = true;

    return () => {
      dispatch(resetParentsNotification());
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (isPasswordSet && firstLanding.current) {
      passwordPromptOnOpen();
    }

    if (isPasswordSet !== null) {
      firstLanding.current = false;
    }
  }, [isPasswordSet]);

  useEffect(() => {
    if (saveDetailsStatusCode === 200) {
      dispatch(getNotificationDetails());
      verifyEmailPromptOnOpen();
    } else if (saveDetailsNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
          duration: 2000,
        });
      }
    }
  }, [saveDetailsStatusCode, saveDetailsNetworkError]);

  useEffect(() => {
    if (cancelNotificationStatusCode === 200) {
      dispatch(getNotificationDetails());
    } else if (cancelNotificationNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [cancelNotificationStatusCode, cancelNotificationNetworkError]);

  useEffect(() => {
    if (confirmOTPStatusCode === 200 && resetPassword.current) {
      resetPassword.current = false;
      verificationCodePromptOnClose();
      resetPasswordPromptOnOpen();
    } else if (confirmOTPStatusCode === 200) {
      verifyEmailPromptOnClose();
      dispatch(getNotificationDetails());
    } else if (confirmOTPStatusCode === 500) {
      if (!toast.isActive(confirmOTPToastId)) {
        toast({
          id: confirmOTPToastId,
          title: confirmOTPMessage,
          position: 'top',
          status: 'error',
        });
      }
    } else if (confirmOTPNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [confirmOTPStatusCode, confirmOTPMessage, confirmOTPNetworkError]);

  useEffect(() => {
    if (verifyPasswordStatusCode === 200 && cancelNotificationRef.current && notificationDetails) {
      dispatch(cancelNotification(notificationDetails.id));
      cancelNotificationRef.current = false;
    } else if (verifyPasswordStatusCode === 200) {
      passwordPromptOnClose();
    } else if (verifyPasswordStatusCode === 500) {
      if (!toast.isActive(verifyPasswordToastId)) {
        toast({
          id: verifyPasswordToastId,
          title: verifyPasswordMessage,
          position: 'top',
          status: 'error',
        });
      }
    } else if (verifyPasswordNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [verifyPasswordStatusCode, verifyPasswordMessage, verifyPasswordNetworkError]);

  useEffect(() => {
    if (updateDetailsStatusCode === 200) {
      setIsEdit(false);
      dispatch(getNotificationDetails());
      if (updateEmail.current) {
        updateEmail.current = false;
        verifyEmailPromptOnOpen();
      }
    } else if (updateDetailsNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [updateDetailsStatusCode, updateDetailsNetworkError]);

  useEffect(() => {
    if (updatePasswordStatusCode === 200) {
      passwordPromptOnClose();
      resetPasswordPromptOnClose();
    } else if (updatePasswordNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [updatePasswordStatusCode, updatePasswordNetworkError]);

  useEffect(() => {
    if (resendOTPStatusCode === 200) {
      toast({
        title: resendOTPMessage,
        position: 'top',
        status: 'info',
      });
    } else if (resendOTPNetworkError) {
      if (!toast.isActive(networkErrorToastId)) {
        toast({
          id: networkErrorToastId,
          title: t('network_error'),
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [resendOTPStatusCode, resendOTPMessage, resendOTPNetworkError]);

  useEffect(() => {
    if (forgotPasswordStatusCode === 200) {
      toast({
        title: forgotPasswordMessage,
        position: 'top',
        status: 'info',
      });
    } else if (forgotPasswordNetworkError) {
      toast({
        title: t('network_error'),
        position: 'top',
        status: 'error',
      });
    }
  }, [forgotPasswordStatusCode, forgotPasswordNetworkError, forgotPasswordMessage]);

  useEffect(() => {
    if (verificationCodePromptIsOpen || verifyEmailPromptIsOpen) {
      debounce.current = setTimeout(() => {
        if (verificationCodePromptIsOpen || verifyEmailPromptIsOpen) {
          setShowResendButton(true);
        }
      }, 5000);
    } else {
      if (showResendButton) {
        setShowResendButton(false);
      }
      if (disableResendButon) {
        setDisableResendButton(false);
      }
      if (debounce.current) {
        clearTimeout(debounce.current);
        debounce.current = undefined;
      }
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = undefined;
        timerCounter.current = 60;
      }
    }
  }, [verificationCodePromptIsOpen, verifyEmailPromptIsOpen]);

  const onClickResendCode = () => {
    setDisableResendButton(true);
    if (notificationDetails?.email) {
      dispatch(resendOTP(notificationDetails.email));
    }

    timer.current = setInterval(() => {
      timerCounter.current--;

      if (timerCounter.current <= 0) {
        setDisableResendButton(false);
        if (timer.current != undefined) {
          clearInterval(timer.current);
        }
        timer.current = undefined;
        timerCounter.current = 60;
      }
      setTimerText(timerCounter.current.toString());
    }, 1000);
  };

  const onClickResetPassword = () => {
    if (notificationDetails?.email) {
      dispatch(forgotPassword(notificationDetails.email));
      verificationCodePromptOnOpen();
    }
  };

  const onClickVerifyPassword = () => {
    const password = (document.getElementById('verifyPassword') as HTMLInputElement).value;
    dispatch(verifyPassword(password));
  };

  const onClickVerifyEmail = (code: string) => {
    if (notificationDetails && notificationDetails.email) {
      dispatch(
        confirmOTP({
          email: notificationDetails.email,
          OTP: code,
        }),
      );
    }
  };

  const onClickConfirmVerificationCode = (code: string) => {
    if (notificationDetails && notificationDetails.email) {
      resetPassword.current = true;
      dispatch(
        confirmOTP({
          email: notificationDetails.email,
          OTP: code,
        }),
      );
    }
  };

  const onClickConfirmPassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const password = (document.getElementById('resetPassword') as HTMLInputElement).value;
    const confirmPassword = (document.getElementById('resetConfirmPassword') as HTMLInputElement)
      .value;

    if (passwordValidation(password, confirmPassword) && notificationDetails?.email) {
      dispatch(
        updatePassword({
          email: notificationDetails.email,
          password: password,
        }),
      );
    }
  };

  const onClickCancelNotification = () => {
    const password = (document.getElementById('cancelNotificationPassword') as HTMLInputElement)
      .value;
    dispatch(verifyPassword(password));
    cancelNotificationRef.current = true;
  };

  const passwordValidation = (password: string, confirmPassword: string) => {
    const pattern = '(?=.*[0-9])(?=.*[a-z]).{8,}';
    const re = new RegExp(pattern);
    const passwordFollowPattern = re.test(password);
    const isPasswordValid = password === confirmPassword;

    if (!passwordFollowPattern && password.length > 0) {
      if (!toast.isActive(passwordPatternToastId)) {
        toast({
          id: passwordPatternToastId,
          title: t('password_validation'),
          position: 'top',
          status: 'error',
        });
      }
      return false;
    } else if (!isPasswordValid) {
      if (!toast.isActive(samePasswordToastId)) {
        toast({
          id: samePasswordToastId,
          title: t('same_password'),
          position: 'top',
          status: 'error',
        });
      }
      return false;
    }

    return true;
  };

  const onClickSubmitDetails = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const parentsName = (document.getElementById('parentsName') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const confirmPassword = (document.getElementById('confirmPassword') as HTMLInputElement).value;
    const phoneNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
    const address = (document.getElementById('address') as HTMLInputElement).value;

    if (passwordValidation(password, confirmPassword)) {
      dispatch(
        saveDetails({
          name: parentsName,
          email: email,
          password: password,
          mobile: phoneNumber,
          residence: address,
        }),
      );
    }
  };

  const onClickUpdateDetails = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (notificationDetails) {
      const parentsName = (document.getElementById('parentsName') as HTMLInputElement).value;
      const email = (document.getElementById('email') as HTMLInputElement).value;
      const passwordInput = document.getElementById('password') as HTMLInputElement;
      const confirmPassword = (document.getElementById('confirmPassword') as HTMLInputElement)
        .value;
      const phoneNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
      const address = (document.getElementById('address') as HTMLInputElement).value;

      const nameIsChanged = !(notificationDetails.name === parentsName);
      const emailIsChanged = !(notificationDetails.email === email);
      const passwordIsChanged = !(passwordInput.value === passwordInput.defaultValue);
      const phoneNumberIsChanged = !(notificationDetails.mobile === phoneNumber);
      const addressIsChanged = !(notificationDetails.residence === address);

      if (passwordIsChanged) {
        if (passwordValidation(passwordInput.value, confirmPassword)) {
          if (emailIsChanged) {
            updateEmail.current = true;
          }
          dispatch(
            updateDetails({
              notificationId: notificationDetails.id,
              name: parentsName,
              email: email,
              password: passwordInput.value.length === 0 ? null : passwordInput.value,
              mobile: phoneNumber,
              residence: address,
            }),
          );
        }
      } else if (nameIsChanged || emailIsChanged || phoneNumberIsChanged || addressIsChanged) {
        if (emailIsChanged) {
          updateEmail.current = true;
        }
        dispatch(
          updateDetails({
            notificationId: notificationDetails.id,
            name: parentsName,
            email: email,
            password: undefined,
            mobile: phoneNumber,
            residence: address,
          }),
        );
      } else {
        setIsEdit(false);
      }
    }
  };

  const showContent = () => {
    if (notificationDetails) {
      return (
        <Stack
          direction="column"
          mx={20}
          my={10}
          maxW="90%"
          shouldWrapChildren
          spacing={5}
          fontSize={20}
          fontWeight="medium"
          as="form"
          onSubmit={isEdit ? onClickUpdateDetails : onClickSubmitDetails}
        >
          {/* Student Name */}
          <Flex>
            <Text w="270px">{t('students_name')}:</Text>
            <Text>{profile?.fullname}</Text>
          </Flex>
          {/* Parents Name */}
          <Flex>
            <Text w="270px">{t('parents_name')}:</Text>
            {notificationDetails.name === null || isEdit ? (
              <Input
                id="parentsName"
                w="500px"
                h="36px"
                required
                borderRadius="full"
                borderColor="#707070"
                _hover={{ borderColor: '#707070' }}
                defaultValue={notificationDetails?.name || undefined}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                }
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
              />
            ) : (
              <Text>{Boolean(notificationDetails.name) ? notificationDetails.name : 'N/A'}</Text>
            )}
          </Flex>
          {/* Parents Email */}
          <Flex>
            <Text w="270px">{t('parents_email')}:</Text>
            {notificationDetails.email === null || isEdit ? (
              <Input
                id="email"
                w="500px"
                type="email"
                h="36px"
                required
                borderRadius="full"
                borderColor="#707070"
                _hover={{ borderColor: '#707070' }}
                defaultValue={notificationDetails?.email || undefined}
                onInvalid={(e) => {
                  const input = e.target as HTMLInputElement;
                  if (input.value.length === 0) {
                    input.setCustomValidity(t('please_fill_up_this_field'));
                  } else {
                    input.setCustomValidity(t('wrong_email_format'));
                  }
                }}
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
              />
            ) : (
              <Box>
                <HStack spacing={5}>
                  <Text>
                    {Boolean(notificationDetails.email) ? notificationDetails.email : 'N/A'}
                  </Text>
                  {!notificationDetails.isActive && Boolean(notificationDetails.email) && (
                    <Text fontSize={14} fontWeight="medium" color="red" minW="fit-content">
                      <InfoIcon boxSize="34" />
                      {t('unverified_email')}
                    </Text>
                  )}
                </HStack>
                {!notificationDetails.isActive && Boolean(notificationDetails.email) && (
                  <Button
                    mt={3}
                    minW="fit-content"
                    fontSize={16}
                    fontWeight="medium"
                    onClick={() => {
                      if (notificationDetails.email) {
                        dispatch(resendOTP(notificationDetails.email));
                        verifyEmailPromptOnOpen();
                      }
                    }}
                  >
                    {t('resend_verification_code')}
                  </Button>
                )}
              </Box>
            )}
          </Flex>
          {/* Parents Mobile Number */}
          <Flex>
            <Text w="270px">{t('parents_mobile_number')}:</Text>
            {notificationDetails.mobile === null || isEdit ? (
              <Input
                id="phoneNumber"
                w="500px"
                h="36px"
                required
                borderRadius="full"
                borderColor="#707070"
                maxLength={13}
                minLength={10}
                pattern="[0-9]{10,}"
                _hover={{ borderColor: '#707070' }}
                onInput={(event) => {
                  const target = event.currentTarget;
                  target.setCustomValidity('');
                  if (target.value.length > target.maxLength) {
                    target.value = target.value.slice(0, target.maxLength);
                  }
                }}
                defaultValue={notificationDetails?.mobile || undefined}
                onInvalid={(e) => {
                  const target = e.currentTarget as HTMLInputElement;
                  if (target.value.length === 0) {
                    target.setCustomValidity(t('please_fill_up_this_field'));
                  } else if (target.value.length < target.minLength) {
                    target.setCustomValidity(t('valid_phone_number'));
                  } else {
                    target.setCustomValidity(t('please_enter_a_number'));
                  }
                }}
              />
            ) : (
              <Text>
                {Boolean(notificationDetails?.mobile) ? notificationDetails.mobile : 'N/A'}
              </Text>
            )}
          </Flex>
          {/* Password */}
          <Flex>
            <Text w="270px">
              {t('password')} ({t('optional')}):
            </Text>
            {notificationDetails.name === null || isEdit ? (
              <Flex flex="1">
                <InputGroup w="500px" h="36px">
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    borderRadius="full"
                    borderColor="#707070"
                    _hover={{ borderColor: '#707070' }}
                    defaultValue={isPasswordSet ? '******' : undefined}
                  />
                  <InputRightElement
                    cursor="pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
                  </InputRightElement>
                </InputGroup>
              </Flex>
            ) : (
              <Text>{isPasswordSet ? '******' : 'N/A'}</Text>
            )}
          </Flex>
          {/* Confirm Password */}
          {(notificationDetails.email === null || isEdit) && (
            <Flex>
              <Text w="270px">{t('confirm_password')}:</Text>
              <Flex flex="1">
                <InputGroup w="500px" h="36px">
                  <Input
                    id="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    borderRadius="full"
                    borderColor="#707070"
                    _hover={{ borderColor: '#707070' }}
                  />
                  <InputRightElement
                    cursor="pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <ShowIcon /> : <HideIcon color="black" />}
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </Flex>
          )}
          {/* Address */}
          <Flex>
            <Text w="270px">{t('residence')}</Text>
            {notificationDetails.residence === null || isEdit ? (
              <Input
                id="address"
                w="500px"
                required
                borderRadius="full"
                borderColor="#707070"
                _hover={{ borderColor: '#707070' }}
                defaultValue={notificationDetails?.residence || undefined}
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                onInvalid={(e) => {
                  const input = e.target as HTMLInputElement;
                  if (input.value.length === 0) {
                    input.setCustomValidity(t('please_fill_up_this_field'));
                  }
                }}
              />
            ) : (
              <Text>
                {Boolean(notificationDetails?.residence) ? notificationDetails.residence : 'N/A'}
              </Text>
            )}
          </Flex>
          {/* Button */}
          {isEdit ? (
            <HStack spacing={5} justify="flex-end" w="780px">
              <Button
                bg="#FF0000"
                color="white"
                onClick={() => setIsEdit(false)}
                _hover={{ bg: 'C20000' }}
                _active={{ bg: 'C20000' }}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                isLoading={updateDetailsLoading}
                bg="#1BC35C"
                color="white"
                _hover={{ bg: '#00912F' }}
                _active={{ bg: '#00912F' }}
              >
                {t('save')}
              </Button>
            </HStack>
          ) : notificationDetails.email === null ? (
            <Flex justifyContent="center">
              <Button
                type="submit"
                isLoading={saveDetailsLoading}
                fontSize={18}
                fontWeight="semibold"
              >
                {t('submit')}
              </Button>
            </Flex>
          ) : notificationDetails.isConditionApproved ? (
            <HStack spacing={5} mt={5}>
              <Button
                minW="fit-content"
                fontSize={18}
                fontWeight="semibold"
                onClick={() => setIsEdit(true)}
              >
                {t('edit_details')}
              </Button>
              {isPasswordSet ? (
                <CancelNotificationPrompt onClickCancelNotification={onClickCancelNotification} />
              ) : (
                <Button
                  bg="#FF0000"
                  color="white"
                  minW="fit-content"
                  fontSize={18}
                  fontWeight="semibold"
                  isLoading={cancelNotificationLoading}
                  _hover={{ bg: 'C20000' }}
                  _active={{ bg: 'C20000' }}
                  onClick={() => {
                    const result = window.messageBox.confirmBox(
                      'Are you sure to cancel notification?',
                    );
                    if (result === 0 && notificationDetails) {
                      dispatch(cancelNotification(notificationDetails.id));
                    }
                  }}
                >
                  {t('cancel_notification')}
                </Button>
              )}
            </HStack>
          ) : (
            <Box>
              <Checkbox
                disabled={!Boolean(notificationDetails.email)}
                defaultChecked={notificationDetails.isConditionApproved}
                fontSize="18px"
                fontWeight="medium"
                w="700px"
                onChange={() => {
                  if (
                    notificationDetails.email &&
                    notificationDetails.name &&
                    notificationDetails.residence
                  ) {
                    dispatch(
                      updateDetails({
                        notificationId: notificationDetails.id,
                        name: notificationDetails.name,
                        email: notificationDetails.email,
                        password: undefined,
                        mobile: notificationDetails.mobile,
                        residence: notificationDetails.residence,
                        isConditionApproved: !notificationDetails.isConditionApproved,
                      }),
                    );
                  }
                }}
              >
                {t('parents_notification_agreement')}
                <Text as="span" fontWeight="light">
                  {t('for_parents')}
                </Text>
              </Checkbox>
              <Flex justifyContent="flex-end" mt={10}>
                <Button
                  minW="fit-content"
                  fontSize={18}
                  fontWeight="semibold"
                  onClick={() => setIsEdit(true)}
                >
                  {t('edit_details')}
                </Button>
              </Flex>
            </Box>
          )}
        </Stack>
      );
    }
  };

  useSessionExpired(saveDetailsStatusCode);
  useSessionExpired(updateDetailsStatusCode);
  useSessionExpired(verifyPasswordStatusCode);
  useSessionExpired(cancelNotificationStatusCode);
  useSessionExpired(confirmOTPStatusCode);
  useSessionExpired(updatePasswordStatusCode);
  useSessionExpired(resendOTPStatusCode);
  useSessionExpired(forgotPasswordStatusCode);
  if (notificationDetailsNetworkError) return <NetworkErrorComponent settingsScreen={true} />;
  if (notificationDetailsLoading)
    return (
      <DashboardShell settingsScreen={true}>
        <TitleTopbar title={t('notification_to_parents')} />
        <Loader />
      </DashboardShell>
    );

  return (
    <DashboardShell settingsScreen={true}>
      <TitleTopbar title={t('notification_to_parents')} />
      <PasswordPrompt
        isOpen={passwordPromptIsOpen}
        onClose={passwordPromptOnClose}
        onOpen={passwordPromptOnOpen}
        onClickResetPassword={onClickResetPassword}
        onClickVerifyPassword={onClickVerifyPassword}
      />
      <VerifyEmailPrompt
        isOpen={verifyEmailPromptIsOpen}
        onClose={verifyEmailPromptOnClose}
        onOpen={verifyEmailPromptOnOpen}
        showResendCodeButton={showResendButton}
        disableResendCodeButton={disableResendButon}
        timer={timerText}
        email={notificationDetails?.email}
        onClickResendVerificationCode={onClickResendCode}
        onClickVerifyEmail={onClickVerifyEmail}
      />
      <VerificationCodePrompt
        isOpen={verificationCodePromptIsOpen}
        onClose={verificationCodePromptOnClose}
        onOpen={verificationCodePromptOnOpen}
        showResendCodeButton={showResendButton}
        disableResendCodeButton={disableResendButon}
        timer={timerText}
        onClickResendVerificationCode={onClickResendCode}
        onClickConfirmVerificationCode={onClickConfirmVerificationCode}
      />
      <ResetPasswordPrompt
        isOpen={resetPasswordPromptIsOpen}
        onClose={resetPasswordPromptOnClose}
        onOpen={resetPasswordPromptOnOpen}
        onClickConfirmPassword={onClickConfirmPassword}
      />
      {showContent()}
    </DashboardShell>
  );
}

export default ParentsNotification;
