import { AppActions } from '../rootActions';
import {
  GET_HOMEWORK_LIST,
  GET_HOMEWORK_RECORDS,
  GET_HOMEWORK_QUESTIONS,
  BOOKMARK_QUESTION,
  RESET_BOOKMARK,
  RESET_HOMEWORK_RECORDS,
  SAVE_ANSWER,
  RESET_SAVE_ANSWER,
  END_HOMEWORK,
  RESET_END_HOMEWORK,
  GET_HOMEWORK_RECORD,
  UPDATE_TIMER,
} from './constants';
import { EndHomeworkPayload, SaveAnswerPayload, UpdateTimerPayload } from './models';

/**
 * Functions to be called in React components
 */

export const getHomeworkList = (): AppActions => ({
  type: GET_HOMEWORK_LIST,
});

export const getHomeworkRecords = (homeworkId: number): AppActions => ({
  type: GET_HOMEWORK_RECORDS,
  homeworkId: homeworkId,
});

export const getHomeworkRecord = (attemptId: number): AppActions => ({
  type: GET_HOMEWORK_RECORD,
  attemptId: attemptId,
});

export const resetHomeworkRecords = (): AppActions => ({
  type: RESET_HOMEWORK_RECORDS,
});

export const getHomeworkQuestions = (homeworkId: number): AppActions => ({
  type: GET_HOMEWORK_QUESTIONS,
  homeworkId: homeworkId,
});

export const bookmarkQuestion = (payload: {
  questionId: number;
  bookmark: boolean;
}): AppActions => ({
  type: BOOKMARK_QUESTION,
  payload: payload,
});

export const resetBookmark = (): AppActions => ({
  type: RESET_BOOKMARK,
});

export const saveAnswer = (payload: SaveAnswerPayload): AppActions => ({
  type: SAVE_ANSWER,
  payload: payload,
});

export const resetSaveAnswer = (): AppActions => ({
  type: RESET_SAVE_ANSWER,
});

export const endHomework = (payload: EndHomeworkPayload): AppActions => ({
  type: END_HOMEWORK,
  payload: payload,
});

export const resetEndHomework = (): AppActions => ({
  type: RESET_END_HOMEWORK,
});

export const updateTimer = (payload: UpdateTimerPayload): AppActions => ({
  type: UPDATE_TIMER,
  payload: payload,
});
