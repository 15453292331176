import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ThreeDotIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 21 5" {...props}>
      <circle id="Ellipse_122" data-name="Ellipse 122" cx="2.5" cy="2.5" r="2.5" />
      <circle
        id="Ellipse_123"
        data-name="Ellipse 123"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(8)"
      />
      <circle
        id="Ellipse_124"
        data-name="Ellipse 124"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(16)"
      />
    </Icon>
  );
}
