import React, { useEffect, useState } from 'react';
import DashboardShell from 'src/components/DashboardShell';
import { HStack, VStack } from '@chakra-ui/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BackTopbar from 'src/components/Topbar/BackTopbar';
import Question from 'src/components/Question/Question';
import { getHomeworkRecord } from 'src/redux/homework/actions';
import {
  selectHomeworkRecordAttemptData,
  selectHomeworkRecordNetworkError,
  selectHomeworkRecordQuestions,
  selectHomeworkRecordStatusCode,
} from 'src/redux/homework/selectors';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import Loader from 'src/components/Loader';
import usePageTracking from '../PageTracking';
import AttemptDetails from 'src/components/QuestionAndHomeworkReview/AttemptDetails';
import QuestionList from 'src/components/QuestionAndHomeworkReview/QuestionList';

interface LocationState {
  homeworkName: string;
}

function HomeworkReview(): JSX.Element {
  usePageTracking('homework-review');
  const params = useParams<{ homeworkId: string; attemptId: string; questionId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const questions = useSelector(selectHomeworkRecordQuestions);
  const attemptData = useSelector(selectHomeworkRecordAttemptData);
  const statusCode = useSelector(selectHomeworkRecordStatusCode);
  const networkError = useSelector(selectHomeworkRecordNetworkError);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    if (questions) {
      const temp = questions.filter((item) => item.id.toString() === params.questionId);
      const pos = questions.indexOf(temp[0]);
      setCurrentQuestion(pos);
    }
  }, [params.questionId]);

  useEffect(() => {
    dispatch(getHomeworkRecord(Number(params.attemptId)));
  }, []);

  useSessionExpired(statusCode);
  if (networkError) return <NetworkErrorComponent />;
  if (!questions || !attemptData) {
    return (
      <DashboardShell>
        <BackTopbar
          title={state.homeworkName}
          pathname={`/homework-records/${params.homeworkId!}`}
        />
        <Loader />
      </DashboardShell>
    );
  }

  const onClickPreviousQuestion = () => {
    navigate(
      `/homework-review/${params.homeworkId!}/${params.attemptId!}/${
        questions[currentQuestion - 1].id
      }`,
      {
        replace: true,
        state: { homeworkName: state.homeworkName },
      },
    );
  };

  const onClickNextQuestion = () => {
    navigate(
      `/homework-review/${params.homeworkId!}/${params.attemptId!}/${
        questions[currentQuestion + 1].id
      }`,
      {
        replace: true,
        state: { homeworkName: state.homeworkName },
      },
    );
  };

  return (
    <DashboardShell>
      <BackTopbar title={state.homeworkName} pathname={`/homework-records/${params.homeworkId!}`} />
      <HStack flex="1" overflow="hidden" spacing={5} px={10} py={5}>
        <Question
          key={questions[currentQuestion].id}
          question={questions[currentQuestion]}
          currentQuestion={currentQuestion}
          totalQuestions={questions.length}
          onClickPreviousQuestion={onClickPreviousQuestion}
          onClickNextQuestion={onClickNextQuestion}
        />
        <VStack h="100%" w="30%" spacing={5}>
          <AttemptDetails {...attemptData} />
          <QuestionList
            attemptData={attemptData}
            type="homework"
            homeworkId={params.homeworkId}
            attemptId={params.attemptId}
            state={{ homeworkName: state.homeworkName }}
          />
        </VStack>
      </HStack>
    </DashboardShell>
  );
}

export default HomeworkReview;
