import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/analytics';
import { IS_DEV } from 'src/constants/environment';

const usePageTracking = (title: string): void => {
  const location = useLocation();

  useEffect(() => {
    if (!IS_DEV) {
      logEvent(analytics, 'page_view', {
        page_title: title,
        page_location: location.pathname,
      });
    }
  }, [location]);
};

export default usePageTracking;
