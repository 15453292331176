import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { resetSaveAnswer } from 'src/redux/homework/actions';

function useSaveAnswer(saveAnswerNetworkError: boolean): void {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (saveAnswerNetworkError) {
      setTimeout(() => dispatch(resetSaveAnswer()), 500);
      toast({
        title: t('save_answer_error'),
        position: 'top',
        status: 'error',
      });
    }
  }, [saveAnswerNetworkError, dispatch, toast]);
}

export default useSaveAnswer;
