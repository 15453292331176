import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function NextIcon(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        d="M10.2017681,21.4595861 L11.3911736,22.6288729 C11.6315308,22.866433 11.9583005,23 12.2991305,23 C12.6399605,23 12.9667302,22.866433 13.2070874,22.6288729 L23.6224873,12.3955486 C23.8641348,12.1592571 24,11.8380147 24,11.5029498 C24,11.1678849 23.8641348,10.8466425 23.6224873,10.6103509 L13.2022866,0.37112707 C12.9619294,0.133567008 12.6351597,0 12.2943297,0 C11.9534997,0 11.6267299,0.133567008 11.3863728,0.37112707 L10.2017681,1.54041387 C9.95806565,1.78129952 9.82307065,2.10836838 9.82713345,2.44808681 C9.83119625,2.78780524 9.97397589,3.11166353 10.2233718,3.34684988 L16.6828881,9.39268797 L1.28182658,9.39268797 C0.941131315,9.39299997 0.614551512,9.52651263 0.374094194,9.76378798 C0.133636876,10.0010633 -0.000951201041,10.3226159 7.2870979e-16,10.6575472 L7.2870979e-16,12.3424528 C-0.000633724418,12.6777936 0.134589151,12.9995803 0.375791173,13.2367024 C0.616993196,13.4738245 0.944316624,13.6067601 1.2854272,13.6061321 L16.6828881,13.6061321 L10.2233718,19.6484305 C9.97003356,19.882432 9.82456582,20.207775 9.82048981,20.5494884 C9.8164138,20.8912018 9.95408113,21.2198088 10.2017681,21.4595861 L10.2017681,21.4595861 Z"
        id="Next_question"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </Icon>
  );
}
