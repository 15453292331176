import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function InstagramIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 26 26" {...props}>
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M13,8.572a6.666,6.666,0,1,0,6.665,6.666A6.655,6.655,0,0,0,13,8.572Zm0,11a4.334,4.334,0,1,1,4.333-4.334A4.341,4.341,0,0,1,13,19.572ZM21.489,8.3a1.554,1.554,0,1,1-1.554-1.555A1.551,1.551,0,0,1,21.489,8.3ZM25.9,9.877a7.7,7.7,0,0,0-2.1-5.448,7.742,7.742,0,0,0-5.447-2.1c-2.146-.122-8.579-.122-10.725,0A7.731,7.731,0,0,0,2.186,4.423a7.721,7.721,0,0,0-2.1,5.448c-.122,2.147-.122,8.581,0,10.727a7.7,7.7,0,0,0,2.1,5.448,7.752,7.752,0,0,0,5.447,2.1c2.146.122,8.579.122,10.725,0a7.692,7.692,0,0,0,5.447-2.1A7.746,7.746,0,0,0,25.9,20.6c.122-2.147.122-8.575,0-10.722ZM23.131,22.9a4.387,4.387,0,0,1-2.471,2.472c-1.711.679-5.771.522-7.662.522s-5.957.151-7.662-.522A4.387,4.387,0,0,1,2.864,22.9c-.679-1.712-.522-5.773-.522-7.664s-.151-5.958.522-7.664A4.387,4.387,0,0,1,5.335,5.1C7.046,4.423,11.107,4.58,13,4.58s5.957-.151,7.662.522a4.387,4.387,0,0,1,2.471,2.472c.679,1.712.522,5.773.522,7.664S23.809,21.2,23.131,22.9Z"
        transform="translate(0.005 -2.238)"
        fill="#fff"
      />
    </Icon>
  );
}
