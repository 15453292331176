import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
  Divider,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { HideIcon, ShowIcon } from 'src/icons';
import {
  selectCancelNotificationStatusCode,
  selectVerifyPasswordLoading,
  selectCancelNotificationLoading,
} from 'src/redux/parentsNotification/selectors';

interface CancelNotificationPromptProps {
  onClickCancelNotification: () => void;
}

function CancelNotificationPrompt({
  onClickCancelNotification,
}: CancelNotificationPromptProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const cancelNotificationStatusCode = useSelector(selectCancelNotificationStatusCode);
  const verifyPasswordLoading = useSelector(selectVerifyPasswordLoading);
  const cancelNotificationLoading = useSelector(selectCancelNotificationLoading);

  useEffect(() => {
    if (cancelNotificationStatusCode === 200) {
      onClose();
    }
  }, [cancelNotificationStatusCode]);

  return (
    <>
      <Button
        bg="#FF0000"
        color="white"
        minW="fit-content"
        fontSize={18}
        fontWeight="semibold"
        onClick={onOpen}
        _hover={{ bg: 'C20000' }}
        _active={{ bg: 'C20000' }}
      >
        {t('cancel_notification')}
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={20} fontWeight="semibold">
            {t('are_you_sure_to_cancel_notification')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalCloseButton mt={2} mr={3} />
          <ModalBody pb={6}>
            <Text mb={5} fontSize={20} fontWeight="medium">
              {t('please_enter_your_password')}
            </Text>
            <HStack spacing={2}>
              <Text fontSize={18} fontWeight="medium">
                {t('password')}:
              </Text>
              <InputGroup>
                <Input
                  id="cancelNotificationPassword"
                  h="28px"
                  type={showPassword ? 'text' : 'password'}
                  borderColor="#707070"
                  borderRadius="full"
                  _hover={{ borderColor: '#707070' }}
                />
                <InputRightElement
                  mt={-1}
                  cursor="pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
                </InputRightElement>
              </InputGroup>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClickCancelNotification}
              fontSize={16}
              fontWeight="bold"
              isLoading={verifyPasswordLoading || cancelNotificationLoading}
            >
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CancelNotificationPrompt;
