import React from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  CircularProgressProps,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CircularProgressBarProps extends CircularProgressProps {
  value: number;
  averageScore?: boolean;
}

function CircularProgressBar({
  value,
  averageScore,
  ...props
}: CircularProgressBarProps): JSX.Element {
  const { t } = useTranslation();
  let color = '';

  if (value <= 30) {
    color = 'progressRed.500';
  } else if (value <= 65) {
    color = 'progressYellow.500';
  } else if (value <= 99) {
    color = 'progressBlue.500';
  } else {
    color = 'progressGreen.500';
  }

  return (
    <CircularProgress value={value} color={color} thickness="10px" size="180px" {...props}>
      <CircularProgressLabel>
        <Flex flexDirection="column">
          <Text as="span" fontSize={30} fontWeight="medium">
            {value}%
          </Text>
          {averageScore ? (
            <Text as="span" fontSize={16} fontWeight="medium">
              {t('average_score')}
            </Text>
          ) : (
            <Text as="span" fontSize={24} fontWeight="medium">
              {t('score')}
            </Text>
          )}
        </Flex>
      </CircularProgressLabel>
    </CircularProgress>
  );
}

export default CircularProgressBar;
