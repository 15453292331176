import React from 'react';
import {
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LeftArrowIcon, RightArrowIcon } from 'src/icons';

interface Step3Props {
  previousStep: () => void;
  nextStep: () => void;
  maxQuestions: number;
  numOfQuestions: {
    numOfQuestions: number;
    setNumOfQuestions: React.Dispatch<React.SetStateAction<number>>;
  };
}

function Step3({ previousStep, nextStep, maxQuestions, numOfQuestions }: Step3Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex fontSize={20} fontWeight="semibold" justifyContent="center">
        {t('step_3')}
      </Flex>
      <Flex fontSize={18} fontWeight="medium" justifyContent="center" mt={10}>
        {t('select_number_of_questions_instruction')}
      </Flex>
      <VStack flex="1" spacing={10} mt={10}>
        <NumberInput
          defaultValue={numOfQuestions.numOfQuestions}
          min={0}
          max={maxQuestions}
          w="280px"
          h="140px"
          onChange={(value) => numOfQuestions.setNumOfQuestions(Number(value))}
        >
          <NumberInputField
            w="280px"
            h="140px"
            fontSize={50}
            fontWeight="medium"
            textAlign="center"
          />
          <NumberInputStepper w="50px">
            <NumberIncrementStepper fontSize={30} color="#003BFF">
              +
            </NumberIncrementStepper>
            <NumberDecrementStepper fontSize={30} color="#003BFF">
              -
            </NumberDecrementStepper>
          </NumberInputStepper>
        </NumberInput>
        <Text>
          <Text as="span" fontWeight="semibold">
            {maxQuestions}{' '}
          </Text>
          {t('maximum_questions_instruction')}
        </Text>
      </VStack>
      <HStack justifyContent="center" spacing={10}>
        <Button
          onClick={previousStep}
          textTransform="uppercase"
          leftIcon={<LeftArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('previous_step')}
        </Button>
        <Button
          disabled={numOfQuestions.numOfQuestions === 0}
          onClick={nextStep}
          textTransform="uppercase"
          rightIcon={<RightArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('next_step')}
        </Button>
      </HStack>
    </Flex>
  );
}

export default Step3;
