import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function MaterialIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 40.829 33.064" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-0.042"
          y1="-0.22"
          x2="-0.008"
          y2="-0.22"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#8bd0f7" />
          <stop offset="1" stopColor="#00a2ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-1.183"
          y1="-4.327"
          x2="-1.016"
          y2="-4.327"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00a1ff" />
          <stop offset="1" stopColor="#006199" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-3.129"
          y1="-4.327"
          x2="-2.963"
          y2="-4.327"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-1.183"
          y1="-6.664"
          x2="-1.016"
          y2="-6.664"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="-3.129"
          y1="-6.664"
          x2="-2.963"
          y2="-6.664"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="-10.131"
          y1="-0.294"
          x2="-9.464"
          y2="-0.294"
          xlinkHref="#linear-gradient-2"
        />
      </defs>
      <g
        id="_12_-_book_reading_library_sign._writing_school_study"
        data-name="12 - book, reading, library, sign. writing, school, study"
        transform="translate(-1.25 -4.055)"
      >
        <path
          id="Path_1239"
          data-name="Path 1239"
          d="M38.273,5.045H5.056A3.806,3.806,0,0,0,1.25,8.851V32.933a3.807,3.807,0,0,0,3.806,3.806H38.273a3.807,3.807,0,0,0,3.806-3.806V8.851A3.806,3.806,0,0,0,38.273,5.045Z"
          transform="translate(0 0.38)"
          fillRule="evenodd"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_1240"
          data-name="Path 1240"
          d="M17.5,5.836,8.658,4.128a4.023,4.023,0,0,0-4.786,3.95V25.839a4.023,4.023,0,0,0,3.261,3.95l10.823,2.09a13.868,13.868,0,0,0,5.255,0l10.822-2.09a4.023,4.023,0,0,0,3.261-3.95V8.078a4.022,4.022,0,0,0-4.786-3.95L23.666,5.836A16.268,16.268,0,0,1,17.5,5.836Z"
          transform="translate(1.007)"
          fill="#cbe8ff"
          fillRule="evenodd"
        />
        <path
          id="Path_1241"
          data-name="Path 1241"
          d="M7.942,12.313l6.232,1.2a1.038,1.038,0,0,0,.394-2.039l-6.232-1.2a1.038,1.038,0,0,0-.394,2.039Z"
          transform="translate(2.247 2.381)"
          fillRule="evenodd"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_1242"
          data-name="Path 1242"
          d="M25.862,10.274l-6.232,1.2a1.038,1.038,0,1,0,.393,2.039l6.232-1.2a1.038,1.038,0,0,0-.393-2.039Z"
          transform="translate(6.735 2.381)"
          fillRule="evenodd"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_1243"
          data-name="Path 1243"
          d="M7.942,17.847l6.232,1.2a1.038,1.038,0,0,0,.394-2.039l-6.232-1.2a1.038,1.038,0,1,0-.394,2.037Z"
          transform="translate(2.247 4.506)"
          fillRule="evenodd"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_1244"
          data-name="Path 1244"
          d="M25.862,15.809l-6.232,1.2a1.038,1.038,0,1,0,.393,2.039l6.232-1.2a1.037,1.037,0,1,0-.393-2.037Z"
          transform="translate(6.735 4.506)"
          fillRule="evenodd"
          fill="url(#linear-gradient-5)"
        />
        <path
          id="Path_1245"
          data-name="Path 1245"
          d="M17.272,5.531a16.236,16.236,0,0,1-2.076,0V31.524a13.678,13.678,0,0,0,2.076,0Z"
          transform="translate(5.356 0.567)"
          fillRule="evenodd"
          fill="url(#linear-gradient-6)"
        />
      </g>
    </Icon>
  );
}
