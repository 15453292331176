import React, { forwardRef } from 'react';
import { Box, HStack, Image, Text, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import { QuestionBank } from 'src/models/QuestionBank';

function QuestionBankCard(
  questionBank: QuestionBank,
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = () => {
    navigate(`/question-bank-records/${questionBank.id}`, {
      state: {
        questionBankName: questionBank.name,
        questionType: questionBank.questionType,
      },
    });
  };

  return (
    <Box
      border="1px solid #707070"
      borderRadius="10px"
      p={5}
      boxShadow="md"
      mb={5}
      onClick={onClick}
      cursor="pointer"
      ref={ref}
      w="fit-content"
      minW="100%"
    >
      <HStack spacing={7} fontSize={18}>
        <Box>
          <Image
            src={questionBank.imageUrl}
            fallbackSrc={TavisPlaceholder}
            borderRadius="10px"
            w="80px"
            h="80px"
          />
        </Box>
        <Text fontWeight="medium">{questionBank.name}</Text>
        <Spacer />
        <Text>{questionBank.questionType}</Text>
        <Text>{questionBank.formName}</Text>
        <Text>
          {questionBank.totalQuestions} {t('questions')}
        </Text>
      </HStack>
    </Box>
  );
}

export default forwardRef(QuestionBankCard);
