import React, { forwardRef } from 'react';
import { AspectRatio, Image, Text, Flex, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import { ExcelIcon, PdfIcon, PhotoIcon, PowerpointIcon, WordIcon } from 'src/icons';

interface ReadingMaterialCardProps {
  materialUrl: string;
  materialName: string;
}
function ReadingMaterialCard(
  { materialUrl, materialName }: ReadingMaterialCardProps,
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  const navigate = useNavigate();

  const onClickMaterial = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      materialUrl.includes('png') ||
      materialUrl.includes('jpeg') ||
      materialUrl.includes('jpg')
    ) {
      const image = (e.currentTarget as HTMLDivElement).firstChild as HTMLImageElement;
      if (image) {
        const viewer = new Viewer(image, {
          hidden() {
            viewer.destroy();
          },
        });
        viewer.show();
      }
    } else {
      navigate('/view-reading-materials', {
        state: { url: materialUrl, filename: materialName },
      });
    }
  };

  const showIcon = () => {
    if (materialUrl.includes('doc') || materialUrl.includes('docx')) {
      return <WordIcon boxSize="30px" />;
    } else if (materialUrl.includes('pdf')) {
      return <PdfIcon boxSize="30px" />;
    } else if (materialUrl.includes('xlsx')) {
      return <ExcelIcon boxSize="30px" />;
    } else if (materialUrl.includes('pptx')) {
      return <PowerpointIcon boxSize="30px" />;
    } else {
      return <PhotoIcon boxSize="30px" />;
    }
  };

  return (
    <Flex
      boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
      minW="200px"
      minH="200px"
      h="fit-content"
      onClick={onClickMaterial}
      borderRadius="10px"
      flexDirection="column"
      ref={ref}
    >
      <AspectRatio ratio={16 / 9}>
        <Image fit="cover" src={materialUrl} fallbackSrc={TavisPlaceholder} roundedTop="lg" />
      </AspectRatio>
      <HStack flex="1" align="center" px={2}>
        {showIcon()}
        <Text fontSize={12}>{materialName}</Text>
      </HStack>
    </Flex>
  );
}

export default forwardRef(ReadingMaterialCard);
