import React from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface NavigationCardProps {
  children: React.ReactNode;
  marks: number;
  totalMarks: number;
  pathname: string;
  state?: unknown;
}

function NavigationCard({
  children,
  marks,
  totalMarks,
  pathname,
  state,
}: NavigationCardProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Flex
      bg="#E6E6E6"
      p={3}
      borderRadius="10px"
      my={2}
      userSelect="none"
      minW="fit-content"
      cursor="pointer"
      onClick={() => navigate(pathname, { replace: true, state: state })}
    >
      {children}
      <Spacer />
      {`${marks}/${totalMarks}`}
    </Flex>
  );
}

export default NavigationCard;
