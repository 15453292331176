import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function WordIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1676" data-name="Rectangle 1676" width="20" height="26" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_370" data-name="Group 370" clipPath="url(#clip-path)">
        <path
          id="Path_1143"
          data-name="Path 1143"
          d="M41.792,4.469v21.06a.9.9,0,0,1-.9.906H24.245a.9.9,0,0,1-.9-.906V1.406a.9.9,0,0,1,.9-.906H37.836Z"
          transform="translate(-21.824 -0.467)"
          fill="#fff"
        />
        <path
          id="Path_1144"
          data-name="Path 1144"
          d="M41.792,4.469v21.06a.9.9,0,0,1-.9.906H24.245a.9.9,0,0,1-.9-.906V1.406a.9.9,0,0,1,.9-.906H37.836Z"
          transform="translate(-21.824 -0.467)"
          fill="none"
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          id="Path_1145"
          data-name="Path 1145"
          d="M231.67,4.469v21.06a.9.9,0,0,1-.9.906h-1.289a.9.9,0,0,0,.9-.906V4.469L226.426.5h1.289Z"
          transform="translate(-211.703 -0.467)"
          fill="#e5e5e5"
        />
        <path
          id="Path_1146"
          data-name="Path 1146"
          d="M250.2,4.469h-3.053a.9.9,0,0,1-.9-.906V.5Z"
          transform="translate(-230.234 -0.467)"
          fill="#cecece"
        />
        <path
          id="Path_1147"
          data-name="Path 1147"
          d="M84.919,73.523H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -67.495)"
          fill="#cecece"
        />
        <path
          id="Path_1148"
          data-name="Path 1148"
          d="M84.919,125.153H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -115.756)"
          fill="#cecece"
        />
        <path
          id="Path_1149"
          data-name="Path 1149"
          d="M84.919,176.782H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -164.016)"
          fill="#cecece"
        />
        <path
          id="Path_1150"
          data-name="Path 1150"
          d="M84.919,226.6H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -210.58)"
          fill="#cecece"
        />
        <path
          id="Path_1151"
          data-name="Path 1151"
          d="M84.919,278.225H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -258.84)"
          fill="#cecece"
        />
        <path
          id="Path_1152"
          data-name="Path 1152"
          d="M84.919,329.854H74.2a.658.658,0,0,1,0-1.316H84.919a.658.658,0,0,1,0,1.316"
          transform="translate(-68.763 -307.1)"
          fill="#cecece"
        />
        <path
          id="Path_1153"
          data-name="Path 1153"
          d="M9.066,76.393H0V72.711a.5.5,0,0,1,.5-.5H9.066a.5.5,0,0,1,.5.5V75.89a.5.5,0,0,1-.5.5"
          transform="translate(-0.003 -67.496)"
          fill="#3586cb"
        />
        <path
          id="Path_1154"
          data-name="Path 1154"
          d="M9.564,75.827v.063a.5.5,0,0,1-.5.5H0V72.711a.5.5,0,0,1,.5-.5H.759v1.309a2.306,2.306,0,0,0,2.3,2.31Z"
          transform="translate(0 -67.496)"
          fill="#366fca"
        />
        <path
          id="Path_1155"
          data-name="Path 1155"
          d="M0,136.341l1.518,1.737v-1.737Z"
          transform="translate(-0.002 -127.444)"
          fill="#3267b2"
        />
        <path
          id="Path_1156"
          data-name="Path 1156"
          d="M29.888,137.565H23.342v-1.224h7.569v.2a1.025,1.025,0,0,1-1.023,1.026"
          transform="translate(-21.824 -127.444)"
          fill="#e5e5e5"
        />
        <path
          id="Path_1157"
          data-name="Path 1157"
          d="M12.863,90.173v-.38h.34V88.3h-.34v-.38h1.062a2.838,2.838,0,0,1,.54.041.963.963,0,0,1,.392.183,1.006,1.006,0,0,1,.3.379,1.215,1.215,0,0,1,.113.527,1.273,1.273,0,0,1-.079.447,1.06,1.06,0,0,1-.2.343.871.871,0,0,1-.28.207,1.159,1.159,0,0,1-.3.1,2.839,2.839,0,0,1-.422.023Zm.922-.38h.14a1.012,1.012,0,0,0,.4-.066.507.507,0,0,0,.239-.234.92.92,0,0,0,.09-.438.959.959,0,0,0-.091-.437.506.506,0,0,0-.247-.248,1,1,0,0,0-.4-.068h-.14Z"
          transform="translate(-12.027 -82.186)"
          fill="#fff"
        />
        <path
          id="Path_1158"
          data-name="Path 1158"
          d="M56.132,87.378a1.227,1.227,0,0,1,.913.337,1.177,1.177,0,0,1,0,1.641,1.229,1.229,0,0,1-.912.336,1.2,1.2,0,0,1-.924-.345,1.15,1.15,0,0,1-.322-.811,1.126,1.126,0,0,1,.331-.819,1.224,1.224,0,0,1,.915-.337m0,.411a.574.574,0,0,0-.438.194.81.81,0,0,0-.18.569.768.768,0,0,0,.177.531.566.566,0,0,0,.44.2.576.576,0,0,0,.438-.195.936.936,0,0,0,.008-1.094.558.558,0,0,0-.447-.2"
          transform="translate(-51.317 -81.676)"
          fill="#fff"
        />
        <path
          id="Path_1159"
          data-name="Path 1159"
          d="M100.1,87.414h.379v.793H100.1a.572.572,0,0,0-.559-.427.582.582,0,0,0-.5.225.873.873,0,0,0-.168.539.777.777,0,0,0,.178.531.577.577,0,0,0,.454.2.569.569,0,0,0,.4-.154.784.784,0,0,0,.229-.427l.442.211a1.054,1.054,0,0,1-1.107.784,1.192,1.192,0,0,1-.912-.327,1.159,1.159,0,0,1-.307-.827,1.191,1.191,0,0,1,.16-.623,1.03,1.03,0,0,1,.43-.4,1.307,1.307,0,0,1,.592-.135,1.063,1.063,0,0,1,.672.21Z"
          transform="translate(-91.86 -81.676)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}
