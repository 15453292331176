import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import {
  getQuestionBankChapters,
  getQuestionBankMaxQuestions,
  resetQuestionBank,
  submitNewAttempt,
} from 'src/redux/questionBank/actions';
import {
  selectQuestion,
  selectQuestionBankChapters,
  selectQuestionBankChaptersStatusCode,
  selectQuestionBankMaxQuestions,
  selectQuestionBankMaxQuestionsStatusCode,
  selectSubmitNewAttemptStatusCode,
} from 'src/redux/questionBank/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import Loader from 'src/components/Loader';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import usePageTracking from '../PageTracking';

interface LocationState {
  isFirstAttempt: boolean;
  questionType: string;
  questionBankName: string;
}

function QuestionBankNewAttempt(): JSX.Element {
  usePageTracking('question-bank-new-attempt');
  const dispatch = useDispatch();
  const params = useParams<{ subjectId: string }>();
  const navigate = useNavigate();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { isFirstAttempt, questionType, questionBankName } = state;

  const chapters = useSelector(selectQuestionBankChapters);
  const chaptersStatusCode = useSelector(selectQuestionBankChaptersStatusCode);
  const newAttemptStatusCode = useSelector(selectSubmitNewAttemptStatusCode);
  const questions = useSelector(selectQuestion);
  const maxQuestions = useSelector(selectQuestionBankMaxQuestions);
  const maxQuestionsStatusCode = useSelector(selectQuestionBankMaxQuestionsStatusCode);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedChapters, setSelectedChapters] = useState<number[]>([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState<number | undefined>(undefined);
  const [numOfQuestions, setNumOfQuestions] = useState(0);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    dispatch(getQuestionBankChapters(Number(params.subjectId)));
    return () => {
      dispatch(resetQuestionBank());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newAttemptStatusCode === 200 && questions) {
      const q = questions[0];
      navigate(`/question-bank-question/${params.subjectId!}/${q.attemptId}/${q.id}`, {
        state: {
          attemptName: name,
          questionBankName,
          questionType,
        },
      });
    }
  }, [newAttemptStatusCode, questions]);

  useSessionExpired(chaptersStatusCode);
  useSessionExpired(newAttemptStatusCode);
  useSessionExpired(maxQuestionsStatusCode);

  if (!chapters || (currentStep === 3 && maxQuestions === null)) {
    return (
      <DashboardShell>
        <BackTopbar
          title={questionBankName}
          pathname={`/question-bank-records/${params.subjectId!}`}
          state={{ questionType, questionBankName }}
        />
        <Loader />
      </DashboardShell>
    );
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = () => {
    if (currentStep === 2) {
      dispatch(
        getQuestionBankMaxQuestions({
          id: Number(params.subjectId),
          query: {
            chapters: selectedChapters.join(','),
            questionsType: selectedQuestionType!,
          },
        }),
      );
    }
    setCurrentStep(currentStep + 1);
  };

  const launchQB = () => {
    dispatch(
      submitNewAttempt({
        id: Number(params.subjectId),
        name: name,
        chapters: selectedChapters.join(','),
        questionsType: selectedQuestionType!,
        maxQuestions: numOfQuestions,
      }),
    );
  };

  const showContent = () => {
    switch (currentStep) {
      case 2:
        return (
          <Step2
            previousStep={previousStep}
            nextStep={nextStep}
            isFirstAttempt={isFirstAttempt}
            questionType={questionType}
            selectedQuestionType={{ selectedQuestionType, setSelectedQuestionType }}
          />
        );
      case 3:
        return (
          <Step3
            previousStep={previousStep}
            nextStep={nextStep}
            maxQuestions={maxQuestions || 0}
            numOfQuestions={{ numOfQuestions, setNumOfQuestions }}
          />
        );
      case 4:
        return <Step4 previousStep={previousStep} launchQB={launchQB} name={{ name, setName }} />;
      default:
        return (
          <Step1
            nextStep={nextStep}
            chapters={chapters}
            selectedChapters={{ selectedChapters, setSelectedChapters }}
          />
        );
    }
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={questionBankName}
        pathname={`/question-bank-records/${params.subjectId!}`}
        state={{ questionType, questionBankName }}
      />
      <Flex
        as={OverlayScrollbarsComponent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        flex="1"
        m={5}
        boxShadow="0 0 5px gray"
        borderRadius="10px"
        p={10}
        flexDirection="column"
      >
        {showContent()}
      </Flex>
    </DashboardShell>
  );
}

export default QuestionBankNewAttempt;
