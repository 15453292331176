import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
  Divider,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { endHomework, resetEndHomework } from 'src/redux/homework/actions';
import {
  selectHomeworkQuestions,
  selectHomeworkSummary,
  selectHomeworkSummaryNetworkError,
  selectHomeworkSummaryStatusCode,
} from 'src/redux/homework/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import NavigationCard from 'src/components/QuestionAndHomework/NavigationCard';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface EndHomeworkProps {
  timer: string;
  homeworkName: string;
}

function EndHomework({ timer, homeworkName }: EndHomeworkProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams<{ homeworkId: string }>();
  const { t } = useTranslation();

  const questions = useSelector(selectHomeworkQuestions);
  const homeworkSummary = useSelector(selectHomeworkSummary);
  const homeworkSummaryStatusCode = useSelector(selectHomeworkSummaryStatusCode);
  const homeworkSummaryNetworkError = useSelector(selectHomeworkSummaryNetworkError);

  useEffect(() => {
    if (homeworkSummaryNetworkError) {
      setTimeout(() => dispatch(resetEndHomework()), 500);
      toast({
        title: t('end_session_error'),
        position: 'top',
        status: 'error',
      });
    }
  }, [homeworkSummaryNetworkError]);

  useEffect(() => {
    if (homeworkSummary) {
      navigate(`/homework-summary/${params.homeworkId!}`, {
        state: { homeworkName: homeworkName },
      });
    }
  }, [homeworkSummary]);

  useSessionExpired(homeworkSummaryStatusCode);

  const onClickEndHomework = () => {
    if (questions) {
      dispatch(
        endHomework({
          totalTime: timer,
          attemptId: questions[0].attemptId,
          homeworkId: Number(params.homeworkId),
        }),
      );
    }
  };

  const showUnmarkedQuestions = () => {
    if (questions) {
      const essayQuestions = questions.filter((item) => item.quesType === 'Essay');
      const unmarkedQuestions = essayQuestions.filter((item) => item.mark === null);

      if (essayQuestions.length === 0 || unmarkedQuestions.length === 0) {
        return <></>;
      } else {
        return (
          <Box w="70%">
            <Text fontSize={14} fontWeight="medium" my={5}>
              {t('questions_unmarked')}
            </Text>
            <OverlayScrollbarsComponent
              style={{ minHeight: '100px', height: '100px', width: '60%', paddingRight: '20px' }}
              options={{ scrollbars: { autoHide: 'scroll' } }}
            >
              {unmarkedQuestions.map((item) => {
                return (
                  <NavigationCard
                    key={item.id}
                    backgroundColor={item.answer === null ? 'black' : '#E6E6E6'}
                    color={item.answer === null ? 'white' : 'black'}
                    bookmark={item.bookmark}
                    pathname={`/homework/${params.homeworkId!}/${item.id}`}
                    closeModal={onClose}
                  >
                    {item.quesName}
                  </NavigationCard>
                );
              })}
            </OverlayScrollbarsComponent>
          </Box>
        );
      }
    }
  };

  return (
    <>
      <Button fontSize={18} fontWeight="semibold" colorScheme="red" onClick={onOpen}>
        {t('end_session')}
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={18} fontWeight="medium">
            {t('confirm_end_session')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalBody pb={6}>
            <Text>{t('end_session_text')}</Text>
            {showUnmarkedQuestions()}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              fontSize={14}
              fontWeight="medium"
              onClick={onClickEndHomework}
            >
              <Text>{t('end')}</Text>
            </Button>
            <Button onClick={onClose} fontSize={14} fontWeight="medium">
              <Text>{t('cancel')}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EndHomework;
