import React from 'react';
import { Flex, Input, HStack, Button, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LeftArrowIcon } from 'src/icons';

interface Step4Props {
  previousStep: () => void;
  launchQB: () => void;
  name: {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
  };
}

function Step4({ previousStep, name, launchQB }: Step4Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex fontSize={20} fontWeight="semibold" justifyContent="center">
        {t('step_4')}
      </Flex>
      <Flex fontSize={18} fontWeight="medium" justifyContent="center" mt={10}>
        {t('enter_name_instruction')}
      </Flex>
      <Flex justifyContent="center" mt={10}>
        <Input
          h="40px"
          w="500px"
          maxLength={150}
          defaultValue={name.name}
          onChange={(e) => name.setName(e.target.value)}
        />
      </Flex>
      <Spacer />
      <HStack justifyContent="center" spacing={10}>
        <Button
          onClick={previousStep}
          textTransform="uppercase"
          leftIcon={<LeftArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('previous_step')}
        </Button>
        <Button
          disabled={name.name.length === 0}
          textTransform="uppercase"
          w="240px"
          onClick={launchQB}
        >
          {t('launch_qb')}
        </Button>
      </HStack>
    </Flex>
  );
}

export default Step4;
