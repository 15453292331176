import React from 'react';
import { Flex, HStack, Text, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Props } from 'framer-motion/types/types';

interface ItemCardProps {
  children: React.ReactNode;
  icon: React.ElementType<Props>;
  to: string;
}

function ItemCard({ children, to, icon }: ItemCardProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Flex
      boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
      p={8}
      m="auto"
      borderRadius="10px"
      w="250px"
      justifyContent="center"
      fontSize={24}
      fontWeight="medium"
      color="#595959"
      cursor="pointer"
      _hover={{ border: '1px solid #65CD7D', color: '#65CD7D' }}
      onClick={() => navigate(to)}
    >
      <HStack>
        <Icon
          mr="2"
          boxSize="40px"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
        <Text textAlign="center">{children}</Text>
      </HStack>
    </Flex>
  );
}

export default ItemCard;
