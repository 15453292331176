import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';

import {
  MaterialIcon,
  RecordedClassesIcon,
  ResourcesHomeworkIcon,
  ResourcesQuestionBankIcon,
} from 'src/icons';
import DashboardShell from 'src/components/DashboardShell';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ItemCard from './ItemCard';
import usePageTracking from '../PageTracking';

function Resources(): JSX.Element {
  usePageTracking('resources');
  const { t } = useTranslation();

  return (
    <DashboardShell>
      <TitleTopbar title={t('resources')} />
      <OverlayScrollbarsComponent
        style={{ height: '100%', width: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Grid h="100%" w="100%" gridTemplateColumns="auto auto">
          <ItemCard to="/resources/reading-materials" icon={MaterialIcon}>
            {t('reading_materials')}
          </ItemCard>
          <ItemCard to="/resources/recorded-lectures" icon={RecordedClassesIcon}>
            {t('recorded_classes')}
          </ItemCard>
          <ItemCard to="/homework" icon={ResourcesHomeworkIcon}>
            {t('homework')}
          </ItemCard>
          <ItemCard to="/question-bank" icon={ResourcesQuestionBankIcon}>
            {t('question_bank')}
          </ItemCard>
        </Grid>
      </OverlayScrollbarsComponent>
    </DashboardShell>
  );
}

export default Resources;
