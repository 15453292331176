import React from 'react';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Flex, Image, IconButton, Text, HStack, Spacer } from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/analytics';
import { RemoveIcon } from 'src/icons';
import { formatPrice } from 'src/utils/formatPrice';
import { Course } from 'src/models/cart/Course';
import { deleteCartItem, getCart } from 'src/redux/cart/actions';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import { useTranslation } from 'react-i18next';
import { IS_DEV } from 'src/constants/environment';

interface CourseItemProps {
  course: Course;
  canPurchase: boolean;
  resetPromoCode: () => void;
}

function ItemCard({ course, resetPromoCode, canPurchase }: CourseItemProps): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hasDiscount, discountPrice, originalPrice } = getCoursePriceStructure(course);

  const onDelete = (id: string) => {
    resetPromoCode();
    dispatch(deleteCartItem(id));
    setTimeout(() => {
      if (!IS_DEV) {
        logEvent(analytics, 'remove_from_cart', {
          items: [
            {
              item_id: course.id.toString(),
              item_name: course.courseName,
              price: course.discountPrice ? course.discountPrice : course.price,
              item_category: 'Courses',
              item_list_name: 'Courses',
            },
          ],
        });
      }
      dispatch(getCart());
    }, 500);
  };

  return (
    <Flex
      w="100%"
      py="4"
      pr="6"
      borderBottomColor="gray.600"
      borderBottomWidth="1px"
      bg={canPurchase ? course.color : 'gray.100'}
      color={canPurchase ? 'black' : 'gray.400'}
      cursor={canPurchase ? '' : 'not-allowed'}
    >
      <Flex direction="column" justifyContent="center">
        <IconButton
          mx="4"
          aria-label="remove-cart-item"
          icon={<RemoveIcon boxSize="20px" />}
          variant="unstyled"
          _hover={{ color: 'red' }}
          _focus={{ outline: 'none' }}
          onClick={() => onDelete(String(course.cartId))}
        />
      </Flex>
      <Image
        src={course.courseImageUrl || TavisPlaceholder}
        minW="100px"
        minH="100px"
        maxW="100px"
        maxH="100px"
        borderRadius="lg"
        fit="cover"
      />
      <Flex w="full" ml="4" justifyContent="space-between">
        <Flex direction="column">
          <Text fontSize="lg" fontWeight="medium">
            {course.courseName} - {course.form.name} - {format(parseISO(course.startDate), 'yyyy')}{' '}
            - Cikgu {course.teacher.firstname} {course.teacher.lastname}
          </Text>
          <Text fontSize="lg" fontWeight="medium">
            (#{course.sku})
          </Text>
          <Spacer />
          <Text>{t('billed_instruction')}</Text>
        </Flex>
        <Flex direction="column" fontSize="lg" fontWeight="medium" alignItems="flex-end">
          {hasDiscount ? (
            <HStack spacing="2">
              <Text>
                {t('fee')}/{t('month_word')}:
              </Text>
              <Text textDecoration="line-through">{formatPrice(String(originalPrice))}</Text>
              <Text color="red.500">{formatPrice(String(discountPrice))}</Text>
            </HStack>
          ) : (
            <Text>
              {t('fee')}/{t('month_word')}: {formatPrice(String(originalPrice))}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ItemCard;
