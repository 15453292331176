import '@fontsource/poppins/400.css'; // regular
import '@fontsource/poppins/500.css'; // medium
import '@fontsource/poppins/600.css'; // semibold
import '@fontsource/poppins/700.css'; // bold

import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from './styles/theme';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import './analytics';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HashRouter>
            <App />
          </HashRouter>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
