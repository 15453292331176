import React from 'react';
import { Route, Routes } from 'react-router-dom';
import '../i18n';

import Login from './Login/Login';
import Courses from './Courses/Courses';
import Register from './Register/Register';
import Dashboard from './Dashboard/Dashboard';
import QuestionBank from './QuestionBank/QuestionBank';
import QuestionBankDetails from './QuestionBankDetails/QuestionBankDetails';
import PurchasedQuestionBank from './PurchasedQuestionBank/PurchasedQuestionBank';
import CourseDetails from './CourseDetails/CourseDetails';
import QuestionBankQuestion from './QuestionBankQuestion/QuestionBankQuestion';
import QuestionBankRecords from './QuestionBankRecords/QuestionBankRecords';
import QuestionBankReview from './QuestionBankReview/QuestionBankReview';
import Profile from './Profile/Profile';
import MyPurchases from './MyPurchases/MyPurchases';
import MyPurchaseDetails from './MyPurchaseDetails/MyPurchaseDetails';
import Prepaid from './Prepaid/Prepaid';
import Subscription from './Subscription/Subscription';
import VirtualClassroom from './VirtualClassroom/VirtualClassroom';
import Resources from './Resources/Resources';
import ReadingMaterial from './ReadingMaterials/ReadingMaterials';
import RecordedLectures from './RecordedLectures/RecordedLectures';
import HomeworkRecords from './HomeworkRecords/HomeworkRecords';
import EmailVerification from './EmailVerification/EmailVerification';
import HomeworkSummary from './HomeworkSummary/HomeworkSummary';
import HomeworkReview from './HomeworkReview/HomeworkReview';
import ParentsNotification from './ParentsNotification/ParentsNotification';
import BillingInfo from './BillingInfo/BillingInfo';
import FileViewer from './ReadingMaterials/FileViewer';
import PurchasedRecordedLectures from './PurchasedRecordedLectures/PurchasedRecordedLectures';
import VideoPlayer from './PurchasedRecordedLectures/VideoPlayer';
import Language from './Language/Language';
import Settings from './Settings/Settings';
import QuestionBankRecordDetails from './QuestionBankRecordDetails/QuestionBankRecordDetails';
import QuestionBankNewAttempt from './QuestionBankNewAttempt/QuestionBankNewAttempt';
import QuestionBankPerformanceTracker from './QuestionBankPerformanceTracker/QuestionBankPerformanceTracker';
import HomeworkQuestion from './HomeworkQuestion/HomeworkQuestion';
import Homework from './Homework/Homework';
import ContactUs from './ContactUs/ContactUs';
// import Exam from './Exam/Exam';
// import StartExam from './Exam/StartExam';

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/my-purchases" element={<MyPurchases />} />
      <Route path="/my-purchases/:id" element={<MyPurchaseDetails />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/register" element={<Register />} />
      <Route path="/courses/:id" element={<CourseDetails />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/resources" element={<Resources />} />
      <Route path="/resources/reading-materials" element={<ReadingMaterial />} />
      <Route path="/resources/recorded-lectures" element={<RecordedLectures />} />
      <Route path="/view-reading-materials" element={<FileViewer />} />
      <Route
        path="/resources/purchased-recorded-lectures"
        element={<PurchasedRecordedLectures />}
      />
      <Route path="/view-recorded-lectures" element={<VideoPlayer />} />
      <Route path="/question-bank" element={<QuestionBank />} />
      <Route path="/purchased-question-bank" element={<PurchasedQuestionBank />} />
      <Route
        path="/question-bank/performance-tracker/:subjectId"
        element={<QuestionBankPerformanceTracker />}
      />
      <Route path="/question-bank-records/:subjectId" element={<QuestionBankRecords />} />
      <Route
        path="/question-bank-records/:subjectId/new-attempt"
        element={<QuestionBankNewAttempt />}
      />
      <Route
        path="/question-bank-records/:subjectId/:attemptId"
        element={<QuestionBankRecordDetails />}
      />
      <Route path="/question-bank/:subjectId" element={<QuestionBankDetails />} />
      <Route
        path="/question-bank-review/:subjectId/:attemptId/:questionId"
        element={<QuestionBankReview />}
      />
      <Route
        path="/question-bank-question/:subjectId/:attemptId/:questionId"
        element={<QuestionBankQuestion />}
      />
      <Route path="/homework/:homeworkId/:questionId" element={<HomeworkQuestion />} />
      <Route path="/homework" element={<Homework />} />
      <Route path="/homework-records/:homeworkId" element={<HomeworkRecords />} />
      <Route path="/homework-summary/:homeworkId" element={<HomeworkSummary />} />
      <Route
        path="/homework-review/:homeworkId/:attemptId/:questionId"
        element={<HomeworkReview />}
      />
      <Route path="/prepaid" element={<Prepaid />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/virtual-class/:eventId" element={<VirtualClassroom />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/notifications-to-parents" element={<ParentsNotification />} />
      <Route path="/settings/language" element={<Language />} />
      <Route path="/settings/contact-us" element={<ContactUs />} />
      <Route path="/checkout/billing-info" element={<BillingInfo />} />
    </Routes>
  );
}

export default App;
