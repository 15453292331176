import React, { useEffect, useRef } from 'react';
import { Flex, Text, VStack, Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import SummaryComponent from 'src/components/QuestionAndHomeworkSummary/SummaryComponent';
import {
  selectHomeworkQuestions,
  selectHomeworkQuestionsNetworkError,
  selectHomeworkQuestionsStatusCode,
  selectHomeworkRecordNetworkError,
  selectHomeworkRecordQuestions,
  selectHomeworkRecordStatusCode,
  selectHomeworkSummary,
} from 'src/redux/homework/selectors';
import Loader from 'src/components/Loader';
import {
  getHomeworkQuestions,
  getHomeworkRecord,
  resetEndHomework,
} from 'src/redux/homework/actions';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import usePageTracking from '../PageTracking';

interface LocationState {
  homeworkName: string;
}

function HomeworkSummary(): JSX.Element {
  usePageTracking('homework-summary');
  const navigate = useNavigate();
  const params = useParams<{ homeworkId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const clickReattemptButton = useRef(false);
  const clickReviewButton = useRef(false);

  const homeworkSummary = useSelector(selectHomeworkSummary);

  const questions = useSelector(selectHomeworkQuestions);
  const questionsStatusCode = useSelector(selectHomeworkQuestionsStatusCode);
  const questionsNetworkError = useSelector(selectHomeworkQuestionsNetworkError);

  const reviewQuestions = useSelector(selectHomeworkRecordQuestions);
  const reviewQuestionStatusCode = useSelector(selectHomeworkRecordStatusCode);
  const reviewQuestionNetworkError = useSelector(selectHomeworkRecordNetworkError);

  useEffect(() => {
    if (clickReattemptButton.current && questions) {
      if (questions.length > 0) {
        dispatch(resetEndHomework());
        navigate(`/homework/${params.homeworkId!}/${questions[0].id}`);
      } else {
        window.messageBox.alertBox(t('no_questions'));
        clickReattemptButton.current = false;
      }
    }
  }, [questions]);

  useEffect(() => {
    if (clickReviewButton.current && homeworkSummary && reviewQuestions) {
      navigate(
        `/homework-review/${params.homeworkId!}/${homeworkSummary.attemptId}/${
          reviewQuestions[0].id
        }`,
        {
          state: { homeworkName: state.homeworkName },
        },
      );
      clickReviewButton.current = false;
    }
  }, [reviewQuestions]);

  useEffect(() => {
    if (!homeworkSummary) {
      navigate('/homework');
    }
  }, [homeworkSummary]);

  useEffect(() => {
    return () => {
      dispatch(resetEndHomework());
    };
  }, []);

  useSessionExpired(questionsStatusCode);
  useSessionExpired(reviewQuestionStatusCode);
  if (questionsNetworkError || reviewQuestionNetworkError) return <NetworkErrorComponent />;
  if (!homeworkSummary) {
    return (
      <DashboardShell>
        <BackTopbar title={state.homeworkName} pathname="/homework" />
        <Loader />
      </DashboardShell>
    );
  }

  const onClickReattempt = () => {
    dispatch(getHomeworkQuestions(Number(params.homeworkId)));
    clickReattemptButton.current = true;
  };

  const onClickReview = () => {
    dispatch(getHomeworkRecord(homeworkSummary.attemptId));
    clickReviewButton.current = true;
  };

  return (
    <DashboardShell>
      <BackTopbar title={state.homeworkName} pathname="/homework" />
      <Flex
        as={OverlayScrollbarsComponent}
        style={{ width: '100%', height: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        py={5}
        px={7}
      >
        <Flex
          h="100%"
          w="100%"
          minW="fit-content"
          boxShadow="0 0 5px gray"
          borderRadius="10px"
          p={7}
          flexDirection="column"
        >
          <Text fontSize={24} fontWeight="semibold">
            {t('homework_summary')}
          </Text>
          <Flex h="100%" w="100%">
            <SummaryComponent summary={homeworkSummary} />
            <VStack justifyContent="flex-end" ml={10} spacing={5} w="fit-content">
              <Button
                fontWeight="medium"
                color="white"
                bg="#249520"
                _hover={{ backgroundColor: '#006600' }}
                _active={{ backgroundColor: '#006600' }}
                onClick={onClickReview}
              >
                {t('review')}
              </Button>
              <Button
                fontWeight="medium"
                color="white"
                bg="#251445"
                _hover={{ backgroundColor: '#01001f' }}
                _active={{ backgroundColor: '#01001f' }}
                onClick={onClickReattempt}
              >
                {t('reattempt')}
              </Button>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default HomeworkSummary;
