import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function YoutubeIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 30 21.094" {...props}>
      <path
        id="Icon_awesome-youtube"
        data-name="Icon awesome-youtube"
        d="M30.423,7.8a3.77,3.77,0,0,0-2.652-2.67C25.431,4.5,16.05,4.5,16.05,4.5s-9.381,0-11.721.631A3.77,3.77,0,0,0,1.677,7.8a39.544,39.544,0,0,0-.627,7.268,39.544,39.544,0,0,0,.627,7.268,3.713,3.713,0,0,0,2.652,2.627c2.34.631,11.721.631,11.721.631s9.381,0,11.721-.631a3.713,3.713,0,0,0,2.652-2.627,39.543,39.543,0,0,0,.627-7.268A39.543,39.543,0,0,0,30.423,7.8ZM12.982,19.529V10.608l7.841,4.461-7.841,4.46Z"
        transform="translate(-1.05 -4.5)"
        fill="#fff"
      />
    </Icon>
  );
}
