import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Box, HStack, Spinner, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useNavigate } from 'react-router-dom';

import {
  getQuestionBank,
  getMoreQuestionBank,
  resetQuestionBank,
} from 'src/redux/questionBank/actions';
import {
  selectQuestionBank,
  selectQuestionBankRefetching,
  selectQuestionBankPagination,
  selectQuestionBankLoading,
  selectQuestionBankStatusCode,
  selectQuestionBankNetworkError,
} from 'src/redux/questionBank/selectors';
import DashboardShell from 'src/components/DashboardShell';
import FilterTab from 'src/components/FilterTab';
import QuestionBankCard from './QuestionBankCard';
import Loader from 'src/components/Loader';
import { QuestionBankQuery } from 'src/models/QuestionBank';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import usePageTracking from '../PageTracking';

function QuestionBank(): JSX.Element {
  usePageTracking('question-bank');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | null>(null);

  const questionBank = useSelector(selectQuestionBank);
  const refetching = useSelector(selectQuestionBankRefetching);
  const pagination = useSelector(selectQuestionBankPagination);
  const loading = useSelector(selectQuestionBankLoading);
  const statusCode = useSelector(selectQuestionBankStatusCode);
  const networkError = useSelector(selectQuestionBankNetworkError);

  const getApiQeury = () => {
    const apiQuery: QuestionBankQuery = {
      subject: selectedSubject.join(','),
      form: selectedLevel.join(','),
      price_up: maxPrice,
      price_down: minPrice,
      keyword: keyword,
      limit: 10,
    };

    return apiQuery;
  };

  useEffect(() => {
    const debounce = setTimeout(() => dispatch(getQuestionBank(getApiQeury())), 500);
    return () => clearTimeout(debounce);
  }, [dispatch, selectedSubject, selectedLevel, minPrice, maxPrice, keyword]);

  useEffect(() => {
    dispatch(resetQuestionBank());
  }, [dispatch]);

  useSessionExpired(statusCode);
  if (networkError) return <NetworkErrorComponent />;

  if (!questionBank)
    return (
      <DashboardShell>
        <BackTopbar
          title={t('question_bank')}
          pathname="/resources"
          keywordSearch={{ keyword, setKeyword }}
        />
        <Loader />
      </DashboardShell>
    );

  const onEnter = (position: number) => {
    const hasMore = pagination?.page !== pagination?.totalPages;
    const isLastItem = position === Number(questionBank.length - 1);
    const apiQuery = {
      ...getApiQeury(),
      page: pagination ? pagination.page + 1 : 1,
    };

    if (isLastItem && hasMore) {
      dispatch(getMoreQuestionBank(apiQuery));
    }
  };

  const showContent = () => {
    if (loading) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    }

    if (questionBank.length === 0) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Text>{t('no_question_bank_available')}</Text>
        </Flex>
      );
    }

    return (
      <OverlayScrollbarsComponent
        style={{ flex: '1' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Grid
          templateColumns={'repeat(2, 1fr)'}
          gap={8}
          h="100%"
          w="full"
          minW="80%"
          autoRows="max-content"
          p={10}
          py={5}
        >
          {questionBank.map((item, position) => (
            <Waypoint key={item.id} onEnter={() => onEnter(position)}>
              <QuestionBankCard {...item} />
            </Waypoint>
          ))}
          {refetching && (
            <Flex
              flexDirection="column"
              mx="auto"
              rounded="lg"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          )}
        </Grid>
      </OverlayScrollbarsComponent>
    );
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={t('question_bank')}
        pathname="/resources"
        keywordSearch={{ keyword, setKeyword }}
      />
      <Flex m="3vh" mt="0" h="100%" maxH="85vh">
        <Flex flexDirection="column" w="30%" minW="30%">
          <HStack py="4" bg="white" spacing="8">
            <Box
              as="button"
              onClick={() => navigate('/purchased-question-bank')}
              px="4"
              py="2"
              color="#595959"
              borderBottomColor="#595959"
              borderBottomWidth="1px"
              fontSize={20}
              fontWeight="medium"
            >
              {t('purchased')}
            </Box>
            <Box
              as="button"
              onClick={() => navigate('/question-bank')}
              px="4"
              py="2"
              color="#65CD7D"
              borderBottomColor="#65CD7D"
              borderBottomWidth="1px"
              fontSize={20}
              fontWeight="medium"
            >
              {t('browse')}
            </Box>
          </HStack>
          <Text fontSize={18} fontWeight="medium" as="h2" mt="4">
            {t('filter')}:
          </Text>
          <FilterTab
            width="70%"
            subjectFilter={{ selectedSubject, setSelectedSubject }}
            levelFilter={{ selectedLevel, setSelectedLevel }}
            priceFilter={{ minPrice, setMinPrice, maxPrice, setMaxPrice }}
          />
        </Flex>
        <OverlayScrollbarsComponent
          style={{ width: '100%', height: '100%' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          {showContent()}
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default QuestionBank;
