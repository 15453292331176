import { of } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import * as AuthConstants from './constants';
import * as AuthModels from './models';
import { ErrorResponse } from 'src/models/ErrorResponse';
import { toUrlString } from 'src/utils/queryString';

function loginEpic(action$: ActionsObservable<AuthModels.LoginAction>) {
  return action$.pipe(
    ofType(AuthConstants.LOGIN),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/manualLogin',
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          email: action.payload.email,
          password: action.payload.password,
          role: action.payload.role,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.LoginSuccessAction => {
          return {
            type: AuthConstants.LOGIN_SUCCESS,
            payload: {
              ...payload.response,
              rememberMe: action.payload.rememberMe,
            } as AuthModels.LoginResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.LOGIN_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.LOGIN_ERROR,
            payload: error.response as AuthModels.LoginErrorResponse,
          });
        }),
      );
    }),
  );
}

function refreshTokenEpic(action$: ActionsObservable<AuthModels.RefreshTokenAction>) {
  return action$.pipe(
    ofType(AuthConstants.REFRESH_TOKEN),
    switchMap(() => {
      return ajax({
        url: API_URL + '/auth/refreshToken',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.RefreshTokenSuccessAction => {
          return {
            type: AuthConstants.REFRESH_TOKEN_SUCCESS,
            payload: payload.response as AuthModels.LoginResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.REFRESH_TOKEN_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.REFRESH_TOKEN_ERROR,
            payload: error.response as AuthModels.LoginErrorResponse,
          });
        }),
      );
    }),
  );
}

function forgetPasswordEpic(action$: ActionsObservable<AuthModels.ForgetPasswordAction>) {
  return action$.pipe(
    ofType(AuthConstants.FORGET_PASSWORD),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/forgetPassword',
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          email: action.payload.email,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.ForgetPasswordSuccessAction => {
          return {
            type: AuthConstants.FORGET_PASSWORD_SUCCESS,
            payload: payload.response as AuthModels.ForgetPasswordResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.FORGET_PASSWORD_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.FORGET_PASSWORD_ERROR,
            payload: error.response as AuthModels.ForgetPasswordErrorResponse,
          });
        }),
      );
    }),
  );
}

function registerEpic(action$: ActionsObservable<AuthModels.RegisterAction>) {
  return action$.pipe(
    ofType(AuthConstants.REGISTER),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/register',
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
          password: action.payload.password,
          role: action.payload.role,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.RegisterSuccessAction => {
          return {
            type: AuthConstants.REGISTER_SUCCESS,
            payload: payload.response as AuthModels.RegisterResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.REGISTER_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.REGISTER_ERROR,
            payload: error.response as AuthModels.RegisterErrorResponse,
          });
        }),
      );
    }),
  );
}

function loginWithGoogleEpic(action$: ActionsObservable<AuthModels.LoginWithGoogleAction>) {
  return action$.pipe(
    ofType(AuthConstants.LOGIN_GOOGLE),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/googleLogin',
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          sub: action.payload.sub,
          email: action.payload.email,
          given_name: action.payload.given_name,
          family_name: action.payload.family_name,
          role: action.payload.role,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.LoginWithGoogleSuccessAction => {
          return {
            type: AuthConstants.LOGIN_GOOGLE_SUCCESS,
            payload: payload.response as AuthModels.LoginResponse,
          };
        }),
        catchError((error: AjaxError) =>
          of({
            type: AuthConstants.LOGIN_GOOGLE_ERROR,
            payload: error.response as AuthModels.LoginErrorResponse,
          }),
        ),
      );
    }),
  );
}

function loginWithFacebookEpic(action$: ActionsObservable<AuthModels.LoginWithFacebookAction>) {
  return action$.pipe(
    ofType(AuthConstants.LOGIN_FACEBOOK),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/facebookLogin',
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          id: action.payload.id,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          birthday: action.payload.birthday,
          gender: action.payload.gender,
          role: action.payload.role,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.LoginWithFacebookSuccessAction => {
          return {
            type: AuthConstants.LOGIN_FACEBOOK_SUCCESS,
            payload: payload.response as AuthModels.LoginResponse,
          };
        }),
        catchError((error: AjaxError) =>
          of({
            type: AuthConstants.LOGIN_FACEBOOK_ERROR,
            payload: error.response as AuthModels.LoginErrorResponse,
          }),
        ),
      );
    }),
  );
}

function loginWithAppleEpic(action$: ActionsObservable<AuthModels.LoginWithAppleAction>) {
  return action$.pipe(
    ofType(AuthConstants.LOGIN_APPLE),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/appleLogin',
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: {
          code: action.payload.code,
          role: action.payload.role,
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.LoginWithAppleSuccessAction => {
          return {
            type: AuthConstants.LOGIN_APPLE_SUCCESS,
            payload: payload.response as AuthModels.LoginResponse,
          };
        }),
        catchError((error: AjaxError) =>
          of({
            type: AuthConstants.LOGIN_APPLE_ERROR,
            payload: error.response as AuthModels.LoginErrorResponse,
          }),
        ),
      );
    }),
  );
}

function logoutEpic(action$: ActionsObservable<AuthModels.LogoutAction>) {
  return action$.pipe(
    ofType(AuthConstants.LOGOUT),
    switchMap(() => {
      return ajax({
        url: API_URL + '/auth/logout',
        method: 'post',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.LogoutSuccessAction => {
          return {
            type: AuthConstants.LOGOUT_SUCCESS,
            payload: payload.response as AuthModels.LogoutResponse,
          };
        }),
        catchError((error: AjaxError) =>
          of({
            type: AuthConstants.LOGOUT_ERROR,
            payload: error.response as AuthModels.LogoutResponse,
          }),
        ),
      );
    }),
  );
}

function confirmVerificationCodeEpic(
  action$: ActionsObservable<AuthModels.ConfirmVerificationCodeAction>,
) {
  return action$.pipe(
    ofType(AuthConstants.CONFIRM_VERIFICATION_CODE),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/confirmOTP',
        method: 'post',
        body: {
          email: action.payload.email,
          code: action.payload.code,
        },
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.ConfirmVerificationCodeSuccessAction => {
          return {
            type: AuthConstants.CONFIRM_VERIFICATION_CODE_SUCCESS,
            payload: payload.response as AuthModels.VerificationCodeResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.CONFIRM_VERIFICATION_CODE_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.CONFIRM_VERIFICATION_CODE_ERROR,
            payload: error.response as AuthModels.VerificationCodeErrorResponse,
          });
        }),
      );
    }),
  );
}

function resendVerificationCodeEpic(
  action$: ActionsObservable<AuthModels.ResendVerificationCodeAction>,
) {
  return action$.pipe(
    ofType(AuthConstants.RESEND_VERIFICATION_CODE),
    switchMap((action) => {
      return ajax({
        url: API_URL + '/auth/resendOTP',
        method: 'post',
        body: {
          email: action.payload.email,
        },
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map((payload: AjaxResponse): AuthModels.ResendVerificationCodeSuccessAction => {
          return {
            type: AuthConstants.RESEND_VERIFICATION_CODE_SUCCESS,
            payload: payload.response as AuthModels.ResendVerificationCodeResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.RESEND_VERIFICATION_CODE_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.RESEND_VERIFICATION_CODE_ERROR,
            payload: error.response as AuthModels.ResendVerificationCodeErrorResponse,
          });
        }),
      );
    }),
  );
}

function getUserDetailsEpic(action$: ActionsObservable<AuthModels.GetUserDetailsAction>) {
  return action$.pipe(
    ofType(AuthConstants.GET_USER_DETAILS),
    switchMap(() => {
      return ajax({
        url: API_URL + '/auth/me',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.GetUserDetailsSuccessAction => {
          return {
            type: AuthConstants.GET_USER_DETAILS_SUCCESS,
            payload: payload.response as AuthModels.UserDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.GET_USER_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.GET_USER_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
      );
    }),
  );
}

function updateOnboardingDetailsEpic(
  action$: ActionsObservable<AuthModels.UpdateOnboardingDetailsAction>,
) {
  return action$.pipe(
    ofType(AuthConstants.UPDATE_ONBOARDING_DETAILS),
    switchMap((action) => {
      let body;
      if (action.payload.step === 1) {
        body = {
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          mobile: action.payload.mobile,
          level_of_study: action.payload.level_of_study,
          reference_code: action.payload.reference_code,
          birthday: action.payload.birthday,
        };
      } else if (action.payload.step === 2) {
        body = {
          name: action.payload.name,
          email: action.payload.email,
          mobile: action.payload.parents_mobile,
          residence: action.payload.residence,
        };
      } else if (action.payload.step === 3) {
        body = {
          agreement: action.payload.agreement,
        };
      }
      return ajax({
        url: API_URL + '/student/onboarding/' + action.payload.step.toString(),
        method: 'post',
        withCredentials: true,
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: body,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.UpdateOnboardingDetailsSuccessAction => {
          return {
            type: AuthConstants.UPDATE_ONBOARDING_DETAILS_SUCCESS,
            payload: payload.response as AuthModels.OnboardingDetailsResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.UPDATE_ONBOARDING_DETAILS_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.UPDATE_ONBOARDING_DETAILS_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
      );
    }),
  );
}

function getStudyLevelEpic(action$: ActionsObservable<AuthModels.GetStudyLevelAction>) {
  return action$.pipe(
    ofType(AuthConstants.GET_STUDY_LEVEL),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/study-level?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.GetStudyLevelSuccessAction => {
          return {
            type: AuthConstants.GET_STUDY_LEVEL_SUCCESS,
            payload: {
              ...(payload.response as AuthModels.StudyLevelResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.GET_STUDY_LEVEL_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.GET_STUDY_LEVEL_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
      );
    }),
  );
}

function getMoreStudyLevelEpic(action$: ActionsObservable<AuthModels.GetMoreStudyLevelAction>) {
  return action$.pipe(
    ofType(AuthConstants.GET_MORE_STUDY_LEVEL),
    switchMap((action) => {
      const apiQuery = toUrlString(action.payload);
      return ajax({
        url: API_URL + '/study-level?' + apiQuery,
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.GetMoreStudyLevelSuccessAction => {
          return {
            type: AuthConstants.GET_MORE_STUDY_LEVEL_SUCCESS,
            payload: {
              ...(payload.response as AuthModels.StudyLevelResponse),
              pagination: {
                page: Number(payload.xhr.getResponseHeader('x-page')),
                limit: Number(payload.xhr.getResponseHeader('x-limit')),
                totalPages: Number(payload.xhr.getResponseHeader('x-total-pages')),
                totalRecords: Number(payload.xhr.getResponseHeader('x-total-records')),
              },
            },
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.GET_STUDY_LEVEL_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.GET_STUDY_LEVEL_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
      );
    }),
  );
}

function skipVerificationEpic(action$: ActionsObservable<AuthModels.SkipVerificationAction>) {
  return action$.pipe(
    ofType(AuthConstants.SKIP_VERIFICATION),
    switchMap(() => {
      return ajax({
        url: API_URL + '/auth/skipVerification',
        method: 'post',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): AuthModels.SkipVerificationSuccessAction => {
          return {
            type: AuthConstants.SKIP_VERIFICATION_SUCCESS,
            payload: payload.response as AuthModels.SkipVerificationResponse,
          };
        }),
        catchError((error: AjaxError) => {
          if (error.status === 0) {
            return of({
              type: AuthConstants.SKIP_VERIFICATION_NETWORK_ERROR,
            });
          }
          return of({
            type: AuthConstants.SKIP_VERIFICATION_ERROR,
            payload: error.response as ErrorResponse,
          });
        }),
      );
    }),
  );
}

export const authEpics: Epic = combineEpics(
  ...[
    loginEpic,
    registerEpic,
    forgetPasswordEpic,
    loginWithGoogleEpic,
    loginWithFacebookEpic,
    loginWithAppleEpic,
    logoutEpic,
    refreshTokenEpic,
    confirmVerificationCodeEpic,
    resendVerificationCodeEpic,
    getUserDetailsEpic,
    updateOnboardingDetailsEpic,
    getStudyLevelEpic,
    getMoreStudyLevelEpic,
    skipVerificationEpic,
  ],
);
