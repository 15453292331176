import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  HStack,
  Box,
  Divider,
  Text,
  Input,
  Select,
  IconButton,
  Table,
  Tbody,
  Td,
  Tr,
  Checkbox,
  Stack,
  Flex,
  Button,
  Thead,
  Th,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { selectProfile } from 'src/redux/profile/selectors';
import {
  selectOnboadingDetailsLoading,
  selectOnboadingDetailsMessage,
  selectOnboadingDetailsStatusCode,
  selectStudyLevel,
  selectStudyLevelPagination,
  selectStudyLevelStatusCode,
} from 'src/redux/auth/selectors';
import { getMoreStudyLevel, getStudyLevel, updateOnboardingDetails } from 'src/redux/auth/actions';
import useSessionExpired from 'src/components/SessionExpired';
import { getMyProfile } from 'src/redux/profile/actions';
import { StudyLevelQuery } from 'src/redux/auth/models';
import { formatDate } from 'src/utils/formatDate';

interface StudentState {
  firstname: string | null;
  lastname: string | null;
  mobileNumber: string | null;
  dob: string | null;
  levelOfStudy: string | null;
  referenceCode: string | null;
}

interface ParentsState {
  name: string | null;
  mobileNumber: string | null;
  email: string | null;
  residence: string | null;
}

function Onboarding(): JSX.Element {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  // Clone i18n instance so that onboarding can use english, and background (dashboard) can use another language
  const [translation] = useState(i18n.cloneInstance());

  const { isOpen, onClose, onOpen } = useDisclosure();

  // Profile
  const profile = useSelector(selectProfile);

  // Onboarding
  const updateOnboardingDetailsLoading = useSelector(selectOnboadingDetailsLoading);
  const updateOnboardingDetailsStatusCode = useSelector(selectOnboadingDetailsStatusCode);
  const updateOnboardingDetailsMessage = useSelector(selectOnboadingDetailsMessage);

  // Level of study
  const studyLevel = useSelector(selectStudyLevel);
  const studyLevelStatusCode = useSelector(selectStudyLevelStatusCode);
  const studyLevelPagination = useSelector(selectStudyLevelPagination);

  const [studentDetails, setStudentDetails] = useState<StudentState>({
    firstname: null,
    lastname: null,
    mobileNumber: null,
    dob: null,
    levelOfStudy: null,
    referenceCode: null,
  });
  const [parentsDetails, setParentsDetails] = useState<ParentsState>({
    name: null,
    mobileNumber: null,
    email: null,
    residence: null,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [userAgreementIsChecked, setUserAgreementIsChecked] = useState(false);
  const [parentsNotificationIsChecked, setParentsNotificationIsChecked] = useState(false);

  // Toast ID
  const onboardingMessageToastId = 'onboardingMessageToast';

  const getApiQeury = () => {
    const apiQuery: StudyLevelQuery = {
      page: studyLevelPagination ? studyLevelPagination.page + 1 : 1,
      limit: 100,
    };

    return apiQuery;
  };

  useEffect(() => {
    void translation.changeLanguage('en');
    onOpen();
    dispatch(getStudyLevel(getApiQeury()));
    return () => {
      dispatch(getMyProfile());
    };
  }, [dispatch]);

  useEffect(() => {
    if (profile && studyLevel) {
      setCurrentStep(profile.onboarding_step);
      const studyLevelItem = studyLevel.filter((level) => {
        return level.name === profile.level_of_study;
      });
      setStudentDetails({
        firstname: profile.firstname,
        lastname: profile.lastname,
        mobileNumber: profile.mobile,
        dob: profile.birthday,
        levelOfStudy: studyLevelItem.length > 0 ? studyLevelItem[0].id.toString() : null,
        referenceCode: profile.reference_code,
      });
      setParentsDetails({
        name: profile.parent_data.name,
        mobileNumber: profile.parent_data.mobile,
        email: profile.parent_data.email,
        residence: profile.parent_data.residence,
      });
    }
  }, [profile, studyLevel]);

  useEffect(() => {
    if (updateOnboardingDetailsStatusCode === 200) {
      setCurrentStep(currentStep + 1);
      if (currentStep + 1 > 3) {
        onClose();
      }
    }
  }, [updateOnboardingDetailsStatusCode]);

  useEffect(() => {
    if (updateOnboardingDetailsMessage) {
      if (!toast.isActive(onboardingMessageToastId)) {
        toast({
          id: onboardingMessageToastId,
          title: updateOnboardingDetailsMessage,
          position: 'top',
          status: 'error',
        });
      }
    }
  }, [updateOnboardingDetailsMessage]);

  const saveParentsDetails = (isPreviousStep?: boolean) => {
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const mobileNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const residence = (document.getElementById('address') as HTMLInputElement).value;
    setParentsDetails({
      name,
      mobileNumber,
      email,
      residence,
    });

    if (!isPreviousStep) {
      dispatch(
        updateOnboardingDetails({
          step: currentStep,
          name,
          parents_mobile: mobileNumber,
          email,
          residence,
        }),
      );
    }
  };

  const previousStep = () => {
    if (currentStep === 2) {
      saveParentsDetails(true);
    }
    setCurrentStep(currentStep - 1);
  };

  const submitStep1 = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const firstname = (document.getElementById('firstname') as HTMLInputElement).value;
    const lastname = (document.getElementById('lastname') as HTMLInputElement).value;
    const mobileNumber = (document.getElementById('phoneNumber') as HTMLInputElement).value;
    const dob = (document.getElementById('dob') as HTMLInputElement).value;
    const levelOfStudy = (document.getElementById('levelOfStudy') as HTMLInputElement).value;
    const referenceCode = (document.getElementById('referenceCode') as HTMLInputElement).value;

    setStudentDetails({
      firstname,
      lastname,
      mobileNumber,
      dob,
      levelOfStudy,
      referenceCode,
    });

    dispatch(
      updateOnboardingDetails({
        step: currentStep,
        firstname,
        lastname,
        mobile: mobileNumber,
        birthday: dob,
        level_of_study: levelOfStudy,
        reference_code: referenceCode,
      }),
    );
  };

  const submitStep2 = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    saveParentsDetails();
  };

  const submitStep3 = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateOnboardingDetails({
        step: currentStep,
        agreement: parentsDetails.email === '' ? false : parentsNotificationIsChecked,
      }),
    );
  };

  const getDOB = () => {
    if (studentDetails.dob) {
      if (studentDetails.dob.includes('/')) {
        const day = studentDetails.dob.slice(0, 2);
        const month = studentDetails.dob.slice(3, 5);
        const year = studentDetails.dob.slice(6, 10);

        return `${year}-${month}-${day}`;
      } else {
        return studentDetails.dob;
      }
    }
  };

  const onEnter = (position: number) => {
    if (studyLevel) {
      const hasMore = studyLevelPagination?.page !== studyLevelPagination?.totalPages;
      const isLastItem = position === Number(studyLevel.length - 1);

      const apiQuery = {
        ...getApiQeury(),
        page: studyLevelPagination ? studyLevelPagination.page + 1 : 1,
      };

      if (isLastItem && hasMore) {
        dispatch(getMoreStudyLevel(apiQuery));
      }
    }
  };

  const renderContent = () => {
    // Student details
    if (currentStep === 1) {
      return (
        // Add key to the form so that react knows the student from is different from parents form
        <HStack
          as="form"
          onSubmit={submitStep1}
          mt={5}
          spacing="70px"
          id="studentForm"
          key="studentForm"
        >
          {/* To create empty space */}
          <Box></Box>
          <Box flex="1" fontSize="18px" fontWeight="medium">
            <Table>
              <Thead>
                <Tr>
                  <Th colSpan={2} border="none" textTransform="none">
                    <Text fontSize="18px" fontWeight="semibold" color="black">
                      {translation.t<string>('student_details')}
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* First name */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('firstname')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={studentDetails.firstname ? studentDetails.firstname : undefined}
                      required
                      borderColor="#E2E8F0"
                      borderRadius="full"
                      id="firstname"
                    />
                  </Td>
                </Tr>
                {/* Last name */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('lastname')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={studentDetails.lastname ? studentDetails.lastname : undefined}
                      required
                      borderColor="#E2E8F0"
                      borderRadius="full"
                      id="lastname"
                    />
                  </Td>
                </Tr>
                {/* Mobile Number */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('mobile_number')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={
                        studentDetails.mobileNumber ? studentDetails.mobileNumber : undefined
                      }
                      required
                      maxLength={13}
                      minLength={10}
                      pattern="[0-9]{10,}"
                      borderRadius="full"
                      id="phoneNumber"
                      borderColor="#E2E8F0"
                      onInput={(event) => {
                        const target = event.currentTarget;
                        target.setCustomValidity('');
                        if (target.value.length > target.maxLength) {
                          target.value = target.value.slice(0, target.maxLength);
                        }
                      }}
                      onInvalid={(e) => {
                        const target = e.currentTarget as HTMLInputElement;
                        if (target.value.length === 0) {
                          target.setCustomValidity(translation.t('please_fill_up_this_field'));
                        } else if (target.value.length < target.minLength) {
                          target.setCustomValidity(translation.t('valid_phone_number'));
                        } else {
                          target.setCustomValidity(translation.t('please_enter_a_number'));
                        }
                      }}
                    />
                  </Td>
                </Tr>
                {/* DOB */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('dob')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={studentDetails.dob ? getDOB() : undefined}
                      borderColor="#E2E8F0"
                      type="date"
                      required
                      borderRadius="full"
                      max={formatDate(new Date())}
                      id="dob"
                    />
                  </Td>
                </Tr>
                {/* Level of study */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('current_level_of_study')}:</Text>
                  </Td>
                  <Td border="none">
                    <Select
                      defaultValue={
                        studentDetails.levelOfStudy ? studentDetails.levelOfStudy : undefined
                      }
                      placeholder={translation.t('please_select')}
                      required
                      borderRadius="full"
                      id="levelOfStudy"
                    >
                      {studyLevel?.map((level, position) => (
                        <Waypoint key={level.id} onEnter={() => onEnter(position)}>
                          <option key={level.id} value={level.id}>
                            {level.name}
                          </option>
                        </Waypoint>
                      ))}
                    </Select>
                  </Td>
                </Tr>
                {/* Reference Code */}
                <Tr>
                  <Td border="none">
                    <Text>{translation.t<string>('reference_code')}</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={
                        studentDetails.referenceCode ? studentDetails.referenceCode : undefined
                      }
                      borderColor="#E2E8F0"
                      borderRadius="full"
                      id="referenceCode"
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <IconButton
            isLoading={updateOnboardingDetailsLoading}
            aria-label="next page"
            icon={<ChevronRightIcon boxSize="50px" />}
            bg="white"
            type="submit"
          />
        </HStack>
      );
    } else if (currentStep === 2) {
      // Parents details
      return (
        <HStack
          as="form"
          onSubmit={submitStep2}
          mt={5}
          spacing="70px"
          id="parentsForm"
          key="parentsForm"
        >
          <IconButton
            isLoading={updateOnboardingDetailsLoading}
            aria-label="previous page"
            icon={<ChevronLeftIcon boxSize="50px" />}
            bg="white"
            onClick={previousStep}
          />
          <Box flex="1" fontSize="18px" fontWeight="medium">
            <Table>
              <Thead>
                <Tr>
                  <Th colSpan={2} border="none" textTransform="none">
                    <Text fontSize="18px" fontWeight="semibold" color="black">
                      {translation.t<string>('parents_details')}
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* Name */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('name')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={parentsDetails.name ? parentsDetails.name : undefined}
                      required
                      borderColor="#E2E8F0"
                      borderRadius="full"
                      id="name"
                    />
                  </Td>
                </Tr>
                {/* Mobile Number */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('mobile_number')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={
                        parentsDetails.mobileNumber ? parentsDetails.mobileNumber : undefined
                      }
                      required
                      maxLength={13}
                      minLength={10}
                      pattern="[0-9]{10,}"
                      borderRadius="full"
                      id="phoneNumber"
                      borderColor="#E2E8F0"
                      onInput={(event) => {
                        const target = event.currentTarget;
                        target.setCustomValidity('');
                        if (target.value.length > target.maxLength) {
                          target.value = target.value.slice(0, target.maxLength);
                        }
                      }}
                      onInvalid={(e) => {
                        const target = e.currentTarget as HTMLInputElement;
                        if (target.value.length === 0) {
                          target.setCustomValidity(translation.t('please_fill_up_this_field'));
                        } else if (target.value.length < target.minLength) {
                          target.setCustomValidity(translation.t('valid_phone_number'));
                        } else {
                          target.setCustomValidity(translation.t('please_enter_a_number'));
                        }
                      }}
                    />
                  </Td>
                </Tr>
                {/* Email Address */}
                <Tr>
                  <Td border="none">
                    <Text>{translation.t<string>('email_address')}:</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={parentsDetails.email ? parentsDetails.email : undefined}
                      borderColor="#E2E8F0"
                      type="email"
                      borderRadius="full"
                      id="email"
                    />
                  </Td>
                </Tr>
                {/* Residence */}
                <Tr>
                  <Td border="none">
                    <Text>*{translation.t<string>('residence')}</Text>
                  </Td>
                  <Td border="none">
                    <Input
                      defaultValue={parentsDetails.residence ? parentsDetails.residence : undefined}
                      borderColor="#E2E8F0"
                      id="address"
                      required
                      borderRadius="full"
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <IconButton
            isLoading={updateOnboardingDetailsLoading}
            aria-label="next page"
            icon={<ChevronRightIcon boxSize="50px" />}
            bg="white"
            type="submit"
          />
        </HStack>
      );
    } else if (currentStep === 3) {
      // User agreement
      return (
        <Box as="form" onSubmit={submitStep3} mt={5}>
          <HStack spacing="70px">
            <IconButton
              isLoading={updateOnboardingDetailsLoading}
              aria-label="previous page"
              icon={<ChevronLeftIcon boxSize="50px" />}
              bg="white"
              onClick={previousStep}
            />
            <Box flex="1" fontSize="18px" fontWeight="medium">
              <Text fontWeight="semibold">{translation.t<string>('user_agreement')}</Text>
              <Stack mt={5} spacing={10}>
                <Checkbox
                  disabled={parentsDetails.email === ''}
                  defaultChecked={
                    parentsDetails.email === '' ? false : parentsNotificationIsChecked
                  }
                  onChange={() => setParentsNotificationIsChecked(!parentsNotificationIsChecked)}
                >
                  <Text as="span">{translation.t<string>('parents_notification_agreement')}</Text>
                  <Text as="span" fontWeight="light">
                    {translation.t<string>('for_parents')}
                  </Text>
                </Checkbox>
                <Checkbox
                  defaultChecked={userAgreementIsChecked}
                  onChange={() => setUserAgreementIsChecked(!userAgreementIsChecked)}
                >
                  <Text as="span">{translation.t<string>('user_agreement_details')}&nbsp;</Text>
                  <Text
                    as="span"
                    textDecoration="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      window.openExternal.openTermsOfUse();
                    }}
                  >
                    {translation.t<string>('terms_of_service')}
                  </Text>
                  <Text as="span">&nbsp;{translation.t<string>('and')}&nbsp;</Text>
                  <Text
                    as="span"
                    textDecoration="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      window.openExternal.openPaymentPolicy();
                    }}
                  >
                    {translation.t<string>('privacy_policy')}
                  </Text>
                  <Text as="span">.</Text>
                </Checkbox>
              </Stack>
            </Box>
            {/* To create empty space */}
            <Box></Box>
          </HStack>
          <Flex justifyContent="flex-end">
            <Button
              isLoading={updateOnboardingDetailsLoading}
              mt={10}
              type="submit"
              color="white"
              bg="#1BC35C"
              disabled={!userAgreementIsChecked}
              _hover={{ bg: '#00912F' }}
              _active={{ bg: '#00912f' }}
            >
              {translation.t<string>('finish')}
            </Button>
          </Flex>
        </Box>
      );
    }
  };

  const renderPageNumber = () => {
    if (currentStep === 1) {
      return (
        <HStack>
          <Box w="10px" h="10px" borderRadius="full" background="black" />
          <Box w="10px" h="10px" borderRadius="full" background="#C3C3C3" />
          <Box w="10px" h="10px" borderRadius="full" background="#C3C3C3" />
        </HStack>
      );
    } else if (currentStep === 2) {
      return (
        <HStack>
          <Box w="10px" h="10px" borderRadius="full" background="#1BC35C" />
          <Box w="10px" h="10px" borderRadius="full" background="black" />
          <Box w="10px" h="10px" borderRadius="full" background="#C3C3C3" />
        </HStack>
      );
    } else if (currentStep === 3) {
      return (
        <HStack>
          <Box w="10px" h="10px" borderRadius="full" background="#1BC35C" />
          <Box w="10px" h="10px" borderRadius="full" background="#1BC35C" />
          <Box w="10px" h="10px" borderRadius="full" background="black" />
        </HStack>
      );
    }
  };

  useSessionExpired(studyLevelStatusCode);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
        isCentered
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="26px" color="#65CD7D" fontWeight="semibold">
              {translation.t<string>('welcome_to_tavis_community')}
            </Text>
            <Divider mt={4} orientation="horizontal" borderColor="#595959" />
          </ModalHeader>
          <ModalBody>
            <Text fontSize="20px" fontWeight="medium">
              {translation.t<string>('complete_sign_up_process_instruction')}
            </Text>
            {renderContent()}
          </ModalBody>
          <ModalFooter justifyContent="center">{renderPageNumber()}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Onboarding;
