import React from 'react';
import { Flex, Spacer, Text, Button, Box, VStack, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LeftArrowIcon, RightArrowIcon, TickIcon } from 'src/icons';

interface Step2Props {
  previousStep: () => void;
  nextStep: () => void;
  isFirstAttempt: boolean;
  questionType: string;
  selectedQuestionType: {
    selectedQuestionType: number | undefined;
    setSelectedQuestionType: React.Dispatch<React.SetStateAction<number | undefined>>;
  };
}

function Step2({
  previousStep,
  nextStep,
  isFirstAttempt,
  questionType,
  selectedQuestionType,
}: Step2Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex fontSize={20} fontWeight="semibold" justifyContent="center">
        {t('step_2')}
      </Flex>
      <Flex fontSize={18} fontWeight="medium" justifyContent="center" mt={10}>
        {t('select_questions_instruction')}
      </Flex>
      <VStack flex="1" spacing={10} mt={10}>
        <Flex
          border="1px solid #707070"
          minW="400px"
          p={3}
          borderRadius="10px"
          onClick={() => selectedQuestionType.setSelectedQuestionType(1)}
          cursor="pointer"
        >
          <Text>{t('all_questions')}</Text>
          <Spacer />
          {selectedQuestionType.selectedQuestionType === 1 ? (
            <TickIcon boxSize="20px" />
          ) : (
            <Box w="20px" h="20px" borderRadius="full" background="#C3C3C3" />
          )}
        </Flex>
        <Flex
          border="1px solid #707070"
          minW="400px"
          p={3}
          borderRadius="10px"
          onClick={() => selectedQuestionType.setSelectedQuestionType(2)}
          cursor="pointer"
        >
          <Text>{t('unattempted_questions')}</Text>
          <Spacer />
          {selectedQuestionType.selectedQuestionType === 2 ? (
            <TickIcon boxSize="20px" />
          ) : (
            <Box w="20px" h="20px" borderRadius="full" background="#C3C3C3" />
          )}
        </Flex>
        <Flex
          border="1px solid #707070"
          minW="400px"
          p={3}
          borderRadius="10px"
          onClick={() => {
            if (!isFirstAttempt && questionType === 'Mcq') {
              selectedQuestionType.setSelectedQuestionType(3);
            }
          }}
          cursor="pointer"
          aria-disabled={isFirstAttempt || questionType === 'Essay'}
          _disabled={{ cursor: 'auto', color: 'gray.400', borderColor: 'gray.400' }}
        >
          <Text>{t('previously_incorrect')}</Text>
          <Spacer />
          {selectedQuestionType.selectedQuestionType === 3 ? (
            <TickIcon boxSize="20px" />
          ) : (
            <Box w="20px" h="20px" borderRadius="full" background="#C3C3C3" />
          )}
        </Flex>
      </VStack>
      <HStack justifyContent="center" spacing={10}>
        <Button
          onClick={previousStep}
          textTransform="uppercase"
          leftIcon={<LeftArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('previous_step')}
        </Button>
        <Button
          disabled={selectedQuestionType.selectedQuestionType === undefined}
          onClick={nextStep}
          textTransform="uppercase"
          rightIcon={<RightArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('next_step')}
        </Button>
      </HStack>
    </Flex>
  );
}

export default Step2;
