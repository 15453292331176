import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Select, HStack, Grid, Spinner, Text } from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { CoursesQuery } from 'src/models/Course';
import { getCourses, getMoreCourses, resetCourses } from 'src/redux/course/actions';
import {
  selectCourses,
  selectCoursesLoading,
  selectCoursesNetworkError,
  selectCoursesPagination,
  selectCoursesRefetching,
  selectCoursesStatusCode,
} from 'src/redux/course/selectors';
import DashboardShell from 'src/components/DashboardShell';
import TitleTopbar from 'src/components/Topbar/TitleTopbar';
import Loader from 'src/components/Loader';
import FilterTab from 'src/components/FilterTab';
import CourseCard from './CourseCard';
import RefetchingLoader from './RefetchingLoader';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import usePageTracking from '../PageTracking';

function Courses(): JSX.Element {
  usePageTracking('courses');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [selectedWeekday, setSelectedWeekday] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);
  const [sort, setSort] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | null>(null);

  const courses = useSelector(selectCourses);
  const loading = useSelector(selectCoursesLoading);
  const refetching = useSelector(selectCoursesRefetching);
  const pagination = useSelector(selectCoursesPagination);
  const statusCode = useSelector(selectCoursesStatusCode);
  const networkError = useSelector(selectCoursesNetworkError);

  const getSort = (value: string | null) => {
    if (value === 'fullname') return [value, 'ASC'];
    if (value === 'price_asc') return ['price', 'ASC'];
    if (value === 'price_des') return ['price', 'DESC'];
    return [];
  };

  const getApiQeury = () => {
    const [sortBy, sortOrder] = getSort(sort);

    const apiQuery: CoursesQuery = {
      subject: selectedSubject.join(','),
      form: selectedLevel.join(','),
      language: selectedLanguage.join(','),
      weekdays: selectedWeekday.join(','),
      price_up: maxPrice,
      price_down: minPrice,
      sortby: sortBy,
      sortorder: sortOrder,
      keyword: keyword,
      limit: 10,
    };

    return apiQuery;
  };

  useEffect(() => {
    // Wait 0.5s before make API request
    const debounce = setTimeout(() => dispatch(getCourses(getApiQeury())), 500);
    return () => clearTimeout(debounce);
  }, [
    dispatch,
    selectedSubject,
    selectedLevel,
    selectedLanguage,
    selectedWeekday,
    minPrice,
    maxPrice,
    sort,
    keyword,
  ]);

  // Avoid stale course list to appear after leaving page
  useEffect(() => {
    return () => {
      dispatch(resetCourses());
    };
  }, [dispatch]);

  useSessionExpired(statusCode);
  if (networkError) return <NetworkErrorComponent />;

  if (!courses)
    return (
      <DashboardShell>
        <TitleTopbar title={t('classes')} />
        <Loader />
      </DashboardShell>
    );

  const isListEmpty = Boolean(!courses.length);
  const sortHasValue = Boolean(sort);

  const onEnter = (position: number) => {
    const hasMore = pagination?.page !== pagination?.totalPages;
    const isLastItem = position === Number(courses.length - 1);

    const apiQuery = {
      ...getApiQeury(),
      page: pagination ? pagination.page + 1 : 1,
    };

    if (isLastItem && hasMore) {
      dispatch(getMoreCourses(apiQuery));
    }
  };

  const renderList = () => {
    if (loading)
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );

    if (isListEmpty)
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Text>{t('no_classes_available')}</Text>
        </Flex>
      );

    return (
      <OverlayScrollbarsComponent
        style={{ width: '100%' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Grid
          templateColumns="repeat(3, 1fr)"
          templateRows="repeat(3, 1fr)"
          gap="4"
          py="8"
          pl="8"
          pr="8"
          w="full"
          maxW="fit-content"
          h="100%"
        >
          {courses.map((course, position) => (
            <Waypoint key={course.id} onEnter={() => onEnter(position)}>
              <CourseCard {...course} />
            </Waypoint>
          ))}
          {refetching && <RefetchingLoader />}
        </Grid>
      </OverlayScrollbarsComponent>
    );
  };

  return (
    <DashboardShell>
      <TitleTopbar title={t('classes')} keywordSearch={{ keyword, setKeyword }} />
      <Flex
        m="3vh"
        mt="5"
        direction="column"
        h="100%"
        maxH="85vh"
        boxShadow="0 0 5px gray"
        borderRadius="10px"
      >
        <Flex
          background="black"
          roundedTop="lg"
          h="50px"
          fontSize={20}
          fontWeight="semibold"
          color="white"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          {t('add_class_instruction')}
        </Flex>
        <HStack py="2vh" bg="white" spacing="8" px={5}>
          <Flex
            py="2"
            px="4"
            borderBottomWidth="1px"
            borderBottomColor={sortHasValue ? 'black' : 'gray.400'}
          >
            <Select
              w="full"
              color={sortHasValue ? 'black' : 'gray.400'}
              fontSize="lg"
              placeholder={t('sort_by')}
              variant="unstyled"
              icon={<React.Fragment />}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSort(e.target.value)}
            >
              <option value="fullname">{t('alphabetical')}</option>
              <option value="price_asc">{t('price_asc')}</option>
              <option value="price_des">{t('price_des')}</option>
            </Select>
          </Flex>
        </HStack>

        <Flex flex="1" p={5} pt={0}>
          <Flex flexDirection="column" w="30%" minW="30%">
            <Text fontSize={18} fontWeight="medium" as="h2" mt="4">
              {t('filter')}:
            </Text>
            <FilterTab
              width="70%"
              subjectFilter={{ selectedSubject, setSelectedSubject }}
              levelFilter={{ selectedLevel, setSelectedLevel }}
              priceFilter={{ minPrice, setMinPrice, maxPrice, setMaxPrice }}
              languageFilter={{ selectedLanguage, setSelectedLanguage }}
              weekdayFilter={{ selectedWeekday, setSelectedWeekday }}
            />
          </Flex>
          {renderList()}
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default Courses;
