import React from 'react';
import { Box, VStack, Text, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Props } from 'framer-motion/types/types';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/auth/actions';
import { useTranslation } from 'react-i18next';

interface ItemCardProps {
  children: React.ReactNode;
  icon: React.ElementType<Props>;
  to: string;
}

function ItemCard({ children, icon, to }: ItemCardProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box
      w="300px"
      h="230px"
      m="auto"
      border="1px solid #707070"
      _hover={{ border: '1px solid black' }}
      cursor="pointer"
      onClick={() => {
        if (to === 'about') {
          window.openExternal.openAbout();
        } else if (to === 'logout') {
          const result = window.messageBox.confirmBox(t('logout_prompt'));
          if (result === 0) {
            dispatch(logout());
            navigate('/');
          }
        } else {
          navigate(to);
        }
      }}
    >
      <VStack spacing={8} justify="center" h="100%">
        <Icon
          boxSize="80px"
          color="black"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
        <Text fontWeight="medium">{children}</Text>
      </VStack>
    </Box>
  );
}

export default ItemCard;
