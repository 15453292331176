import { Question } from 'src/models/Question';
import {
  QuestionBank,
  QuestionBankAttemptData,
  QuestionBankChapter,
  QuestionBankDetails,
  QuestionBankPerformance,
} from 'src/models/QuestionBank';

import { QuestionBankState } from './models';
import AppState from '../rootState';
import { Pagination } from 'src/models/Pagination';

const selectQuestionBankState = (state: AppState): QuestionBankState => state.questionBank;

// Get question bank
export const selectQuestionBank = (state: AppState): QuestionBank[] | null =>
  selectQuestionBankState(state).questionBank;

export const selectQuestionBankLoading = (state: AppState): boolean | undefined =>
  selectQuestionBankState(state).questionBankLoading;

export const selectQuestionBankRefetching = (state: AppState): boolean | undefined =>
  selectQuestionBankState(state).questionBankRefetching;

export const selectQuestionBankPagination = (state: AppState): Pagination | undefined =>
  selectQuestionBankState(state).questionBankPagination;

export const selectQuestionBankStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).questionBankStatusCode;

export const selectQuestionBankNetworkError = (state: AppState): boolean =>
  selectQuestionBankState(state).questionBankNetworkError;

// Get purchased question bank
export const selectPurchasedQuestionBank = (state: AppState): QuestionBank[] | null =>
  selectQuestionBankState(state).purchasedQuestionBank;

export const selectPurchasedQuestionBankLoading = (state: AppState): boolean | undefined =>
  selectQuestionBankState(state).purchasedQuestionBankLoading;

export const selectPurchasedQuestionBankRefetching = (state: AppState): boolean | undefined =>
  selectQuestionBankState(state).purchasedQuestionBankRefetching;

export const selectPurchasedQuestionBankPagination = (state: AppState): Pagination | undefined =>
  selectQuestionBankState(state).purchasedQuestionBankPagination;

export const selectPurchasedQuestionBankStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).purchasedQuestionBankStatusCode;

export const selectPurchasedQuestionBankNetworkError = (state: AppState): boolean =>
  selectQuestionBankState(state).purchasedQuestionBankNetworkError;

// Get questions
export const selectQuestion = (state: AppState): Question[] | null =>
  selectQuestionBankState(state).question;

export const selectQuestionNetworkError = (state: AppState): boolean =>
  selectQuestionBankState(state).questionNetworkError;

export const selectQuestionStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).questionStatusCode;

// Get question bank details
export const selectQuestionBankDetails = (state: AppState): QuestionBankDetails | null =>
  selectQuestionBankState(state).questionBankDetails;

export const selectQuestionBankDetailsStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).questionBankDetailsStatusCode;

// Get question bank chapters
export const selectQuestionBankChapters = (state: AppState): QuestionBankChapter[] | null =>
  selectQuestionBankState(state).questionBankChapters;

export const selectQuestionBankChaptersStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).questionBankChaptersStatusCode;

// Get question bank max questions
export const selectQuestionBankMaxQuestions = (state: AppState): number | null =>
  selectQuestionBankState(state).maxQuestions;

export const selectQuestionBankMaxQuestionsStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).maxQuestionsStatusCode;

// Submit new attempt
export const selectSubmitNewAttemptStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).submitNewAttemptStatusCode;

// Delete Attempt
export const selectDeleteAttemptStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).deleteAttemptStatusCode;

// Bookmark
export const selectBookmarkStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).bookmarkStatusCode;
export const selectBookmarkNetworkError = (state: AppState): boolean =>
  selectQuestionBankState(state).bookmarkNetworkError;

// Save answer
export const selectSaveAnswerStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).saveAnswerStatusCode;
export const selectSaveAnswerNetworkError = (state: AppState): boolean =>
  selectQuestionBankState(state).saveAnswerNetworkError;

// Update timer
export const selectUpdateTimerStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).updateTimerStatusCode;

// End question bank
export const selectEndQuestionBankStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).endQuestionBankStatusCode;

// Get attempt details
export const selectAttemptDetailsStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).getAttemptDetailsStatusCode;

export const selectAttemptDetails = (state: AppState): QuestionBankAttemptData | null =>
  selectQuestionBankState(state).attemptData;

// Edit attempt
export const selectEditAttemptStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).editAttemptStatusCode;

// Get question bank performance
export const selectQuestionBankPerformance = (state: AppState): QuestionBankPerformance | null =>
  selectQuestionBankState(state).questionBankPerformance;

export const selectQuestionBankPerformanceStatusCode = (state: AppState): number | null =>
  selectQuestionBankState(state).questionBankPerformanceStatusCode;
