import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Text,
  Divider,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { HideIcon, ShowIcon } from 'src/icons';
import { selectVerifyPasswordLoading } from 'src/redux/parentsNotification/selectors';

interface PasswordPromptProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onClickResetPassword: () => void;
  onClickVerifyPassword: () => void;
}

function PasswordPrompt({
  isOpen,
  onClose,
  onClickResetPassword,
  onClickVerifyPassword,
}: PasswordPromptProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const verifyPasswordLoading = useSelector(selectVerifyPasswordLoading);

  const onClickClose = () => {
    onClose();
    navigate('/settings');
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={20} fontWeight="semibold">
          {t('please_enter_your_password')}
          <Divider borderColor="black" mt={3} />
        </ModalHeader>
        <ModalCloseButton mt={2} mr={3} onClick={onClickClose} />
        <ModalBody pb={6}>
          <HStack spacing={2}>
            <Text fontSize={18} fontWeight="medium">
              {t('password')}:
            </Text>
            <InputGroup>
              <Input
                id="verifyPassword"
                h="28px"
                type={showPassword ? 'text' : 'password'}
                borderColor="#707070"
                borderRadius="full"
                _hover={{ borderColor: '#707070' }}
              />
              <InputRightElement
                mt={-1}
                cursor="pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
              </InputRightElement>
            </InputGroup>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClickResetPassword} fontSize={16} fontWeight="bold" mr={3}>
            {t('reset_password')}
          </Button>
          <Button
            onClick={onClickVerifyPassword}
            fontSize={16}
            fontWeight="bold"
            isLoading={verifyPasswordLoading}
          >
            {t('verify')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PasswordPrompt;
