import React, { forwardRef } from 'react';
import { Flex, HStack, Box, Text, Image, AspectRatio, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { formatPrice } from 'src/utils/formatPrice';
import { QuestionBank } from '../../models/QuestionBank';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';

function QuestionBankCard(
  { id, name, price, discountPrice, imageUrl, questionType }: QuestionBank,
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  const navigate = useNavigate();

  const onClickSubject = () => {
    navigate(`/question-bank/${id}`);
  };

  return (
    <React.Fragment>
      <Flex
        boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
        minW="240px"
        minH="250px"
        h="fit-content"
        onClick={onClickSubject}
        borderRadius="10px"
        flexDirection="column"
        ref={ref}
      >
        <Box position="relative">
          <AspectRatio ratio={16 / 9}>
            <Image
              w="180px"
              h="120px"
              fit="cover"
              src={imageUrl}
              fallbackSrc={TavisPlaceholder}
              rounded="lg"
              boxShadow="md"
            />
          </AspectRatio>
          <Box
            top="10px"
            right="0px"
            p={2}
            position="absolute"
            bg="black"
            color="white"
            fontSize={12}
            fontWeight="medium"
            borderLeftRadius="md"
            zIndex="2"
          >
            {questionType}
          </Box>
        </Box>
        <HStack flex="1" align="center" p={2}>
          <Text fontSize={12}>{name}</Text>
          <Spacer />
          <Box>
            {discountPrice ? (
              <>
                <Box
                  px={3}
                  bg="#65CD7D"
                  color="white"
                  borderRadius="full"
                  fontSize={12}
                  fontWeight="semibold"
                  pos="relative"
                  _before={{
                    pos: 'absolute',
                    content: '""',
                    left: 0,
                    top: '50%',
                    right: 0,
                    borderTop: '2px solid red',
                    transform: 'rotate(-10deg)',
                  }}
                >
                  {formatPrice(String(price))}
                </Box>
                <Box
                  mt={3}
                  px={3}
                  bg="#65CD7D"
                  color="white"
                  borderRadius="full"
                  fontSize={12}
                  fontWeight="semibold"
                >
                  {formatPrice(String(discountPrice))}
                </Box>
              </>
            ) : (
              <Box
                px={3}
                bg="#65CD7D"
                color="white"
                borderRadius="full"
                fontSize={12}
                fontWeight="semibold"
              >
                {formatPrice(String(price))}
              </Box>
            )}
          </Box>
        </HStack>
      </Flex>
    </React.Fragment>
  );
}

export default forwardRef(QuestionBankCard);
