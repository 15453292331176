import { CHANGE_LANGUAGE } from './constants';
import { LanguageActionType, LanguageState } from './models';

const initialState: LanguageState = {
  language: 'en',
};

export const languageReducer = (
  state = initialState,
  action: LanguageActionType,
): LanguageState => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        language: action.language,
      };
    default:
      return state;
  }
};
