import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function AddIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <g id="Add_icon" data-name="Add icon" transform="translate(-3.5 -3.5)">
        <path
          id="Path_1058"
          data-name="Path 1058"
          d="M6.722,4.5H22.278A2.222,2.222,0,0,1,24.5,6.722V22.278A2.222,2.222,0,0,1,22.278,24.5H6.722A2.222,2.222,0,0,1,4.5,22.278V6.722A2.222,2.222,0,0,1,6.722,4.5Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1059"
          data-name="Path 1059"
          d="M18,12v8.889"
          transform="translate(-3.5 -1.944)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1060"
          data-name="Path 1060"
          d="M12,18h8.889"
          transform="translate(-1.944 -3.5)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
