import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Text, Spinner } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import FilterTab from 'src/components/FilterTab';
import ReadingMaterialCard from './ReadingMaterialCard';
import {
  getMoreReadingMaterials,
  getReadingMaterials,
  resetResources,
} from 'src/redux/resources/actions';
import {
  selectReadingMaterials,
  selectReadingMaterialsLoading,
  selectReadingMaterialsNetworkError,
  selectReadingMaterialsPagination,
  selectReadingMaterialsRefetching,
  selectReadingMaterialsStatusCode,
} from 'src/redux/resources/selectors';
import { ReadingMaterialQuery } from 'src/models/resources/ReadingMaterialQuery';
import useSessionExpired from 'src/components/SessionExpired';
import NetworkErrorComponent from 'src/components/NetworkError';
import usePageTracking from '../PageTracking';

function ReadingMaterial(): JSX.Element {
  usePageTracking('reading-materials');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedSubject, setSelectedSubject] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);

  const readingMaterials = useSelector(selectReadingMaterials);
  const readingMaterialStatusCode = useSelector(selectReadingMaterialsStatusCode);
  const readingMaterialNetworkError = useSelector(selectReadingMaterialsNetworkError);
  const loading = useSelector(selectReadingMaterialsLoading);
  const pagination = useSelector(selectReadingMaterialsPagination);
  const refetching = useSelector(selectReadingMaterialsRefetching);

  const getApiQuery = () => {
    const apiQuery: ReadingMaterialQuery = {
      subject: selectedSubject.join(','),
      form: selectedLevel.join(','),
      materialLevel: selectedWeek.join(','),
      keyword: keyword,
    };

    return apiQuery;
  };

  useEffect(() => {
    return () => {
      dispatch(resetResources());
    };
  }, []);

  useEffect(() => {
    // Wait 0.5s before make API request
    const debounce = setTimeout(() => dispatch(getReadingMaterials(getApiQuery())), 500);
    return () => clearTimeout(debounce);
  }, [dispatch, selectedSubject, selectedLevel, selectedWeek, keyword]);

  const showContent = () => {
    if (loading || !readingMaterials) {
      return (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    } else if (readingMaterials.length === 0) {
      return (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          {t('no_reading_materials')}
        </Flex>
      );
    } else {
      return (
        <Grid
          templateColumns="repeat(3, 1fr)"
          templateRows="repeat(3, 1fr)"
          gap="4"
          py="8"
          pl="8"
          pr="8"
          h="100%"
          w="full"
          minW="80%"
          mb="8"
        >
          {readingMaterials.map((item, position) => (
            <Waypoint key={item.id} onEnter={() => onEnter(position)}>
              <ReadingMaterialCard
                key={item.id}
                materialUrl={item.materialUrl}
                materialName={item.materialName}
              />
            </Waypoint>
          ))}
          {refetching && (
            <Flex
              flexDirection="column"
              mx="auto"
              rounded="lg"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          )}
        </Grid>
      );
    }
  };

  const onEnter = (position: number) => {
    if (readingMaterials) {
      const hasMore = pagination?.page !== pagination?.totalPages;
      const isLastItem = position === Number(readingMaterials.length - 1);

      const apiQuery = {
        ...getApiQuery(),
        page: pagination ? pagination.page + 1 : 1,
      };

      if (isLastItem && hasMore) {
        dispatch(getMoreReadingMaterials(apiQuery));
      }
    }
  };

  useSessionExpired(readingMaterialStatusCode);
  if (readingMaterialNetworkError) return <NetworkErrorComponent />;

  return (
    <DashboardShell>
      <BackTopbar
        title={t('reading_materials')}
        pathname="/resources"
        keywordSearch={{ keyword, setKeyword }}
      />
      <Flex m="3vh" mt="0" direction="column" h="100%" maxH="85vh">
        <Text fontSize={18} fontWeight="medium" as="h2" mt="4">
          {t('filter')}:
        </Text>
        <Flex h="100%">
          <FilterTab
            subjectFilter={{ selectedSubject, setSelectedSubject }}
            levelFilter={{ selectedLevel, setSelectedLevel }}
            weekFilter={{ selectedWeek, setSelectedWeek }}
          />
          <OverlayScrollbarsComponent
            style={{ width: '100%', height: '100%' }}
            options={{ scrollbars: { autoHide: 'scroll' } }}
          >
            {showContent()}
          </OverlayScrollbarsComponent>
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default ReadingMaterial;
