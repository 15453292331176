import React, { useState, useEffect, useRef, useCallback } from 'react';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Loader from 'src/components/Loader';
import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Question from 'src/components/Question/Question';
import EndHomework from './EndHomework';
import {
  selectBookmarkNetworkError,
  selectBookmarkStatusCode,
  selectHomeworkQuestions,
  selectHomeworkQuestionsNetworkError,
  selectHomeworkQuestionsStatusCode,
  selectSaveAnswerStatusCode,
  selectSaveAnswerNetworkError,
} from 'src/redux/homework/selectors';
import {
  bookmarkQuestion,
  getHomeworkQuestions,
  resetBookmark,
  resetSaveAnswer,
  saveAnswer,
  updateTimer,
} from 'src/redux/homework/actions';
import useBookmark from 'src/components/QuestionAndHomework/Bookmark';
import useSaveAnswer from 'src/components/QuestionAndHomework/SaveAnswer';
import { Question as QuestionType } from 'src/models/Question';
import usePageTracking from '../PageTracking';
import QuestionList from 'src/components/QuestionAndHomework/QuestionList';

function HomeworkQuestion(): JSX.Element {
  usePageTracking('homework-questions');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ homeworkId: string; questionId: string }>();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState<string[] | null>(null);
  const [marks, setMarks] = useState<number | null>(null);
  const [bookmark, setBookmark] = useState<boolean | null>(null);
  const [tempQuestions, setTempQuestions] = useState<QuestionType[] | null>(null);

  const timer = useRef<NodeJS.Timer>();
  const startTimer = useRef(false);
  const unmountComponent = useRef(false);
  const timerSeconds = useRef(0);
  const timerMinutes = useRef(0);
  const timerHours = useRef(0);
  const [timerText, setTimerText] = useState('00:00:00');

  const questions = useSelector(selectHomeworkQuestions);
  const questionsStatusCode = useSelector(selectHomeworkQuestionsStatusCode);
  const questionsNetworkError = useSelector(selectHomeworkQuestionsNetworkError);

  const bookmarkStatusCode = useSelector(selectBookmarkStatusCode);
  const bookmarkNetworkError = useSelector(selectBookmarkNetworkError);

  const saveAnswerStatusCode = useSelector(selectSaveAnswerStatusCode);
  const saveAnswerNetworkError = useSelector(selectSaveAnswerNetworkError);

  useEffect(() => {
    dispatch(getHomeworkQuestions(Number(params.homeworkId)));
    startTimer.current = true;

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        unmountComponent.current = true;
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (startTimer.current) {
      if (questions) {
        const timer = questions[0].totalTime;
        const hours = parseInt(timer.slice(0, 2));
        const minutes = parseInt(timer.slice(3, 5));
        const seconds = parseInt(timer.slice(6, 8));

        timerSeconds.current = seconds;
        timerMinutes.current = minutes;
        timerHours.current = hours;

        setTimerText(
          `${timerHours.current.toString().padStart(2, '0')}:${timerMinutes.current
            .toString()
            .padStart(2, '0')}:${timerSeconds.current.toString().padStart(2, '0')}`,
        );
      }

      startTimer.current = false;
      timer.current = setInterval(() => {
        timerSeconds.current++;
        if (timerSeconds.current > 59) {
          timerSeconds.current = 0;
          timerMinutes.current++;
        }
        if (timerMinutes.current > 59) {
          timerMinutes.current = 0;
          timerHours.current++;
        }

        setTimerText(
          `${timerHours.current.toString().padStart(2, '0')}:${timerMinutes.current
            .toString()
            .padStart(2, '0')}:${timerSeconds.current.toString().padStart(2, '0')}`,
        );
      }, 1000);
    }

    if (questions && tempQuestions === null) {
      setTempQuestions(JSON.parse(JSON.stringify(questions)) as []);
    }
  }, [questions]);

  useEffect(() => {
    return () => {
      if (questions && unmountComponent.current) {
        dispatch(
          updateTimer({
            totalTime: timerText,
            attemptId: questions[currentQuestion].attemptId,
            homeworkId: Number(params.homeworkId),
          }),
        );
      }
    };
  }, [timerText]);

  useEffect(() => {
    if (questions) {
      const temp = questions.filter((item) => item.id.toString() === params.questionId);
      const pos = questions.indexOf(temp[0]);
      setCurrentQuestion(pos);
      setAnswer(questions[pos].answer);
      setMarks(questions[pos].mark);
      setBookmark(questions[pos].bookmark);
      dispatch(resetBookmark());
      dispatch(resetSaveAnswer());
    }
  }, [params.questionId]);

  useEffect(() => {
    if (
      questions &&
      (answer !== questions[currentQuestion].answer || marks !== questions[currentQuestion].mark) &&
      answer !== null
    ) {
      dispatch(
        saveAnswer({
          questionId: questions[currentQuestion].id,
          answer: answer,
          totalTime: timerText,
          mark: calculateMarks(),
          attemptId: questions[currentQuestion].attemptId,
        }),
      );
    }
  }, [answer, marks]);

  useEffect(() => {
    if (questions && bookmark !== questions[currentQuestion].bookmark && bookmark !== null) {
      dispatch(
        bookmarkQuestion({
          questionId: questions[currentQuestion].id,
          bookmark: bookmark,
        }),
      );
    }
  }, [bookmark]);

  window.onbeforeunload = () => {
    if (questions) {
      dispatch(
        updateTimer({
          totalTime: timerText,
          attemptId: questions[currentQuestion].attemptId,
          homeworkId: Number(params.homeworkId),
        }),
      );
    }
  };

  const onClickPreviousQuestion = useCallback(() => {
    if (questions) {
      navigate(`/homework/${params.homeworkId!}/${questions[currentQuestion - 1].id}`, {
        replace: true,
      });
    }
  }, [questions, currentQuestion]);

  const onClickNextQuestion = useCallback(() => {
    if (questions) {
      navigate(`/homework/${params.homeworkId!}/${questions[currentQuestion + 1].id}`, {
        replace: true,
      });
    }
  }, [questions, currentQuestion]);

  useSessionExpired(questionsStatusCode);
  useSessionExpired(bookmarkStatusCode);
  useSessionExpired(saveAnswerStatusCode);
  useBookmark(bookmarkNetworkError);
  useSaveAnswer(saveAnswerNetworkError);
  if (questionsNetworkError) return <NetworkErrorComponent />;
  if (!questions || !tempQuestions) {
    return (
      <DashboardShell>
        <BackTopbar title="" pathname={`/homework-records/${params.homeworkId!}`} />
        <Loader />
      </DashboardShell>
    );
  }

  const calculateMarks = () => {
    if (questions[currentQuestion].quesType === 'Essay' && answer) {
      return marks;
    } else if (answer) {
      for (let i = 0; i < answer.length; i++) {
        if (questions[currentQuestion].correctAnsId.includes(parseInt(answer[i]))) {
          return questions[currentQuestion].defaultmark;
        }
      }
    }
    return 0;
  };

  const calculateProgress = () => {
    const answered = questions.filter((item) => item.answer !== null);
    const progress = (answered.length / questions.length) * 100;
    return progress;
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={questions[0].categoryName}
        pathname={`/homework-records/${params.homeworkId!}`}
      />
      <HStack flex="1" overflow="hidden" spacing={5} px={10} py={5}>
        <Question
          key={questions[currentQuestion].id}
          progress={calculateProgress()}
          question={questions[currentQuestion]}
          tempQuestion={tempQuestions[currentQuestion]}
          currentQuestion={currentQuestion}
          totalQuestions={questions.length}
          onClickPreviousQuestion={onClickPreviousQuestion}
          onClickNextQuestion={onClickNextQuestion}
          setFinalAnswer={setAnswer}
          bookmark={bookmark}
          setBookmark={setBookmark}
          marks={marks}
          setMarks={setMarks}
        />
        <VStack h="100%" w="30%" spacing={5}>
          <QuestionList type="homework" questions={questions} homeworkId={params.homeworkId} />
          <VStack
            bg="white"
            h="20%"
            w="100%"
            borderRadius="10px"
            justifyContent="center"
            p={5}
            boxShadow="0 0 5px gray"
          >
            <Text fontSize={20}>{timerText}</Text>
            <EndHomework timer={timerText} homeworkName={questions[0].categoryName} />
          </VStack>
        </VStack>
      </HStack>
    </DashboardShell>
  );
}

export default HomeworkQuestion;
