import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Text,
  HStack,
  Flex,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LockIcon } from 'src/icons';
import { selectConfirmOTPLoading } from 'src/redux/parentsNotification/selectors';
import { getButtonStatus } from 'src/utils/getButtonStatus';

interface VerificationCodePromptProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  showResendCodeButton: boolean;
  disableResendCodeButton: boolean;
  timer: string;
  onClickResendVerificationCode: () => void;
  onClickConfirmVerificationCode: (code: string) => void;
}

function VerificationCodePrompt({
  isOpen,
  onClose,
  showResendCodeButton,
  disableResendCodeButton,
  timer,
  onClickConfirmVerificationCode,
  onClickResendVerificationCode,
}: VerificationCodePromptProps): JSX.Element {
  const { t } = useTranslation();
  const loading = useSelector(selectConfirmOTPLoading);

  const [verificationCode, setVerificationCode] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const onCompleteVerification = (value: string) => {
    setVerificationCode(value);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={18} fontWeight="medium">
          <Flex flexDirection="column" alignItems="center">
            <LockIcon boxSize="60px" color="#E00000" />
            <Text fontSize={30} fontWeight="bold" mt={5}>
              {t('reset_password')}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton mt={2} mr={3} />
        <ModalBody pb={6}>
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="medium" mb={4}>
              {t('verification_instruction')}
            </Text>
            <HStack>
              <PinInput
                type="number"
                placeholder=""
                autoFocus={true}
                onChange={(value) => setDisableButton(getButtonStatus(value))}
                onComplete={onCompleteVerification}
              >
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
              </PinInput>
            </HStack>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          {showResendCodeButton ? (
            <HStack>
              <Button
                w="200px"
                minW="fit-content"
                fontSize={24}
                fontWeight="semibold"
                color="white"
                bg="#7ABFD3"
                _hover={{ bg: '#488FA2' }}
                _active={{ bg: '#488FA2' }}
                isDisabled={disableResendCodeButton}
                onClick={onClickResendVerificationCode}
              >
                {t('resend_code')}
                {disableResendCodeButton && `(${timer})`}
              </Button>
              <Button
                w="200px"
                minW="fit-content"
                fontSize={24}
                fontWeight="semibold"
                color="white"
                bg="#7ABFD3"
                _hover={{ bg: '#488FA2' }}
                _active={{ bg: '#488FA2' }}
                isDisabled={disableButton}
                isLoading={loading}
                onClick={() => {
                  onClickConfirmVerificationCode(verificationCode);
                }}
              >
                {t('confirm')}
              </Button>
            </HStack>
          ) : (
            <Button
              w="200px"
              minW="fit-content"
              fontSize={24}
              fontWeight="semibold"
              color="white"
              bg="#7ABFD3"
              _hover={{ bg: '#488FA2' }}
              _active={{ bg: '#488FA2' }}
              isDisabled={disableButton}
              isLoading={loading}
              onClick={() => {
                onClickConfirmVerificationCode(verificationCode);
              }}
            >
              {t('confirm')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default VerificationCodePrompt;
