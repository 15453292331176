import React, { forwardRef } from 'react';
import { Flex, Image, Text, AspectRatio, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';

interface RecordedLectureCardProps {
  filename: string;
  thumbnail: string;
  video: string;
}

function RecordedLectureCard(
  { filename, thumbnail, video }: RecordedLectureCardProps,
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  const navigate = useNavigate();

  const onClickRecordedLectures = () => {
    navigate('/view-recorded-lectures', {
      state: {
        video: video,
        thumbnail: thumbnail,
        filename: filename,
      },
    });
  };

  return (
    <React.Fragment>
      <Flex
        boxShadow="0 0 5px rgba(0, 0, 0, 0.16)"
        minW="240px"
        minH="210px"
        h="fit-content"
        onClick={onClickRecordedLectures}
        borderRadius="10px"
        flexDirection="column"
        ref={ref}
      >
        <AspectRatio ratio={16 / 9}>
          <Image fit="cover" src={thumbnail} fallbackSrc={TavisPlaceholder} roundedTop="lg" />
        </AspectRatio>
        <HStack flex="1" align="center" p={2}>
          <Text fontSize={12}>{filename}</Text>
        </HStack>
      </Flex>
    </React.Fragment>
  );
}

export default forwardRef(RecordedLectureCard);
