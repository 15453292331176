import React from 'react';
import { Tr, Td, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

import { CorrectAnswerIcon, WrongAnswerIcon } from 'src/icons';
import { selectQuestion } from 'src/redux/questionBank/selectors';

interface TableBodyProps {
  index: number;
  question: string;
  answer?: boolean;
  score: number | null;
  attemptName: string;
  questionBankName: string;
  questionType: string;
}
function TableBody({
  index,
  question,
  answer,
  score,
  attemptName,
  questionBankName,
  questionType,
}: TableBodyProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ subjectId: string; attemptId: string }>();

  const text = parse(question);

  const questions = useSelector(selectQuestion);

  const onClickView = () => {
    if (questions) {
      navigate(
        `/question-bank-review/${params.subjectId!}/${params.attemptId!}/${questions[index].id}`,
        {
          state: { attemptName, questionBankName, questionType },
        },
      );
    }
  };

  return (
    <Tr fontSize={18}>
      <Td px={0} pl={3} borderColor="#D1D1D1">
        <Flex>
          <Text mr={2}>{`${index + 1})`}</Text>
          {text}
        </Flex>
      </Td>
      {answer != null && (
        <Td px={0} borderColor="#D1D1D1" textAlign="center">
          {answer ? <CorrectAnswerIcon boxSize="30px" /> : <WrongAnswerIcon boxSize="30px" />}
        </Td>
      )}
      <Td px={0} borderColor="#D1D1D1" textAlign="center">
        {score}
      </Td>
      <Td px={0} borderColor="#D1D1D1" textAlign="center">
        <Button
          variant="outline"
          pos="unset"
          fontWeight="medium"
          bg="none"
          color="#003BFF"
          borderColor="#003BFF"
          onClick={onClickView}
        >
          {t('view')}
        </Button>
      </Td>
    </Tr>
  );
}

export default TableBody;
