import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function DeleteIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 15 18" {...props}>
      <path
        id="Delete_icon"
        data-name="Delete icon"
        d="M5.653,7.553V18.8a1.143,1.143,0,0,0,1.154,1.125H17.191A1.143,1.143,0,0,0,18.345,18.8V7.553H5.653ZM9.114,17.678H7.96V9.8H9.114Zm2.308,0H10.268V9.8h1.154Zm2.308,0H12.576V9.8H13.73Zm2.308,0H14.883V9.8h1.154Zm2.6-13.5h-3.75V2.772a.857.857,0,0,0-.865-.844H9.98a.857.857,0,0,0-.865.844V4.178H5.364a.857.857,0,0,0-.865.844V6.428h15V5.022a.857.857,0,0,0-.865-.844Zm-4.9,0H10.268V3.067H13.73V4.178Z"
        transform="translate(-4.499 -1.928)"
        fill="red"
      />
    </Icon>
  );
}
