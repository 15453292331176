import React from 'react';
import { Flex, Spacer, Text, Button, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ChapterCard from './ChapterCard';
import { RightArrowIcon } from 'src/icons';
import { QuestionBankChapter } from 'src/models/QuestionBank';

interface Step1Props {
  nextStep: () => void;
  chapters: QuestionBankChapter[];
  selectedChapters: {
    selectedChapters: number[];
    setSelectedChapters: React.Dispatch<React.SetStateAction<number[]>>;
  };
}

function Step1({ nextStep, chapters, selectedChapters }: Step1Props): JSX.Element {
  const { t } = useTranslation();

  const onClickSelectAll = () => {
    const selectAll: number[] = [];
    chapters.forEach((chapter) => {
      selectAll.push(chapter.id);
    });
    selectedChapters.setSelectedChapters(selectAll);
  };

  const onClickUnselectAll = () => {
    selectedChapters.setSelectedChapters([]);
  };

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex fontSize={20} fontWeight="semibold" justifyContent="center">
        {t('step_1')}
      </Flex>
      <Flex fontSize={20} fontWeight="semibold" justifyContent="center" mt={10}>
        <Text fontSize={18} fontWeight="medium">
          {t('choose_chapters_instruction')}
        </Text>
        <Spacer />
        {selectedChapters.selectedChapters.length < chapters.length ? (
          <Button onClick={onClickSelectAll}>{t('select_all')}</Button>
        ) : (
          <Button onClick={onClickUnselectAll}>{t('unselect_all')}</Button>
        )}
      </Flex>
      <Flex flex="1" py={5}>
        <Grid templateColumns="repeat(3, 1fr)" gap={6} w="100%" h="100%">
          {chapters.map((chapter) => (
            <ChapterCard key={chapter.id} {...chapter} selectedChapters={selectedChapters} />
          ))}
        </Grid>
      </Flex>
      <Flex justifyContent="center">
        <Button
          disabled={selectedChapters.selectedChapters.length === 0}
          onClick={nextStep}
          textTransform="uppercase"
          rightIcon={<RightArrowIcon boxSize="10px" />}
          w="240px"
        >
          {t('next_step')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default Step1;
