import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getCart, resetAddToCart } from 'src/redux/cart/actions';
import { getMyProfile } from 'src/redux/profile/actions';
import {
  selectProfileStatusCode,
  selectProfileNetworkError,
  selectProfile,
} from 'src/redux/profile/selectors';
import Sidebar from './Sidebar/Sidebar';
import SettingsSidebar from './Settings/Sidebar';
import useSessionExpired from './SessionExpired';
import { selectCartNetworkError, selectCartStatusCode } from 'src/redux/cart/selectors';
import { resetOrder } from 'src/redux/order/actions';

interface DashboardShellProps {
  children: React.ReactNode;
  settingsScreen?: boolean;
}

function DashboardShell({ children, settingsScreen }: DashboardShellProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profileNetworkError = useSelector(selectProfileNetworkError);
  const profileStatusCode = useSelector(selectProfileStatusCode);
  const cartNetworkError = useSelector(selectCartNetworkError);
  const cartStatusCode = useSelector(selectCartStatusCode);
  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (!profile) {
      dispatch(getMyProfile());
    }

    dispatch(getCart());
    dispatch(resetAddToCart());
    dispatch(resetOrder());
  }, [dispatch]);

  useSessionExpired(cartStatusCode);
  useSessionExpired(profileStatusCode);
  if (profileNetworkError || cartNetworkError) {
    return (
      <Flex bg="#07080F" maxH="100vh" overflow="hidden">
        {settingsScreen ? <SettingsSidebar /> : <Sidebar />}
        <Flex
          overflow="hidden"
          h="96vh"
          w="85%"
          direction="column"
          borderRadius="10px"
          bg="white"
          my="4"
        >
          <VStack h="100vh" justifyContent="center">
            <Text>{t('internet_connect_error')}</Text>
          </VStack>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex bg="#07080F" maxH="100vh" overflow="hidden">
      {settingsScreen ? <SettingsSidebar /> : <Sidebar />}
      <Flex
        overflow="hidden"
        h="96vh"
        w="85%"
        direction="column"
        borderRadius="10px"
        bg="white"
        my="4"
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default DashboardShell;
