import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType, ActionsObservable, Epic } from 'redux-observable';

import { API_URL } from 'src/constants/api';
import { GET_SUBJECT_LIST, GET_SUBJECT_LIST_SUCCESS } from './constants';
import { GetSubjectListAction, GetSubjectListSuccessAction, SubjectListResponse } from './models';
import { LOGOUT } from '../auth/constants';

function getSubjectsEpic(action$: ActionsObservable<GetSubjectListAction>) {
  return action$.pipe(
    ofType(GET_SUBJECT_LIST),
    switchMap(() => {
      return ajax({
        url: API_URL + '/subject',
        method: 'get',
        withCredentials: true,
      }).pipe(
        map((payload: AjaxResponse): GetSubjectListSuccessAction => {
          return {
            type: GET_SUBJECT_LIST_SUCCESS,
            payload: payload.response as SubjectListResponse,
          };
        }),
        takeUntil(action$.pipe(ofType(LOGOUT))),
      );
    }),
  );
}

export const subjectEpics: Epic = combineEpics(...[getSubjectsEpic]);
