import React, { useEffect } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface MathTypeProps extends BoxProps {
  children: React.ReactNode;
}

function MathType({ children, ...props }: MathTypeProps): JSX.Element {
  const node = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (window.MathJax.Hub) {
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
    }
  });

  return (
    <Box ref={node} {...props}>
      {children}
    </Box>
  );
}

export default MathType;
