import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

function Loader(): JSX.Element {
  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  );
}

export default Loader;
