import { Homework } from 'src/models/homework/Homework';

import { HomeworkState } from './models';
import AppState from '../rootState';
import { HomeworkReview } from 'src/models/homework/HomeworkReview';
import { Question } from 'src/models/Question';
import { Summary } from 'src/models/homework/Summary';
import { AttemptData } from 'src/models/homework/AttemptData';

const selectHomeworkState = (state: AppState): HomeworkState => state.homework;

// Homework List
export const selectHomeworkList = (state: AppState): Homework[] | null =>
  selectHomeworkState(state).homeworkList;
export const selectHomeworkListStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).homeworkListStatusCode;
export const selectHomeworkListNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).homeworkListNetworkError;
export const selectHomeworkListLoading = (state: AppState): boolean | undefined =>
  selectHomeworkState(state).homeworkListLoading;

// Homework Records
export const selectHomeworkRecords = (state: AppState): HomeworkReview[] | null =>
  selectHomeworkState(state).homeworkRecords;
export const selectHomeworkRecordsStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).homeworkRecordsStatusCode;
export const selectHomeworkRecordsNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).homeworkRecordsNetworkError;
export const selectHomeworkRecordsLoading = (state: AppState): boolean | undefined =>
  selectHomeworkState(state).homeworkRecordsLoading;

// Homework Records
export const selectHomeworkRecordQuestions = (state: AppState): Question[] | null =>
  selectHomeworkState(state).homeworkRecordQuestions;
export const selectHomeworkRecordAttemptData = (state: AppState): AttemptData | null =>
  selectHomeworkState(state).homeworkRecordAttemptData;
export const selectHomeworkRecordStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).homeworkRecordStatusCode;
export const selectHomeworkRecordNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).homeworkRecordNetworkError;
export const selectHomeworkRecordLoading = (state: AppState): boolean | undefined =>
  selectHomeworkState(state).homeworkRecordLoading;

// Questions
export const selectHomeworkQuestions = (state: AppState): Question[] | null =>
  selectHomeworkState(state).questions;
export const selectHomeworkQuestionsStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).questionsStatusCode;
export const selectHomeworkQuestionsNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).questionsNetworkError;
export const selectHomeworkQuestionsLoading = (state: AppState): boolean | undefined =>
  selectHomeworkState(state).questionsLoading;

// Bookmark
export const selectBookmarkStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).bookmarkStatusCode;
export const selectBookmarkNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).bookmarkNetworkError;

// Save answer
export const selectSaveAnswerStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).saveAnswerStatusCode;
export const selectSaveAnswerNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).saveAnswerNetworkError;

// Homework summary
export const selectHomeworkSummary = (state: AppState): Summary | null =>
  selectHomeworkState(state).homeworkSummary;
export const selectHomeworkSummaryStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).homeworkSummaryStatusCode;
export const selectHomeworkSummaryNetworkError = (state: AppState): boolean =>
  selectHomeworkState(state).homeworkSummaryNetworkError;

// Update timer
export const selectUpdateTimerStatusCode = (state: AppState): number | null =>
  selectHomeworkState(state).updateTimerStatusCode;
