import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Button,
  Text,
  Stack,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  Img,
  Heading,
  Box,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TavisLogo from 'src/images/auth/TavisLogo.png';
import Image from 'src/images/auth/Image.png';
import { BackIcon } from 'src/icons';
import {
  selectConfirmVerificationCodeLoading,
  selectConfirmVerificationCodeMessage,
  selectConfirmVerificationCodeNetworkError,
  selectConfirmVerificationCodeStatusCode,
  selectResendVerificationCodeMessage,
  selectResendVerificationCodeNetworkError,
  selectResendVerificationCodeStatusCode,
  selectSkipVerificationMessage,
  selectSkipVerificationStatusCode,
  selectverificationSkipsLeft,
} from 'src/redux/auth/selectors';
import {
  clearStatus,
  clearVerificationStatus,
  confirmVerificationCode,
  resendVerificationCode,
  skipVerification,
} from 'src/redux/auth/actions';
import usePageTracking from '../PageTracking';
import { selectLanguage } from 'src/redux/language/selectors';
import { getButtonStatus } from 'src/utils/getButtonStatus';

interface LocationState {
  email: string;
}

function EmailVerification(): JSX.Element {
  usePageTracking('email-verification');
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const navigate = useNavigate();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const confirmVerificationCodeLoading = useSelector(selectConfirmVerificationCodeLoading);
  const confirmVerificationCodeMessage = useSelector(selectConfirmVerificationCodeMessage);
  const confirmVerificationCodeStatusCode = useSelector(selectConfirmVerificationCodeStatusCode);
  const confirmVerificationCodeNetworkError = useSelector(
    selectConfirmVerificationCodeNetworkError,
  );

  const resendVerificationCodeMessage = useSelector(selectResendVerificationCodeMessage);
  const resendVerificationCodeStatusCode = useSelector(selectResendVerificationCodeStatusCode);
  const resendVerificationCodeNetworkError = useSelector(selectResendVerificationCodeNetworkError);

  const verificationSkipsLeft = useSelector(selectverificationSkipsLeft);
  const skipVerificationMessage = useSelector(selectSkipVerificationMessage);
  const skipVerificationStatusCode = useSelector(selectSkipVerificationStatusCode);

  const [verificationCode, setVerificationCode] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [showResendCodeMessage, setShowResendCodeMessage] = useState(false);
  const [showResendCodeButton, setShowResendCodeButton] = useState(false);
  const [timerText, setTimerText] = useState('');

  const timer = useRef<NodeJS.Timer>();
  const timerCounter = useRef(60);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setShowResendCodeButton(true);
      setShowResendCodeMessage(true);
    }, 2000);

    return () => {
      clearTimeout(debounce);
      if (timer.current != undefined) {
        clearInterval(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (confirmVerificationCodeStatusCode === 200) {
      void i18n.changeLanguage(language);
      dispatch(clearVerificationStatus());
      navigate('/dashboard');
    } else if (confirmVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: confirmVerificationCodeMessage,
      });
    } else if (confirmVerificationCodeNetworkError) {
      toast({
        position: 'top',
        status: 'error',
        title: t('verify_code_error'),
      });
    }
  }, [
    confirmVerificationCodeMessage,
    confirmVerificationCodeStatusCode,
    confirmVerificationCodeNetworkError,
  ]);

  useEffect(() => {
    if (
      resendVerificationCodeMessage &&
      resendVerificationCodeStatusCode === 200 &&
      resendVerificationCodeMessage
    ) {
      toast({
        position: 'top',
        status: 'info',
        title: resendVerificationCodeMessage,
      });
    } else if (resendVerificationCodeMessage) {
      toast({
        position: 'top',
        status: 'error',
        title: resendVerificationCodeMessage,
      });
    } else if (resendVerificationCodeNetworkError) {
      toast({
        position: 'top',
        status: 'error',
        title: t('send_code_error'),
      });
    }
  }, [
    resendVerificationCodeMessage,
    resendVerificationCodeStatusCode,
    resendVerificationCodeNetworkError,
  ]);

  useEffect(() => {
    if (skipVerificationStatusCode === 200 && skipVerificationMessage) {
      toast({
        title: skipVerificationMessage,
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 1500,
      });
      dispatch(clearVerificationStatus());
      void i18n.changeLanguage(language);
      navigate('/dashboard');
    } else if (skipVerificationMessage) {
      toast({
        position: 'top',
        status: 'error',
        isClosable: true,
        duration: 1500,
        title: skipVerificationMessage,
      });
      dispatch(clearVerificationStatus());
    }
  }, [skipVerificationMessage, skipVerificationStatusCode]);

  const showResend = () => {
    if (showResendCodeButton && showResendCodeMessage) {
      return (
        <Text fontWeight="medium" mb={28}>
          {t('did_not_receive')}{' '}
          <Box
            as="span"
            color="#0062FF"
            onClick={onClickResendCode}
            cursor="pointer"
            _hover={{ textDecoration: 'underline' }}
            _active={{ textDecoration: 'underline' }}
          >
            {t('resend')}
          </Box>
        </Text>
      );
    } else if (showResendCodeMessage) {
      return (
        <Text fontWeight="medium" mb={28}>
          {t('did_not_receive')}
          {t('resend_in_seconds', { seconds: timerText })}
        </Text>
      );
    }
    return <></>;
  };

  const onCompleteVerification = (value: string) => {
    setVerificationCode(value);
  };

  const onSubmitVerificationCode = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      confirmVerificationCode({
        email: state.email,
        code: verificationCode,
      }),
    );
  };

  const onClickResendCode = () => {
    setShowResendCodeButton(false);
    dispatch(
      resendVerificationCode({
        email: state.email,
      }),
    );

    timer.current = setInterval(() => {
      timerCounter.current--;

      if (timerCounter.current <= 0) {
        setShowResendCodeButton(true);
        if (timer.current != undefined) {
          clearInterval(timer.current);
        }
        timer.current = undefined;
        timerCounter.current = 60;
      }
      setTimerText(timerCounter.current.toString());
    }, 1000);
  };

  const onClickSkip = () => {
    dispatch(skipVerification());
  };

  return (
    <Flex w="100vw" h="100vh">
      <Stack
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        backgroundColor="white"
        overflow="hidden"
      >
        <Img src={TavisLogo} />
        <Img src={Image} />
      </Stack>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{ width: '60%', maxWidth: '60%', backgroundColor: '#65CD7D' }}
      >
        <Flex h="100%" w="100%">
          <VStack
            as="form"
            onSubmit={onSubmitVerificationCode}
            color="white"
            spacing={20}
            m="auto"
            minW="500px"
            maxW="700px"
            shouldWrapChildren
          >
            <Flex>
              <IconButton
                mr={5}
                aria-label="Back"
                icon={<BackIcon boxSize="24px" />}
                bg="none"
                _hover={{ bg: 'none' }}
                _active={{ bg: 'none' }}
                onClick={() => {
                  dispatch(clearStatus());
                  navigate('/');
                }}
              />
              <Heading as="h1" fontSize={34} fontWeight="bold">
                {t('verification_code')}
              </Heading>
            </Flex>
            <Text fontSize={26} textAlign="center">
              {t('email_verification_instruction', { email: state.email })}
            </Text>
            <HStack mb={-10}>
              <PinInput
                type="number"
                placeholder=""
                autoFocus={true}
                onChange={(value) => setDisableButton(getButtonStatus(value))}
                onComplete={onCompleteVerification}
              >
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
                <PinInputField
                  backgroundColor="white"
                  color="black"
                  w="60px"
                  h="60px"
                  fontSize={30}
                  fontWeight="bold"
                />
              </PinInput>
            </HStack>
            {showResend()}
            <HStack spacing={5} mb={5}>
              <Button
                disabled={verificationSkipsLeft === null || verificationSkipsLeft === undefined}
                w="150px"
                h="50px"
                bg="#595959"
                fontWeight="medium"
                _hover={{ bg: '#303030' }}
                _active={{ bg: '#303030' }}
                onClick={onClickSkip}
              >
                {t('skip')} ({verificationSkipsLeft ? verificationSkipsLeft : 0})
              </Button>
              <Button
                isLoading={confirmVerificationCodeLoading}
                isDisabled={disableButton}
                w="150px"
                h="50px"
                bg="#19AED9"
                fontWeight="medium"
                _hover={{ bg: '#007FA7' }}
                _active={{ bg: '#007FA7' }}
                type="submit"
              >
                {t('confirm')}
              </Button>
            </HStack>
          </VStack>
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default EmailVerification;
