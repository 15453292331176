import { ReadingMaterialQuery } from 'src/models/resources/ReadingMaterialQuery';
import { RecordedLectureQuery } from 'src/models/resources/RecordedLectureQuery';
import { AppActions } from '../rootActions';
import {
  GET_LEVELS,
  GET_READING_MATERIALS,
  GET_RECORDED_LECTURES,
  GET_MORE_READING_MATERIALS,
  GET_MORE_RECORDED_LECTURES,
  RESET_RESOURCES,
} from './constants';

/**
 * Functions to be called in React components
 */

export const getLevels = (courseId?: string): AppActions => ({
  type: GET_LEVELS,
  courseId: courseId,
});

export const getReadingMaterials = (payload: ReadingMaterialQuery): AppActions => ({
  type: GET_READING_MATERIALS,
  payload: payload,
});

export const getRecordedLectures = (payload: RecordedLectureQuery): AppActions => ({
  type: GET_RECORDED_LECTURES,
  payload: payload,
});

export const getMoreReadingMaterials = (payload: ReadingMaterialQuery): AppActions => ({
  type: GET_MORE_READING_MATERIALS,
  payload: payload,
});

export const getMoreRecordedLectures = (payload: RecordedLectureQuery): AppActions => ({
  type: GET_MORE_RECORDED_LECTURES,
  payload: payload,
});

export const resetResources = (): AppActions => ({
  type: RESET_RESOURCES,
});
