import { Pagination } from 'src/models/Pagination';
import { ReadingMaterial } from 'src/models/resources/ReadingMaterial';
import { RecordedLecture } from 'src/models/resources/RecordedLecture';
import AppState from '../rootState';
import { ResourcesState } from './models';

const selectResources = (state: AppState): ResourcesState => state.resources;

// Get levels
export const selectLevels = (state: AppState): string[] | null => selectResources(state).levels;

// Get reading materials
export const selectReadingMaterials = (state: AppState): ReadingMaterial[] | null =>
  selectResources(state).readingMaterials;

export const selectReadingMaterialsLoading = (state: AppState): boolean =>
  selectResources(state).readingMaterialsLoading;

export const selectReadingMaterialsStatusCode = (state: AppState): number | null =>
  selectResources(state).readingMaterialsStatusCode;

export const selectReadingMaterialsNetworkError = (state: AppState): boolean =>
  selectResources(state).readingMaterialsNetworkError;

export const selectReadingMaterialsPagination = (state: AppState): Pagination | undefined =>
  selectResources(state).readingMaterialsPagination;

export const selectReadingMaterialsRefetching = (state: AppState): boolean =>
  selectResources(state).readingMaterialsRefetching;

// Get recorded lectures
export const selectRecordedLectures = (state: AppState): RecordedLecture[] | null =>
  selectResources(state).recordedLectures;

export const selectRecordedLecturesLoading = (state: AppState): boolean =>
  selectResources(state).recordedLecturesLoading;

export const selectRecordedLecturesStatusCode = (state: AppState): number | null =>
  selectResources(state).recordedLecturesStatusCode;

export const selectRecordedLecturesNetworkError = (state: AppState): boolean =>
  selectResources(state).recordedLecturesNetworkError;

export const selectRecordedLecturesPagination = (state: AppState): Pagination | undefined =>
  selectResources(state).recordedLecturesPagination;

export const selectRecordedLecturesRefetching = (state: AppState): boolean =>
  selectResources(state).recordedLecturesRefetching;
