import { Flex, Text, Grid } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttemptData } from 'src/models/homework/AttemptData';
import { QuestionBankAttemptData } from 'src/models/QuestionBank';

function AttemptDetails(attemptData: AttemptData | QuestionBankAttemptData): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex
      bg="white"
      h="50%"
      w="100%"
      borderRadius="10px"
      flexDirection="column"
      p={5}
      boxShadow="0 0 5px gray"
    >
      <Text mb={5} fontSize={24}>
        {t('attempt_details')}
      </Text>
      <OverlayScrollbarsComponent
        style={{ flex: '1' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <Grid templateColumns="auto auto" gap={7} fontSize={16}>
          <Text fontWeight="medium">{t('score')}:</Text>
          <Text>{`${attemptData.mark}/${attemptData.totalMark} (${Math.floor(
            (attemptData.mark / attemptData.totalMark) * 100,
          )}%)`}</Text>
          <Text fontWeight="medium">{t('start_date')}:</Text>
          <Text>{new Date(attemptData.startDate).toLocaleDateString()}</Text>
          <Text fontWeight="medium">{t('end_date')}:</Text>
          <Text>{new Date(attemptData.endDate).toLocaleDateString()}</Text>
          <Text fontWeight="medium">{t('total_time')}:</Text>
          <Text>{attemptData.totalTime}</Text>
        </Grid>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default AttemptDetails;
