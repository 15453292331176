import { CombinedState, combineReducers } from 'redux';
import { courseReducer, courseInitialState } from './course/reducers';
import { eventReducer, eventInitialState } from './event/reducers';
import { levelReducer, levelInitialState } from './level/reducers';
import { authReducer, authInitialState } from './auth/reducers';
import { questionBankReducer, questionBankInitialState } from './questionBank/reducers';
import { subjectReducer, subjectInitialState } from './subject/reducers';
import { cartReducer, cartInitialState } from './cart/reducer';
import { profileReducer, profileInitialState } from './profile/reducers';
import { filterReducer, filterInitialState } from './filter/reducers';
import AppState from './rootState';
import { AppActions } from './rootActions';
import { LOGOUT } from './auth/constants';
import { homeworkReducer, homeworkInitialState } from './homework/reducers';
import {
  parentsNotificationReducer,
  parentsNotificationInitialState,
} from './parentsNotification/reducers';
import { orderReducer, orderInitialState } from './order/reducer';
import { resourcesReducer, resourcesInitialState } from './resources/reducers';
import { languageReducer } from './language/reducers';
import { purchaseReducer, purchaseInitialState } from './purchase/reducer';
import { contactUsInitialState, contactUsReducer } from './contactUs/reducers';

const allReducers = combineReducers({
  course: courseReducer,
  questionBank: questionBankReducer,
  subject: subjectReducer,
  level: levelReducer,
  event: eventReducer,
  auth: authReducer,
  cart: cartReducer,
  profile: profileReducer,
  filter: filterReducer,
  homework: homeworkReducer,
  parentsNotification: parentsNotificationReducer,
  order: orderReducer,
  resources: resourcesReducer,
  language: languageReducer,
  purchase: purchaseReducer,
  contactUs: contactUsReducer,
});

const rootReducer = (state: AppState | undefined, action: AppActions): CombinedState<AppState> => {
  if (action.type === LOGOUT) {
    if (state !== undefined) {
      state = {
        language: state.language,
        course: courseInitialState,
        questionBank: questionBankInitialState,
        subject: subjectInitialState,
        level: levelInitialState,
        event: eventInitialState,
        auth: authInitialState,
        cart: cartInitialState,
        profile: profileInitialState,
        filter: filterInitialState,
        homework: homeworkInitialState,
        parentsNotification: parentsNotificationInitialState,
        order: orderInitialState,
        resources: resourcesInitialState,
        purchase: purchaseInitialState,
        contactUs: contactUsInitialState,
      };
    }
  }
  return allReducers(state, action);
};

export default rootReducer;
