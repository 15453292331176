import React from 'react';
import { Heading, IconButton, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import RightTopbar from './RightTopbar';
import { BackIcon } from 'src/icons';

interface BackTopbarProps {
  title: string;
  pathname?: string;
  state?: unknown;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
}

function BackTopbar({ title, pathname, state, keywordSearch }: BackTopbarProps): JSX.Element {
  const navigate = useNavigate();

  const onClickBack = () => {
    if (pathname) {
      navigate(pathname, { state: state });
    } else {
      navigate(-1);
    }
  };

  return (
    <RightTopbar keywordSearch={keywordSearch}>
      <HStack spacing={5}>
        <IconButton aria-label="Back" icon={<BackIcon />} bg="white" onClick={onClickBack} />
        <Heading fontSize={26} fontWeight="semibold">
          {title}
        </Heading>
      </HStack>
    </RightTopbar>
  );
}

export default React.memo(BackTopbar);
