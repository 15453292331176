import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Heading,
  Input,
  Button,
  Text,
  HStack,
  Stack,
  Box,
  IconButton,
  Img,
  VStack,
  InputGroup,
  InputRightElement,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import TavisLogo from 'src/images/auth/TavisLogo.png';
import Image from 'src/images/auth/Image.png';
import { BackIcon, HideIcon, InfoIcon, ShowIcon } from 'src/icons';
import { register, clearStatus, login } from 'src/redux/auth/actions';
import {
  selectRegisterStatus,
  selectRegisterErrorMessage,
  selectRegisterLoading,
  selectLoginStatus,
} from 'src/redux/auth/selectors';
import usePageTracking from '../PageTracking';

function Register(): JSX.Element {
  usePageTracking('register');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const registerStatus = useSelector(selectRegisterStatus);
  const errorMessageFromRedux = useSelector(selectRegisterErrorMessage);
  const registerLoading = useSelector(selectRegisterLoading);
  const loginStatus = useSelector(selectLoginStatus);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onClickRegister = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const emailInput = document.getElementById('email') as HTMLInputElement;
    const firstNameInput = document.getElementById('firstname') as HTMLInputElement;
    const lastNameInput = document.getElementById('lastname') as HTMLInputElement;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    const confirmPasswordInput = document.getElementById('confirmPassword') as HTMLInputElement;

    const email = emailInput.value;
    const firstname = firstNameInput.value;
    const lastname = lastNameInput.value;
    const password = passwordInput.value;
    const confirmPassword = confirmPasswordInput.value;

    const isPasswordValid = password === confirmPassword;

    if (!isPasswordValid) {
      setErrorMessage(t('same_password'));
    } else {
      setErrorMessage(null);
      dispatch(
        register({
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          role: 'student',
        }),
      );
    }
  };

  useEffect(() => {
    if (loginStatus) {
      const emailInput = document.getElementById('email') as HTMLInputElement;
      const email = emailInput.value;
      navigate('/email-verification', {
        state: { email: email },
      });
    }
  }, [loginStatus]);

  useEffect(() => {
    if (registerStatus && errorMessageFromRedux) {
      const emailInput = document.getElementById('email') as HTMLInputElement;
      const passwordInput = document.getElementById('password') as HTMLInputElement;
      const email = emailInput.value;
      const password = passwordInput.value;

      toast({
        position: 'top',
        title: parse(errorMessageFromRedux),
        status: 'success',
      });

      dispatch(clearStatus());
      dispatch(
        login({
          email,
          password,
          role: 'student',
          rememberMe: true,
        }),
      );
    }
  }, [registerStatus, dispatch, navigate, errorMessageFromRedux]);

  const displayErrorMessage = () => {
    if (errorMessage) {
      return (
        <Text color="red" mb={10}>
          {errorMessage}
        </Text>
      );
    } else if (errorMessageFromRedux) {
      return (
        <Text color="red" mb={10}>
          {parse(errorMessageFromRedux)}
        </Text>
      );
    }
  };

  return (
    <Flex w="100vw" h="100vh">
      <Stack
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        backgroundColor="white"
        overflow="hidden"
      >
        <Img src={TavisLogo} />
        <Img src={Image} />
      </Stack>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{ width: '60%', maxWidth: '60%', backgroundColor: '#65CD7D' }}
      >
        <Flex h="100%" w="100%">
          <VStack color="white" spacing={8} m="auto">
            <HStack minW="500px" maxW="700px">
              <IconButton
                aria-label="Back"
                icon={<BackIcon boxSize="24px" />}
                bg="none"
                _hover={{ bg: 'none' }}
                _active={{ bg: 'none' }}
                onClick={() => navigate('/')}
              />
              <Heading as="h1" fontSize={34} fontWeight="bold">
                {t('register_with_us')}
              </Heading>
            </HStack>
            <Box as="form" onSubmit={onClickRegister} minW="500px" maxW="700px">
              <HStack spacing={5} fontSize={18} fontWeight="bold" mb={10}>
                <Box w="50%">
                  <Text mb={2} ml={3}>
                    {t('firstname')}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </Text>
                  <Input required bg="white" color="black" borderRadius="full" id="firstname" />
                </Box>
                <Box w="50%">
                  <Text mb={2} ml={3}>
                    {t('lastname')}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </Text>
                  <Input required bg="white" color="black" borderRadius="full" id="lastname" />
                </Box>
              </HStack>
              <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
                {t('email')}
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <Input
                required
                type="email"
                mb={10}
                bg="white"
                w="100%"
                color="black"
                borderRadius="full"
                id="email"
              />
              <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
                {t('password')}
                <Box as="span" color="red">
                  *
                </Box>
                <Tooltip label={`*${t('password_validation')}`} fontSize={12} placement="right">
                  <Box as="span">
                    <InfoIcon color="#595959" boxSize="34" />
                  </Box>
                </Tooltip>
              </Text>
              <InputGroup mb={10}>
                <Input
                  required
                  type={showPassword ? 'text' : 'password'}
                  bg="white"
                  color="black"
                  borderRadius="full"
                  id="password"
                  pattern="(?=.*[0-9])(?=.*[a-z]).{8,}"
                  title={t('password_validation')}
                />
                <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
                </InputRightElement>
              </InputGroup>
              <Text fontSize={18} fontWeight="bold" ml={3} mb={2}>
                {t('confirm_password')}
                <Box as="span" color="red">
                  *
                </Box>
              </Text>
              <InputGroup mb={10}>
                <Input
                  required
                  type={showConfirmPassword ? 'text' : 'password'}
                  bg="white"
                  color="black"
                  borderRadius="full"
                  id="confirmPassword"
                />
                <InputRightElement
                  cursor="pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <ShowIcon /> : <HideIcon color="black" />}
                </InputRightElement>
              </InputGroup>
              {displayErrorMessage()}
              <Flex justifyContent="center">
                <Button
                  isLoading={registerLoading}
                  p={5}
                  w="50%"
                  bg="none"
                  fontSize={26}
                  fontWeight="bold"
                  borderRadius="full"
                  border="4px solid white"
                  type="submit"
                  _active={{ backgroundColor: '#2F9B50' }}
                  _hover={{ backgroundColor: '#2F9B50' }}
                >
                  {t('continue')}
                </Button>
              </Flex>
            </Box>
          </VStack>
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default Register;
