import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Flex,
  Spacer,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Img,
  HStack,
  Divider,
  Box,
} from '@chakra-ui/react';

import { selectCartItemCount } from 'src/redux/cart/selectors';
import { SearchIcon, CartIcon } from 'src/icons';
import { selectProfile } from 'src/redux/profile/selectors';
import User from 'src/images/User.svg';
import VerifyEmailWarning from 'src/containers/Dashboard/VerifyEmailWarning';

interface RightTopbarProps {
  children: React.ReactNode;
  showVerifyEmailWarning?: boolean;
  keywordSearch?: {
    keyword: string | null;
    setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  };
}

function RightTopbar({
  children,
  keywordSearch,
  showVerifyEmailWarning,
}: RightTopbarProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const profile = useSelector(selectProfile);
  const cartItemCount = useSelector(selectCartItemCount);

  if (!profile) return <></>;

  const onClickCart = () => {
    navigate('/subscription');
  };

  const submitKeyword = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const element = document.getElementById('search') as HTMLInputElement;
    if (keywordSearch && element.value.length > 0) {
      keywordSearch.setKeyword(element.value);
    }
  };

  const onChangeSearch = () => {
    const element = document.getElementById('search') as HTMLInputElement;
    if (element.value.length === 0 && keywordSearch && keywordSearch.keyword) {
      keywordSearch.setKeyword(element.value);
    }
  };

  const displayIcon = () => {
    if (cartItemCount !== null && cartItemCount !== 0) {
      return (
        <>
          <CartIcon boxSize="35px" />
          <Box
            as="span"
            pos="absolute"
            top="-1px"
            right="-1px"
            px={2}
            py={1}
            fontSize="xs"
            fontWeight="bold"
            lineHeight="none"
            color="red.100"
            transform="translate(50%,-50%)"
            bg="red.600"
            rounded="full"
          >
            {cartItemCount}
          </Box>
        </>
      );
    } else {
      return <CartIcon boxSize="35px" />;
    }
  };

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="full"
      pt="4vh"
      pb="1vh"
      px="8"
      flexDirection="column"
    >
      <Flex w="100%">
        <Flex>{children}</Flex>
        <Spacer />
        <HStack spacing={5}>
          {showVerifyEmailWarning && <VerifyEmailWarning />}
          {keywordSearch && (
            <InputGroup w={60} as="form" onSubmit={submitKeyword}>
              <Input
                id="search"
                type="search"
                placeholder={t('search')}
                onChange={onChangeSearch}
              />
              <InputRightElement as="button" type="submit" cursor="pointer">
                <SearchIcon color="black" />
              </InputRightElement>
            </InputGroup>
          )}
          <IconButton
            aria-label="cart"
            bg="white"
            _hover={{ bg: 'none' }}
            _active={{ bg: 'none' }}
            onClick={onClickCart}
            icon={displayIcon()}
          />
          <Link to="/profile">
            <Img
              w="50px"
              h="50px"
              borderRadius="full"
              src={profile.profile_url || User}
              objectFit="cover"
            />
          </Link>
        </HStack>
      </Flex>
      <Divider mt={4} borderColor="#707070" />
    </Flex>
  );
}

export default RightTopbar;
