import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function PowerpointIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1679" data-name="Rectangle 1679" width="20" height="26" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_379" data-name="Group 379" clipPath="url(#clip-path)">
        <path
          id="Path_1184"
          data-name="Path 1184"
          d="M41.238,4.467V25.527a.9.9,0,0,1-.9.906H23.691a.9.9,0,0,1-.9-.906V1.4a.9.9,0,0,1,.9-.906H37.283Z"
          transform="translate(-21.271 -0.466)"
          fill="#fff"
        />
        <path
          id="Path_1185"
          data-name="Path 1185"
          d="M41.238,4.467V25.527a.9.9,0,0,1-.9.906H23.691a.9.9,0,0,1-.9-.906V1.4a.9.9,0,0,1,.9-.906H37.283Z"
          transform="translate(-21.271 -0.466)"
          fill="none"
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_1186"
          data-name="Path 1186"
          d="M62.83,178.515H49.441a.532.532,0,0,1-.531-.533v-7.329a5.569,5.569,0,0,1,5.559-5.578H62.83a.532.532,0,0,1,.531.533v12.375a.532.532,0,0,1-.531.533"
          transform="translate(-45.653 -154.042)"
          fill="#f9cdaf"
        />
        <path
          id="Path_1187"
          data-name="Path 1187"
          d="M226.313,4.467V25.527a.9.9,0,0,1-.9.906h-1.289a.9.9,0,0,0,.9-.906V4.467L221.069.5h1.289Z"
          transform="translate(-206.346 -0.466)"
          fill="#e2e1de"
        />
        <path
          id="Path_1188"
          data-name="Path 1188"
          d="M244.376,4.467h-3.053a.9.9,0,0,1-.9-.906V.5Z"
          transform="translate(-224.41 -0.466)"
          fill="#c9c9c9"
        />
        <path
          id="Path_1189"
          data-name="Path 1189"
          d="M9.065,63.275H0V59.593a.5.5,0,0,1,.5-.5H9.065a.5.5,0,0,1,.5.5v3.179a.5.5,0,0,1-.5.5"
          transform="translate(-0.002 -55.141)"
          fill="#e88032"
        />
        <path
          id="Path_1190"
          data-name="Path 1190"
          d="M9.564,62.71v.063a.5.5,0,0,1-.5.5H0V59.594a.5.5,0,0,1,.5-.5H.759V60.4a2.306,2.306,0,0,0,2.3,2.31Z"
          transform="translate(0 -55.142)"
          fill="#ce6b29"
        />
        <path
          id="Path_1191"
          data-name="Path 1191"
          d="M0,121.706l1.518,1.737v-1.737Z"
          transform="translate(-0.002 -113.573)"
          fill="#b75f23"
        />
        <path
          id="Path_1192"
          data-name="Path 1192"
          d="M29.651,122.8H22.79v-1.092h7.529v.421a.67.67,0,0,1-.669.671"
          transform="translate(-21.272 -113.572)"
          fill="#e2e1de"
        />
        <path
          id="Path_1193"
          data-name="Path 1193"
          d="M84.2,196.489a5.255,5.255,0,1,1-5.255-5.273v5.273Z"
          transform="translate(-68.786 -178.437)"
          fill="#e88032"
        />
        <path
          id="Path_1194"
          data-name="Path 1194"
          d="M167.807,185.88h-5.255v-5.273a5.264,5.264,0,0,1,5.255,5.273"
          transform="translate(-151.726 -168.537)"
          fill="#e88032"
        />
        <path
          id="Path_1195"
          data-name="Path 1195"
          d="M14.666,70.706v.468H13.212v-.468h.351V68.873h-.351V68.4h1.454a1.165,1.165,0,0,1,.784.239.806.806,0,0,1,.277.643.9.9,0,0,1-.1.419.748.748,0,0,1-.249.289.932.932,0,0,1-.347.137,2.7,2.7,0,0,1-.489.037h-.264v.537Zm-.385-1h.107a.652.652,0,0,0,.475-.12.437.437,0,0,0,.106-.3.443.443,0,0,0-.073-.258.3.3,0,0,0-.169-.131,1.37,1.37,0,0,0-.311-.025h-.135Z"
          transform="translate(-12.332 -63.833)"
          fill="#fff"
        />
        <path
          id="Path_1196"
          data-name="Path 1196"
          d="M55.344,70.706v.468H53.89v-.468h.351V68.873H53.89V68.4h1.454a1.165,1.165,0,0,1,.784.239.806.806,0,0,1,.277.643.9.9,0,0,1-.1.419.748.748,0,0,1-.249.289.932.932,0,0,1-.347.137,2.7,2.7,0,0,1-.489.037h-.264v.537Zm-.385-1h.107a.652.652,0,0,0,.475-.12.437.437,0,0,0,.106-.3.443.443,0,0,0-.073-.258.3.3,0,0,0-.169-.131,1.37,1.37,0,0,0-.311-.025h-.135Z"
          transform="translate(-50.301 -63.833)"
          fill="#fff"
        />
        <path
          id="Path_1197"
          data-name="Path 1197"
          d="M95.1,71.174v-.468h.415V68.873H95.1v.645h-.474V68.406h2.5v1.112h-.468v-.645h-.426v1.833h.415v.468Z"
          transform="translate(-88.326 -63.834)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}
