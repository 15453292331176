export const GET_QUESTION_BANK = 'questionBank/getQuestionBank';
export const GET_QUESTION_BANK_SUCCESS = 'questionBank/getQuestionBankSuccess';
export const GET_QUESTION_BANK_ERROR = 'questionBank/getQuestionBankError';
export const GET_QUESTION_BANK_NETWORK_ERROR = 'questionBank/getQuestionBankNetworkError';

export const GET_MORE_QUESTION_BANK = 'questionBank/getMoreQuestionBank';
export const GET_MORE_QUESTION_BANK_SUCCESS = 'questionBank/getMoreQuestionBankSuccess';

export const GET_PURCHASED_QUESTION_BANK = 'questionBank/getPurchasedQuestionBank';
export const GET_PURCHASED_QUESTION_BANK_SUCCESS = 'questionBank/getPurchasedQuestionBankSuccess';
export const GET_PURCHASED_QUESTION_BANK_ERROR = 'questionBank/getPurchasedQuestionBankError';
export const GET_PURCHASED_QUESTION_BANK_NETWORK_ERROR =
  'questionBank/getPurchasedQuestionBankNetworkError';

export const GET_MORE_PURCHASED_QUESTION_BANK = 'questionBank/getMorePurchasedQuestionBank';
export const GET_MORE_PURCHASED_QUESTION_BANK_SUCCESS =
  'questionBank/getMorePurchasedQuestionBankSuccess';

export const GET_QUESTION_BANK_DETAILS = 'questionBank/getQuestionBankDetails';
export const GET_QUESTION_BANK_DETAILS_SUCCESS = 'questionBank/getQuestionBankDetailsSuccess';
export const GET_QUESTION_BANK_DETAILS_ERROR = 'questionBank/getQuestionBankDetailsError';
export const GET_QUESTION_BANK_DETAILS_NETWORK_ERROR =
  'questionBank/getQuestionBankDetailsNetworkError';

export const GET_QUESTION_BANK_CHAPTERS = 'questionBank/getQuestionBankChapters';
export const GET_QUESTION_BANK_CHAPTERS_SUCCESS = 'questionBank/getQuestionBankChaptersSuccess';
export const GET_QUESTION_BANK_CHAPTERS_ERROR = 'questionBank/getQuestionBankChaptersError';
export const GET_QUESTION_BANK_CHAPTERS_NETWORK_ERROR =
  'questionBank/getQuestionBankChaptersNetworkError';

export const GET_QUESTION_BANK_MAX_QUESTIONS = 'questionBank/getQuestionBankMaxQuestions';
export const GET_QUESTION_BANK_MAX_QUESTIONS_SUCCESS =
  'questionBank/getQuestionBankMaxQuestionsSuccess';
export const GET_QUESTION_BANK_MAX_QUESTIONS_ERROR =
  'questionBank/getQuestionBankMaxQuestionsError';
export const GET_QUESTION_BANK_MAX_QUESTIONS_NETWORK_ERROR =
  'questionBank/getQuestionBankMaxQuestionsNetworkError';

export const SUBMIT_NEW_ATTEMPT = 'questionBank/submitNewAttempt';
export const SUBMIT_NEW_ATTEMPT_SUCCESS = 'questionBank/submitNewAttemptSuccess';
export const SUBMIT_NEW_ATTEMPT_ERROR = 'questionBank/submitNewAttemptError';
export const SUBMIT_NEW_ATTEMPT_NETWORK_ERROR = 'questionBank/submitNewAttemptNetworkError';

export const DELETE_ATTEMPT = 'questionBank/deleteAttempt';
export const DELETE_ATTEMPT_SUCCESS = 'questionBank/deleteAttemptSuccess';
export const DELETE_ATTEMPT_ERROR = 'questionBank/deleteAttemptError';
export const DELETE_ATTEMPT_NETWORK_ERROR = 'questionBank/deleteAttemptNetworkError';

export const GET_QUESTION = 'questionBank/getQuestion';
export const GET_QUESTION_SUCCESS = 'questionBank/getQuestionSuccess';
export const GET_QUESTION_ERROR = 'questionBank/getQuestionError';
export const GET_QUESTION_NETWORK_ERROR = 'questionBank/getQuestionNetworkError';

export const SAVE_ANSWER = 'questionBank/saveAnswer';
export const SAVE_ANSWER_SUCCESS = 'questionBank/saveAnswerSuccess';
export const SAVE_ANSWER_ERROR = 'questionBank/saveAnswerError';
export const SAVE_ANSWER_NETWORK_ERROR = 'questionBank/saveAnswerNetworkError';

export const RESET_QUESTION_BANK = 'questionBank/resetQuestionBank';
export const RESET_SAVE_ANSWER = 'questionBank/resetSaveAnswer';

export const BOOKMARK_QUESTION = 'questionBank/bookmarkQuestion';
export const BOOKMARK_QUESTION_SUCCESS = 'questionBank/bookmarkQuestionSuccess';
export const BOOKMARK_QUESTION_ERROR = 'questionBank/bookmarkQuestionError';
export const BOOKMARK_QUESTION_NETWORK_ERROR = 'questionBank/bookmarkQuestionNetworkError';
export const RESET_BOOKMARK = 'questionBank/resetBookmarkQuestion';

export const UPDATE_TIMER = 'questionBank/updateTimer';
export const UPDATE_TIMER_SUCCESS = 'questionBank/updateTimerSuccess';
export const UPDATE_TIMER_ERROR = 'questionBank/updateTimerError';
export const UPDATE_TIMER_NETWORK_ERROR = 'questionBank/updateTimerNetworkError';

export const END_QUESTION_BANK = 'questionBank/endQuestionBank';
export const END_QUESTION_BANK_SUCCESS = 'questionBank/endQuestionBankSuccess';
export const END_QUESTION_BANK_ERROR = 'questionBank/endQuestionBankError';
export const END_QUESTION_BANK_NETWORK_ERROR = 'questionBank/endQuestionBankNetworkError';

export const GET_ATTEMPT_DETAILS = 'questionBank/getAttemptDetails';
export const GET_ATTEMPT_DETAILS_SUCCESS = 'questionBank/getAttemptDetailsSuccess';
export const GET_ATTEMPT_DETAILS_ERROR = 'questionBank/getAttemptDetailsError';
export const GET_ATTEMPT_DETAILS_NETWORK_ERROR = 'questionBank/getAttemptDetailsNetworkError';

export const EDIT_ATTEMPT = 'questionBank/editAttempt';
export const EDIT_ATTEMPT_SUCCESS = 'questionBank/editAttemptSuccess';
export const EDIT_ATTEMPT_ERROR = 'questionBank/editAttemptError';
export const EDIT_ATTEMPT_NETWORK_ERROR = 'questionBank/editAttemptNetworkError';

export const GET_QUESTION_BANK_PERFORMANCE = 'questionBank/getQuestionBankPerformance';
export const GET_QUESTION_BANK_PERFORMANCE_SUCCESS =
  'questionBank/getQuestionBankPerformanceSuccess';
export const GET_QUESTION_BANK_PERFORMANCE_ERROR = 'questionBank/getQuestionBankPerformanceError';
export const GET_QUESTION_BANK_PERFORMANCE_NETWORK_ERROR =
  'questionBank/getQuestionBankPerformanceNetworkError';
