import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Text,
  Divider,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { HideIcon, ShowIcon } from 'src/icons';
import { selectUpdatePasswordLoading } from 'src/redux/parentsNotification/selectors';

interface ResetPasswordPromptProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onClickConfirmPassword: (e: { preventDefault: () => void }) => void;
}

function ResetPasswordPrompt({
  isOpen,
  onClose,
  onClickConfirmPassword,
}: ResetPasswordPromptProps): JSX.Element {
  const { t } = useTranslation();
  const loading = useSelector(selectUpdatePasswordLoading);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onClickConfirmPassword}>
        <ModalHeader fontSize={20} fontWeight="semibold">
          {t('please_enter_your_new_password')}
          <Divider borderColor="black" mt={3} />
        </ModalHeader>
        <ModalCloseButton mt={2} mr={3} />
        <ModalBody pb={6}>
          <HStack spacing={2} mb={5}>
            <Text fontSize={18} fontWeight="medium" w="300px">
              {t('password')}:
            </Text>
            <InputGroup>
              <Input
                required
                id="resetPassword"
                h="28px"
                type={showPassword ? 'text' : 'password'}
                borderColor="#707070"
                borderRadius="full"
                _hover={{ borderColor: '#707070' }}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                }
              />
              <InputRightElement
                mt={-1}
                cursor="pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
              </InputRightElement>
            </InputGroup>
          </HStack>
          <HStack spacing={2}>
            <Text fontSize={18} fontWeight="medium" w="300px">
              {t('confirm_password')}:
            </Text>
            <InputGroup>
              <Input
                required
                id="resetConfirmPassword"
                h="28px"
                type={showConfirmPassword ? 'text' : 'password'}
                borderColor="#707070"
                borderRadius="full"
                _hover={{ borderColor: '#707070' }}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(t('please_fill_up_this_field'))
                }
              />
              <InputRightElement
                mt={-1}
                cursor="pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <ShowIcon /> : <HideIcon color="black" />}
              </InputRightElement>
            </InputGroup>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" fontSize={16} fontWeight="bold" isLoading={loading}>
            {t('confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ResetPasswordPrompt;
