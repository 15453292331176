import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Spinner, Button, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getClassroomLink } from 'src/redux/event/actions';
import {
  selectClassroomLink,
  selectClassroomLinkNetworkError,
  selectClassroomLinkStatusCode,
} from 'src/redux/event/selectors';
import useSessionExpired from 'src/components/SessionExpired';
import usePageTracking from '../PageTracking';

function VirtualClassroom(): JSX.Element {
  usePageTracking('virtual-classroom');
  const params = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const link = useSelector(selectClassroomLink);
  const statusCode = useSelector(selectClassroomLinkStatusCode);
  const networkError = useSelector(selectClassroomLinkNetworkError);

  useEffect(() => {
    dispatch(
      getClassroomLink({
        eventId: Number(params.eventId),
      }),
    );
  }, [dispatch, params.eventId]);

  useSessionExpired(statusCode);

  if (networkError) {
    return (
      <VStack h="100vh" justifyContent="center">
        <Text>{t('internet_connect_error')}</Text>
      </VStack>
    );
  }

  const onClickExit = () => {
    const result = window.messageBox.confirmBox('Do you want to leave the class?');
    if (result === 0) {
      navigate('/dashboard');
    }
  };

  const classroom = () => {
    if (link) {
      return (
        <Flex h="100vh" w="100vw" pos="relative">
          <iframe
            title="Classroom"
            src={link}
            allow="microphone *; camera *; speakers; usermedia *; autoplay *; fullscreen *; display-capture *;"
            allowFullScreen
            width="100%"
            height="100%"
          ></iframe>
          <Button colorScheme="red" onClick={onClickExit} pos="fixed" left="10px" top="90vh">
            Exit
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex h="100vh" w="100vw" justifyContent="center" alignContent="center">
          <VStack spacing={5} m="auto">
            <Spinner />
            <Button colorScheme="red" onClick={onClickExit}>
              Exit
            </Button>
          </VStack>
        </Flex>
      );
    }
  };

  return classroom();
}

export default VirtualClassroom;
