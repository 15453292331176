import React, { useState, useEffect } from 'react';
import DashboardShell from 'src/components/DashboardShell';
import { HStack, VStack } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { selectAttemptDetails, selectQuestion } from 'src/redux/questionBank/selectors';
import Loader from 'src/components/Loader';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Question from 'src/components/Question/Question';
import { getAttemptDetails, resetQuestionBank } from 'src/redux/questionBank/actions';
import usePageTracking from '../PageTracking';
import AttemptDetails from 'src/components/QuestionAndHomeworkReview/AttemptDetails';
import QuestionList from 'src/components/QuestionAndHomeworkReview/QuestionList';

interface LocationState {
  attemptName: string;
  questionBankName: string;
  questionType: string;
}

function QuestionBankReview(): JSX.Element {
  usePageTracking('question-bank-review');
  const params = useParams<{ subjectId: string; attemptId: string; questionId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const { attemptName, questionBankName, questionType } = state;
  const questions = useSelector(selectQuestion);
  const attemptData = useSelector(selectAttemptDetails);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(resetQuestionBank());
    };
  }, [dispatch]);

  useEffect(() => {
    if (questions === null) {
      dispatch(getAttemptDetails(Number(params.attemptId)));
    }
  }, [dispatch, questions]);

  useEffect(() => {
    if (questions) {
      const temp = questions.filter((item) => item.id.toString() === params.questionId);
      const pos = questions.indexOf(temp[0]);
      setCurrentQuestion(pos);
    }
  }, [params.questionId]);

  if (!questions || !attemptData) {
    return (
      <DashboardShell>
        <BackTopbar
          title={attemptName}
          pathname={`/question-bank-records/${params.subjectId!}/${params.attemptId!}`}
          state={{ attemptName, questionBankName, questionType }}
        />
        <Loader />
      </DashboardShell>
    );
  }

  const onClickPreviousQuestion = () => {
    navigate(
      `/question-bank-review/${params.subjectId!}/${params.attemptId!}/${
        questions[currentQuestion - 1].id
      }`,
      {
        replace: true,
        state: { attemptName, questionBankName, questionType },
      },
    );
  };

  const onClickNextQuestion = () => {
    navigate(
      `/question-bank-review/${params.subjectId!}/${params.attemptId!}/${
        questions[currentQuestion + 1].id
      }`,
      {
        replace: true,
        state: { attemptName, questionBankName, questionType },
      },
    );
  };

  return (
    <DashboardShell>
      <BackTopbar
        title={attemptName}
        pathname={`/question-bank-records/${params.subjectId!}/${params.attemptId!}`}
        state={{ attemptName, questionBankName, questionType }}
      />
      <HStack flex="1" overflow="hidden" spacing={5} px={10} py={5}>
        <Question
          key={questions[currentQuestion].id}
          question={questions[currentQuestion]}
          currentQuestion={currentQuestion}
          totalQuestions={questions.length}
          onClickPreviousQuestion={onClickPreviousQuestion}
          onClickNextQuestion={onClickNextQuestion}
        />
        <VStack h="100%" w="30%" spacing={5}>
          <AttemptDetails {...attemptData} />
          <QuestionList
            attemptData={attemptData}
            type="questionBank"
            subjectId={params.subjectId}
            attemptId={params.attemptId}
            state={{ attemptName, questionBankName, questionType }}
          />
        </VStack>
      </HStack>
    </DashboardShell>
  );
}

export default QuestionBankReview;
