import React, { useState } from 'react';
import { Flex, Select, HStack, Text, Spacer, Table, Thead, Tbody, Tr, Td } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import BackTopbar from '../Topbar/BackTopbar';
import DashboardShell from '../DashboardShell';
import { TimeIcon } from 'src/icons';
import CircularProgressBar from '../CircularProgressBar';
import { QuestionBankAttemptData } from 'src/models/QuestionBank';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { Question } from 'src/models/Question';

interface RecordDetailsProps {
  attemptName: string;
  pathname: string;
  state: unknown;
  attemptDetails: QuestionBankAttemptData;
  name: string;
  questionType: string;
  questions: Question[];
}

function RecordDetails({
  attemptName,
  pathname,
  state,
  attemptDetails,
  name,
  questionType,
  questions,
}: RecordDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const [showOnly, setShowOnly] = useState<string | undefined>(undefined);

  const filteredQuestions =
    showOnly === 'correct'
      ? attemptDetails.questions.filter((question) => question.correct)
      : showOnly === 'incorrect'
      ? attemptDetails.questions.filter((question) => !question.correct)
      : [];

  return (
    <DashboardShell>
      <BackTopbar title={attemptName} pathname={pathname} state={state} />
      <Flex
        as={OverlayScrollbarsComponent}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        h="100%"
        m={5}
        boxShadow="0 0 5px gray"
        borderRadius="10px"
        pt={10}
        flexDirection="column"
      >
        <HStack w="100%" h="fit-content" spacing={28} justify="center">
          <Flex
            background="#003BFF"
            borderRadius="full"
            h="140px"
            w="140px"
            justifyContent="center"
            alignItems="center"
            color="white"
            flexDirection="column"
          >
            <Text fontSize={12}>
              <TimeIcon />
              <Text as="span" ml={2}>
                {t('time')}
              </Text>
            </Text>
            <Text fontSize={22} fontWeight="medium">
              {attemptDetails.totalTime}
            </Text>
          </Flex>
          <CircularProgressBar value={Number(attemptDetails.score.toFixed(2))} />
          <Flex
            background="#003BFF"
            borderRadius="full"
            h="140px"
            w="140px"
            justifyContent="center"
            alignItems="center"
            color="white"
            flexDirection="column"
          >
            <Text fontSize={12}>{t('questions')}</Text>
            <Text fontSize={22} fontWeight="medium">
              {`${attemptDetails.correctAnswers}/${attemptDetails.totalQuestions}`}
            </Text>
          </Flex>
        </HStack>
        <Flex flexDirection="column" h="100%" w="100%" p={10}>
          <Flex w="100%" mb={5} alignItems="center">
            <Text fontSize={14} fontWeight="semibold">
              {t('results')}
            </Text>
            <Spacer />
            {questionType !== 'Essay' && (
              <HStack>
                <Text fontSize={14} fontWeight="semibold">
                  {t('show_only')}
                </Text>
                <Select
                  fontSize={14}
                  border="none"
                  w="fit-content"
                  _focus={{ outline: 'none' }}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setShowOnly(e.target.value)
                  }
                  placeholder={t('all')}
                >
                  <option value="correct">{t('correct')}</option>
                  <option value="incorrect">{t('incorrect')}</option>
                </Select>
              </HStack>
            )}
          </Flex>
          <Table w="100%">
            <Thead>
              <Tr>
                <TableHeader px={0} pl={3}>
                  {t('questions')}
                </TableHeader>
                {questionType !== 'Essay' && (
                  <TableHeader textAlign="center">{t('answers')}</TableHeader>
                )}
                <TableHeader textAlign="center">{t('score')}</TableHeader>
                <TableHeader textAlign="center">{t('review')}</TableHeader>
              </Tr>
            </Thead>
            <Tbody>
              {questions.length === 0 ? (
                <Tr fontSize={18}>
                  <Td
                    px={0}
                    pl={3}
                    colSpan={questionType === 'Essay' ? 3 : 4}
                    textAlign="center"
                    border="none"
                  >
                    <Text>{t('no_answered_question')}</Text>
                  </Td>
                </Tr>
              ) : filteredQuestions.length === 0 ? (
                questions.map((question, index) => {
                  const isCorrect = attemptDetails.questions[index].correct;
                  const score = attemptDetails.questions[index].mark;
                  return (
                    <TableBody
                      key={question.id}
                      index={index}
                      question={question.quesText}
                      answer={question.quesType === 'Essay' ? undefined : isCorrect}
                      score={score}
                      attemptName={attemptName}
                      questionBankName={name}
                      questionType={questionType}
                    />
                  );
                })
              ) : (
                filteredQuestions.map((question) => {
                  const q = questions.filter((q) => q.id === question.id);
                  const index = questions.indexOf(q[0]);
                  return (
                    <TableBody
                      key={question.id}
                      index={index}
                      question={q[0].quesText}
                      answer={q[0].quesType === 'Essay' ? undefined : question.correct}
                      score={question.mark}
                      attemptName={attemptName}
                      questionBankName={name}
                      questionType={questionType}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </DashboardShell>
  );
}

export default RecordDetails;
