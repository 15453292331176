import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useSelector, useDispatch } from 'react-redux';

import { selectEndQuestionBankStatusCode, selectQuestion } from 'src/redux/questionBank/selectors';
import NavigationCard from 'src/components/QuestionAndHomework/NavigationCard';
import { endQuestionBank } from 'src/redux/questionBank/actions';

interface EndSessionProps {
  totalTimer: string;
  attemptName: string;
  questionBankName: string;
  questionType: string;
}

function EndSession({
  totalTimer,
  attemptName,
  questionBankName,
  questionType,
}: EndSessionProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const params = useParams<{ subjectId: string; attemptId: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const questions = useSelector(selectQuestion);
  const statusCode = useSelector(selectEndQuestionBankStatusCode);

  useEffect(() => {
    if (statusCode === 200) {
      navigate(`/question-bank-records/${params.subjectId!}/${params.attemptId!}`, {
        state: { attemptName, questionBankName, questionType },
      });
    }
  }, [statusCode]);

  const onClickEndSession = () => {
    dispatch(
      endQuestionBank({
        totalTime: totalTimer,
        attemptId: Number(params.attemptId),
        questionBankId: Number(params.subjectId),
      }),
    );
  };

  const showUnmarkedQuestions = () => {
    if (questions && questions[0].quesType === 'Essay') {
      const unmarkedQuestions = questions.filter((item) => item.mark === null);

      if (questions.length === 0 || unmarkedQuestions.length === 0) {
        return <></>;
      } else {
        return (
          <Box w="70%">
            <Text fontSize={14} fontWeight="medium" my={5}>
              {t('questions_unmarked')}
            </Text>
            <OverlayScrollbarsComponent
              style={{ minHeight: '100px', height: '100px', width: '60%', paddingRight: '20px' }}
              options={{ scrollbars: { autoHide: 'scroll' } }}
            >
              {unmarkedQuestions.map((item) => {
                return (
                  <NavigationCard
                    key={item.id}
                    backgroundColor={item.answer === null ? 'black' : '#E6E6E6'}
                    color={item.answer === null ? 'white' : 'black'}
                    bookmark={item.bookmark}
                    pathname={`/question-bank-question/${params.subjectId!}/${item.attemptId}/${
                      item.id
                    }`}
                    state={{ attemptName, questionBankName, questionType }}
                    closeModal={onClose}
                  >
                    {item.quesName}
                  </NavigationCard>
                );
              })}
            </OverlayScrollbarsComponent>
          </Box>
        );
      }
    }
  };

  return (
    <>
      <Button fontSize={18} fontWeight="semibold" onClick={onOpen} colorScheme="red" color="white">
        {t('end_session')}
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={18} fontWeight="medium">
            {t('confirm_end_session')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalBody pb={6}>
            <Text>{t('end_session_text')}</Text>
            {showUnmarkedQuestions()}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              color="white"
              mr={3}
              fontSize={14}
              fontWeight="medium"
              onClick={onClickEndSession}
            >
              {t('end')}
            </Button>
            <Button onClick={onClose} fontSize={14} fontWeight="medium">
              {t('cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EndSession;
