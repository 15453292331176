import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compareAsc, format, parseISO } from 'date-fns';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Tooltip,
  VStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { logEvent } from 'firebase/analytics';

import {
  CalendarIcon,
  CourseIcon,
  InfoIcon,
  LanguageIcon,
  StudentsIcon,
  TimeIcon,
} from 'src/icons';
import { addToCart } from 'src/redux/cart/actions';
import {
  selectAddToCartStatusCode,
  selectAddToCartErrorMessage,
  selectAddToCartNetworkError,
  selectCurrentDate,
  selectCutOffDateStatusCode,
} from 'src/redux/cart/selectors';
import { formatPrice } from 'src/utils/formatPrice';
import { getCourseDetails } from 'src/redux/course/actions';
import {
  selectCourseDetails,
  selectCourseDetailsNetworkError,
  selectCourseDetailsStatusCode,
} from 'src/redux/course/selectors';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import Loader from 'src/components/Loader';
import StudyTrack from './StudyTrack';
import TavisPlaceholder from 'src/images/TavisPlaceholder.svg';
import useSessionExpired from 'src/components/SessionExpired';
import useAddToCart from 'src/components/AddToCart';
import NetworkErrorComponent from 'src/components/NetworkError';
import { IS_DEV } from 'src/constants/environment';
import { createFreeClassOrder, createOrder } from 'src/redux/order/actions';
import {
  selectFreeClassOrderLoading,
  selectFreeClassOrderNetworkError,
  selectFreeClassOrderStatusCode,
  selectNewOrder,
  selectNewOrderLoading,
} from 'src/redux/order/selectors';
import usePageTracking from '../PageTracking';
import { selectFreeTrialAccessDate, selectFreeTrialExpiry } from 'src/redux/auth/selectors';
import { CreateOrderPayload, PaymentDetails } from 'src/redux/order/models/payload';
import { getFreeTrial } from 'src/utils/getFreeTrial';
import { getUserDetails } from 'src/redux/auth/actions';
import { analytics } from 'src/analytics';

function CourseDetails(): JSX.Element {
  usePageTracking('course-details');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const toast = useToast();

  const [checkoutNow, setCheckoutNow] = useState(false);
  const [checkoutTo, setCheckoutTo] = useState('');
  const [freeTrial, setFreeTrial] = useState(false);

  const { isOpen: isOpenOneTime, onOpen: onOpenOneTime, onClose: onCloseOneTime } = useDisclosure();
  const {
    isOpen: isOpenSubscribe,
    onOpen: onOpenSubscribe,
    onClose: onCloseSubscribe,
  } = useDisclosure();

  const courseId = Number(params.id);

  const statusCode = useSelector(selectCourseDetailsStatusCode);
  const networkError = useSelector(selectCourseDetailsNetworkError);

  const courseArray = useSelector(selectCourseDetails);

  const addToCartStatusCode = useSelector(selectAddToCartStatusCode);
  const addToCartErrorMessage = useSelector(selectAddToCartErrorMessage);
  const addToCartNetworkError = useSelector(selectAddToCartNetworkError);

  const freeClassOrderStatusCode = useSelector(selectFreeClassOrderStatusCode);
  const freeClassOrderNetworkError = useSelector(selectFreeClassOrderNetworkError);
  const freeClassOrderLoading = useSelector(selectFreeClassOrderLoading);

  const currentDate = useSelector(selectCurrentDate);
  const freeTrialExpiryDate = useSelector(selectFreeTrialExpiry);
  const freeTrialAccessDate = useSelector(selectFreeTrialAccessDate);

  const cutOffDateStatusCode = useSelector(selectCutOffDateStatusCode);

  // Create order
  const orderCreated = useSelector(selectNewOrder);
  const createOrderLoading = useSelector(selectNewOrderLoading);

  useEffect(() => {
    dispatch(getCourseDetails(courseId));
    dispatch(getUserDetails());
  }, [dispatch, courseId]);

  useEffect(() => {
    if (freeClassOrderStatusCode === 200) {
      toast({
        position: 'top',
        title: t('enrol_for_free_successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/dashboard', { state: { needDelay: true } });
    }
  }, [freeClassOrderStatusCode]);

  useEffect(() => {
    if (freeClassOrderNetworkError) {
      toast({
        title: t('network_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [freeClassOrderNetworkError]);

  useEffect(() => {
    if (currentDate && freeTrialExpiryDate) {
      setFreeTrial(getFreeTrial(new Date(currentDate), freeTrialExpiryDate));
    }
  }, [currentDate, freeTrialExpiryDate]);

  useEffect(() => {
    if (orderCreated) {
      toast({
        position: 'top',
        title: t('enrol_for_free_successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/dashboard', { state: { needDelay: true } });
    }
  }, [orderCreated]);

  useAddToCart(addToCartStatusCode, addToCartErrorMessage, addToCartNetworkError);
  useSessionExpired(cutOffDateStatusCode);
  useSessionExpired(addToCartStatusCode);

  useSessionExpired(statusCode);
  useSessionExpired(freeClassOrderStatusCode);

  useEffect(() => {
    if (addToCartStatusCode === 200 && courseArray) {
      if (!IS_DEV) {
        logEvent(analytics, 'add_to_cart', {
          currency: 'MYR',
          items: [
            {
              item_id: courseId.toString(),
              item_name: courseArray[0].courseName,
              price: courseArray[0].discountPrice
                ? courseArray[0].discountPrice
                : courseArray[0].price,
              item_category: 'Courses',
              item_list_name: 'Courses',
            },
          ],
        });
      }
    }

    if (addToCartStatusCode === 200 && checkoutNow) {
      setTimeout(() => {
        navigate(`/${checkoutTo}`);
      }, 500);
    }
  }, [addToCartStatusCode]);

  if (networkError) return <NetworkErrorComponent />;

  // Loading state
  if (!courseArray)
    return (
      <DashboardShell>
        <BackTopbar title="Course Details" />
        <Loader />
      </DashboardShell>
    );
  const course = courseArray[0];

  const hasDiscount = Boolean(course.discountPrice);

  const calculateDiscount = () => {
    if (!hasDiscount) return '';

    const { discountPrice, price } = course;
    const discount = (100 - (Number(discountPrice) / Number(price)) * 100).toFixed(0);

    return `${discount}% off`;
  };

  const onAddToCart = () => {
    dispatch(
      addToCart({
        cartType: 'course',
        instanceId: courseId.toString(),
      }),
    );

    onCloseOneTime();
    onCloseSubscribe();
  };

  const onCheckoutNow = (checkoutTo: string) => {
    onAddToCart();
    setCheckoutNow(true);
    setCheckoutTo(checkoutTo);
  };

  const onClickEnrolFreeCourse = () => {
    dispatch(
      createFreeClassOrder({
        id: course.id,
        categoryType: 'course',
      }),
    );
  };

  // For free trial
  const onClickEnrolCourse = () => {
    if (freeTrialAccessDate) {
      let startMonth;
      let startYear;
      const startDate = new Date(course.startDate);
      const result = compareAsc(freeTrialAccessDate, startDate);
      if (result === -1) {
        startMonth = startDate.getMonth() + 1;
        startYear = startDate.getFullYear();
      } else {
        startMonth = freeTrialAccessDate.getMonth() + 1;
        startYear = freeTrialAccessDate.getFullYear();
      }
      const paymentDetails: PaymentDetails = {
        freeTrial: true,
        gateway: 'n/a',
        startMonth: startMonth,
        startYear: startYear,
      };
      const newOrder: CreateOrderPayload = {
        paymentDetails,
        cartItems: [
          {
            id: course.id,
            bundleDiscount: 0,
          },
        ],
        testMode: IS_DEV,
      };
      dispatch(createOrder(newOrder));
    }
  };

  const checkFreeTrialEligibility = () => {
    if (freeTrialAccessDate) {
      const endDate = new Date(course.endDate);
      const result = compareAsc(freeTrialAccessDate, endDate);
      if (result === 1) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <DashboardShell>
      <BackTopbar title={course.courseName} />
      <OverlayScrollbarsComponent
        style={{ height: '100%', width: '100%', paddingBottom: '12px' }}
        options={{ scrollbars: { autoHide: 'scroll' }, paddingAbsolute: true }}
      >
        <Flex px="8" h="80vh" p={4}>
          <Flex
            direction="column"
            w="33%"
            mx="4"
            alignItems="center"
            borderColor="gray.400"
            borderWidth="1px"
            h="fit-content"
            minH="calc(100% - 16px)"
          >
            {course.videoUrl ? (
              <AspectRatio w="full" ratio={16 / 9}>
                <Box
                  as="video"
                  width="full"
                  height="full"
                  controls
                  poster={course.videoThumbnailUrl || TavisPlaceholder}
                  controlsList="nodownload"
                >
                  <source src={course.videoUrl} />
                </Box>
              </AspectRatio>
            ) : (
              <AspectRatio w="full" ratio={16 / 9}>
                <Image
                  w="100%"
                  h="100%"
                  src={course.backgroundImageUrl}
                  fallbackSrc={TavisPlaceholder}
                />
              </AspectRatio>
            )}
            <Flex
              mt="8"
              w="80%"
              justifyContent="space-between"
              alignItems="center"
              visibility={hasDiscount ? 'unset' : 'hidden'}
            >
              <Text fontSize="20" color="#AFAFAF" textDecoration="line-through">
                {formatPrice(course.price)}/{t('month', { count: undefined })}
              </Text>
              <Text fontSize="20">{calculateDiscount()}</Text>
            </Flex>
            <Text mt="4" fontSize="28" fontWeight="semibold">
              {hasDiscount
                ? formatPrice(course.discountPrice) + '/' + t('month', { count: undefined })
                : formatPrice(course.price) + '/' + t('month', { count: undefined })}
            </Text>
            {course.isFree ? (
              <VStack mt="4" w="75%" minW="fit-content">
                <Flex w="full" alignItems="center">
                  <Button
                    isLoading={freeClassOrderLoading}
                    p="2"
                    minW="fit-content"
                    w="full"
                    fontSize={18}
                    fontWeight="semibold"
                    bg="#161DFF"
                    color="white"
                    mr="2"
                    onClick={onClickEnrolFreeCourse}
                    disabled={course.purchased}
                    _hover={{ bg: '#0000CA' }}
                    _active={{ bg: '#0000CA' }}
                  >
                    {t('enrol_for_free')}
                  </Button>
                </Flex>
              </VStack>
            ) : (
              <VStack mt="4" w="75%" minW="fit-content">
                <Flex w="full" alignItems="center" mt="4">
                  <Button
                    py="2"
                    w="250px"
                    minW="fit-content"
                    fontSize={18}
                    fontWeight="semibold"
                    mr="2"
                    color="white"
                    bg="#1BC35C"
                    _hover={{ bg: '#00912F' }}
                    _active={{ bg: '#00912F' }}
                    onClick={onOpenSubscribe}
                  >
                    {t('subscribe')}
                  </Button>
                  <Modal isOpen={isOpenSubscribe} onClose={onCloseSubscribe} isCentered size="lg">
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader fontSize={26} fontWeight="semibold">
                        {t('please_choose')}
                        <Divider borderColor="black" mt={3} />
                      </ModalHeader>
                      <ModalFooter as={Flex} justifyContent="space-around" alignItems="center">
                        <Button
                          fontWeight="medium"
                          backgroundColor="#3007A3"
                          color="white"
                          _hover={{ backgroundColor: '#000073' }}
                          _active={{ backgroundColor: '#000073' }}
                          onClick={() => onCheckoutNow('subscription')}
                        >
                          {t('checkout_now')}
                        </Button>
                        <Button
                          background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                          color="white"
                          fontWeight="medium"
                          _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          onClick={onAddToCart}
                        >
                          {t('add_to_subs')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Tooltip
                    label={t('subscribe_info')}
                    fontSize={10}
                    fontWeight="medium"
                    placement="right"
                  >
                    <Box as="span">
                      <InfoIcon color="#afafaf" boxSize="34" />
                    </Box>
                  </Tooltip>
                </Flex>
                <Flex w="full" alignItems="center">
                  <Button
                    p="2"
                    minW="fit-content"
                    w="250px"
                    mr="2"
                    bg="white"
                    border="1px solid #1BC35C"
                    onClick={onOpenOneTime}
                  >
                    {t('pay_as_you_go')}
                  </Button>
                  <Modal isOpen={isOpenOneTime} onClose={onCloseOneTime} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader fontSize={26} fontWeight="semibold">
                        {t('please_choose')}
                        <Divider borderColor="black" mt={3} />
                      </ModalHeader>
                      <ModalFooter as={Flex} justifyContent="space-around" alignItems="center">
                        <Button
                          fontWeight="medium"
                          backgroundColor="#3007A3"
                          color="white"
                          _hover={{ backgroundColor: '#000073' }}
                          _active={{ backgroundColor: '#000073' }}
                          onClick={() => onCheckoutNow('prepaid')}
                        >
                          {t('checkout_now')}
                        </Button>
                        <Button
                          background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                          color="white"
                          fontWeight="medium"
                          _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                          onClick={onAddToCart}
                        >
                          {t('add_to_cart')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Tooltip
                    label={t('buy_and_add_to_cart_info')}
                    fontSize={10}
                    fontWeight="medium"
                    placement="right"
                  >
                    <Box as="span">
                      <InfoIcon color="#afafaf" boxSize="34" />
                    </Box>
                  </Tooltip>
                </Flex>
                {freeTrial && !course.purchased && (
                  <Flex w="full" alignItems="center">
                    <Button
                      isLoading={createOrderLoading}
                      p="2"
                      minW="fit-content"
                      w="250px"
                      bg="white"
                      border="1px solid #1BC35C"
                      mr="2"
                      onClick={onClickEnrolCourse}
                      disabled={!checkFreeTrialEligibility() || course.ordered}
                    >
                      {t('free_trial_30_days')}
                    </Button>
                  </Flex>
                )}
              </VStack>
            )}
            <Flex flex="1" w="80%" mt="16" flexDirection="column">
              <Text fontWeight="medium">{t('description')}:</Text>
              <Flex
                flex="1"
                as={OverlayScrollbarsComponent}
                options={{ scrollbars: { autoHide: 'scroll' } }}
              >
                <Box fontSize="sm" minH="100%" h="100px">
                  {parse(course.description)}
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column" minW="33%" w="33%" mx="4">
            <Heading size="md">{t('study_track')}</Heading>
            <StudyTrack track={course.studyTrack} {...course.duration} lectures={course.lectures} />
          </Flex>
          <Flex w="33%" direction="column" mx="4">
            <Heading size="md">{t('tutor')}</Heading>

            <Image
              w="150px"
              h="150px"
              mt="4"
              bg="white"
              objectFit="scale-down"
              src={course.teacher.imageUrl}
              fallbackSrc={TavisPlaceholder}
            />

            <Flex direction="column" mt="4">
              <Text
                fontSize={20}
                textDecoration="underline"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {`Cikgu ${course.teacher.firstname} ${course.teacher.lastname}`}
              </Text>
              <Text fontSize="sm" mt="4">
                {course.teacher.qualification}
              </Text>
              <Text fontSize="sm" mt="4">
                <StudentsIcon boxSize="20px" mr="4" />
                {t('student_taught', { count: Number(course.teacher.students) })}
              </Text>
              <Text fontSize="sm" mt="4">
                <CourseIcon boxSize="20px" mr="4" />
                {t('class', { count: Number(course.teacher.courses) })}
              </Text>
            </Flex>

            <Flex direction="column" mt="4">
              <Heading size="md" mt="8">
                {t('about_class')}
              </Heading>
              <Flex w="80%" direction="column">
                <Text fontSize="sm" mt="4">
                  <LanguageIcon boxSize="20px" mr="4" />
                  {t('language')}: {course.language.name}
                </Text>
                <Text fontSize="sm" mt="4">
                  <TimeIcon boxSize="20px" mr="4" />
                  {t('length')}:{' '}
                  {Boolean(course.duration.months) &&
                    t('month', { count: Number(course.duration.months) })}{' '}
                  {Boolean(course.duration.weeks) &&
                    t('week', { count: Number(course.duration.weeks) })}
                  {!Boolean(course.duration.months) &&
                    !Boolean(course.duration.weeks) &&
                    `${t('month', { count: Number(course.duration.months) })} ${t('week', {
                      count: Number(course.duration.weeks),
                    })}`}
                </Text>
                <Text fontSize="sm" mt="4">
                  <CalendarIcon boxSize="20px" mr="4" />
                  {t('start_date')}: {format(parseISO(course.startDate), 'do MMM yyyy')}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </OverlayScrollbarsComponent>
    </DashboardShell>
  );
}

export default CourseDetails;
