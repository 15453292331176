import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface BookmarkIconProps extends IconProps {
  filled?: boolean;
}

export function BookmarkIcon({ filled, ...props }: BookmarkIconProps): JSX.Element {
  return (
    <Icon {...props}>
      <path
        id="Bookmark_icon"
        data-name="Bookmark icon"
        d="M23.5,26.5l-8-6.111L7.5,26.5V6.944A2.369,2.369,0,0,1,9.786,4.5H21.214A2.369,2.369,0,0,1,23.5,6.944Z"
        transform="translate(-6.25 -3.25)"
        fill={filled ? '#000' : 'none'}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      ></path>
    </Icon>
  );
}
