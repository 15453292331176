import React from 'react';
import { Flex, Text, Grid } from '@chakra-ui/react';
import compareAsc from 'date-fns/compareAsc';
import isWithinInterval from 'date-fns/isWithinInterval';
import intervalToDuration from 'date-fns/intervalToDuration';
import { useTranslation } from 'react-i18next';

import EventCard from './EventCard/EventCard';
import ActiveEventCard from './EventCard/ActiveEventCard';
// import CancelledEventCard from './EventCard/CancelledEventCard';
import PastEventCard from './EventCard/PastEventCard';
import { Event } from 'src/models/Event';

interface CalendarEventCardProps {
  day: Date;
  date: string;
  event: Event[];
  width: number | undefined;
}

function CalendarEventCard({ day, date, event, width }: CalendarEventCardProps): JSX.Element {
  const { t } = useTranslation();
  let hasActiveEvent = false;
  const sortedEvents = event.sort((var1, var2) => {
    const a = new Date(var1.start_time);
    const b = new Date(var2.start_time);
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });

  return (
    <Flex minW="100%" w={width} mb={5} display="inline-flex" id={`day${date}`}>
      <Flex
        textAlign="center"
        justifyContent="center"
        flexDirection="column"
        pos="sticky"
        bg="white"
        w={24}
        left="0px"
        flexGrow={0}
        flexShrink={0}
      >
        <Text fontSize={35} fontWeight="semibold">
          {date}
        </Text>
        <Text fontSize={22} fontWeight="bold">
          {t(day.toLocaleDateString('en-us', { weekday: 'short' }).toLowerCase())}
        </Text>
      </Flex>
      {event.length === 0 ? (
        <Flex alignItems="center">
          <Text>{t('no_classes')}</Text>
        </Flex>
      ) : (
        <Grid templateRows="auto" autoFlow="column" gap={6}>
          {sortedEvents.map((item) => {
            let today = new Date(item.serverTime * 1000);
            const startTime = new Date(item.start_time);
            const endTime = new Date(item.end_time);
            today.setHours(0, 0, 0, 0);
            const isToday = compareAsc(day, today) === 0;

            if (compareAsc(day, today) === -1) {
              return <PastEventCard key={item.id} {...item} />;
            } else if (compareAsc(day, today) === 0) {
              today = new Date(item.serverTime * 1000);
              const duration = intervalToDuration({ start: today, end: startTime });

              if (isWithinInterval(today, { start: startTime, end: endTime })) {
                hasActiveEvent = true;
                return <ActiveEventCard key={item.id} {...item} />;
              } else if (compareAsc(endTime, today) === -1) {
                return <PastEventCard key={item.id} {...item} />;
              } else if (
                duration.hours != undefined &&
                duration.minutes != undefined &&
                duration.hours === 0 &&
                duration.minutes <= 15
              ) {
                hasActiveEvent = true;
                return <ActiveEventCard key={item.id} {...item} />;
              } else if (!hasActiveEvent) {
                hasActiveEvent = true;
                return <ActiveEventCard key={item.id} {...item} />;
              }
            }

            return <EventCard key={item.id} event={item} isToday={isToday} />;
          })}
        </Grid>
      )}
    </Flex>
  );
}

export default CalendarEventCard;
