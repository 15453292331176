import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function PhotoIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1681" data-name="Rectangle 1681" width="20" height="26" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_383" data-name="Group 383" clipPath="url(#clip-path)">
        <path
          id="Path_1198"
          data-name="Path 1198"
          d="M40.413,4.468V25.525a.9.9,0,0,1-.9.906H22.867a.9.9,0,0,1-.9-.906V1.406a.9.9,0,0,1,.9-.906h13.59Z"
          transform="translate(-20.447 -0.465)"
          fill="#fff"
        />
        <path
          id="Path_1199"
          data-name="Path 1199"
          d="M40.413,4.468V25.525a.9.9,0,0,1-.9.906H22.867a.9.9,0,0,1-.9-.906V1.406a.9.9,0,0,1,.9-.906h13.59Z"
          transform="translate(-20.447 -0.465)"
          fill="none"
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_1200"
          data-name="Path 1200"
          d="M218.315,4.468V25.525a.9.9,0,0,1-.9.906h-1.289a.9.9,0,0,0,.9-.906V4.468L213.071.5h1.289Z"
          transform="translate(-198.349 -0.465)"
          fill="#e2e1de"
        />
        <path
          id="Path_1201"
          data-name="Path 1201"
          d="M235.677,4.468h-3.053a.9.9,0,0,1-.9-.906V.5Z"
          transform="translate(-215.712 -0.465)"
          fill="#c9c9c9"
        />
        <path
          id="Path_1202"
          data-name="Path 1202"
          d="M9.064,61.156H0V57.475a.5.5,0,0,1,.5-.5H9.064a.5.5,0,0,1,.5.5v3.179a.5.5,0,0,1-.5.5"
          transform="translate(-0.002 -53.022)"
          fill="#4acc97"
        />
        <path
          id="Path_1203"
          data-name="Path 1203"
          d="M9.563,60.589v.063a.5.5,0,0,1-.5.5H0V57.474a.5.5,0,0,1,.5-.5H.759V58.28a2.305,2.305,0,0,0,2.3,2.309Z"
          transform="translate(0 -53.021)"
          fill="#38c487"
        />
        <path
          id="Path_1204"
          data-name="Path 1204"
          d="M0,117.322l1.517,1.737v-1.737Z"
          transform="translate(-0.002 -109.187)"
          fill="#00b564"
        />
        <path
          id="Path_1205"
          data-name="Path 1205"
          d="M28.826,118.413h-6.86v-1.092h7.529v.421a.67.67,0,0,1-.669.671"
          transform="translate(-20.448 -109.187)"
          fill="#e2e1de"
        />
        <path
          id="Path_1206"
          data-name="Path 1206"
          d="M20.707,67.09v-.48a.72.72,0,0,0,.2.032.269.269,0,0,0,.226-.088.518.518,0,0,0,.068-.308V64.123h-.393v-.447h1.437v.447h-.358v2.148a.887.887,0,0,1-.213.654.77.77,0,0,1-.568.212,1.872,1.872,0,0,1-.4-.048"
          transform="translate(-19.276 -59.261)"
          fill="#fff"
        />
        <path
          id="Path_1207"
          data-name="Path 1207"
          d="M46.964,65.875v.447h-1.39v-.447h.336V64.122h-.336v-.447h1.39a1.113,1.113,0,0,1,.75.228.771.771,0,0,1,.265.615.859.859,0,0,1-.093.4.715.715,0,0,1-.238.276.9.9,0,0,1-.332.131,2.585,2.585,0,0,1-.468.035H46.6v.514ZM46.6,64.92h.1a.624.624,0,0,0,.454-.115.417.417,0,0,0,.1-.286.424.424,0,0,0-.07-.246.289.289,0,0,0-.161-.126,1.31,1.31,0,0,0-.3-.024H46.6Z"
          transform="translate(-42.425 -59.26)"
          fill="#fff"
        />
        <path
          id="Path_1208"
          data-name="Path 1208"
          d="M85.938,64.872v-.447h1.236a1.446,1.446,0,0,1-.174.763,1.186,1.186,0,0,1-.5.443,1.669,1.669,0,0,1-.742.162,1.479,1.479,0,0,1-1.1-.384,1.327,1.327,0,0,1-.382-.976,1.381,1.381,0,0,1,.19-.726,1.223,1.223,0,0,1,.51-.475,1.537,1.537,0,0,1,.7-.16,1.215,1.215,0,0,1,.827.259v-.217h.448v.94H86.5a.658.658,0,0,0-.279-.375.8.8,0,0,0-.448-.137.728.728,0,0,0-.537.224.841.841,0,0,0-.222.619.963.963,0,0,0,.226.69.731.731,0,0,0,.55.237.65.65,0,0,0,.4-.116.6.6,0,0,0,.218-.324Z"
          transform="translate(-78.452 -58.699)"
          fill="#fff"
        />
        <path
          id="Path_1209"
          data-name="Path 1209"
          d="M57.037,177.035H42.9a.407.407,0,0,1-.406-.408V165.364a.407.407,0,0,1,.406-.408H57.037a.407.407,0,0,1,.406.408v11.263a.407.407,0,0,1-.406.408"
          transform="translate(-39.559 -153.519)"
          fill="#7cc8fb"
        />
        <path
          id="Path_1210"
          data-name="Path 1210"
          d="M57.444,208.843s-3.57-2.222-6.649-.849a4.85,4.85,0,0,0-1.019.628c-.792.623-3.89,2.787-7.28,1.471v2.776H57.444Z"
          transform="translate(-39.56 -193.156)"
          fill="#00b564"
        />
        <path
          id="Path_1211"
          data-name="Path 1211"
          d="M42.5,208.843s3.57-2.222,6.649-.849a4.85,4.85,0,0,1,1.019.628c.792.623,3.89,2.787,7.28,1.471v2.776H42.5Z"
          transform="translate(-39.56 -193.156)"
          fill="#4acc97"
        />
        <path
          id="Path_1212"
          data-name="Path 1212"
          d="M56.907,288.131H43.034a.539.539,0,0,1-.538-.539v-3.263H57.444v3.263a.539.539,0,0,1-.538.539"
          transform="translate(-39.56 -264.615)"
          fill="#41c0e3"
        />
      </g>
    </Icon>
  );
}
