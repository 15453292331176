import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Course } from 'src/models/cart/Course';
import { formatPrice } from 'src/utils/formatPrice';
import { getCoursePriceStructure } from 'src/utils/getCoursePriceStructure';
import { month } from 'src/constants/months';

interface CourseItemProps {
  course: Course;
  isPrepaid: boolean;
  duration: number | undefined;
  startYear: number | undefined;
  startMonth: number | undefined;
}

function CourseItem({
  course,
  isPrepaid,
  duration,
  startMonth,
  startYear,
}: CourseItemProps): JSX.Element {
  const { t } = useTranslation();
  const { hasDiscount, discountPrice, originalPrice } = getCoursePriceStructure(course);
  const price = hasDiscount ? discountPrice : originalPrice;

  return (
    <Flex mt="4" borderBottomWidth="1px" borderBottomColor="white" pb="4">
      <Box w="70%" mr="5%">
        <Text w="100%">
          {course.courseName} - {course.form.name} - {format(parseISO(course.startDate), 'yyyy')} -{' '}
          Cikgu {course.teacher.firstname} {course.teacher.lastname}
        </Text>
        {startMonth && startYear && (
          <Text mt={5}>
            {t('starting_date')}: {t(month[startMonth - 1].toLowerCase())} {startYear}
          </Text>
        )}
      </Box>
      <Text w="25%">
        {isPrepaid
          ? formatPrice(String(price * Number(duration)))
          : formatPrice(String(price)) + '/ month'}
      </Text>
    </Flex>
  );
}

export default CourseItem;
