import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Flex, Button } from '@chakra-ui/react';

import { AppleIcon } from 'src/icons';
import { AppleResponse } from 'src/models/SocialLogin';
import { loginWithApple } from 'src/redux/auth/actions';

interface SignInWithAppleProps {
  setFirstLogin: () => void;
}

function SignInWithApple({ setFirstLogin }: SignInWithAppleProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    window.auth.signInWithApple();
    window.auth.appleResponse((response: AppleResponse) => {
      setFirstLogin();
      dispatch(
        loginWithApple({
          code: response.data,
          role: 'student',
        }),
      );
    });
  };

  return (
    <Button
      minW="fit-content"
      w="300px"
      color="white"
      bg="black"
      fontWeight="regular"
      rounded="full"
      justifyContent="flex-start"
      _hover={{ bg: 'gray.800' }}
      _active={{ bg: 'gray.800' }}
      onClick={onClick}
    >
      <AppleIcon />
      <Flex justifyContent="center" flex="1">
        {t('sign_in_with', { provider: 'Apple' })}
      </Flex>
    </Button>
  );
}

export default SignInWithApple;
