import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function TikTokIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 23.264 26.777" {...props}>
      <path
        id="Icon_simple-tiktok"
        data-name="Icon simple-tiktok"
        d="M14.587.022C16.048,0,17.5.011,18.949,0A6.949,6.949,0,0,0,20.9,4.653a7.868,7.868,0,0,0,4.731,2v4.5a11.942,11.942,0,0,1-4.686-1.082,13.785,13.785,0,0,1-1.807-1.038c-.011,3.258.011,6.516-.022,9.762a8.522,8.522,0,0,1-1.506,4.4,8.312,8.312,0,0,1-6.594,3.581,8.135,8.135,0,0,1-4.552-1.149,8.414,8.414,0,0,1-4.072-6.371c-.022-.558-.033-1.116-.011-1.662a8.4,8.4,0,0,1,9.74-7.453c.022,1.651-.045,3.3-.045,4.954A3.828,3.828,0,0,0,7.19,17.45a4.426,4.426,0,0,0-.156,1.8,3.8,3.8,0,0,0,3.9,3.2,3.748,3.748,0,0,0,3.091-1.8,2.574,2.574,0,0,0,.457-1.183c.112-2,.067-3.983.078-5.98.011-4.5-.011-8.981.022-13.467Z"
        transform="translate(-2.369)"
        fill="#fff"
      />
    </Icon>
  );
}
