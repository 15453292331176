import React from 'react';
import { Progress, ProgressProps } from '@chakra-ui/react';

interface ProgressBarProps extends ProgressProps {
  value: number;
}

function ProgressBar({ value, ...props }: ProgressBarProps): JSX.Element {
  let color = '';

  if (value <= 30) {
    color = 'progressRed';
  } else if (value <= 65) {
    color = 'progressYellow';
  } else if (value <= 99) {
    color = 'progressBlue';
  } else {
    color = 'progressGreen';
  }

  return <Progress value={value} colorScheme={color} borderRadius="10px" size="xs" {...props} />;
}

export default ProgressBar;
