import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function UploadIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3.5 -3.5)">
        <path
          id="Path_1214"
          data-name="Path 1214"
          d="M18.5,22.5v3.111a1.556,1.556,0,0,1-1.556,1.556H6.056A1.556,1.556,0,0,1,4.5,25.611V22.5"
          transform="translate(0 -8.667)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1215"
          data-name="Path 1215"
          d="M18.278,8.389,14.389,4.5,10.5,8.389"
          transform="translate(-2.889)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1216"
          data-name="Path 1216"
          d="M18,4.5v9.333"
          transform="translate(-6.5)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
