import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Flex,
  Spacer,
  Heading,
  Input,
  Button,
  Text,
  Box,
  Checkbox,
  Stack,
  VStack,
  Img,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { HideIcon, ShowIcon } from 'src/icons';
import { login, logout, clearStatus, refreshToken, getUserDetails } from 'src/redux/auth/actions';
import {
  selectLoginStatus,
  selectRememberMe,
  selectForgetPasswordMessage,
  selectMoodleSession,
  selectLoginLoading,
  selectLoginErrorMessage,
  selectLoginStatusCode,
  selectLoginRole,
  selectUserDetailsStatusCode,
  selectAccountVerified,
} from 'src/redux/auth/selectors';
import TavisLogo from 'src/images/auth/TavisLogo.png';
import Image from 'src/images/auth/Image.png';
import SignInWithGoogle from 'src/components/SocialLogin/SignInWithGoogle';
import SignInWithApple from 'src/components/SocialLogin/SignInWithApple';
import SignInWithFacebook from 'src/components/SocialLogin/SignInWithFacebook';
import ForgotPassword from './ForgotPassword';
import { selectLanguage } from 'src/redux/language/selectors';
import usePageTracking from '../PageTracking';

function Login(): JSX.Element {
  usePageTracking('login');
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [firstLogin, setFirstLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const getRefreshToken = useRef(false);

  const loginStatus = useSelector(selectLoginStatus);
  const loginLoading = useSelector(selectLoginLoading);
  const loginStatusCode = useSelector(selectLoginStatusCode);
  const errorMessage = useSelector(selectLoginErrorMessage);
  const rememberMeStatus = useSelector(selectRememberMe);
  const forgetPasswordMessage = useSelector(selectForgetPasswordMessage);
  const moodleSession = useSelector(selectMoodleSession);
  const loginRole = useSelector(selectLoginRole);
  const userDetailsStatusCode = useSelector(selectUserDetailsStatusCode);
  const accountVerified = useSelector(selectAccountVerified);

  const language = useSelector(selectLanguage);

  useEffect(() => {
    void i18n.changeLanguage('en');
  }, [dispatch]);

  useEffect(() => {
    if (forgetPasswordMessage) {
      window.messageBox.alertBox(forgetPasswordMessage);
      dispatch(clearStatus());
    }
  }, [dispatch, forgetPasswordMessage]);

  useEffect(() => {
    if (userDetailsStatusCode === 200) {
      dispatch(refreshToken());
      setFirstLogin(true);
    } else if (userDetailsStatusCode === 401) {
      dispatch(clearStatus());
      setFirstLogin(false);
      getRefreshToken.current = false;
    }
  }, [userDetailsStatusCode]);

  useEffect(() => {
    if (moodleSession) {
      window.cookies.setCookies(moodleSession);
    }

    if (!firstLogin && !rememberMeStatus) {
      dispatch(logout());
    } else if (loginStatus && !getRefreshToken.current && !firstLogin) {
      dispatch(getUserDetails());
      getRefreshToken.current = true;
    } else if (loginStatus && firstLogin) {
      if (loginRole === 'student') {
        void i18n.changeLanguage(language);
        navigate('/dashboard');
      } else {
        setFirstLogin(false);
        dispatch(clearStatus());
        window.messageBox.alertBox('You do not have permission to access it');
      }
    }
  }, [
    dispatch,
    loginRole,
    loginStatus,
    rememberMeStatus,
    firstLogin,
    navigate,
    moodleSession,
    language,
  ]);

  useEffect(() => {
    if (
      (loginStatusCode === 422 || accountVerified === false) &&
      getRefreshToken.current === false
    ) {
      navigate('email-verification', {
        state: { email: email },
      });
    }
  }, [loginStatusCode, accountVerified]);

  const onClickLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setFirstLogin(true);

    dispatch(
      login({
        email,
        password,
        role: 'student',
        rememberMe,
      }),
    );
  };

  return (
    <Flex w="100vw" h="100vh">
      <Stack
        direction="column"
        align="center"
        justify="center"
        spacing={20}
        w="40%"
        h="100%"
        backgroundColor="white"
        overflow="hidden"
      >
        <Img src={TavisLogo} />
        <Img src={Image} />
      </Stack>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{ width: '60%', maxWidth: '60%', backgroundColor: '#65CD7D' }}
      >
        <Flex h="100%" w="100%">
          <VStack color="white" spacing={8} m="auto">
            <Heading as="h1" fontSize={34} fontWeight="bold">
              {`${t('welcome_to_tavis')}`}
            </Heading>
            <Box as="form" onSubmit={onClickLogin} minW="500px" maxW="700px">
              <Text fontSize={14} fontWeight="bold" ml={3} mb={2}>
                {t('email')}
              </Text>
              <Input
                required
                type="email"
                mb={5}
                bg="white"
                w="100%"
                color="black"
                borderRadius="full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Text fontSize={14} fontWeight="bold" ml={3} mb={2}>
                {t('password')}
              </Text>
              <InputGroup mb={5}>
                <Input
                  required
                  type={showPassword ? 'text' : 'password'}
                  bg="white"
                  color="black"
                  borderRadius="full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ShowIcon /> : <HideIcon color="black" />}
                </InputRightElement>
              </InputGroup>
              {errorMessage && (
                <Text color="red" mb={5} maxW="500px">
                  {errorMessage}
                </Text>
              )}
              <Flex justifyContent="center">
                <Button
                  isLoading={loginLoading}
                  p={5}
                  w="50%"
                  bg="none"
                  fontSize={27}
                  fontWeight="bold"
                  borderRadius="full"
                  border="4px solid white"
                  type="submit"
                  _active={{ backgroundColor: '#2F9B50' }}
                  _hover={{ backgroundColor: '#2F9B50' }}
                >
                  {t('sign_in')}
                </Button>
              </Flex>
              <Flex justifyContent="center" mt="4">
                <Button
                  p="5"
                  w="50%"
                  bg="none"
                  fontSize={27}
                  fontWeight="bold"
                  borderRadius="full"
                  border="4px solid white"
                  _active={{ backgroundColor: '#2F9B50' }}
                  _hover={{ backgroundColor: '#2F9B50' }}
                  onClick={() => navigate('/register')}
                >
                  {t('create_account')}
                </Button>
              </Flex>
            </Box>
            <Box minW="500px" maxW="700px">
              <Flex w="100%" mb={12}>
                <Checkbox defaultChecked size="sm" onChange={() => setRememberMe(!rememberMe)}>
                  {t('remember_me')}
                </Checkbox>
                <Spacer />
                <ForgotPassword />
              </Flex>
              <VStack spacing={5} mb={6}>
                <SignInWithGoogle setFirstLogin={() => setFirstLogin(true)} />
                <SignInWithFacebook setFirstLogin={() => setFirstLogin(true)} />
                <SignInWithApple setFirstLogin={() => setFirstLogin(true)} />
              </VStack>
            </Box>
          </VStack>
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
}

export default Login;
