import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function ExcelIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1678" data-name="Rectangle 1678" width="20" height="26" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_377" data-name="Group 377" clipPath="url(#clip-path)">
        <path
          id="Path_1174"
          data-name="Path 1174"
          d="M21.754,4.42v20.8a.93.93,0,0,1-.961.895H3.08a.93.93,0,0,1-.961-.895V1.395A.93.93,0,0,1,3.08.5H17.545Z"
          transform="translate(-1.968 -0.467)"
          fill="#fff"
        />
        <path
          id="Path_1175"
          data-name="Path 1175"
          d="M21.754,4.42v20.8a.93.93,0,0,1-.961.895H3.08a.93.93,0,0,1-.961-.895V1.395A.93.93,0,0,1,3.08.5H17.545Z"
          transform="translate(-1.968 -0.467)"
          fill="none"
          stroke="#595959"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_1176"
          data-name="Path 1176"
          d="M204.911,4.42v20.8a.93.93,0,0,1-.961.895h-1.372a.93.93,0,0,0,.96-.895V4.42L199.33.5H200.7Z"
          transform="translate(-185.125 -0.467)"
          fill="#dddbdb"
        />
        <path
          id="Path_1177"
          data-name="Path 1177"
          d="M222.787,4.42h-3.249a.929.929,0,0,1-.961-.895V.5Z"
          transform="translate(-203 -0.467)"
          fill="#c6c6c6"
        />
        <path
          id="Path_1178"
          data-name="Path 1178"
          d="M19.128,284.93H.872A.844.844,0,0,1,0,284.117v-6.779H20v6.779a.844.844,0,0,1-.872.812"
          transform="translate(0 -258.93)"
          fill="#00b564"
        />
        <path
          id="Path_1179"
          data-name="Path 1179"
          d="M21.3.5H19.632l-1.323,2.03L16.986.5H15.321l2.156,3.308L15.321,7.117h1.665l1.323-2.03,1.323,2.03H21.3L19.141,3.809Z"
          transform="translate(-14.229 -0.467)"
          fill="#00b564"
        />
        <path
          id="Path_1180"
          data-name="Path 1180"
          d="M22.05,75.421v5.941H15.684v6.408H32.1V75.421Zm6.865.465H31.6v2.5H28.915Zm-3.682,8.441H22.549v-2.5h2.684Zm3.183,0H25.732v-2.5h2.684Zm-2.684-5.463h2.684v2.5H25.732Zm0-2.978h2.684v2.5H25.732Zm-3.183,0h2.684v2.5H22.549Zm0,2.978h2.684v2.5H22.549Zm-3.183,2.962H22.05v2.5H19.366Zm-3.183,0h2.684v2.5H16.183ZM18.867,87.3H16.183V84.8h2.684Zm3.183,0H19.366V84.8H22.05Zm3.183,0H22.549V84.8h2.684Zm3.183,0H25.732V84.8h2.684Zm3.183,0H28.915V84.8H31.6Zm0-2.978H28.915v-2.5H31.6Zm-2.684-5.463H31.6v2.5H28.915Z"
          transform="translate(-14.566 -70.415)"
          fill="#dddbdb"
        />
        <path
          id="Path_1181"
          data-name="Path 1181"
          d="M60.233,311.591v-.616h.576l.952-1.253-.952-1.16h-.537v-.616h2.047v.615h-.375l.549.642.5-.642h-.354v-.615H64.4v.616h-.543l-.927,1.2,1.023,1.216h.562v.616h-2.1v-.615H62.8l-.591-.719-.56.719h.4v.615Z"
          transform="translate(-55.94 -287.507)"
          fill="#fff"
        />
        <path
          id="Path_1182"
          data-name="Path 1182"
          d="M122.425,311.591v-.616h.56v-2.412h-.56v-.616h2.148v.616H124v2.412h1.156v-1.126h.762v1.742Z"
          transform="translate(-113.7 -287.507)"
          fill="#fff"
        />
        <path
          id="Path_1183"
          data-name="Path 1183"
          d="M175.771,311.076v-1.242h.664a.672.672,0,0,0,.191.5.6.6,0,0,0,.411.161.427.427,0,0,0,.3-.117.364.364,0,0,0,.124-.274.372.372,0,0,0-.121-.282,1.724,1.724,0,0,0-.442-.26,3.471,3.471,0,0,1-.921-.538.833.833,0,0,1-.243-.63.9.9,0,0,1,.321-.74,1.119,1.119,0,0,1,.728-.262.853.853,0,0,1,.723.339v-.3h.661v1.108h-.661a.6.6,0,0,0-.19-.4.542.542,0,0,0-.37-.14.458.458,0,0,0-.276.081.25.25,0,0,0-.112.212.312.312,0,0,0,.093.214,1.89,1.89,0,0,0,.543.286,4.672,4.672,0,0,1,.665.325,1.084,1.084,0,0,1,.367.377,1.048,1.048,0,0,1,.135.536.983.983,0,0,1-.338.813,1.232,1.232,0,0,1-.8.273.949.949,0,0,1-.793-.355v.31Z"
          transform="translate(-163.213 -286.992)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}
