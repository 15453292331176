import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Text,
  Table,
  Thead,
  Tr,
  Td,
  Divider,
  Tbody,
  Spinner,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NetworkErrorComponent from 'src/components/NetworkError';
import useSessionExpired from 'src/components/SessionExpired';
import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { RedoIcon } from 'src/icons';
import {
  getHomeworkQuestions,
  getHomeworkRecords,
  resetHomeworkRecords,
} from 'src/redux/homework/actions';
import {
  selectHomeworkRecords,
  selectHomeworkRecordsLoading,
  selectHomeworkRecordsNetworkError,
  selectHomeworkRecordsStatusCode,
  selectHomeworkQuestionsNetworkError,
  selectHomeworkQuestionsStatusCode,
  selectHomeworkQuestions,
  selectHomeworkRecordStatusCode,
  selectHomeworkRecordNetworkError,
} from 'src/redux/homework/selectors';
import Loader from 'src/components/Loader';
import usePageTracking from '../PageTracking';

function HomeworkRecords(): JSX.Element {
  usePageTracking('homework-records');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ homeworkId: string }>();
  const navigate = useNavigate();

  const records = useSelector(selectHomeworkRecords);
  const recordsStatusCode = useSelector(selectHomeworkRecordsStatusCode);
  const recordsNetworkError = useSelector(selectHomeworkRecordsNetworkError);
  const recordsLoading = useSelector(selectHomeworkRecordsLoading);

  const questions = useSelector(selectHomeworkQuestions);
  const recordStatusCode = useSelector(selectHomeworkRecordStatusCode);
  const recordNetworkError = useSelector(selectHomeworkRecordNetworkError);

  const questionStatusCode = useSelector(selectHomeworkQuestionsStatusCode);
  const questionNetworkError = useSelector(selectHomeworkQuestionsNetworkError);

  const clickButton = useRef(false);
  const [showRedoButton, setShowRedoButton] = useState(false);

  useEffect(() => {
    dispatch(getHomeworkRecords(Number(params.homeworkId)));

    return () => {
      dispatch(resetHomeworkRecords());
    };
  }, [dispatch]);

  useEffect(() => {
    if (records && records.length === 0) {
      navigate('/homework');
      window.messageBox.alertBox(t('no_records'));
    } else if (records) {
      for (let i = 0; i < records.length; i++) {
        if (records[i].endDate === null) {
          setShowRedoButton(false);
          return;
        }
      }
      setShowRedoButton(true);
    }
  }, [records]);

  useEffect(() => {
    if (clickButton.current && questions) {
      if (questions.length > 0) {
        navigate(`/homework/${params.homeworkId!}/${questions[0].id}`);
      } else {
        window.messageBox.alertBox(t('no_questions'));
        clickButton.current = false;
      }
    }
  }, [questions]);

  const showRecords = () => {
    if (recordsLoading) {
      return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      );
    } else if (records) {
      return (
        <Table w="100%">
          <Thead>
            <Tr>
              <TableHeader>{t('no')}</TableHeader>
              <TableHeader>{t('progress')}</TableHeader>
              <TableHeader textAlign="center">{t('date')}</TableHeader>
              <TableHeader>{t('actions')}</TableHeader>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td colSpan={4} border="none" p={0} pos="sticky" top="40px">
                <Divider borderColor="#707070" />
              </Td>
            </Tr>
            {records.map((item, index) => {
              return <TableBody key={item.id} record={item} index={index + 1} />;
            })}
          </Tbody>
        </Table>
      );
    }
  };

  const showTitle = () => {
    if (records && records.length > 0) {
      return records[0].homeworkName;
    }
    return '';
  };

  useSessionExpired(recordsStatusCode);
  useSessionExpired(questionStatusCode);
  useSessionExpired(recordStatusCode);
  if (recordsNetworkError || questionNetworkError || recordNetworkError)
    return <NetworkErrorComponent />;
  if (!records) {
    return (
      <DashboardShell>
        <BackTopbar title={showTitle()} pathname="/homework" />
        <Loader />
      </DashboardShell>
    );
  }

  const onClickRedo = () => {
    dispatch(getHomeworkQuestions(Number(params.homeworkId)));
    clickButton.current = true;
  };

  return (
    <DashboardShell>
      <BackTopbar title={showTitle()} pathname="/homework" />
      <Flex
        flex="1"
        boxShadow="0 0 5px gray"
        m={7}
        mt={5}
        borderRadius="10px"
        p={7}
        px={10}
        flexDirection="column"
      >
        <Flex>
          <Text fontSize={20} fontWeight="medium" mb={5}>
            {t('homework_records')}
          </Text>
          <Spacer />
          {showRedoButton && (
            <IconButton
              aria-label="Redo"
              icon={<RedoIcon boxSize="20px" />}
              bg="none"
              onClick={onClickRedo}
            />
          )}
        </Flex>
        <OverlayScrollbarsComponent
          style={{ flex: '1' }}
          options={{ scrollbars: { autoHide: 'scroll' } }}
        >
          {showRecords()}
        </OverlayScrollbarsComponent>
      </Flex>
    </DashboardShell>
  );
}

export default HomeworkRecords;
