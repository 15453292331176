import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Icon } from '@chakra-ui/react';
import { Props } from 'framer-motion/types/types';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/auth/actions';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

interface NavItemProps {
  icon: React.ElementType<Props>;
  to: string;
  children: React.ReactNode;
  onToggle?: () => void;
}

function NavItem({ icon, children, to, onToggle, ...rest }: NavItemProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = createBrowserHistory();

  const isCurrentPage = location.pathname.includes(to);

  useEffect(() => {
    if (onToggle && isCurrentPage && history.action !== 'REPLACE') {
      onToggle();
    }
  }, [location.pathname]);

  const onClick = () => {
    if (to === 'about') {
      window.openExternal.openAbout();
    } else if (to === 'logout') {
      const result = window.messageBox.confirmBox(t('logout_prompt'));
      if (result === 0) {
        dispatch(logout());
        navigate('/');
      }
    } else if (to) {
      navigate(to);
    }
  };

  return (
    <Flex
      align="center"
      mx="5"
      px="4"
      py="3"
      my="3"
      cursor="pointer"
      color="white"
      role="group"
      fontWeight="medium"
      transition=".15s ease"
      borderRadius="10px"
      onClick={onClick}
      aria-selected={isCurrentPage}
      _hover={{ bg: '#1479B3' }}
      _selected={{ background: '#1479B3' }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="2"
          boxSize="18px"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
}

export default NavItem;
