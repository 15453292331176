import React, { useEffect, useRef, useState } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Event } from 'src/models/Event';
import intervalToDuration from 'date-fns/intervalToDuration';
import isWithinInterval from 'date-fns/isWithinInterval';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

function ActiveEventCard(event: Event): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [masterTime, setMasterTime] = useState(event.serverTime);
  const masterTimeRef = useRef(event.serverTime);

  const today = new Date(masterTime * 1000);
  const startTime = new Date(event.start_time);
  const endTime = new Date(event.end_time);

  // Update class "starts in" countdown every 1 minute
  useEffect(() => {
    const timer = setInterval(() => {
      masterTimeRef.current = masterTimeRef.current + 60;
      setMasterTime(masterTimeRef.current);
    }, 60000);

    // clearing interval
    return () => clearInterval(timer);
  }, []);

  const showTime = () => {
    const interval = intervalToDuration({ start: today, end: startTime });
    if (
      !isWithinInterval(today, { start: startTime, end: endTime }) &&
      interval.hours != null &&
      interval.minutes != null
    ) {
      if ((interval.hours === 2 && interval.minutes === 0) || interval.hours < 2) {
        if (interval.hours > 0 && interval.minutes > 0) {
          return (
            <>{`${t('starts_in')} ${t('hour', { count: interval.hours })} ${t('minute', {
              count: interval.minutes,
            })}`}</>
          );
        } else if (interval.hours > 0) {
          return <>{`${t('starts_in')} ${t('hour', { count: interval.hours })}`}</>;
        } else if (interval.minutes > 0) {
          return <>{`${t('starts_in')} ${t('minute', { count: interval.minutes })}`}</>;
        }
      }
    }
  };

  const onClickEvent = () => {
    const interval = intervalToDuration({ start: today, end: startTime });
    if (interval.minutes != null && interval.hours != null) {
      const constraint = (interval.minutes > 10 && interval.hours === 0) || interval.hours > 0;
      if (isWithinInterval(today, { start: startTime, end: endTime }) || !constraint) {
        navigate(`/virtual-class/${event.eventId}`);
      }

      // Class hasn't started yet popup
      else {
        window.messageBox.alertBox(t('class_not_started'));
      }
    }
  };

  return (
    <Box
      fontSize={18}
      px={5}
      py={3}
      bg="#63FFE0"
      borderRadius={20}
      id="active"
      onClick={onClickEvent}
      cursor="pointer"
    >
      <Text fontWeight="bold" whiteSpace="nowrap">
        {event.courseName}
      </Text>
      <Text whiteSpace="nowrap" fontSize={16} fontWeight="semibold">
        {format(new Date(event.start_time), 'HH:mm') +
          ' - ' +
          format(new Date(event.end_time), 'HH:mm')}
      </Text>
      <Text whiteSpace="nowrap" fontWeight="semibold">
        Cikgu {event.teacherFirstname + ' ' + event.teacherLastname}
      </Text>
      <Text whiteSpace="nowrap" color="red" fontSize={16}>
        {showTime()}
      </Text>
    </Box>
  );
}

export default ActiveEventCard;
