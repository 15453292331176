import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addToCart } from 'src/redux/cart/actions';

interface BuyAndAddToCartButtonProps {
  id: number;
  onCheckout: {
    onCheckout: boolean;
    setOnCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  };
  disable: boolean;
}

function BuyAndAddToCartButton({
  id,
  onCheckout,
  disable,
}: BuyAndAddToCartButtonProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickAddToCart = () => {
    dispatch(
      addToCart({
        cartType: 'questionbank',
        instanceId: id.toString(),
      }),
    );
    onClose();
  };

  const onCheckoutNow = () => {
    onCheckout.setOnCheckout(true);
    onClickAddToCart();
  };

  return (
    <>
      <Button
        id="buyAndAddToCartButton"
        w="50%"
        fontSize={18}
        minW="fit-content"
        maxW="300px"
        bg="#161DFF"
        color="white"
        borderRadius={0}
        _hover={{ background: '#0000CA' }}
        _active={{ background: '#0000CA' }}
        onClick={onOpen}
        disabled={disable}
      >
        {t('buy_and_add_to_cart')}
      </Button>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={26} fontWeight="semibold">
            {t('please_choose')}
            <Divider borderColor="black" mt={3} />
          </ModalHeader>
          <ModalBody pb={6}>
            <HStack spacing={5} justifyContent="center">
              <Button
                fontWeight="medium"
                backgroundColor="#3007A3"
                color="white"
                onClick={onCheckoutNow}
                _hover={{ backgroundColor: '#000073' }}
                _active={{ backgroundColor: '#000073' }}
              >
                {t('checkout_now')}
              </Button>
              <Button
                background="linear-gradient(to right, #2E62B7 , #4F91FC)"
                color="white"
                fontWeight="medium"
                onClick={onClickAddToCart}
                _hover={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
                _active={{ background: 'linear-gradient(to right, #2E62B7 , #4F91FC)' }}
              >
                {t('add_to_cart')}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BuyAndAddToCartButton;
