import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function EditQuestionBankIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 13.5 13.5" {...props}>
      <g
        id="Icon_feather-edit-3"
        data-name="Icon feather-edit-3"
        transform="translate(-3.75 -3.568)"
      >
        <path
          id="Path_1061"
          data-name="Path 1061"
          d="M18,30h6"
          transform="translate(-7.5 -13.682)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1062"
          data-name="Path 1062"
          d="M13.5,4.753a1.366,1.366,0,0,1,2,0,1.541,1.541,0,0,1,0,2.1L7.167,15.617l-2.667.7.667-2.8Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </Icon>
  );
}
