import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BackIcon, NextIcon } from 'src/icons';

interface TopButtonProps {
  isNextQuestion: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

function TopButton({ isNextQuestion, isDisabled, onClick }: TopButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label={isNextQuestion ? t('next_question') : t('previous_question')}
      icon={isNextQuestion ? <NextIcon /> : <BackIcon />}
      bg="white"
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
}

export default TopButton;
