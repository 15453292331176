import React from 'react';
import { useLocation } from 'react-router-dom';

import DashboardShell from 'src/components/DashboardShell';
import BackTopbar from 'src/components/Topbar/BackTopbar';
import usePageTracking from '../PageTracking';

interface LocationState {
  url: string;
  filename: string;
}

function FileViewer(): JSX.Element {
  usePageTracking('view-reading-materials');
  let state = useLocation().state as LocationState;

  if (state) {
    window.localStorage.setItem('routeState', JSON.stringify(state));
  } else {
    const item = window.localStorage.getItem('routeState');
    if (item) {
      state = JSON.parse(item) as LocationState;
    }
  }

  const url = state.url;
  const filename = state.filename;

  let src = '';

  if (url.includes('docx') || url.includes('doc') || url.includes('xlsx') || url.includes('pptx')) {
    src = 'https://view.officeapps.live.com/op/embed.aspx?src=' + url;
  } else {
    src = `${url}#toolbar=0`;
    return (
      <DashboardShell>
        <BackTopbar title={filename} />
        <iframe src={src} width="100%" height="100%"></iframe>
      </DashboardShell>
    );
  }

  return (
    <DashboardShell>
      <BackTopbar title={filename} pathname="/resources/reading-materials" />
      <iframe src={src} width="100%" height="100%">
        This is an embedded{' '}
        <a target="_blank" rel="noreferrer" href="http://office.com">
          Microsoft Office
        </a>{' '}
        document, powered by{' '}
        <a target="_blank" rel="noreferrer" href="http://office.com/webapps">
          Office Online
        </a>
        .
      </iframe>
    </DashboardShell>
  );
}

export default FileViewer;
