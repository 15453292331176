export const translationEn = {
  // Month
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',

  // Weekdays short form
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',

  // Weekdays
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  // Login
  welcome_to_tavis: 'Welcome To Tavis!',
  sign_in: 'Sign In',
  sign_in_with: 'Sign in with {{provider}}',
  create_account: 'Create Account',
  remember_me: 'Remember me',
  email: 'Email',
  password: 'Password',
  forgot_password: 'Forgot Password?',
  join_tavis: 'Join tavis',
  dont_have_account_yet: "Don't have an account yet?",
  please_enter_your_email: 'Please enter your email',
  please_fill_up_this_field: 'Please fill up this field',
  please_enter_a_number: 'Please enter a number',
  valid_phone_number: 'Mobile phone numbers must be between 10 and 13 digits',
  wrong_email_format: 'This seems to be a wrong Email address!',

  // Register
  register: 'Register',
  register_with_us: 'Register with us now!',
  confirm_password: 'Confirm Password',
  date_of_birth: 'Date of Birth',
  gender: 'Gender',
  password_validation:
    'Password must be at least 8 characters long, with at least 1 lower case letter(s) and at least 1 digit(s)',
  same_password: 'Please ensure password and confirm password are the same',
  please_select: 'Please select',

  // Email verification
  verification_code: 'Verification Code',
  email_verification_instruction: 'Please enter the verification code sent to {{email}}',
  confirm: 'Confirm',
  resend_in_seconds: 'Resend in {{seconds}} seconds',
  resend: 'Tap here to resend',
  did_not_receive: "Didn't receive?",
  verify_code_error: 'Could not verify verification code. Please try again.',
  send_code_error: 'Could not send verification code to {{email}}. Please try again.',
  verification: 'Verification',
  skip: 'Skip',
  email_verification_instruction_2:
    'Please enter the verification code sent to <1>{{email}}</1> to continue using our platform.',
  resend_2: 'Click here to resend verification code.',
  please_verify_your_email: 'Please verify your email ({{email}})',

  // Common
  id: 'ID',
  ic: 'IC / Passport No.',
  language: 'Language',
  filter: 'Filter',
  subject: 'Subject',
  level: 'Level',
  price: 'Price',
  search: 'Search',
  length: 'Length',
  start_date: 'Start Date',
  tutor: 'Tutor',
  name: 'Name',
  firstname: 'First Name',
  lastname: 'Last Name',
  male: 'Male',
  female: 'Female',
  profile: 'Profile',
  payment: 'Payment',
  amount: 'Amount',
  total: 'Total',
  phone_number: 'Phone Number',
  category: 'Category',
  chapters: 'Chapters',
  questions: 'Questions',
  answer: 'Answer',
  question: 'Question',
  time: 'Time',
  description: 'Description',
  not_available: 'Not available',
  network_error: "You're offline.",
  session_expired: 'Session Expired, please log in again!',
  internet_connect_error: 'Check your internet and try again',
  retry: 'Retry',
  full_name: 'Full Name',
  phone_no: 'Phone No.',
  address: 'Address',
  cart: 'Cart',
  week_word: 'Week',
  weekdays_word: 'Weekdays',
  logout: 'Logout',
  logout_prompt: 'Are you sure you want to log out?',
  resend_code_successful: 'Verification code sent successfully',

  // Dynamic i18n
  week_zero: '{{count}} week',
  week_one: '{{count}} week',
  week_other: '{{count}} weeks',

  month_zero: '{{count}} month',
  month_one: '{{count}} month',
  month_other: '{{count}} months',

  lecture_zero: '{{count}} lecture',
  lecture_one: '{{count}} lecture',
  lecture_other: '{{count}} lectures',

  class_zero: '{{count}} class',
  class_one: '{{count}} class',
  class_other: '{{count}} classes',

  student_taught_zero: '{{count}} student taught',
  student_taught_one: '{{count}} student taught',
  student_taught_other: '{{count}} students taught',

  // Sidebar
  dashboard: 'Dashboard',
  resources: 'Resources',
  classes: 'Classes',
  question_bank: 'Question Bank',
  purchased: 'Purchased',
  settings: 'Settings',

  // Settings Sidebar
  notification_to_parents: 'Notification to Parents',
  about: 'About',

  // Sort
  alphabetical: 'Alphabetically',
  price_asc: 'Price - Ascending',
  price_des: 'Price - Descending',

  // Class
  add_class_instruction: 'Add classes of the same level (same colours) to get bundle discount.',
  featured: 'Featured',
  about_class: 'About this class',
  study_track: 'Study Track',
  add_to_cart: 'Add to Cart',
  buy_now: 'Buy now',
  subscribe: 'Subscribe',
  subscribe_info: 'Automatic monthly recurring payment - Only available for credit/debit cards.',
  buy_and_add_to_cart_info: 'Pay upfront for classes',
  no_classes_available: 'No classes found',

  // Dashboard
  no_classes: 'No classes',
  greetings: 'Hello {{name}}. Welcome Back!',
  previous_month: 'Previous Month',
  next_month: 'Next Month',
  homework_tracker: 'Homework Tracker',
  starts_in: 'Starts in',
  minute_zero: '{{count}} minute',
  minute_one: '{{count}} minute',
  minute_other: '{{count}} minutes',
  hour_zero: '{{count}} hour',
  hour_one: '{{count}} hour',
  hour_other: '{{count}} hours',
  class_not_started:
    'The class has not started yet. Please try again 5-10 minutes before the class.',

  // Prepaid
  pay_as_you_go: 'Pay-as-you-go',
  prepaid: 'Prepaid',
  please_select_month_for_class: 'Please select month(s) for class(es)',
  subtotal: 'Subtotal',
  total_savings: 'Total savings',
  promo_code: 'Promo code',
  grand_total: 'Grand Total',
  others: 'Others',
  people_also_buy: 'People also buy',
  duration: 'Duration',
  starting_month: 'Starting Month',
  starting_date: 'Starting Date',
  every: 'Every',
  payment_policy: 'Payment Policy',
  fee: 'Fee',
  number_of_months: 'No. of months',
  apply: 'Apply',

  // Subscription
  subscription: 'Subscription',
  please_select_starting_month: 'Please select starting month',
  monthly_savings: 'Monthly savings',
  monthly_recurring_payment: 'Monthly Recurring Payment',
  year: 'Year',
  month_word: 'Month',
  billed_instruction: 'Billed on 1st of each month',
  one_month_free_trial: '*1 month free trial',

  // My Purchases
  my_purchases: 'My Purchases',
  invoice_no: 'Invoice no.',
  order_date: 'Order Date',
  total_price: 'Total Price',
  no_purchases_yet: 'There are no purchases yet',
  no_purchases_free_trial_description:
    'Students who purchase subjects after {{date}} of each month will start their classes in the following month',
  browse_classes: 'Browse Classes',
  terminate: 'Terminate',
  terminate_subscription: 'Terminate Subscription',
  terminate_confirmation: 'Are you sure to terminate subscription?',

  // Payment
  payment_method: 'Payment method',

  // Profile
  upload_image: 'Upload Image',
  update_birthday_instruction: 'Birthday field is required',
  update_name_instruction: 'First name and last name are required',
  dob: 'D.O.B',

  // Question Bank
  no_question_bank_available: 'No question bank found',
  no_attempts: 'No attempts found',

  // Question Bank Details
  please_choose: 'Please Choose',
  buy_and_add_to_cart: 'Buy / Add to Cart',

  // Question bank records
  total_questions: 'Total Qs',
  correct: 'Correct',
  incorrect: 'Incorrect',
  answered: 'Answered',
  new: 'New',
  sort_by: 'Sort By',
  date_descending: 'Date descending',
  date_ascending: 'Date ascending',
  in_progress: 'In progress',
  done: 'Done',
  edit_name: 'Edit Name',
  delete: 'Delete',
  average_score: 'Avg Score',
  performance_tracker: 'Performance Tracker',
  question_bank_records: 'Question Bank Records',
  no: 'No.',
  scores: 'Scores',

  // Question Bank New Attempt
  // Step 1
  step_1: 'Step 1 of 4: Choose Chapters',
  choose_chapters_instruction: 'Choose chapters to include',
  choose_chapters_error: 'Please choose at least 1 chapter',
  select_all: 'Select All',
  unselect_all: 'Unselect All',
  next_step: 'Next Step',

  // Step 2
  step_2: 'Step 2 of 4: Question Types',
  select_questions_instruction: 'Select types of question to include',
  select_questions_error: 'Please select types of question',
  all_questions: 'All questions from chosen chapters',
  unattempted_questions: 'Unattempted questions',
  previously_incorrect: 'Previously incorrect',
  previous_step: 'Previous Step',

  // Step 3
  step_3: 'Step 3 of 4: Number of Questions',
  select_number_of_questions_instruction: 'How many questions do you want to practice?',
  maximum_questions_instruction: 'is the maximum number of questions available or allowed',

  // Step 4
  step_4: 'Step 4 of 4: Naming',
  enter_name_instruction: 'Enter a name for your question bank attempt:',
  enter_name_error: 'Please enter a name',
  launch_qb: 'Launch QB',

  // Performance tracker
  answered_questions: 'Answered Questions',
  performance_by_chapters: 'Performance by Chapters',
  all_chapters: 'All Chapters',

  // Question bank record details
  results: 'Results',
  show_only: 'Show Only',
  answers: 'Answers',
  view: 'View',
  all: 'All',
  no_answered_question: 'No answered questions found',

  // Purchased Question Bank
  score: 'Score',
  last_attempt: 'Last Attempt',
  actions: 'Actions',
  reattempt: 'Re-attempt',
  launch: 'Launch',
  start: 'Start',
  continue: 'Continue',
  review: 'Review',

  // Question
  self_marking: 'Self-marking',
  hide_answer: 'Hide Answer',
  show_answer: 'Show Answer',
  previous_question: 'Previous Question',
  next_question: 'Next Question',
  submit_answer: 'Submit Answer',
  no_chapter_name: 'No chapter name',
  bookmark_error: 'Question could not bookmark. Please try again later',
  save_answer_error: 'Could not save answer. Please try again later',
  submit_marks: 'Submit marks',

  // Question End Session
  end_session: 'End Session',
  confirm_end_session: 'Confirm End Session?',
  end_session_text:
    'Ending session will show summary of questions attempted. Please make sure all subjective questions are marked for accurate scoring.',
  end_session_error: 'Could not end session!',

  // End Session
  questions_unmarked: 'Questions Unmarked',
  end: 'End',
  cancel: 'Cancel',

  // Question bank summary
  question_bank_summary: 'Question Bank Summary',

  // Homework summary
  homework_summary: 'Homework Summary',

  // Question bank and homework summary
  no_of_questions: 'No. of Questions',
  questions_answered: 'Questions Answered',
  questions_unanswered: 'Questions Unanswered',
  end_date: 'End Date',
  total_time: 'Total Time',

  // Resources
  reading_materials: 'Reading Materials',
  recorded_classes: 'Recorded Classes',
  no_reading_materials: 'No reading materials found',
  no_recorded_classes: 'No recorded classes',
  watch_replay: 'Watch Replay',
  successful: 'Successful',
  browse: 'Browse',

  // Homework
  question_list: 'Question List',
  homework: 'Homework',

  // Homework records
  progress: 'Progress',
  homework_records: 'Homework Records',
  date: 'Date',
  no_records: 'No homework records found.',

  // Homework record
  attempt_details: 'Attempt Details',

  // Homework tracker
  due: 'Due',
  submitted: 'Submitted',
  no_homework: 'No homework today!',
  late: 'Late',
  details: 'Details',
  late_submission: 'Late Submission',
  due_in_month_zero: 'Due in {{count}} month',
  due_in_month_one: 'Due in {{count}} month',
  due_in_month_other: 'Due in {{count}} months',
  due_in_week_zero: 'Due in {{count}} week',
  due_in_week_one: 'Due in {{count}} week',
  due_in_week_other: 'Due in {{count}} weeks',
  due_in_day_zero: 'Due in {{count}} day',
  due_in_day_one: 'Due in {{count}} day',
  due_in_day_other: 'Due in {{count}} days',
  due_today: 'Due Today',

  // Homework and Question Bank
  no_questions: 'No questions added yet',

  // Add to cart
  added_to_cart: 'Item added to cart',
  add_to_cart_error: 'Item could not add to cart. Please try again later',

  // Payment
  checkout_now: 'Checkout Now',
  billing_info: 'Billing Info',
  add_to_subs: 'Add to Subscription',

  // Parents notification
  please_enter_your_password: 'Please enter your password',
  please_enter_your_new_password: 'Please enter your new password',
  verify: 'Verify',
  reset_password: 'Reset Password',
  verification_instruction: 'Please enter 6-digit verification code sent to your email.',
  submit: 'Submit',
  optional: 'Optional',
  save: 'Save',
  parents_email: "Parent's Email",
  parents_name: "Parent's Name",
  parents_mobile_number: "Parent's Mobile Number",
  students_name: "Student's Name",
  edit_details: 'Edit Details',
  are_you_sure_to_cancel_notification: 'Are you sure to cancel notification?',
  cancel_notification: 'Cancel Notification',
  verify_email: 'Verify Email',
  resend_code: 'Resend Code',
  unverified_email: 'Unverified email',
  resend_verification_code: 'Resend Verification Code',
  residence: 'State: (Eg: Kuala Lumpur)',
  parents_notification_agreement:
    "Would you like to receive a copy of the student's assessment, attention, and attendance report? Reports will be prepared and send to students fortnightly.",
  for_parents: ' *For parents (optional)',

  // Change language
  failed_to_change_language: 'Failed to change language',
  choose_language: 'Choose Language',

  // Billing info
  items: 'Item(s)',
  street: 'Street address, apartment, suite, etc',
  city: 'City',
  postcode: 'Postcode',
  state: 'State',

  // Purchase details
  purchase_details: 'Purchase Details',
  order_summary: 'Order Summary',
  bundle_discount: 'Bundle Discount',
  total_summary: 'Total Summary',
  terminate_subscription_successful: 'Subscription payment terminated successfully',
  total_monthly_recurring: 'Total Monthly Recurring',
  paid_by: 'Paid by',
  total_fee: 'Total Fee',

  // Course details:
  enrol_for_free: 'Enrol For Free',
  enrol_for_free_successful: 'You have enrolled successfully',
  subscribe_now: 'Subscribe Now',
  free_trial_30_days: 'Free Trial',
  start_1_month_trial: 'Start Your 1 Month Free Trial',
  try_course_for_free: 'Try {{name}} free.',
  add_to_trial_list: 'Add to trial list',
  free_trial_information:
    "*After 1 month of free trial, you will be charged with the class(es)' stated price. Cancel at any time to stop future charges.",
  go_to_cart: 'Go to cart',
  add_more_classes: 'Add more classes',

  // Contact Us
  contact_us: 'Contact Us',
  get_in_touch: 'Get in touch',
  message: 'Message',
  more_info: 'More Info',
  tavis_showroom: 'Tavis showroom',
  social_media: 'Social Media',
  privacy_policy: 'Privacy Policy',
  customer_service_information:
    'When you contact Customer Service, your personal data will be processed in accordance with our',
  message_sent: 'The message is sent successfully',
  upload_from_computer: 'Upload from computer',
  max_file_size: 'Attachment cannot be greater than 10 MB',
  incorrect_captcha: 'Incorrect captcha, please try again.',

  // Onboarding
  welcome_to_tavis_community: 'Welcome to the Tavis Community!',
  complete_sign_up_process_instruction:
    'To complete the sign up process, please complete the information below:',
  student_details: "Student's Details",
  mobile_number: 'Mobile Number',
  current_level_of_study: 'Current Level of Study',
  email_address: 'Email Address',
  parents_details: 'Parent’s Details (emergency contact)',
  user_agreement: 'User Agreement',
  user_agreement_details: 'By completing sign up, you agree to the Tavis',
  terms_of_service: 'Terms of Service',
  and: 'and',
  finish: 'Finish',
  reference_code: 'Reference Code: (if any)',

  // Exam
  exam: 'Exam',
  upcoming: 'Upcoming',
  past: 'Past',
  test_id: 'Test ID',
  time_remaining: 'Time Remaining',
  confirm_end_exam: 'Confirm End Exam',
  end_exam_description:
    'Ending exam will submit all questions attempted, questions without selections would be marked as incorrect.',
  questions_without_selections: 'Questions without selections',
};
