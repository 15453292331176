import React from 'react';
import { Tr, Td, TableRowProps } from '@chakra-ui/react';

interface TableBodyProps extends TableRowProps {
  name: string;
  answered: number;
  correct: number;
  score: number;
}

function TableBody({ name, answered, correct, score, ...props }: TableBodyProps): JSX.Element {
  return (
    <Tr fontWeight="medium" bg="#F8F8F8" {...props}>
      <Td borderColor="#D1D1D1" pl="60px">
        {name}
      </Td>
      <Td borderColor="#D1D1D1" textAlign="center">
        {answered}
      </Td>
      <Td borderColor="#D1D1D1" textAlign="center">
        {correct}
      </Td>
      <Td borderColor="#D1D1D1" textAlign="center">
        {score.toFixed(2)}%
      </Td>
    </Tr>
  );
}

export default TableBody;
